import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingContext, PreferencesContext, SizeContext, UserContext } from "../../../context";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";
import { Button, ButtonBase, Divider, FormControlLabel, Link, Modal, Switch, useTheme } from "@mui/material";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from '../login/firebase'
import google from '../../../static/google.png'
import successLottie from '../../../static/success.json'

import { 
    FormControl, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    OutlinedInput, 
    TextField, 
    Typography 
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { passwordStrength } from 'check-password-strength'
import PinInput from "react-pin-input";
import Lottie from "react-lottie-player";
import LogoutIcon from '@mui/icons-material/Logout';

function formatarNome(nome) {
    if (!nome) return nome
    let palavras = nome.toLowerCase().split(' ');

    // Capitalize a primeira letra de cada palavra
    for (let i = 0; i < palavras.length; i++) {
        palavras[i] = palavras[i].charAt(0).toUpperCase() + palavras[i].slice(1);
    }

    // Junte as palavras novamente para formar o nome formatado
    let nomeFormatado = palavras.join(' ');

    return nomeFormatado;
}

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

export default function Verify (props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [verify, setVerify] = useState({})
    const [state, setState] = useState({})
    const [pass, setPass] = useState(false)
    const [application, setApplication] = useState({})

    const load = ( auth = false ) => {
        let unique_id = searchParams.get('unique_id')
        let key = searchParams.get('key') 
        let data = searchParams.get('data')
        let client_id = searchParams.get('client_id')

        if (client_id) client_id = decodeURIComponent(client_id)

        setLoading(true)
        axios
        .get(`${api}/client/integration/verify?${unique_id ? 'unique_id='+encodeURIComponent(unique_id) : 'data='+encodeURIComponent(data)+'&key='+encodeURIComponent(key)}`,{
            headers: {
                Authorization: auth ? user?.token : undefined,
                client_id
            }
        })
        .then(response => {
            setVerify(response.data || {})
            setState({
                verification: Object.entries(response.data?.verification || {}).reduce((acumulator, next) => (acumulator = {...acumulator, [next[0]]: typeof next[0] === 'string' ? '' : next[0]}),{}),
                name: response.data?.name,
                auth,
                openAuth: false,
                confirm: false
            })
        })
        .catch(err => {
            if ( err?.response?.data?.require_verification !== undefined) {
                setVerify(err.response.data || {})
                return setState({
                    verification: Object.entries(err.response.data?.verification || {}).reduce((acumulator, next) => (acumulator = {...acumulator, [next[0]]: typeof next[0] === 'string' ? '' : next[0]}),{}),
                    name: err.response.data?.name,
                    auth,
                    openAuth: false,
                    confirm: false
                })
            }
            toast.error(err?.response?.data || err?.response?.data?.message)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        let idaplicacao = searchParams.get('idaplicacao')
        let idaplicacaodispositivo = searchParams.get('idaplicacaodispositivo') 
        let unique_id = searchParams.get('unique_id')
        let data = searchParams.get('data')

        if (idaplicacao) idaplicacao = decodeURIComponent(idaplicacao)
        if (idaplicacaodispositivo) idaplicacaodispositivo = decodeURIComponent(idaplicacaodispositivo)
        
        if (!unique_id && !data) load(true)
        else if ( !user?.token ) load()
        else setState({...state, confirm: true})

        let url = (idaplicacao || idaplicacaodispositivo) ? `?${idaplicacao ? 'idaplicacao='+idaplicacao : 'idaplicacaodispositivo='+idaplicacaodispositivo}` : ''

        axios
        .get(`${api}/preferences/application${url}`)
        .then(response => setApplication(typeof response.data === 'string' ? {} : response.data))
        .catch(err => {})
    },[])

    const theme = useTheme()
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
        'login_hint': 'user@example.com'
    });

    const googleLogin = () => {
        setLoading(true)
        signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;

            setState({...state,...user})

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            toast.error(errorMessage)
        })
        .finally(() => setLoading(false))
    }

    const validate = () => {
        if ( !state.accessToken && state.password !== state.password_) return

        setState({...state, confirm: false})
        setLoading(true)
        axios
        .post(`${api}/client/integration/verify`,{
            ...state,
            unique_id: verify.unique_id
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            if (data.message) {
                load(true)
                return toast.success(data.message)
            }
            if (!data.message) setUser(data)
            localStorage.setItem('_exp',`${data.exp}.${Date.now()}`)
            setState({...state, openAuth: true, confirm: false})
        })
        .catch(err => {
            if ( err?.response?.data?.blocked) {
                return toast.error('Bloqueado, entre em contato com o setor responsável')
            }

            if ( err?.response?.data?.noAuth) {
                return toast.error(`${err?.response?.data?.attemps_left} tentativas restantes`)
            }

            toast.error(err?.response?.data?.message || err?.response?.data)
        })
        .finally(() => setLoading(false))
    }

    const enable = () => {
        setLoading(true)
        axios
        .put(`${api}/client`,{
            pin: state.pin,
            compra_unificada: true
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            toast.success('Autenticação unificada habilitada')
            load(true)
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
    }

    const navigate = useNavigate()

    return (
        <div
        style={{
            width:'100%',
            minHeight:'100vh',
            display:"flex",
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Modal
            open={state.confirm}
            onClose={() => setState({...state, confirm: false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                    maxHeight:'80vh',
                    overflowY:'auto'
                }}
                >
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'18px'
                    }}
                    >
                        Esse dispositivo está autenticado como {user.nome}
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'15px'
                    }}
                    >
                        {user.email}
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Estamos validando a conta correta ?
                    </Typography>

                    <div
                    style={{
                        marginTop:'10px',
                        display:"flex",
                        justifyContent:'space-between'
                    }}>
                        <Button
                        color='success'
                        sx={{
                            width:'calc(50% - 5px)'
                        }}
                        variant='contained'
                        onClick={() => load(true)}
                        >
                            sim
                        </Button>
                        <Button
                        color='error'
                        sx={{
                            width:'calc(50% - 5px)'
                        }}
                        variant='outlined'
                        onClick={() => load(false)}
                        >
                            Não
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
            open={state.open}
            onClose={() => setState({...state, open: false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                    maxHeight:'80vh',
                    overflowY:'auto'
                }}
                >
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'18px'
                    }}
                    >
                        Para a sua própria segurança, {preferences.nome} adicionou uma camada de verificação na sua conta.
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Para concluir o processo de criação da sua conta, você precisa escolher um E-mail válido e uma senha segura. (Ou entrar usando a conta Google)
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        {preferences.nome} também solicitou alguns dados de validação, esses dados já foram previamente cadastrados e serão utilizados para comparar com os dados que 
                        você nos enviará nessa verificação.
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        É importante que os dados de validação que serão enviados sejam idênticos aos dados já cadastrados por {preferences.nome} (exceto letras maiúsculas, minúsculas, acentos e caracteres especiais), todos os campos terão a resposta
                        camuflada com "*****", que ajudará com o tamanho.
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Você terá apenas 3 tentativas, excedido o número máximo, seu CPF será bloqueado para a integração com {preferences.nome} e somente o setor responsável em {preferences.nome} poderá desbloquear.
                    </Typography>
                    <Button
                    fullWidth
                    sx={{marginTop:'10px'}}
                    onClick={() => setState({...state, open: false})}
                    >
                        Entendi
                    </Button>
                </div>
            </Modal>

            <Modal
            open={state.openAuth}
            onClose={() => setState({...state, openAuth: false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                    maxHeight:'80vh',
                    overflowY:'auto'
                }}
                >
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'18px'
                    }}
                    >
                        Deseja habilitar a autenticação inteligente ?
                    </Typography>
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Com a autenticação inteligente é possível acessar seus dados de QUALQUER lugar utilizando seu CPF e seu PIN.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">Como vou usar ?</Divider>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Em compras físicas, é possível comprar usando seus cartões ( crédito / débito ) e benefícios ( convênios etc ) cadastrados na nuvem, 
                        basta inserir seu CPF e seu PIN na finalização da compra em qualquer loja física parceira.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        É possível consultar saldos e compras.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        ... E qualquer outro recurso futuro que não solicite E-mail e senha.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">É seguro ?</Divider>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Serão listados somente os cartões e benefícios que forem cadastrados na conta.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        A autenticação inteligente tem um limite de 3 tentativas, após isso será bloqueado, enviados avisos ao responsável da conta (Whatsapp e E-mail), e somente 
                        o responsável poderá desbloquear através do E-commerce > Menu > Minha conta.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        O responsável poderá alterar o PIN a qualquer momento através do E-commerce > Menu > Minha conta.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">Aviso</Divider>

                    <Typography
                    sx={{
                        // color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    color='error'
                    >
                        Para fazer compras usando benefícios, convênios, cartões virtuais etc, por canais fora da loja virtual ( Totens, caixas etc ), a autenticação inteligente 
                        deverá estar ativada
                    </Typography>

                    <FormControlLabel 
                    sx={{color:'text.primary',marginBottom:'10px'}}
                    control={<Switch 
                    checked={state.entendi}
                    defaultChecked={user.entendi}
                    onChange={({target}) => setState({...state, entendi: target.checked})}
                    />} label="Li e entendi" /> 

                    <PinInput
                    length={5}
                    focus
                    disabled={!state.entendi}
                    secret={true}
                    type="numeric"
                    onChange={data => setState({...state, pin: data})}
                    inputStyle={{color:theme.palette.mode === 'dark' ? '#fff' : '#000'}}
                    />
                    <Typography
                    sx={{
                        color:'text.secondary',
                        fontSize:'12px'
                    }}
                    color='error'
                    >
                        Cadastre um PIN
                    </Typography>
                    <div
                    style={{
                        marginTop:'10px',
                        display:"flex",
                        justifyContent:'space-between'
                    }}>
                        <Button
                        color='success'
                        sx={{
                            width:'calc(50% - 5px)'
                        }}
                        variant='contained'
                        disabled={!state.entendi}
                        onClick={enable}
                        >
                            Ativar
                        </Button>
                        <Button
                        color='error'
                        sx={{
                            width:'calc(50% - 5px)'
                        }}
                        variant='outlined'
                        disabled={!state.entendi}
                        onClick={() => {
                            setState({...state, openAuth: false})
                            validate()
                        }}
                        >
                            Não
                        </Button>
                    </div>
                </div>
            </Modal>

            {verify.require_verification || verify.require_verification === undefined ? 
            <div
            style={{
                padding:'20px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                display:'flex',
                flexDirection:"column",
                maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                maxHeight:'80vh',
                overflowY:'auto'
            }}
            >
                {user.token ? 
                <div
                style={{
                    width:'100%',
                    justifyContent:'right',
                    display:'flex'
                }}
                >
                    <Button
                    size='small'
                    color='error'
                    startIcon={<LogoutIcon />}
                    onClick={() => {
                        setUser({})
                        navigate('/')
                    }}
                    >
                        sair
                    </Button>
                </div> : ''}
                <Typography
                sx={{
                    fontSize:'18px',
                    color:'text.primary',
                    fontWeight:'bold'
                }}
                >
                    Olá, {formatarNome(state.name)}
                </Typography>
                <Typography
                sx={{
                    fontSize:'15px',
                    color:'text.primary'
                }}>
                    Preencha alguns dados para verificar sua conta.
                </Typography>
                <Link
                sx={{
                    marginBottom:'20px',
                    cursor: 'pointer'
                }}
                onClick={() => setState({...state, open: true})}
                >
                    <Typography
                    sx={{
                        fontSize:'13px'
                    }}>
                    Como esses dados são utilizados ?
                    </Typography>
                </Link>

                <TextField 
                value={state.name}
                onChange={({target}) => setState({...state, name: target.value})}
                label='Nome'
                size='small'
                InputLabelProps={{
                    shrink: state.name ? true : false
                }}
                />

                {(!user.token || !state.auth) ? <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="center">Autenticação</Divider> : ''}

                {(!user.token || !state.auth) ?
                <TextField 
                value={state.email}
                onChange={({target}) => setState({...state, email: target.value})}
                label='E-mail'
                size='small'
                InputLabelProps={{
                    shrink: state.email ? true : false,
                    marginTop:'10px'
                }}
                disabled={state.accessToken}
                /> : ''}

                {!state.accessToken && 
                (!user.token || !state.auth) ? 
                <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                    <OutlinedInput  
                    label='Nova senha'
                    type={pass ? 'text' : 'password'}
                    value={state.password}
                    onChange={({target}) => setState({...state, password: target.value})}
                    helperText={
                        state.password && <Typography sx={{
                            color: strength_level[passwordStrength(state.password).id].color
                        }}>{strength_level[passwordStrength(state.password).id].label}</Typography>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPass(!pass)}
                            onMouseDown={() => setPass(!pass)}
                            edge="end"
                        >
                            {!pass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    />
                </FormControl> : ''}

                {!state.accessToken && 
                (!user.token || !state.auth) ? 
                <TextField 
                value={state.password_}
                onChange={({target}) => setState({...state, password_: target.value})}
                label='Confirme a senha'
                type='password'
                helperText={state.password_ && state.password && state.password_ !== state.password ? 'Senhas não conferem' : ''}
                error={state.password_ && state.password && state.password_ !== state.password}
                sx={{marginTop:'10px'}}
                /> : ''}

                {(!user.token || !state.auth) ? 
                <ButtonBase
                onClick={() => googleLogin()}
                >
                    <div
                    style={{
                        margin:'10px 0px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <img 
                        style={{
                            width:'25px',
                            height:'25px',
                            objectFit:'contain',
                            marginRight:'15px'
                        }}
                        src={google} alt='google'></img>
                        <Typography
                        sx={{
                            fontFamily:'Quicksand',
                            color: 'text.primary'
                        }}>Entrar com Google</Typography>
                    </div>
                </ButtonBase> : ''}

                {Object.entries(verify.verification || {}).length ?
                <Divider sx={{ color:'text.secondary', marginTop:'10px'}} textAlign="center">Verificação</Divider> : ''}

                {Object.entries(verify.verification || {}).map(e => (
                    <TextField 
                    value={state.verification[e[0]]}
                    onChange={({target}) => setState({...state, verification: {...state.verification, [e[0]]: target.value}})}
                    label={e[0]}
                    helperText={`Dica: ${e[1]}`}
                    sx={{marginTop:'10px'}}
                    size='small'
                    />
                ))}

                <Button
                fullWidth
                onClick={validate}
                variant='contained'
                color='success'
                sx={{marginTop:'10px'}}
                >
                    Validar
                </Button>
            </div> : 
            <div
            style={{
                padding:'20px',
                borderRadius:'5px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                display:'flex',
                flexDirection:"column",
                maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                maxHeight:'80vh',
                overflowY:'auto'
            }}
            >
                <Typography
                sx={{
                    fontSize:'18px',
                    color:'text.primary',
                    fontWeight:'bold'
                }}
                >
                    Olá, {formatarNome(state.name)}
                </Typography>
                <Typography
                sx={{
                    fontSize:'15px',
                    color:'text.secondary'
                }}
                >
                    Está tudo certo com a sua conta
                </Typography>
                <Lottie 
                animationData={successLottie}
                // segments={[57,271]}
                // goTo={57}
                height={'70px'}
                width={'70px'}
                onEnterFrame={(data) => {
                    // if (data.currentTime >= 152) {
                    //     setStartSector(false)
                    // }
                }}
                play={true}
                // onClick={() => setStart(true)}
                loop={false}
                />
                {application.idaplicacao ?
                <Typography
                sx={{
                    fontSize:'15px',
                    color:'text.secondary'
                }}
                >
                    Você já pode retornar ao aplicativo {application.nomeaplicacao}{application.nomedispositivo ? `, dispositivo ${application.nomedispositivo}` : ''}
                </Typography> : ''}
                <Button
                onClick={() => navigate('/')}
                fullWidth
                color='success'
                sx={{marginTop:'10px'}}
                >
                    ir para tela inicial
                </Button>
            </div>}
        </div>
    )
}