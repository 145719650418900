import { useContext, useEffect, useReducer, useState } from "react"
import { CartContext, PreferencesContext, SizeContext } from "../../../../../context"
import get_tag from "../../../../../utils/getPromoTag"
import vlrvenda from "../../../../../utils/vlrvenda"
import getColors from "../../../../getColor"
import { useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { 
    IconButton, 
    Typography,
    ButtonBase,
    TextField,
    Button
} from '@mui/material'

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import addToCart from "../../../../../utils/addToCart"
import { integer } from "../../../../../utils/input"
import ImageGallery from 'react-image-gallery';
import { RWebShare } from "react-web-share";
import axios from "axios"

export default function Card (props) {
    const [quantidade, setQuantidade] = useState(0)
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [cart, setCart] = useContext(CartContext)

    const [state, setState] = useState(props)
    const [products, setProducts] = useState([])
    const [caracteristicas, setCaracteristicas] = useState({})
    const [caracteristicas_filtradas, setCaracteristicas_filtradas] = useState({})
    const [filter, setFilter] = useState({})

    const [filtered, setFiltered] = useState([])
    const [images, setImages] = useState([])

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    useEffect(() => {
        if (!props.idproduto && !props.idvariacaoproduto) return setState({loading: true, caracteristicas: []})

        const p = [...(props.variacao || [props])]
        const c = [...new Set(
            p
            ?.map(e => (e.caracteristicas))
            .reduce((a, next) => (a = [...a, ...(next || [])]),[])
            ?.map(e => (JSON.stringify(e)))
        )].reduce((a, next) => {
            next = JSON.parse(next)
            if (!next || next.propriedade === null) return a
            return a = {
                ...a,
                [next.propriedade] : [
                    ...(a[next.propriedade] || []),
                    next
                ]
            }
        },{})

        setProducts(p.map(e => ({...e, vlrvenda: vlrvenda(e)})))
        setCaracteristicas(c)
        setState(props)

        axios
        .get(`https://${storecode}.allon.cloud/image/${props.idproduto || props.idprodutopai}`)
        .then(response => setImages(response.data.reduce((a, next) => {
            return a = [...a, ...next.images?.map(e => ({...e, ...next, images:undefined}))]
        },[])))
    },[
        props.idproduto,
        props.idvariacaoproduto
    ])

    useEffect(() => {
        const f = products.filter(e => {
            const check = e.caracteristicas.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check.length) return e
        }).map((e) => ({
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        setCaracteristicas_filtradas([...new Set(
            f
            ?.map(e => (e.caracteristicas))
            .reduce((a, next) => (a = [...a, ...(next || [])]),[])
            ?.map(e => (JSON.stringify(e)))
        )].reduce((a, next) => {
            if (!next || next.propriedade === null) return a
            next = JSON.parse(next)
            return a = {
                ...a,
                [next.propriedade] : [
                    ...(a[next.propriedade] || []),
                    next
                ]
            }
        },{}))

        setFiltered(f)
        setQuantidade(parseFloat((f[0]?.adicionado ? (f[0]?.variavel ? f[0]?.adicionado / (f[0]?.incrementovariavel || 1) : f[0]?.adicionado) : 0).toFixed(1)))
    },[
        products.length,
        filter,
        cart.reduce((a, {adicionado}) => a += adicionado,0),
        state.idproduto,
        state.idvariacaoproduto
    ])

    useEffect(() => {
        const filtered = products.filter(e => {
            const check = e.caracteristicas.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check.length) return e
        }).map((e) => ({
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        if (
            filtered[0]?.adicionado === parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1) )||
            filtered[0]?.adicionado === undefined && quantidade === 0
        ) return

        const t = setTimeout(() => {
            const c = addToCart(filtered[0],cart,quantidade)
            if (c[1]) {
                setCart(c[0])
                const f = filtered
                f[0].adicionado = parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1))
                setFiltered(f)
            }
        }, 300);

        return () => {
            clearTimeout(t)
        }
    },[quantidade])

    const disable = () => {
        if (!filtered.length) return true
        if (filtered.length === 1) return false
        // if (Object.entries(caracteristicas).length !== Object.entries(filter).length) return true
        return true
    }

    const storecode = preferences.storecode
    const promotag = get_tag(filtered[0])
    const vrvenda = vlrvenda(filtered[0])

    return (
        <div
        style={{ 
            backgroundColor:color.secondary,
            minWidth:'300px',
            maxWidth: size.width <= 500 ? '95vw' : '380px',
            width: size.width <= 500 ? '95vw' : '380px',
            borderRadius: '5px',
            maxHeight: '95vh',
            overflowY: 'auto'
        }}>
            <div style={{ position:'relative' }}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:promotag ? 'space-between' : 'right',
                    alignItems:'center',
                    position:'absolute',
                    zIndex:99,
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.5)',
                    borderRadius: '5px 5px 5px 5px'
                }}>
                    {promotag && 
                    <Typography 
                    sx={{
                        backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                        color:theme.palette.mode==='dark'?'#000':'#fff',
                        fontWeight:'bold',
                        fontSize:'15px',
                        borderRadius:'5px',
                        padding:'6px',
                        margin:'0px 0px 0px 10px',
                        boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.5)`
                    }}>
                        {promotag}
                    </Typography>}
                    <div
                    style={{
                        width:'50%',
                        justifyContent:'space-around',
                        display:'flex'
                    }}
                    >
                        <RWebShare
                        data={{
                            text: `Veja ${filtered[0]?.nome} que eu encontrei em ${preferences.nome} por ${filtered[0]?.vlrvenda?.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}`,
                            url: `https://${storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                            title: `${filtered[0]?.nome} em ${preferences.nome}`,
                        }}
                        // onClick={() => console.log("shared successfully!")}
                        >
                            <IconButton sx={{ color: color.primary }}>
                                <ShareIcon />
                            </IconButton>
                        </RWebShare>

                        <IconButton
                        onClick={() => {
                            navigate(`/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`)
                            props.close()
                        }}
                        >
                            <OpenInNewIcon sx={{ color: color.primary }} />
                        </IconButton>

                        <IconButton 
                        onClick={() => props.close( false )}
                        color='error'>
                            <HighlightOffIcon />
                        </IconButton>

                    </div>
                </div>
                <div
                style={{
                    maxWidth:'380px',
                    width:'100%',
                    backgroundColor:'#fff',
                    borderRadius: '5px 5px 0px 0px'
                }}>
                <ImageGallery
                style={{
                    width: size.width >= 500 ? 'fit-content' : '300px'
                }}
                items={images.length ? [
                    ...(images.filter(e => {
                        const check = filtered.find(i => (
                            (e.idvariacaoproduto === i.idvariacaoproduto && i.idvariacaoproduto !== null)
                            || (e.idproduto === i.idproduto && i.idproduto !== null)
                        ))

                        if (check) return e
                    }))?.map(e => ({
                        original: `data:${e.type};base64,${e.file}`,
                        thumbnail: `data:${e.type};base64,${e.file}`
                    }))] : [
                        {
                            original: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                            thumbnail: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`
                        }
                    ]} 
                />
                </div>
                {!filtered[0]?.ignoraecommerce &&
                <Typography
                sx={{
                    fontSize:'12px',
                    //color:color.fontSecondary,
                    color:'text.secondary',
                    opacity:0.8,
                    textAlign:'center'
                }}>{(filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))} {filtered[0]?.unidmedida?.toUpperCase()} no estoque</Typography>}
            </div>

            <div
            style={{
                padding:'10px'
            }}
            >
            <Typography
            sx={{
                //color:color.fontSecondary,
                color:'text.primary',
                fontFamily:'Quicksand',
                textAlign:'center',
                fontSize:'16px',
                margin:'5px'
            }}>{filtered[0]?.nome}</Typography>
            
            <div
            style={{
                display:'flex',
                justifyContent:'space-around',
                width:'100%',
                alignItems:'center'
            }}>
                {promotag &&
                <Typography sx={{
                    //color:color.fontSecondary,
                    color:'text.secondary',
                    textAlign:"center",
                    fontSize:'17px',
                    fontWeight:"bold",
                    textDecoration:'line-through',
                    opacity:0.7
                }}>
                    {(parseFloat(filtered[0]?.valor)).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                </Typography>}
                <Typography 
                sx={{
                    fontSize:'20px',
                    margin:'10px',
                    //color:color.fontSecondary,
                    color:'text.primary',
                }}>
                    {vrvenda?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                </Typography>
            </div>

            <div
            style={{
                display:'flex',
                flexWrap:'wrap',
                backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                borderRadius:'10px',
                width:'calc(100% - 10px)',
                marginBottom:'10px'
            }}
            >
                {Object.entries(caracteristicas)?.map(e => (
                    <div 
                    style={{
                        // width:'200px',
                        // marginBottom:'10px'
                        margin:'10px'
                    }}>
                        <Typography 
                        sx={{
                            margin:'0px 0px 5px 10px',
                            fontWeight:'bold',
                            //color:color.fontSecondary,
                            color:'text.primary',
                            fontSize:'12px'
                        }}>{e[0]}</Typography>
                        <div 
                        style={{
                            display:'flex',
                            flexWrap:'wrap',
                            justifyContent:'center'
                        }}>
                            {e[1]?.map(j => (
                                <ButtonBase
                                disabled={!caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo))}
                                sx={{
                                    opacity: !caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo)) ? 0.35 : 1
                                }}
                                onClick={() => setFilter({
                                    ...filter,
                                    [e[0]] : filter[e[0]] && filter[e[0]].atributo === j.atributo ? undefined : j
                                })}>
                                    {filter[e[0]]?.atributo === j.atributo &&
                                    <div
                                    style={{
                                        position:'absolute',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'right',
                                        height:'100%'
                                    }}>
                                        <CheckCircleIcon sx={{
                                            fontSize:'15px',
                                            color: color.fontSecondary
                                        }} />
                                    </div>}
                                    <Typography sx={{
                                        width:'fit-content',
                                        padding:'5px',
                                        borderRadius:'2.5px',
                                        margin:'5px',
                                        // color:filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor,
                                        color: color.fontSecondary,
                                        //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                        fontSize:'12px',
                                        //color:color.fontSecondary,
                                        border: `1px solid ${filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor}`,
                                        // backgroundColor:filter[e[0]]?.atributo === j.atributo ? j.cor : ''
                                        backgroundColor: j.cor
                                    }}>
                                        {(j.atributo)?.toUpperCase()}
                                    </Typography>
                                </ButtonBase>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            
            {filtered[0]?.variavel &&
            <div>
                <Typography sx={{
                    color:'text.secondary',
                    fontSize:'14px',
                    fontWeight:'bold'
                }}>Adicionado</Typography>
                <div
                style={{
                    display:'flex',
                    // flexDirection:'column',
                    marginBottom:'15px'
                }}
                >
                    <Typography
                    sx={{
                        color: 'text.primary',
                        fontSize:'15px',
                        width:'50%'
                    }}
                    >Quantidade: {quantidade * (filtered[0]?.incrementovariavel || 1)} {filtered[0]?.unidmedida?.toUpperCase()}</Typography>

                    <div
                    style={{
                        width:'1px',
                        height:'20px',
                        backgroundColor: 'rgba(255,255,255,0.3)',
                        borderRadius:'10px'
                    }}
                    ></div>


                    <Typography
                    sx={{
                        color: 'text.primary',
                        fontSize:'15px',
                        fontWeight:'bold',
                        width:'calc(50% - 10px)',
                        textAlign:'right'
                    }}
                    >Total: {(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                </div>
                {filtered[0]?.incremento_minimo &&
                <Typography sx={{
                    color:'text.secondary',
                    fontSize:'14px',
                    fontWeight:'bold',
                    marginBottom:'10px'
                }}>Mínimo de {`${filtered[0]?.incremento_minimo} ${filtered[0]?.unidmedida}`}</Typography>}
            </div>
            }

            <div
            style={{
                display:'flex',
                // justifyContent:'space-between',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    width:'100%'
                }}
                >
                    {quantidade ? 
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        textAlign:'center'
                    }}>
                        <IconButton
                        disabled={disable()}
                        sx={{
                            opacity: disable() ? 0.5 : 1
                        }}
                        onClick={() => {
                            if (quantidade === 0) {
                                const ad = addToCart(filtered[0],cart,-1)
                                if (ad[1]) {
                                    const f = filtered
                                    f[0].adicionado = undefined
                                    setFiltered(f)
                                    setCart(ad[0])
                                    forceUpdate()
                                }
                            } else {
                                setQuantidade(quantidade===0 ? 0 : quantidade - 1)
                            }
                        }}>
                            {quantidade === 0 && filtered[0]?.adicionado !== undefined ? <DeleteIcon sx={{color: color.fontSecondary}} /> : <RemoveIcon sx={{color: color.fontSecondary}} />}
                        </IconButton>

                        <TextField
                        value={quantidade}
                        disabled={disable()}
                        onChange={({target}) => {
                            const quant = integer(target.value)
                            if (filtered[0] && !filtered[0]?.ignoraecommerce && quant > (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))) return setQuantidade((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)))
                            setQuantidade(quant)
                        }}
                        sx={{
                            width:'100px',
                            textAlign:'center',
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}
                        size='small'
                        inputProps={{min: 0, 
                            style: { 
                                textAlign: 'center', 
                                //color:color.fontSecondary,
                                color:'text.primary',
                            }}}
                        />
                        
                        <IconButton
                        disabled={filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()}
                        sx={{
                            opacity: (filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()) ? 0.5 : 1
                        }}
                        onClick={() => setQuantidade(quantidade+1)}>
                            <AddIcon sx={{color: color.fontSecondary}} />
                        </IconButton>
                    </div> : 
                    <Button
                    onClick={() => setQuantidade(quantidade+1)}
                    startIcon={
                        <AddShoppingCartIcon />
                    }
                    variant='contained'
                    size=''
                    sx={{
                        backgroundColor: color.fontPrimary,
                        width: 'calc(100% - 10px)'
                    }}
                    disabled={disable()}
                    >
                        adicionar ao carrinho
                    </Button>}

                    <div>
                        {filtered[0]?.variavel ? <Typography
                        sx={{
                            fontSize:'12px',
                            //color:color.fontSecondary,
                            color:'text.secondary',
                            opacity:0.8,
                            textAlign:'center'
                        }}>1 equivale a {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida}`}</Typography> : 
                        <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize:'14px',
                            fontWeight:'bold',
                            textAlign:'center'
                        }}
                        >{(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>}
                    </div>
                </div>
                
                {/* <div
                style={{
                    width:'1px',
                    height:'50px',
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    borderRadius:'10px'
                }}
                ></div> */}

            </div>
            </div>
        </div>
    )
}