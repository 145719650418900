import {
    useState,
    useEffect,
    useContext,
    useReducer,
    useRef
} from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
    CardContext, 
    EmailConfirmContext, 
    LoadingContext, 
    LoginContext, 
    PreferencesContext, 
    UserContext 
} from '../../../../context';

import axios from 'axios';
import { api } from '../../../../utils/defaults';
import { toast } from 'react-toastify';

import { 
    Button,
    Modal,
    TextField, 
    Typography, 
    useTheme
} from '@mui/material';

import getColors from '../../../getColor';
import InputMask from 'react-input-mask'

import AddIcon from '@mui/icons-material/Add';
import { isMobile, deviceDetect } from 'react-device-detect';
import Checkout from '../../../checkout/index'
import createOrder from '../../../../utils/createOrder';
import isAuth from '../../../../utils/isauth';
import Check from '../cart/check';

export default function Payment (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const loginRef = useContext(LoginContext)
    const [emailConfirm, setEmailConfirm] = useContext(EmailConfirmContext)
    const [received, setReceived] = useState({})
    const [card, setCard] = useState({})
    const [mercado_pago, setMercado_pago] = useState({})
    const [check, setCheck] = useState([])
    const [cards, setCards] = useState([])
    const [order, setOrder] = useState({})
    const [number, setNumber] = useState({})
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    const [query] = useSearchParams();

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/payment/cart?code_1=${query.get('code_1')}&code_4=${query.get('code_4')}&store_code=${query.get('store_code')}`)
        .then(response => {
            setReceived(response.data)
            setOrder({
                ...response.data.complete,
                list: response.data.complete.list.map((e,index) => ({...e,index}))
            })
            if (!user.token) loginRef.current?.open()
        })
        .catch(err => {
            toast.error(typeof err.response?.data === 'object' ? err.response?.data.message : err.response?.data)
            // if (err.response.status === 404) window.location.replace(`/`)
        })
        .finally(() => setLoading(false))
    },[])

    useEffect(() => {
        if (!user.token) return
        if (!user.email_verificado) setEmailConfirm(true)
        if ( user.token ) {
            axios
            .get(`${api}/client/cards?verificado=true`, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => setCards(response.data))
            .catch(err => {})
        }
    },[
        preferences.storecode,
        user.token
    ])

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()

    const vlrcompras = order.list?.reduce((acumulator, next) => (acumulator += next.adicionado*next.vlrvenda),0)

    const fn_checkout = ( card=undefined , usr = undefined ) => {
        usr = usr || user 

        if (!received.uuid_v1) return
        if (!isAuth(usr)) return loginRef.current?.open((err, out) => {
            if (out) fn_checkout( card, out )
        })
        if (!usr.email_verificado) return setEmailConfirm(true)
        if (!usr.telefone1 && !usr.telefone2) return setNumber({ open:true })
        
        checkoutRef.current?.init({
            onReady: () => {},
            onError: () => {},
            onPay: async (data, resolve=()=>{}, reject=()=>{}) => {
                setLoading(true)
                createOrder({ 
                    ...order, 
                    payment: data.formData, 
                    list: order?.list?.filter(({adicionado}) => adicionado).map(e => ({
                        idproduto: e.idproduto,
                        idvariacaoproduto: e.idvariacaoproduto,
                        idprodutopai: e.idprodutopai,
                        quantidade: e.quantidade,
                        qtdminima: e.qtdminima,
                        valor: e.valor,
                        desconto: e.desconto,
                        promocao: e.promocao,
                        porcdesconto: e.porcdesconto,
                        idlojaecommerceacao: e.idlojaecommerceacao,
                        vlrvenda: e.vlrvenda,
                        adicionado: e.adicionado
                    })), 
                    save_payment: data.save_payment || false,
                    card: data.card ? {
                        ...data.card,
                        mp_info: undefined,
                        ...data.card?.mp_info
                    } : undefined,
                    replace_payment: order.replace_payment,
                    idvenda: order.idvenda,
                    metadata: {
                        ...isMobile,
                        ...deviceDetect()
                    },
                    socketId: order.socketId
                }, usr.token,(err, out) => {
                    setLoading(false)

                    if ( err ) {
                        reject()
                        if (err?.response?.status === 400) {
                            checkRef.current?.check(err?.response?.data?.list || [])
                            if (err?.response?.data?.message) toast.error(err?.response?.data?.message)
                        } else {
                            toast.error(typeof err?.response?.data === 'string' ? err?.response?.data : err?.response?.data?.message)

                            axios
                            .get(`${api}/client/cards?verificado=true`, {
                                headers: {
                                    Authorization: usr.token
                                }
                            })
                            .then(response => setCards(response.data))
                            .catch(err => {})
                        }
                        return
                    }

                    resolve()

                    axios
                    .delete(`${api}/payment/cart?code_1=${query.get('code_1')}&code_4=${query.get('code_4')}&store_code=${query.get('store_code')}`)
                    .then(response => {})
                    .catch(err=> {})
                    .finally(() => navigate(`/order/${out.idvenda}?store_code=${query.get('store_code')}`))

                    checkoutRef.current?.unmount()
                    setTimeout(() => navigate(`/order/${out.idvenda}`), 500)
                })
            },
            enableSaveCard: true,
            amount: vlrcompras+taxa(),
            card
        })
    }

    const taxa = () => {
        let valor = 0
        const distance = parseFloat((order.address?.rows[0]?.elements[0]?.distance?.value || 0) / 1000)

        if (order.delivery_type === 'retirada') return valor
        if (order.delivery_type === 'frete') {
            if (order.frete?.change === 'pac') return parseFloat(order.frete?.valorpac)
            if (order.frete?.change === 'sedex') return parseFloat(order.frete?.valorsedex)
        }

        if (preferences.delivery_vlr_fixo && parseFloat(preferences.delivery_vlr_fixo)) {
            valor = parseFloat(preferences.delivery_vlr_fixo)
        }

        if (!preferences.delivery_vlr_fixo || !parseFloat(preferences.delivery_vlr_fixo) || distance > preferences.delivery_vlr_fixo_max) {
            valor += (
                Number.isNaN(parseFloat(preferences.delivery_vlr_variavel) * ((distance - preferences.delivery_vlr_fixo_max)/preferences.delivery_vlr_variavel_max)) ? 0 :
                parseFloat(preferences.delivery_vlr_variavel) * ((distance - preferences.delivery_vlr_fixo_max)/preferences.delivery_vlr_variavel_max)
            )
        }

        return valor
    }

    useEffect(() => {
        checkoutRef.current?.unmount()
        setCard({})
    },[
        check.filter(({altered}) => altered).length
    ])

    const checkoutRef = useRef()
    const checkRef = useRef()

    return (
        <div
        style={{
            flexDirection:"column",
            display:'flex',
            backgroundColor: color.background,
            width: '100vw',
            minHeight:'100vh'
        }}
        >   
            <Modal
            open={number.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                    <div
                    style={{
                        padding:'10px',
                        display:'flex',
                        justifyContent:'space-between',
                        backgroundColor:color.background,
                        borderRadius:'10px',
                        flexDirection:"column",
                        maxWidth:'330px'
                    }}>
                        <Typography
                        sx={{
                            color:'text.primary',
                            fontsize:'15px',
                            padding:'10px'
                        }}
                        >Podemos entrar em contato por qual número de whatsapp para informá-lo(a) sobre seu pedido ?</Typography>
                        {/* <Typography
                        sx={{
                            color:'text.primary',
                            fontSize:'12px',
                            padding:'10px',
                            opacity:0.7
                        }}
                        >*O contato será feito somente por mensagens automáticas da plataforma*</Typography> */}
                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={number.telefone1}
                        onChange={({target}) => setNumber({...number, telefone1:target.value})}
                        >
                            {(inputProps) =>  <TextField 
                            {...inputProps}
                            InputLabelProps={{shrink:number.telefone1?true:false}}
                            label='telefone 1'
                            sx={{ margin:'5px', width:'320px' }}
                            helperText='(21) 999999999'
                            />}
                        </InputMask>

                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={number.telefone2}
                        onChange={({target}) => setNumber({...number, telefone2:target.value})}
                        >
                            {(inputProps) => <TextField
                            {...inputProps} 
                            InputLabelProps={{shrink:number.telefone2?true:false}}
                            label='telefone 2'
                            sx={{ margin:'5px', width:'320px' }}
                            helperText='(21) 999999999 (opcional)'
                            />}
                        </InputMask>

                        <Button
                        onClick={() => {
                            if (!number.telefone1 && !number.telefone2) return toast.error('Preencha algum número para contato da plataforma')
                            setLoading(true)
                            axios
                            .put(`${api}/client`,number,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Número adicionado ao seu cadastro')
                                setUser({...user, telefone1:number.telefone1, telefone2: number.telefone2})
                                setNumber({open:false})
                                setTimeout(() => fn_checkout(card), 200);
                            })
                            .catch(err => toast.error(typeof err.response?.data === 'object' ? err.response?.data.message : err.response?.data))
                            .finally(() => setLoading(false))
                        }}
                        variant='contained'
                        sx={{
                            backgroundColor: color.primary,
                            margin:'5px'
                        }}
                        >continuar</Button>

                    </div>
            </Modal>

            <Check 
            ref={checkRef}
            />

            <div
            style={{
                display:'flex',
                width:'100%',
                overflowX:'scroll',
                marginTop:'20px'
            }}>
                <div
                onClick={() => fn_checkout()}
                style={{
                    width:'100px',
                    height:'100px',
                    margin:'10px',
                    boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                    padding:'10px',
                    minWidth:'100px',
                    borderRadius:'10px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between',
                    cursor:'pointer',
                    opacity: !received.uuid_v1 || !user.token ? 0.3 : 1
                }}>
                    <div
                    style={{
                        display:'flex',
                        width:'100%'
                    }}>
                        <AddIcon sx={{color: color.fontPrimary}} />
                    </div>
                    <div>
                        {/* <Typography 
                        sx={{
                            color:'text.primary',
                            fontSize:'13px',
                            marginLeft:'5px'
                        }}>Cadastrar</Typography>

                        <Typography 
                        sx={{
                            color:'text.primary',
                            fontSize:'13px',
                            marginLeft:'5px',
                            fontWeight:'bold'
                        }}>Novo Cartão</Typography> */}
                        <Typography 
                        sx={{
                            color:'text.primary',
                            fontSize:'13px',
                            marginLeft:'5px'
                        }}>Abrir</Typography>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            fontSize:'13px',
                            marginLeft:'5px',
                            fontWeight:'bold'
                        }}>Pagamento</Typography>
                    </div>
                </div>
                {cards.filter(({verificado, ativo, interno}) => verificado && ativo && !interno)?.map(e => (
                    <div
                    onClick={() => {
                        if (!user.email_verificado) return toast.error('E-mail não verificado')
                        setCard(e)
                        setTimeout(() => fn_checkout(e), 200);
                    }}
                    style={{
                        width:'100px',
                        height:'100px',
                        margin:'10px',
                        boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                        padding:'10px',
                        minWidth:'100px',
                        borderRadius:'10px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between',
                        cursor:'pointer'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            width:'100%'
                        }}>
                            {
                                e.mp_info?.payment_method.secure_thumbnail ? 
                                <img 
                                style={{
                                    width:'20px',
                                    objectFit:'contain'
                                }}
                                src={e.mp_info?.payment_method.secure_thumbnail} /> : 
                                <Typography 
                                sx={{
                                    color:'text.primary',
                                    fontSize:'13px',
                                    marginLeft:'5px'
                                }}>{e.brand}</Typography>
                            }
                            <Typography 
                            sx={{
                                color:'text.primary',
                                fontSize:'13px',
                                marginLeft:'5px'
                            }}>{{credit_card:'Crédito', debit_card:'Débito'}[e.mp_info?.payment_method.payment_type_id]}</Typography>
                        </div>
                        <div>
                            <Typography 
                            sx={{
                                color:'text.primary',
                                fontSize:'13px',
                                marginLeft:'5px'
                            }}>{e.expiration}</Typography>

                            <Typography 
                            sx={{
                                color:'text.primary',
                                fontSize:'13px',
                                marginLeft:'5px',
                                fontWeight:'bold'
                            }}>&#9679; &#9679; &#9679; &#9679; {e.cardNumber?.substring(12, 16)}</Typography>
                        </div>
                    </div>
                ))}
            </div>

            <div
            style={{
                display:'flex',
                justifyContent:'center'
            }}
            >
                <div
                style={{
                    maxWidth:'400px'
                }}
                >
                    <Checkout 
                    ref={checkoutRef}
                    style={{
                        width:'auto'
                    }}
                    ComponentProps={{
                        accordionProps: {
                            sx: {
                                width: 'auto !important',
                                // display:'flex'
                            }
                        },
                        accordionSumaryProps: {
                            sx: {
                                width: '100% !important'
                            }
                        }
                    }}
                    />
                </div>
            </div>
        </div>
    )
}