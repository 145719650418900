import { 
    Button
} from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";


import getColors from "../getColor";
import { PreferencesContext, UserContext } from "../../context";
import { useTheme } from '@mui/material/styles'
import { MercadoPagoSettingsContext } from "./context";
import Card from "./card";
import SaveCard from "./saveCard";

export default function Credit_card(props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [paymentParams, setPaymentParams] = useState({})
    const [typeParams, setTypeParams] = useState({})
    const [state, setState] = useState({})
    const [card, setCard] = useState({  })
    const [open, setOpen] = useState(false)
    const [connector, setConnector] = useState({})    

    const mercadoPagoSettings = useContext(MercadoPagoSettingsContext)
    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => setOpen(props.opened), [props.opened])

    useEffect(() => {
        setCard(props.card)
        setPaymentParams(props.paymentParams)
    }, [
        props.card,
        props.paymentParams
    ])

    useEffect(() => {init(card, paymentParams)}, [card, paymentParams])

    const init = async ( card, paymentParams ) => {
        if ( card.conector === 'cielo' ) return
        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()

        mercadoPagoSettings.customization.paymentMethods = {
            'creditCard': 'all'
        }

        mercadoPagoSettings.callbacks.onSubmit = ({ selectedPaymentMethod, formData, ...data }) => {
                            
            return new Promise((resolve, reject) => {

                if ( formData?.payer?.id ) {
                    if (typeof paymentParams.onPay === 'function') 
                    paymentParams.onPay({
                        selectedPaymentMethod,
                        formData: {
                            ...formData, 
                            payment_type_id: selectedPaymentMethod,
                            idformapagto: card.idformapagto,
                            idconectorpagamento: card.idconectorpagamento
                        },
                        paymentParams,
                        card,
                        saved_card: true
                    },() => {
                        resolve();
                        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                    },() => {
                        reject()
                    })
                } else {
                    if ( paymentParams.enableSaveCard ) {

                        saveCardRef.current?.ask((response) => {
    
                            if (typeof paymentParams.onPay === 'function') 
                            paymentParams.onPay({
                                selectedPaymentMethod,
                                formData: {
                                    ...formData, 
                                    payment_type_id: selectedPaymentMethod,
                                    idformapagto: typeParams.idformapagto,
                                    idconectorpagamento: typeParams.idconectorpagamento
                                },
                                paymentParams,
                                save_payment: response
                            },() => {
                                if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                                resolve();
                            },() => {
                                reject();
                            })
                        })
                    } else {
                        if (typeof paymentParams.onPay === 'function') 
                        paymentParams.onPay({
                            selectedPaymentMethod,
                            formData: {
                                ...formData, 
                                payment_type_id: selectedPaymentMethod,
                                idformapagto: typeParams.idformapagto,
                                idconectorpagamento: typeParams.idconectorpagamento
                            },
                            paymentParams
                        },() => {
                            resolve();
                            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                        },() => {
                            reject()
                        })
                    }
                }
                
            })
        }

        mercadoPagoSettings.callbacks.onError = (error) => {
            if ( typeof paymentParams.onError === 'function' ) paymentParams.onError(error)
            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
        }

        mercadoPagoSettings.initialization.payer = {
            customerId: card.mp_customer_id || card.mp_info?.customer_id,
            cardsIds: [card.mp_card_id]
        }

        if ( card.conector === 'mercado_pago' ) {

            const key = paymentParams.conector.mercado_pago.key.public_key
            const mercado_pago = new window.MercadoPago(key)
            const bricksBuilder = mercado_pago.bricks()

            const paymentBrickController = await bricksBuilder.create(
                'payment',
                'paymentBrick_saved_credit_card_container',
                mercadoPagoSettings
            );

            setConnector({ mercado_pago, bricksBuilder, paymentBrickController })

            return () => paymentBrickController.unmount()
        }
    }

    const saveCardRef = useRef()

    return (
        <>
            <SaveCard ref={saveCardRef} />
            {card.conector === 'mercado_pago' && <div id="paymentBrick_saved_credit_card_container" class='payment-brick' style={{fontFamily:'Quicksand'}}></div>}
            {card.conector === 'cielo' && (
                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column'
                }}
                >
                    <Card
                    expiry={card.expiration}
                    number={card.cardNumber}
                    name={user.card_holder || '...'}
                    />

                    <Button
                    onClick={async () => {
                        paymentParams.onPay({
                            formData: {
                                installments: state.installments || 1,
                                // payment_type_id: card?.payment_type_id,
                                card,
                                idformapagto: card.idformapagto
                            },
                            paymentParams,
                            card,
                            saved_card: true
                        },() => {
                        },() => {
                        })
                    }}
                    variant='outlined'
                    sx={{
                        color: color.fontPrimary,
                        marginTop:'10px',
                        fontWeight:'bold',
                        width: '100%',
                        borderColor: color.fontPrimary,
                        maxWidth:'290px'
                    }}
                    >Finalizar</Button>

                </div>
            )}
        </>
    )
}