import { useContext, useEffect, useRef, useState } from "react"
import { 
    BlurContext, 
    FilterContext, 
    PreferencesContext, 
    MenuContext, 
    KeyContext,
    SizeContext
} from "../../../../context"
import ProductCard from './card'
import axios from "axios"
import { api } from "../../../../utils/defaults"
import { toast } from "react-hot-toast"
import getColors from "../../../getColor"
import { useTheme } from "@mui/material/styles"
import { Box, CircularProgress, ImageList, ImageListItem, Typography } from "@mui/material"
// import InfiniteScroll from "../infiniteScroll"
import { useLocation } from "react-router-dom"
import usePersistedState from "../../../../utils/usePersistedState"
import Lottie from "react-lottie-player"
import InfinityScroll from "../infinityScroll"
import ScrollToTop from "react-scroll-to-top";

const perpage = 50

export default function Index (props) {
    // const [products, setProducts] = useContext(ProductsContext)
    const [size, setSize] = useContext(SizeContext)
    const [key, setKey] = useContext(KeyContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [filter, setFilter] = useContext(FilterContext)
    const [products, setProducts] = usePersistedState('products',[],key)
    const [blur, setBlur] = useContext(BlurContext)
    const [stop, setStop] = useState(false)
    const [page, setPage] = useState(0)
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)

    const location = useLocation()
    const menuRef = useContext(MenuContext)

    useEffect(() => {
        setProducts([])
        getData(1, true)
    },[
        filter.nome,
        preferences.storecode
    ])  

    const getData = (pg, reset=false) => {
        if (!pg) return
        setLoading(true)
        axios
        .get(`${api}/product?page=${pg}&perpage=${perpage}${filter.nome ? `&query=${filter.nome}`: ''} ${
            location.state?.idprodutocategoria ? `&idprodutocategoria=${location.state.idprodutocategoria}&nivelcategoria=${location.state.nivelcategoria}` : ''
        }`)
        .then(response => {
            if ( reset ) {
                setStop(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
            if (response.data.length) {
                setPage(pg + 1)
                setProducts(reset ? response.data : products.concat(response.data))
                if (response.data.length % perpage !== 0) {
                    setStop(true)
                }
            } else {
                setStop(true)
                if (reset) setProducts([])
            }
        })
        .catch(err => {
            toast.error(err?.response?.data)
            setStop(true)
        })
        .finally(() => {
            setLoad(true)
            setLoading(false)
        })
    }

    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => {
        if (!location.state?.idprodutocategoria) return
        getData(1, true)
    },[
        location.state?.idprodutocategoria,
        preferences.storecode
    ])

    return (
        <InfinityScroll
        next={getData}
        page={page}
        stop={stop}
        length={products.length}
        >
            <Box
            sx={{
                display: 'flex',
                width: '100%',
                bgcolor:color.background,
                margin:'0px',
                padding:'0px',
                flexDirection:"column",
                // filter:blur && 'blur(5px)',
                overflowY:'hidden',
                minHeight:`calc(100vh - ${menuRef.current?.offsetHeight}px)`,
                overflowX:'hidden'
            }}
            >
                {products.length || !load || loading ? 
                // <InfiniteScroll
                // next={getData}
                // page={page}
                // stop={stop}
                // length={products.length}
                // >
                <ImageList
                cols={parseInt(size.width / (size.width > 950 ? 300 : 180))}
                sx={{ 
                    width: '100%', 
                    height: '100%',
                    overflowX:'hidden',
                    margin:0,
                    justifyContent:'space-around'
                }}>
                {(load ? (products || []) : [...new Array(4)]).map(e => (
                    <ImageListItem
                    sx={{
                        display:'flex',
                        alignItems:"center"
                    }}>
                        <ProductCard {...e} />
                    </ImageListItem>
                ))}
                </ImageList>
                //</InfiniteScroll> 
                :
                <div
                style={{
                    width:'100%',
                    height:`calc(100vh - ${menuRef.current?.offsetHeight}px)`,
                    display:'flex',
                    alignItems:'center',
                    flexDirection:'column',
                    justifyContent:'center'
                }}
                >
                    <Typography
                    sx={{
                        fontFamily:'quicksand',
                        fontSize:'20px'
                    }}
                    >Não encontrado</Typography>
                    <div
                    style={{
                        width:'200px',
                        height:'200px'
                    }}>
                    <Lottie 
                    animationData={require('../../../../static/search_empty.json')}
                    // segments={[57,271]}
                    // goTo={57}
                    onEnterFrame={(data) => {
                        // if (data.currentTime >= 152) {
                        //     setStartSector(false)
                        // }
                    }}
                    play={true}
                    loop={false}
                    // onClick={() => setStart(true)}
                    />
                    </div>
                </div>}
                {loading &&
                <div
                style={{
                    display:'flex',
                    width:'100%',
                    justifyContent:'center',
                    backgroundColor: color.background,
                    padding:'20px 0px 20px 0px'
                }}>
                        <CircularProgress />
                </div>}

                <ScrollToTop smooth style={{
                    backgroundColor: color.primary,
                    boxShadow: 'none'
                }} />
            </Box>
        </InfinityScroll>
    )
}