import { useEffect, useState } from "react"
import Cards from 'react-credit-cards-2';

export default function Card (props) {
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    })

    useEffect(() => setState({
        number: props.number || '',
        expiry: props.expiry || '',
        cvc: props.cvc || '',
        name: props.name || '',
        focus: props.focus || '',
    }), [
        props.number,
        props.expiry,
        props.cvc,
        props.name,
        props.focus
    ])

    const callback = ( parameter, isValid ) => {
        if ( typeof props.callback !== 'function' ) return
        props.callback(parameter, isValid)
    }

    return (
        <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
        callback={callback}
        />
    )
}