import { Button, Divider, IconButton, ImageList, Skeleton, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useContext, useEffect, useState } from "react"
import { 
    PreferencesContext, SizeContext, UserContext 
} from "../../../context"
import getColors from "../../getColor"

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-hot-toast"
import useInterval from "use-interval"

const statusColor = {
    1: '#2a9ede',
    2: '#2a48de',
    3: '#672ade',
    4: '#4ede2a',
    5: '#d80021',
    6: '#00af7f',
    7: '#fff',
    8: '#bb941f',
    9: 'black',
    10: '#bb941f',
    11: '#4ede2a'
}

export default function Index (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [orders, setOrders] = useState([])
    const [loadingList, setLoadingList] = useState(true)

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()

    useEffect(() => getData(),[])

    const getData = () => {
        axios
        .get(`${api}/order`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setOrders(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoadingList(false))
    }

    useInterval(() => getData() , 15000)

    return (
        <Box
        sx={{
            bgcolor: color.background,
            minHeight:'100vh',
        }}>
            <div
            style={{
                width:'100%',
                backgroundColor: color.background,
                width:'100%',
                zIndex: 999
            }}>
                <Button onClick={() => navigate('/')} sx={{color:color.fontPrimary}} startIcon={<ArrowBackIosNewOutlinedIcon />}>
                voltar
                </Button>
            </div>
            {parseInt(size.width / 350) > 1 ?
            <ImageList
            cols={parseInt(size.width / 350)}
            style={{marginTop: '0px'}}
            sx={{
                width:'100%',
                margin:0,
                overflowX:'hidden',
                justifyContent:'space-between'
            }}>
                {loadingList ?
                [...new Array(4)].map(e => (
                    <div
                    style={{
                        borderRadius:'10px',
                        margin:'15px',
                        boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                        padding:'5px',
                        width:'350px',
                        height:'180px'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:"center",
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <Skeleton variant="text" sx={{ fontSize: '25px', width:'50%' }} />
                            <Skeleton variant="circular" width={30} height={30} />
                        </div>
                        <Divider />
                        <div
                        style={{
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <div>
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                            </div>
                            <div>
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                            </div>
                            <div
                            style={{
                                marginTop:'10px'
                            }}>
                                <Skeleton variant="text" sx={{ fontSize: '15px' }} />
                            </div>
                        </div>
                        <Divider />
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-around',
                            padding:'5px'
                        }}>
                            <Skeleton variant="rounded" width={'50%'} height={40} />
                        </div>
                    </div>
                ))
                : orders.map(e => (
                    <div
                    style={{
                        borderRadius:'10px',
                        margin:'15px',
                        boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                        padding:'5px',
                        width:'350px',
                        height:'fit-content'
                    }}>
                        <div
                        onClick={() => navigate(`/order/${e.idvenda}`,{ state: e})}
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:"center",
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <Typography sx={{
                                color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                fontSize:'15px',
                                fontWeight:'bold'
                            }}>{e.ecommerce ? 'Venda Online' : 'Venda Física'} - {e.idvenda}</Typography>
                            <IconButton>
                                <ArrowForwardIosOutlinedIcon sx={{
                                color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                fontSize:'18px'
                            }}/>
                            </IconButton>
                        </div>
                        <Divider />
                        <div
                        onClick={() => navigate(`/order/${e.idvenda}`,{ state: e})}
                        style={{
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <div>
                                <Typography
                                sx={{
                                    color: statusColor[e.idvendastatus],//theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'17px',
                                    fontWeight: 'bold'
                                    // opacity:0.8
                                }}>{e.nmvendastatus}</Typography>
                            </div>
                            <div>
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'13px',
                                    opacity:0.8,
                                    marginTop:'10px'
                                }}>{e.apelido} - {e.nmvendapagamentostatus}</Typography>

                                {e.brand &&
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'13px',
                                    opacity:0.8
                                }}>{e.brand} &#9679; {e.cardNumber?.substring(e.cardNumber.length - 4, e.cardNumber.length)}</Typography>}
                            </div>
                            <div
                            style={{
                                marginTop:'10px'
                            }}>
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'15px',
                                    // opacity:0.8
                                }}>{new Date(e.dtavenda).toLocaleString('pt-BR')}</Typography>
                            </div>
                        </div>
                        <Divider />
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-around',
                            padding:'5px'
                        }}>
                            <Button disabled sx={{color:color.fontPrimary}}>Adicionar à sacola (breve)</Button>
                        </div>
                    </div>
                ))}
            </ImageList> : 
            <div
            style={{
                width:'100%',
                overflowY:'auto',
                margin:0,
                overflowX:'hidden',
                justifyContent:'center',
                display:'flex',
                flexWrap:'wrap'
            }}>
                {loadingList ?
                [...new Array(4)].map(e => (
                    <div
                    style={{
                        borderRadius:'10px',
                        margin:'15px',
                        boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                        padding:'5px',
                        width:'350px',
                        height:'180px'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:"center",
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <Skeleton variant="text" sx={{ fontSize: '25px', width:'50%' }} />
                            <Skeleton variant="circular" width={30} height={30} />
                        </div>
                        <Divider />
                        <div
                        style={{
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <div>
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                            </div>
                            <div>
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                            </div>
                            <div
                            style={{
                                marginTop:'10px'
                            }}>
                                <Skeleton variant="text" sx={{ fontSize: '15px' }} />
                            </div>
                        </div>
                        <Divider />
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-around',
                            padding:'5px'
                        }}>
                            <Skeleton variant="rounded" width={'50%'} height={40} />
                        </div>
                    </div>
                ))
                : orders.map(e => (
                    <div
                    style={{
                        borderRadius:'10px',
                        margin:'15px',
                        boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                        padding:'5px',
                        width:'350px',
                        height:'fit-content'
                    }}>
                        <div
                        onClick={() => navigate(`/order/${e.idvenda}`,{ state: e})}
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:"center",
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <Typography sx={{
                                color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                fontSize:'15px',
                                fontWeight:'bold'
                            }}>{e.ecommerce ? 'Venda Online' : 'Venda Física'} - {e.idvenda}</Typography>
                            <IconButton>
                                <ArrowForwardIosOutlinedIcon sx={{
                                color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                fontSize:'18px'
                            }}/>
                            </IconButton>
                        </div>
                        <Divider />
                        <div
                        onClick={() => navigate(`/order/${e.idvenda}`,{ state: e})}
                        style={{
                            padding:'5px',
                            cursor:'pointer'
                        }}>
                            <div>
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'13px',
                                    opacity:0.8
                                }}>{e.nmvendastatus}</Typography>
                            </div>
                            <div>
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'13px',
                                    opacity:0.8,
                                    marginTop:'10px'
                                }}>{e.apelido} - [{e.nmvendapagamentostatus}]</Typography>
                                {e.payment_method_id &&
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'13px',
                                    opacity:0.8
                                }}>{e.brand} &#9679; {e.cardNumber?.substring(e.cardNumber.length - 4, e.cardNumber.length)}</Typography>}
                            </div>
                            <div
                            style={{
                                marginTop:'10px'
                            }}>
                                <Typography
                                sx={{
                                    color: theme.palette.mode === 'light' ? '#000' : '#fff',
                                    fontSize:'15px',
                                    // opacity:0.8
                                }}>{new Date(e.dtavenda).toLocaleString('pt-BR')}</Typography>
                            </div>
                        </div>
                        <Divider />
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-around',
                            padding:'5px'
                        }}>
                            <Button disabled sx={{color:color.fontPrimary}}>Adicionar à sacola (breve)</Button>
                        </div>
                    </div>
                ))}
            </div>}
        </Box>
    )
}