import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import InfinityScroll from "../infinityScroll"
import getColors from "../../../getColor"
import { useTheme } from "@mui/material/styles"
import axios from 'axios'
import { api } from '../../../../utils/defaults'
import { AnalyticsContext, CartContext, MenuContext, PreferencesContext, SizeContext, UserContext } from '../../../../context'
import { toast } from 'react-toastify'
import { 
    Box, 
    Breadcrumbs, 
    Button, 
    ButtonBase, 
    IconButton, 
    ImageList, 
    ImageListItem, 
    Link, 
    Modal, 
    Skeleton, 
    TextField, 
    Typography 
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import vlrvenda from '../../../../utils/vlrvenda'
import addToCart from '../../../../utils/addToCart'
import ImageGallery from 'react-image-gallery';
import get_tag from '../../../../utils/getPromoTag'

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShareIcon from '@mui/icons-material/Share';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import QRCode from "react-qr-code";
import ProductCard from './card'
import ScrollToTop from "react-scroll-to-top";

import { integer } from '../../../../utils/input'
import sendAction from '../../../../utils/sendAction'
import useGeolocation from '../../../../utils/useGeolocation.ts'
// import { RWebShare } from 'react-web-share/dist'

const perpage = 10

export default function DetailProduct (props) {
    const [user, setUser] = useContext(UserContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [cart, setCart] = useContext(CartContext)
    const [size, setSize] = useContext(SizeContext)
    const [quantidade, setQuantidade] = useState(0)
    const [products, setProducts] = useState([])
    const [state, setState] = useState({})
    const [caracteristicas, setCaracteristicas] = useState({})
    const [caracteristicas_filtradas, setCaracteristicas_filtradas] = useState({})
    const [filter, setFilter] = useState({})
    const [filtered, setFiltered] = useState([])
    const [images, setImages] = useState([])
    const [stop, setStop] = useState(false)
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(true)
    const [productLoading, setProductLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [prodCategoria, setProdCategoria] = useState([])
    const [notFound, setNotFound] = useState(false)

    let cancel 

    const getData = (pg, reset=false) => {
        if (!pg || !(state.idproduto || state.idprodutopai)) return
        setLoading(true)
        axios
        .get(`${api}/product/similar/product/${state.idproduto || state.idprodutopai}?page=${pg}&perpage=${perpage}`)
        .then(response => {
            if (response.data.length) {
                setPage(pg + 1)
                setProducts(reset ? response.data : products.concat(response.data))
                if (response.data.length % perpage !== 0) {
                    setStop(true)
                }
            } else {
                setStop(true)
                if (reset) setProducts([])
            }
        })
        .catch(err => {
            toast.error(err?.response?.data)
            setStop(true)
        })
        .finally(() => {
            setLoad(true)
            setLoading(false)
        })
    }  

    const update = async () => {
        setProductLoading(true)
        const controller1 = new AbortController()
        const controller2 = new AbortController()
        const controller3 = new AbortController()

        try {
            let response = await axios.get(`${api}/product?idproduto=${params.idproduto}`,{
                signal: controller1.signal
            })

            if ( !response.data.length ) return setNotFound( true )

            const prod = { ...response.data[0], notFinded: response.data[0] ? false : true}

            const p = [...(prod.variacao || [prod])]
            const c = [...new Set(
                p
                ?.map(e => (e.caracteristicas))
                .reduce((a, next) => (a = [...a, ...(next || [])]),[])
                ?.map(e => (JSON.stringify(e)))
            )].reduce((a, next) => {
                next = JSON.parse(next)
                if (!next || next.propriedade === null) return a
                return a = {
                    ...a,
                    [next.propriedade] : [
                        ...(a[next.propriedade] || []),
                        next
                    ]
                }
            },{})

            setState(prod)
            setCaracteristicas(c)

            if ( !storecode ) return 

            response = await axios.get(`https://${storecode}.allon.cloud/image/${prod.idproduto || prod.idprodutopai}`,{
                signal: controller1.signal
            })
            
            setImages(response.data.reduce((a, next) => {
                return a = [...a, ...next.images?.map(e => ({...e, ...next, images:undefined}))]
            },[]))

            response = await axios.get(`${api}/product?perpage=20&idprodutocategoria=${prod.idprodutocategorian2}&nivelcategoria=2`,{
                signal: controller3.signal
            })
            setProdCategoria(response.data)

        } catch (err) {
            return setNotFound( true )
            // console.log(err)
            // toast.error(err?.response?.data)
        }
        setProductLoading(false)

        return {
            abort: () => {
                controller1.abort()
                controller2.abort()
                controller3.abort()
            }
        }
    }

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const menuRef = useContext(MenuContext)
    const params = useParams()
    const navigate = useNavigate()
    const geolocation = useGeolocation()

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setProducts([])
            update()
        }, 200);
    }, [ params.idproduto ])

    useEffect(() => {
        const f = (state.variacao || [state]).filter(e => {
            const check = e.caracteristicas?.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check?.length) return e
        }).map((e) => ({
            vlrvenda: vlrvenda(e),
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        setCaracteristicas_filtradas([...new Set(
            f
            ?.map(e => (e.caracteristicas))
            .reduce((a, next) => (a = [...a, ...(next || [])]),[])
            ?.map(e => (JSON.stringify(e)))
        )].reduce((a, next) => {
            if (!next || next.propriedade === null) return a
            next = JSON.parse(next)
            return a = {
                ...a,
                [next.propriedade] : [
                    ...(a[next.propriedade] || []),
                    next
                ]
            }
        },{}))

        setFiltered(f)
        setQuantidade(parseFloat((f[0]?.adicionado ? (f[0]?.variavel ? f[0]?.adicionado / (f[0]?.incrementovariavel || 1) : f[0]?.adicionado) : 0).toFixed(1)))
    },[
        filter,
        cart.reduce((a, {adicionado}) => a += adicionado,0),
        state.idproduto,
        state.idvariacaoproduto
    ])

    useEffect(() => {
        const filtered = (state.variacao || [state]).filter(e => {
            const check = e.caracteristicas?.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check?.length) return e
        }).map((e) => ({
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        if (
            filtered[0]?.adicionado === parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1) )||
            filtered[0]?.adicionado === undefined && quantidade === 0
        ) return

        const t = setTimeout(() => {
            const c = addToCart(filtered[0],cart,quantidade)
            if (c[1]) {
                setCart(c[0])
                const f = filtered
                f[0].adicionado = parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1))
                setFiltered(f)
            }
        }, 300);

        if ( !filtered[0]?.adicionado && quantidade ) {
            sendAction({
                unique_id: analytics.unique_id,
                action_id: 'cart',
                detail_action_id: 'addToCart',
                complete_detail: filtered[0]?.nome,
                idproduto: filtered[0]?.idproduto,
                idvariacaoproduto: filtered[0]?.idvariacaoproduto,
                idprodutocategoria: null,
                nivelcategoria: null,
                token: user.token,
                lat: geolocation?.coords?.latitude,
                lng: geolocation?.coords?.longitude
            },
            (c) => (cancel = c),
            cancel)
        }

        return () => {
            clearTimeout(t)
        }
    },[quantidade])

    useEffect(() => {
        if ( !state.idproduto ) return 

        sendAction({
            unique_id: analytics.unique_id,
            action_id: 'product',
            detail_action_id: 'open',
            complete_detail: `${state.nome}`,
            idproduto: state.idproduto,
            idvariacaoproduto: state.idvariacaoproduto,
            idprodutocategoria: null,
            nivelcategoria: null,
            token: user.token,
            lat: geolocation?.coords?.latitude,
            lng: geolocation?.coords?.longitude
        },
        (c) => (cancel = c),
        cancel)
    },[
        geolocation?.coords?.latitude,
        state.idproduto
    ])

    useEffect(() => getData(1), [state.idproduto || state.idprodutopai])

    const disable = () => {
        if (!filtered.length) return true
        if ((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) <= 0) return true
        if (filtered.length === 1) return false
        // if (Object.entries(caracteristicas).length !== Object.entries(filter).length) return true
        return true
    }

    const storecode = preferences.storecode
    const promotag = get_tag(filtered[0])
    const vrvenda = vlrvenda(filtered[0])

    const parcela = preferences.qtdparcelasemjuros > 1 ? preferences.qtdparcelasemjuros : preferences.qtdmaximaparcelas;
    const dividir = preferences.qtdparcelasemjuros > 1 ? vrvenda : vrvenda*(100 + (parseFloat(preferences.porcjuros) || 0))/100;

    const breadcrumbs = [
        productLoading ? 
        <Skeleton variant="rounded" width={100} height={15} /> :
        <Link 
        underline="hover" 
        key="1" 
        color="inherit"
        disabled 
        sx={{ fontSize:'11px', cursor:'pointer' }}
        onClick={() => {
            navigate('/product',{
                state: {
                    nivelcategoria: 1,
                    idprodutocategoria: filtered[0]?.idprodutocategorian1
                }
            })
        }}>
          {filtered[0]?.categorian1?.toUpperCase()}
        </Link>,
        productLoading ? 
        <Skeleton variant="rounded" width={100} height={15} /> :
        <Link
        underline="hover"
        key="2"
        color="inherit"
        onClick={() => {
            navigate('/product',{
                state: {
                    nivelcategoria: 2,
                    idprodutocategoria: filtered[0]?.idprodutocategorian2
                }
            })
        }}
        sx={{ fontSize:'11px', cursor:'pointer' }}
        >
          {filtered[0]?.categorian2?.toUpperCase()}
        </Link>,
        productLoading ? 
        <Skeleton variant="rounded" width={100} height={15} /> : 
        <Typography 
        key="3" 
        color="text.primary"
        sx={{ fontSize:'11px', cursor:'pointer' }}
        >
          {filtered[0]?.categoria?.toUpperCase()}
        </Typography>,
    ];

    if ( notFound ) {
        return (
            <Box
            sx={{
                display: 'flex',
                width: '100%',
                bgcolor:color.background,
                margin:'0px',
                padding:'0px',
                flexDirection:"column",
                // filter:blur && 'blur(5px)',
                overflowY:'hidden',
                minHeight:`calc(100vh - ${menuRef.current?.offsetHeight}px)`,
                overflowX:'hidden',
                alignItems:'center',
                width: '100%',
                paddingTop: size.width <= 700 ? '0px' : '10px'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:"column",
                    alignItems:'center',
                    marginTop:'50px'
                }}
                >
                    <SearchOffIcon 
                    sx={{
                        fontSize:'100px'
                    }}
                    />
                    <Typography>Parece que essa página não existe</Typography>
                </div>
            </Box>
        )
    }

    return (
        <InfinityScroll
        next={getData}
        page={page}
        stop={stop}
        length={products.length + prodCategoria.length + 1}
        >
            <Box
            sx={{
                display: 'flex',
                width: '100%',
                bgcolor:color.background,
                margin:'0px',
                padding:'0px',
                flexDirection:"column",
                // filter:blur && 'blur(5px)',
                overflowY:'hidden',
                minHeight:`calc(100vh - ${menuRef.current?.offsetHeight}px)`,
                overflowX:'hidden',
                alignItems:'center',
                width: '100%',
                paddingTop: size.width <= 700 ? '0px' : '10px'
            }}
            >
                <Modal
                open={state.qrcode}
                onClose={() => setState({...state, qrcode: false})}
                sx={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                >
                    <div
                    style={{
                        padding:'10px',
                        backgroundColor: color.secondary,
                        borderRadius:'10px'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'right',
                            width:'100%'
                        }}
                        >
                            <Button onClick={() => setState({...state, qrcode: false})} color='error'>fechar</Button>
                        </div>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%", borderRadius:'10px' }}
                        value={`https://${preferences.usa_pagina_centralizada ? preferences.codigo_pagina_centralizada : storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}${preferences.usa_pagina_centralizada ? '?store_code='+storecode: ''}`}
                        viewBox={`0 0 256 256`}
                        />
                    </div>
                </Modal>

                <div
                style={{
                    maxWidth:'1240px',
                    width: '100%'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: '100%',
                        flexWrap: size.width <= 700 ? 'wrap' : 'noWrap'
                    }}
                    className='detail-product-image-container'
                    >

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width:'100%'
                        }}
                        >
                            {productLoading ? 
                            <Skeleton variant="rounded" width={size.width <= 700 ? size.width - 20 : 600} height={400} /> :
                            <ImageGallery
                            style={{
                                width: '100% !important'
                            }}
                            items={images.length ? [
                                {
                                    original: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                                    thumbnail: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`
                                },
                                ...(images.filter(e => {
                                    const check = filtered.find(i => (
                                        (e.idvariacaoproduto === i.idvariacaoproduto && i.idvariacaoproduto !== null)
                                        || (e.idproduto === i.idproduto && i.idproduto !== null)
                                    ))

                                    if (check) return e
                                }))?.map(e => ({
                                    original: `data:${e.type};base64,${e.file}`,
                                    thumbnail: `data:${e.type};base64,${e.file}`
                                }))] : [
                                    {
                                        original: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                                        thumbnail: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`
                                    }
                            ]} 
                            onSlide={console.log}
                            />}

                            {size.width > 700 && <>
                            {size.width >= 500 && <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                                marginTop: '10px'
                            }}
                            >
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </div>}

                            {productLoading ? 
                            <Skeleton 
                            sx={{marginTop:'10px'}}
                            variant="rounded" width={size.width <= 700 ? size.width - 20 : 400} height={150} /> : 
                            <div
                            style={{
                                // width:'100%',
                                display:'flex',
                                minWidth:size.width <= 700 ? 'calc(100% - 20px)' : '400px',
                                marginTop:'10px',
                                backgroundColor: color.secondary,
                                padding:'10px',
                                borderRadius: '10px'
                            }}
                            >
                                <div
                                style={{
                                    width:'50%'
                                }}
                                >
                                    <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight:'bold'
                                    }}>Marca</Typography>
                                    {filtered[0]?.caracteristicas?.map(e => (
                                        <Typography 
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight:'bold'
                                        }}>{e.propriedade}</Typography>
                                    ))}
                                </div>
                                <div
                                style={{
                                    width:'50%'
                                }}>
                                    <Typography
                                    sx={{
                                        fontSize: '13px'
                                    }}>{filtered[0]?.nomemarca}</Typography>
                                    {filtered[0]?.caracteristicas?.map(e => (
                                        <Typography
                                        sx={{
                                            fontSize: '13px'
                                        }}>{e.atributo}</Typography>
                                    ))}
                                </div>
                            </div>}

                            <div
                            style={{
                                width:'100%',
                                marginTop:'20px',
                                padding: size.width <= 700 ? '0px' : '10px'
                            }}
                            >
                                {productLoading ? 
                                <Skeleton variant="rounded" width={150} height={25} /> : 
                                <Typography
                                sx={{ 
                                    fontSize: '20px',
                                    fontWeight:'bold',
                                    textAlign:'left',
                                    width:'100%'
                                }}
                                >Descrição</Typography>}

                                {productLoading ? 
                                <>
                                <Skeleton sx={{marginTop: '20px'}} variant="rounded" width={250} height={15} />
                                <Skeleton sx={{marginTop: '10px'}} variant="rounded" width={250} height={15} />
                                <Skeleton sx={{marginTop: '10px'}} variant="rounded" width={250} height={15} />
                                </> : 
                                <Typography
                                sx={{ 
                                    fontSize: '18px',
                                    textAlign:'left',
                                    width:'100%'
                                }}
                                >{filtered[0]?.descricao}</Typography>}
                            </div>
                            </>}
                        </div>

                        <div
                        style={{
                            minWidth: '350px',
                            border: `1px solid ${color.secondary}`,
                            borderRadius: size.width <= 700 ? '0px' : '10px',
                            padding: '20px',
                            backgroundColor: color.secondary,
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            width: size.width <= 700 ? '100%' : 'auto',
                            marginTop: size.width <= 700 ? '10px' : '0px'
                        }}
                        >
                            <div style={{position:'relative'}}>
                                {productLoading ? 
                                <div
                                style={{
                                    width:'100%',
                                    justifyContent:'space-between',
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <Skeleton variant="rounded" width={100} height={15} />
                                    <div style={{ display:'flex'}}>
                                        <Skeleton variant="rounded" width={25} height={25} />
                                        <Skeleton sx={{marginLeft: '10px'}} variant="rounded" width={25} height={25} />
                                    </div>
                                </div> : 
                                <div
                                style={{
                                    width:'100%',
                                    justifyContent:'space-between',
                                    display:'flex',
                                    alignItems:'center'
                                }}
                                >
                                    <Typography
                                    sx={{
                                        color:color.primary
                                    }}
                                    >
                                        {filtered.reduce((a,next) => (a += parseInt(next.vendidos || 0)),0)} Vendidos
                                    </Typography>
                                    
                                    <div>
                                        {/* <RWebShare
                                        data={{
                                            text: `Veja ${filtered[0]?.nome} que eu encontrei em ${preferences.nome} por ${filtered[0]?.vlrvenda?.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}`,
                                            url: `https://${storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                                            title: `${filtered[0]?.nome} em ${preferences.nome}`,
                                        }}
                                        // onClick={() => console.log("shared successfully!")}
                                        >
                                            
                                        </RWebShare> */}
                                        <IconButton 
                                        onClick={()  => {
                                            if ( !navigator.share ) return toast.error('Desculpa, seu navegador não suporta')
                                            navigator.share({
                                                text: `Veja ${filtered[0]?.nome} que eu encontrei em ${preferences.nome} por ${filtered[0]?.vlrvenda?.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}`,
                                                url: `https://${preferences.usa_pagina_centralizada ? preferences.codigo_pagina_centralizada : storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}${preferences.usa_pagina_centralizada ? '?store_code='+storecode: ''}`,
                                                title: `${filtered[0]?.nome} em ${preferences.nome}`,
                                            })
                                            .then(()=>{})
                                            .catch(()=>{})
                                        }}
                                        sx={{ color: color.primary }}>
                                            <ShareIcon />
                                        </IconButton>

                                        <IconButton
                                        onClick={() => setState({...state, qrcode: true})}
                                        sx={{ color: color.primary }}>
                                            <QrCodeIcon />
                                        </IconButton>
                                    </div>
                                </div>}
                                {productLoading ? 
                                <Skeleton variant="rounded" width={200} height={23} /> : 
                                <Typography
                                sx={{ 
                                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                    fontSize: '20px',
                                    fontWeight: 'bold'
                                }}
                                >
                                    {filtered[0]?.nome}
                                </Typography>}

                                {productLoading ? 
                                <Skeleton sx={{marginTop:'10px'}} variant="rounded" width={100} height={23} /> : 
                                <div
                                style={{
                                    marginTop:'20px'
                                }}>
                                    {promotag ? 
                                    <>
                                    <Typography sx={{
                                        //color:color.fontSecondary,
                                        color:'text.primary',
                                        textAlign:"left",
                                        fontSize:'20px',
                                        fontWeight:"bold",
                                        textDecoration:'line-through',
                                        opacity:0.7
                                    }}>
                                        {(parseFloat(filtered[0]?.valor))?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                                    </Typography>
                                    <Typography sx={{
                                        //color:color.fontSecondary,
                                        color:'text.primary',
                                        textAlign:"left",
                                        fontSize:'27px',
                                        fontWeight:"bold"
                                    }}>
                                        {vrvenda?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                                    </Typography>
                                    </> :
                                    <Typography sx={{
                                        //color:color.fontSecondary,
                                        color:'text.primary',
                                        textAlign:"left",
                                        fontSize:'23px',
                                        fontWeight:"bold"
                                    }}>
                                        {vrvenda?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                                    </Typography>
                                    }
                                </div>}

                                {filtered[0]?.variavel ?
                                <Typography sx={{
                                    //color:color.fontSecondary,
                                    color:'text.secondary',
                                    fontSize:'12px',
                                    textAlign:'center'
                                }}>
                                    Valor referente a {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida?.toUpperCase()}`}
                                </Typography> : 
                                <></>}

                                {filtered[0]?.incremento_minimo ?
                                <Typography sx={{
                                    color:'text.secondary',
                                    fontSize:'12px',
                                    fontWeight:'bold',
                                    marginBottom:'10px',
                                    textAlign:'center'
                                }}>Mínimo de {`${filtered[0]?.incremento_minimo} ${filtered[0]?.unidmedida}`}</Typography> : 
                                <></>}
                                
                                {parcela > 1 && !productLoading ?
                                <Typography sx={{
                                    //color:color.fontSecondary,
                                    color:'text.primary',
                                    textAlign:"center",
                                    fontSize:'15px',
                                    opacity:0.7
                                }}>
                                    {parcela}x de {(dividir/parcela).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}
                                </Typography> :
                                <></>}

                                {productLoading ? 
                                <div
                                style={{marginTop:'20px',marginBottom:size.width <= 700 ? '100px' : '0px'}}
                                >
                                    <Skeleton sx={{}} variant="rounded" width={200} height={15} />
                                    <Skeleton sx={{marginTop:'10px'}} variant="rounded" width={150} height={15} />
                                    <Skeleton sx={{marginTop:'10px'}} variant="rounded" width={180} height={15} />
                                    <Skeleton sx={{marginTop:'10px'}} variant="rounded" width={100} height={15} />
                                </div> : 
                                <div
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    marginTop:'20px'
                                    // backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                                    // borderRadius:'10px',
                                    // width:'calc(100% - 10px)',
                                }}
                                >
                                    {Object.entries(caracteristicas)?.map(e => (
                                        <div 
                                        style={{
                                            // width:'200px',
                                            marginBottom:'10px'
                                        }}>
                                            <Typography 
                                            sx={{
                                                margin:'0px 0px 5px 0px',
                                                fontWeight:'bold',
                                                color:'text.primary',
                                                fontSize:'12px'
                                            }}>{e[0]}</Typography>
                                            <div 
                                            style={{
                                                display:'flex',
                                                flexWrap:'wrap',
                                                justifyContent:'left'
                                            }}>
                                                {e[1]?.map(j => (
                                                    <ButtonBase
                                                    disabled={!caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo))}
                                                    sx={{
                                                        opacity: !caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo)) ? 0.35 : 1
                                                    }}
                                                    onClick={() => setFilter({
                                                        ...filter,
                                                        [e[0]] : filter[e[0]] && filter[e[0]].atributo === j.atributo ? undefined : j
                                                    })}>
                                                        {filter[e[0]]?.atributo === j.atributo &&
                                                        <div
                                                        style={{
                                                            position:'absolute',
                                                            width:'100%',
                                                            display:'flex',
                                                            justifyContent:'right',
                                                            height:'100%'
                                                        }}>
                                                            <CheckCircleIcon sx={{
                                                                fontSize:'15px',
                                                                color: color.fontSecondary
                                                            }} />
                                                        </div>}
                                                        <Typography sx={{
                                                            width:'fit-content',
                                                            padding:'5px',
                                                            borderRadius:'2.5px',
                                                            margin:'5px',
                                                            // color:filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor,
                                                            color: color.fontSecondary,
                                                            //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                                            fontSize:'12px',
                                                            //color:color.fontSecondary,
                                                            border: `1px solid ${filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor}`,
                                                            // backgroundColor:filter[e[0]]?.atributo === j.atributo ? j.cor : ''
                                                            backgroundColor: j.cor
                                                        }}>
                                                            {(j.atributo)?.toUpperCase()}
                                                        </Typography>
                                                    </ButtonBase>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}

                            </div>

                            <div>
                                {productLoading ? 
                                <div>
                                    <Skeleton sx={{}} variant="rounded" width={100} height={20} />
                                    <div
                                    style={{
                                        display:'flex',
                                        // flexDirection:'column',
                                        marginBottom:'15px',
                                        justifyContent:'space-between',
                                        alignItems:'center'
                                    }}
                                    >
                                        <Skeleton sx={{}} variant="rounded" width={150} height={15} />
                
                                        <div
                                        style={{
                                            width:'1px',
                                            height:'20px',
                                            backgroundColor: 'rgba(255,255,255,0.3)',
                                            borderRadius:'10px'
                                        }}
                                        ></div>
                
                
                                        <Skeleton sx={{}} variant="rounded" width={150} height={15} />
                                    </div>
                                </div> : 
                                <></>
                                }
                                {filtered[0]?.variavel ?
                                <div>
                                    <Typography sx={{
                                        color:'text.secondary',
                                        fontSize:'14px',
                                        fontWeight:'bold'
                                    }}>Adicionado</Typography>
                                    <div
                                    style={{
                                        display:'flex',
                                        marginBottom:'15px'
                                    }}
                                    >
                                        <Typography
                                        sx={{
                                            color: 'text.primary',
                                            fontSize:'15px',
                                            width:'50%'
                                        }}
                                        >Quantidade: {quantidade * (filtered[0]?.incrementovariavel || 1)} {filtered[0]?.unidmedida?.toUpperCase()}</Typography>
                
                                        <div
                                        style={{
                                            width:'1px',
                                            height:'20px',
                                            backgroundColor: 'rgba(255,255,255,0.3)',
                                            borderRadius:'10px'
                                        }}
                                        ></div>
                
                
                                        <Typography
                                        sx={{
                                            color: 'text.primary',
                                            fontSize:'15px',
                                            fontWeight:'bold',
                                            width:'calc(50% - 10px)',
                                            textAlign:'right'
                                        }}
                                        >Total: {(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                                    </div>
                                </div> :
                                <></>}

                                {productLoading ? 
                                <Skeleton sx={{marginBottom:'10px'}} variant="rounded" width={150} height={15} /> : 
                                <Typography
                                sx={{
                                    color:'text.primary',
                                    marginBottom:'10px'
                                }}
                                >
                                    {((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) <= 0) ? 'Sem estoque disponível' : 'Estoque disponível'}
                                </Typography>}

                                {
                                    productLoading ? 
                                    <Skeleton sx={{marginTop:'10px'}} variant="rounded" width={'100%'} height={39} /> : 
                                    <>
                                    {quantidade ? 
                                    <div
                                    style={{
                                        display:'flex',
                                        justifyContent:'center',
                                        textAlign:'center'
                                    }}>
                                        <IconButton
                                        disabled={disable()}
                                        sx={{
                                            opacity: disable() ? 0.5 : 1
                                        }}
                                        onClick={() => {
                                            if (quantidade === 0) {
                                                const ad = addToCart(filtered[0],cart,-1)
                                                if (ad[1]) {
                                                    const f = filtered
                                                    f[0].adicionado = undefined
                                                    setFiltered(f)
                                                    setCart(ad[0])
                                                    forceUpdate()
                                                }
                                            } else {
                                                setQuantidade(quantidade===0 ? 0 : quantidade - 1)
                                            }
                                        }}>
                                            {quantidade === 0 && filtered[0]?.adicionado !== undefined ? <DeleteIcon sx={{color: color.fontSecondary}} /> : <RemoveIcon sx={{color: color.fontSecondary}} />}
                                        </IconButton>

                                        <TextField
                                        value={quantidade}
                                        disabled={disable()}
                                        onChange={({target}) => {
                                            const quant = integer(target.value)
                                            if (filtered[0] && !filtered[0]?.ignoraecommerce && quant > (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))) return setQuantidade((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)))
                                            setQuantidade(quant)
                                        }}
                                        sx={{
                                            width:'100px',
                                            textAlign:'center',
                                            //color:color.fontSecondary,
                                            color:'text.primary',
                                        }}
                                        size='small'
                                        inputProps={{min: 0, 
                                            style: { 
                                                textAlign: 'center', 
                                                //color:color.fontSecondary,
                                                color:'text.primary',
                                            }}}
                                        />
                                        
                                        <IconButton
                                        disabled={filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()}
                                        sx={{
                                            opacity: (filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()) ? 0.5 : 1
                                        }}
                                        onClick={() => setQuantidade(quantidade+1)}>
                                            <AddIcon sx={{color: color.fontSecondary}} />
                                        </IconButton>
                                    </div> : 
                                    <Button
                                    onClick={() => setQuantidade(quantidade+1)}
                                    startIcon={
                                        <AddShoppingCartIcon />
                                    }
                                    fullWidth
                                    variant='contained'
                                    size=''
                                    sx={{
                                        backgroundColor: color.fontPrimary
                                    }}
                                    disabled={disable()}
                                    >
                                        adicionar ao carrinho
                                    </Button>
                                    }
                                    </>
                                }

                                {productLoading ? 
                                <div
                                style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'10px'}}>
                                    <Skeleton sx={{marginBottom:'10px'}} variant="rounded" width={150} height={15} />
                                </div> : 
                                <>
                                {!filtered[0]?.ignoraecommerce && ((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) > 0) ?
                                <Typography
                                sx={{
                                    fontSize:'13px',
                                    //color:color.fontSecondary,
                                    color:'text.secondary',
                                    opacity:0.8,
                                    textAlign:'center',
                                    marginTop:'5px'
                                }}>Restam {(filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))} {filtered[0]?.unidmedida?.toUpperCase()} no estoque</Typography> : 
                                <></>
                                }
                                </>
                                }
                            </div>
                        </div>

                        {size.width <= 700 ? <>
                            {<div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                                marginTop:'10px'
                            }}
                            >
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </div>}

                            {productLoading ? 
                            <Skeleton 
                            sx={{marginTop:'10px',marginLeft:'10px'}}
                            variant="rounded" width={size.width <= 700 ? size.width - 20 : 400} height={150} /> : 
                            <div
                            style={{
                                // width:'100%',
                                display:'flex',
                                minWidth:size.width <= 700 ? 'calc(100% - 20px)' : '400px',
                                marginTop:'10px',
                                backgroundColor: color.secondary,
                                padding:'10px',
                                borderRadius: '10px'
                            }}
                            >
                                <div
                                style={{
                                    width:'50%'
                                }}
                                >
                                    <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight:'bold'
                                    }}>Marca</Typography>
                                    {filtered[0]?.caracteristicas?.map(e => (
                                        <Typography 
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight:'bold'
                                        }}>{e.propriedade}</Typography>
                                    ))}
                                </div>
                                <div
                                style={{
                                    width:'50%'
                                }}>
                                    <Typography
                                    sx={{
                                        fontSize: '13px'
                                    }}>{filtered[0]?.nomemarca}</Typography>
                                    {filtered[0]?.caracteristicas?.map(e => (
                                        <Typography
                                        sx={{
                                            fontSize: '13px'
                                        }}>{e.atributo}</Typography>
                                    ))}
                                </div>
                            </div>}

                            <div
                            style={{
                                width:'100%',
                                marginTop:'20px',
                                padding:'10px'
                            }}
                            >
                                {productLoading ? 
                                <Skeleton variant="rounded" width={150} height={25} /> : 
                                <Typography
                                sx={{ 
                                    fontSize: '20px',
                                    fontWeight:'bold',
                                    textAlign:'left',
                                    width:'100%'
                                }}
                                >Descrição</Typography>}

                                {productLoading ? 
                                <>
                                <Skeleton sx={{marginTop: '20px'}} variant="rounded" width={250} height={15} />
                                <Skeleton sx={{marginTop: '10px'}} variant="rounded" width={250} height={15} />
                                <Skeleton sx={{marginTop: '10px'}} variant="rounded" width={250} height={15} />
                                </> : 
                                <Typography
                                sx={{ 
                                    fontSize: '18px',
                                    textAlign:'left',
                                    width:'100%'
                                }}
                                >{filtered[0]?.descricao}</Typography>}
                            </div>
                        </> : 
                        <></>}
                    </div>

                    {loading && !products.length ? 
                    <>
                        <Skeleton sx={{marginTop:'20px',marginBottom:'10px'}} variant="rounded" width={200} height={25} />
                        <ImageList
                        cols={parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180))}
                        sx={{ 
                            width: '100%', 
                            height: '100%',
                            overflowX:'hidden',
                            margin:0,
                            justifyContent:'space-around'
                        }}>
                        {[...new Array(parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180)))].map(e => (
                            <ImageListItem
                            sx={{
                                display:'flex',
                                alignItems:"center"
                            }}>
                                <ProductCard {...e} />
                            </ImageListItem>
                        ))}
                        </ImageList>
                    </>
                    : <>
                    {products.length ? <>
                            <Typography
                            sx={{ 
                                fontSize: '20px',
                                fontWeight:'bold',
                                textAlign:'left',
                                width:'100%',
                                margin:`20px ${size.width < 1200 ? '10px' : '0px'}`,
                                color: color.primary
                            }}
                            >PRÓXIMOS DE {state?.nome?.toUpperCase()?.split(' ').filter((e,index) => (index <= 1)).join(' ')}</Typography>

                            <ImageList
                            cols={parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180))}
                            sx={{ 
                                width: '100%', 
                                height: '100%',
                                overflowX:'hidden',
                                margin:0,
                                justifyContent:'space-around'
                            }}>
                            {(load ? (products || []) : [...new Array(parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180)))]).map(e => (
                                <ImageListItem
                                sx={{
                                    display:'flex',
                                    alignItems:"center"
                                }}>
                                    <ProductCard {...e} />
                                </ImageListItem>
                            ))}
                            </ImageList>
                        </> : <></>
                    }
                    </>}

                    {prodCategoria.length ? <>
                            <ButtonBase
                            onClick={() => navigate('/product',{
                                state: {
                                    nivelcategoria: 2,
                                    idprodutocategoria: state.idprodutocategorian2
                                }
                            })}
                            >
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:"center"
                                }}
                                >
                                    <Typography
                                    sx={{ 
                                        fontSize: '20px',
                                        fontWeight:'bold',
                                        textAlign:'left',
                                        width:'100%',
                                        margin:`20px ${size.width < 1200 ? '10px' : '0px'}`,
                                        color: color.primary
                                    }}
                                    >{state.categorian2}</Typography>

                                    <OpenInNewIcon sx={{color: color.primary, marginLeft:'20px'}} />

                                </div>
                            </ButtonBase>

                            <ImageList
                            cols={parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180))}
                            sx={{ 
                                width: '100%', 
                                height: '100%',
                                overflowX:'hidden',
                                margin:0,
                                justifyContent:'space-around'
                            }}>
                            {(load ? (prodCategoria || []) : [...new Array(parseInt((size.width >= 1200 ? 1200 : size.width) / (size.width > 950 ? 300 : 180)))]).map(e => (
                                <ImageListItem
                                sx={{
                                    display:'flex',
                                    alignItems:"center"
                                }}>
                                    <ProductCard {...e} />
                                </ImageListItem>
                            ))}
                            </ImageList>
                        </> : <></>
                    }
                </div>
                <ScrollToTop smooth style={{
                    backgroundColor: color.primary,
                    boxShadow: 'none'
                }} />
            </Box>
        </InfinityScroll>
    )
}