import axios from "axios"
import { useContext, useEffect } from "react"
import { toast } from "react-hot-toast"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { LoadingContext, LoginContext, UserContext } from "../../context"
import { api } from "../../utils/defaults"

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [login, setLogin] = useContext(LoginContext)
    const [loading, setLoading] = useContext(LoadingContext)

    const params = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!user.token) {
            setLogin(true)
        } else {
            setLoading(true)
            axios
            .get(`${api}/client/mail-confirm/${params.token.split('&')[0]}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => toast.success('E-mail verificado com sucesso'))
            .catch(err => toast.error(err.response.data))
            .finally(() => {
                setLoading(false)
                setUser({...user, email_verificado: true})
                if (searchParams.get('restore')) navigate(searchParams.get('restore').replaceAll(' ','&'))
                else navigate('/')
            })
        }
    },[user.token])

    return (
        <div>

        </div>
    )
}