const vlrvenda = (state) => {
    if (!state) return state
    if (state.valorfinal && !Number.isNaN(parseFloat(state.valorfinal))) return parseFloat(state.valorfinal)
    let promocao = Number.isNaN(state.promocao) ? undefined : parseFloat(state.promocao)
    let desconto = Number.isNaN(state.desconto) ? undefined : parseFloat(state.desconto)
    let porcdesconto = Number.isNaN(state.porcdesconto) ? undefined : parseInt(state.porcdesconto)

    if (promocao) return promocao

    let vlr = parseFloat(state.valor)
    vlr = vlr - (desconto || 0)

    if (desconto) return vlr

    vlr = vlr - (porcdesconto ? vlr*porcdesconto*0.01 : 0)
    
    return vlr
}

module.exports = vlrvenda