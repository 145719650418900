import { Button, FormControl, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, TextField, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { LoadingContext, LoginContext, UserContext } from "../../context"
import { api } from "../../utils/defaults"
import { passwordStrength } from 'check-password-strength'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [login, setLogin] = useContext(LoginContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [password, setPassword] = useState(false)
    const [pass, setPass] = useState(false)
    const [state, setState] = useState({})

    const params = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/client/reset-password/${params.token.split('&')[0]}`)
        .then(response => setPassword(true))
        .catch(err => {
            toast.error('Token inválido')
            navigate('/')
        })
        .finally(() => {
            setLoading(false)
        })
    },[user])

    const sendPassword = () => {
        if (passwordStrength(state.new_password).id !== 3) return toast.error('Crie uma senha forte')
        if (!state.new_password || !state.new_password_retype) return toast.error('Preencha os dois campos de senha')
        if (state.new_password !== state.new_password_retype) return toast.error('Senhas não conferem')

        setLoading(true)
        axios
        .post(`${api}/client/reset-password`,{
            senha: state.new_password
        },{
            headers: {
                Authorization: params.token.split('&')[0]
            }
        })
        .then(response => {
            toast.success('Senha alterada com sucesso')
            setLogin(true)
        })
        .catch(err => toast.error('Token inválido'))
        .finally(() => {
            setLoading(false)
            if (searchParams.get('restore')) navigate(searchParams.get('restore').replaceAll(' ','&'))
            else navigate('/')
        })
    }

    return (
        <div>
            <Modal
            open={password}
            sx={{
                justifyContent:"center",
                alignItems:'center',
                display:"flex"
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727',
                    minWidth:'300px',
                    display:'flex',
                    flexDirection:"column"
                }}>

                    <Typography 
                    sx={{
                        fontFamily:'Quicksand',
                        marginBottom:'10px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                    }}>Crie uma senha forte</Typography>

                    {/* <TextField 
                    value={state.new_password}
                    onChange={({target}) => setState({...state, new_password: target.value})}
                    label='Nova senha'
                    type='password'
                    onKeyDown={(event) => {
                        if (event.keyCode===13) sendPassword()
                    }}
                    helperText={
                        state.new_password && <Typography sx={{
                            color: strength_level[passwordStrength(state.new_password).id].color
                        }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                    }
                    /> */}

                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                        <OutlinedInput  
                        label='Nova senha'
                        type={pass ? 'text' : 'password'}
                        value={state.new_password}
                        onChange={({target}) => setState({...state, new_password: target.value})}
                        onKeyDown={(event) => {
                            if (event.keyCode===13) sendPassword()
                        }}
                        helperText={
                            state.new_password && <Typography sx={{
                                color: strength_level[passwordStrength(state.new_password).id].color
                            }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>

                    <TextField 
                    value={state.new_password_retype}
                    onChange={({target}) => setState({...state, new_password_retype: target.value})}
                    label='Confirme a senha'
                    type='password'
                    onKeyDown={(event) => {
                        if (event.keyCode===13) sendPassword()
                    }}
                    helperText={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype ? 'Senhas não conferem' : ''}
                    error={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype}
                    sx={{marginTop:'10px'}}
                    />

                    <Button
                    variant='outlined'
                    sx={{margin:'10px 0px'}}
                    onClick={() => sendPassword()}
                    >confirmar</Button>
                </div>
            </Modal>
        </div>
    )
}