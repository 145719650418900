import { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import fn_checkout from '../../../../utils/checkout'
import { IconButton } from '@mui/material';
import { LoadingContext, UserContext } from '../../../../context';
import { api } from '../../../../utils/defaults';
import axios from 'axios';

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [checkout_opened, setCheckout_opened] = useState(false)

    const theme = useTheme()

    const onReady = data => {

    }

    const onSubmit = data => {
        
    }

    const onSuccess = data => {

    }

    const onError = data => {
        
    }

    const openCheckout = () => {
        setLoading(true)
        axios
        .get(`${api.replace('/store','')}/checkout/mp/public_key?client=${user?.token}`)
        .then(response => {
            const mp = new window.MercadoPago(response.data);
            const bricksBuilder = mp.bricks();
            
            const renderPaymentBrick = async (bricksBuilder) => {
            const settings = {
            initialization: {
                amount: 100, // valor total a ser pago
                payer: {
                    email: "hiagotorres@gmail.com",
                }
            },
            customization: {
                maxInstallments: 2,
                paymentMethods: {
                    creditCard: 'all',
                    //debitCard: 'all',
                    // bankTransfer: ['pix'],
                    // ticket: 'all',
                    // account_money: ['account_money']
                },
                visual: {
                    style: {
                            theme: theme.palette.mode === 'light' ? 'default' : 'dark'
                        }
                }
            },
            callbacks: {
                onReady: () => setLoading(false),
                onSubmit: ({ selectedPaymentMethod, formData }) => {
                    // console.log(JSON.stringify(formData, null , 4))
                    return new Promise((resolve, reject) => {
                            fetch('https://localhost:3001/api/store/payment/add', {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": user.token
                                },
                                body: JSON.stringify({...formData})
                            })
                            .then((response) => {
                                console.log(response.data)
                                resolve();
                            })
                            .catch((error) => {
                                console.log(error)
                                reject();
                            })
                        });
                    },
                    onError: (error) => {
                        console.error(error);
                    },
                },
            };
            
            window.paymentBrickController = await bricksBuilder.create(
            'payment',
            'paymentBrick_container',
            settings
            );
            };
            
            renderPaymentBrick(bricksBuilder);
        })
        .catch(err => {
        })
    }

    return (
        <div
        style={{
            padding:'20px',
            borderRadius:'10px',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727' 
        }}>
            <IconButton
            onClick={openCheckout}
            // onClick={() => fn_checkout(theme,{
            //     onReady: data => onReady(data),
            //     onSubmit: data => onSubmit(data),
            //     onSuccess: data => onSuccess(data),
            //     onError: data => onError(data)
            // },{
            //     paymentMethods: {
            //         creditCard: 'all',
            //         debitCard: 'all',
            //         //bankTransfer: ['pix'],
            //         //ticket: 'all',
            //         //account_money: ['account_money']
            //     },
            //     initialization: {
            //         amount: parseFloat(Math.random().toFixed(2)),
            //         payer: {
            //             email: user.email,
            //         }
            //     }
            // },user?.token,{
            //     binary_mode: true,
            //     allon_aditional: {
            //         save_payment: true,
            //         api: 'https://localhost:3001/api/store/payment/add'
            //     }
            // })}
            >
                <AddCircleOutlineIcon />
            </IconButton>
            <div id="paymentBrick_container" style={{fontFamily:'Quicksand'}}></div>
        </div>
    )
}