import { useState, useReducer, useCallback, useEffect } from "react";

export type GeolocationOptionsProps = {
  suppressOnMount?: boolean;
  positionOptions?: {
    enableHighAccuracy: boolean;
    timeout: number;
    maximumAge: number;
  };
  watchMode?: boolean;
};

export type CoordinatesProps = {
  latitude: number | null;
  longitude: number | null;
  altitude: number | null;
  accuracy: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  speed: number | null;
};

export type GeolocationProps = {
  coords: CoordinatesProps | undefined;
  isEnabled?: boolean;
  isExpired?: boolean;
  isAvailable?: boolean;
  isSuppressed?: boolean;
  suppressRequest: (bool: boolean) => void;
  watchId: number | undefined;
};

const geolocationDefault = {
  suppressOnMount: false,
  positionOptions: {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  },
  watchMode: false,
};

const geolocationReducer = (
  state: GeolocationProps,
  action: { type: string; value?: boolean; coords?: CoordinatesProps; watchId?: number }
): GeolocationProps => {
  switch (action.type) {
    case "enable":
      return { ...state, isEnabled: action.value, coords: action.coords };
    case "available":
      return { ...state, isAvailable: action.value };
    case "suppressed":
      return { ...state, isSuppressed: action.value };
    case "timedout":
      return { ...state, isExpired: action.value };
    case "watch":
      return { ...state, watchId: action.watchId };
    default:
      return state;
  }
};

const useGeolocation = ({
  suppressOnMount = geolocationDefault.suppressOnMount,
  positionOptions = { ...geolocationDefault.positionOptions },
  watchMode = geolocationDefault.watchMode,
}: GeolocationOptionsProps = geolocationDefault): GeolocationProps => {
  const [suppress, setSuppress] = useState<boolean>(suppressOnMount);
  const [state, dispatch] = useReducer(geolocationReducer, {
    coords: undefined,
    isSuppressed: suppress,
    isAvailable: true,
    isEnabled: undefined,
    isExpired: false,
    suppressRequest: setSuppress,
    watchId: undefined,
  });

  const GeolocationPosition = useCallback(({ coords }: CoordinatesProps) => {
    if (!!coords) {
      dispatch({ type: "enable", value: true, coords });
    }
  }, []);

  const GeolocationPositionError = useCallback((error: PositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        dispatch({ type: "enable", value: false });
        break;
      case error.POSITION_UNAVAILABLE:
        dispatch({ type: "available", value: false });
        break;
      case error.TIMEOUT:
        dispatch({ type: "timedout", value: true });
        break;
      default:
        throw new Error("Position request got an error");
    }
  }, []);

  useEffect(() => {
    const handlePermission = ({ state }: { state: PermissionState }) => {
      if (state === "denied") {
        dispatch({ type: "enable", value: false });
      } else {
        if (!suppress) {
          if (watchMode) {
            if (!state.watchId) {
              const watchId = navigator.geolocation.watchPosition(
                GeolocationPosition,
                GeolocationPositionError,
                { ...positionOptions }
              );
              dispatch({ type: "watch", watchId });
            }
          } else {
            navigator.geolocation.getCurrentPosition(
              GeolocationPosition,
              GeolocationPositionError,
              { ...positionOptions }
            );
          }
        } else {
          dispatch({ type: "suppressed", value: true });
        }
      }
    };

    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then(handlePermission);
    } else {
      dispatch({ type: "available", value: false });
    }
  }, [suppress, watchMode, state.watchId]);

  return state;
};

export default useGeolocation;