import React, { useState, useEffect, useContext, useRef } from 'react'
import { BrowserRouter, Route, Routes, Navigate, useSearchParams, useNavigate, useLocation } from 'react-router-dom'

import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'

import { 
    ColorModeContext,
    SizeContext,
    PreferencesContext,
    BlurContext,
    UserContext,
    LoadingContext,
    CheckoutContext,
    LoadingCheckoutContext,
    LoginContext,
    ProductsContext,
    CartContext,
    EmailConfirmContext,
    ReplaceOrdeContext,
    FilterContext,
    SocketContext,
    StoreContext,
    KeyContext,
    CardContext,
    AnalyticsContext
} from './context'

import {
  Modal,
  CircularProgress,
  Typography,
  Box,
  useMediaQuery,
  Button,
  ButtonBase,
  TextField,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Link,
  Checkbox,
  FormControlLabel
} from '@mui/material'

import usePersistedState from './utils/usePersistedState'

import * as Components from './components/handleComponents'
import Menu from './components/menu'
import Logo from './components/logo'
import Login from './components/cliente/login'
import Wallet from './components/cliente/wallet'
import AddCard from './components/cliente/wallet/addCard'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios'
import { api } from './utils/defaults'
import logout from './utils/logout'
import { io } from 'socket.io-client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt-br';
import getColors from './components/getColor'
import closed from './static/closed.json'
import no_network from './static/no_network.json'
import Lottie from "react-lottie-player";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputMask from 'react-input-mask';
import useGeolocation from "./utils/useGeolocation.ts";
import StorefrontIcon from '@mui/icons-material/Storefront';

import K from './utils/key'
import { deviceDetect } from 'react-device-detect';

import ReactPWAInstallProviderfrom from "react-pwa-install";
import dotenv from 'dotenv';

const datetime = Date.now()
dotenv.config();

if (localStorage.code) {
    axios.interceptors.request.use((config) => {
        const queryString = `store_code=${localStorage.code}`;
        
        config.url += `${config.url.includes('?') ? '&' : '?'}${queryString}`;
        
        return config;
    });
}

export default function App (props) {
    const [key, setKey]                   = useState(K.getKey())
    const [mode, setMode]                 = usePersistedState('_theme','dark')
    const [loading, setLoading]           = useState(false)
    const [user, setUser]                 = usePersistedState('data',{},key)
    const [preferences, setPreferences]   = usePersistedState('_preferences',{},key);
    const [analytics, setAnalytics]       = usePersistedState('_analytics',{},key);
    const [size, setSize]                 = useState({ width:window.innerWidth ,height:window.innerHeight })
    const [blur, setBlur]                 = useState(false)
    const [checkout, setCheckout]         = useState(false)
    const [loadingCheckout, setLoadingCheckout] = useState(false)
    const [login, setLogin]               = useState(false)
    const [products, setProducts]         = useState([])
    const [cart, setCart]                 = usePersistedState('_cart',[], key)
    const [emailConfirm, setEmailConfirm] = useState(false)
    const [replaceOrder, setReplaceOrder] = usePersistedState('replaceOrder',{},key)
    const [filter, setFilter]             = useState({nome:""})
    const [store, setStore]               = usePersistedState('_store',{list:[]}, key)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => setMode(prefersDarkMode ? 'dark' : 'light'),[prefersDarkMode])

    const colorMode = React.useMemo(() => ({toggleColorMode: () => {setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))},}),[],)
    const theme = React.useMemo(() =>createTheme({palette: {mode,},preferences}),[mode],)

    const socket = React.useMemo(() => {
        return io('https://api.allon.cloud', {
            extraHeaders: {
                key: user.token,
                access_type: 'secret_client_key'
            }
        })
    },[user.token])

    useEffect(() => {
        setKey(K.setKey())

        window.addEventListener('resize', () => setSize({width:window.innerWidth,height:window.innerHeight}))

        return () => {
            window.removeEventListener('resize', () => setSize({width:window.innerWidth,height:window.innerHeight}))
        }
    },[])

    useEffect(() => {
        if (!preferences.idloja || !preferences.idlojaecommerce) return
        const storecode = preferences.storecode

        document.querySelector('#icon')?.setAttribute('href', `https://${storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`);
        document.querySelector('title').innerHTML = preferences.codigo?.charAt(0).toUpperCase() + preferences.codigo?.slice(1);

        const t = setInterval(() => {
            axios.get(`${api}/preferences/open/${preferences.idlojaecommerce}`)
            .then(response => {
                setPreferences({...preferences, aberta: true, closed_message: false})
                if (!preferences.aberta) toast.success('Loja aberta')
            })
            .catch(err => setPreferences({...preferences, aberta: false, closed_message: !preferences.idlojaecommerce || preferences.aberta ? true : false}))
        }, 120000);

        return () => {
            clearInterval(t)
        }
    },[
        preferences.storecode, 
        preferences.aberta,
        preferences.codigo,
        preferences.open_location
    ])

    const loginRef = useRef()
    
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ReactPWAInstallProviderfrom enableLogging={false}>
            <KeyContext.Provider value={[key, setKey]}>
            <StoreContext.Provider value={[store, setStore]}>
            <ThemeProvider theme={theme}>
            <PreferencesContext.Provider value={[preferences, setPreferences]}>
            <LoadingContext.Provider value={[loading, setLoading]}>
            <UserContext.Provider value={[user, setUser]}>
            <BlurContext.Provider value={[blur, setBlur]}>
            <SizeContext.Provider value={[size, setSize]}>
            <CheckoutContext.Provider value={[checkout, setCheckout]}>
            <LoadingCheckoutContext.Provider value={[loadingCheckout, setLoadingCheckout]}>
            <LoginContext.Provider value={loginRef}>
            <ProductsContext.Provider value={[products, setProducts]}>
            <CartContext.Provider value={[cart, setCart]}>
            <EmailConfirmContext.Provider value={[emailConfirm, setEmailConfirm]}>
            <ReplaceOrdeContext.Provider value={[replaceOrder, setReplaceOrder]}>
            <FilterContext.Provider value={[filter, setFilter]}>
            <SocketContext.Provider value={socket}>
            <AnalyticsContext.Provider value={[analytics, setAnalytics]}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <Modal open={loading} sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <CircularProgress color='secondary' />
                </Modal>
                <Modal
                open={emailConfirm}
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div
                    style={{
                        padding:'20px',
                        borderRadius:'10px',
                        backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                        width:'350px'
                    }}>
                        <Typography 
                        sx={{
                            color:'text.primary',
                            fontSize:'20px',
                            fontWeight:'bold'
                        }}>Pedimos desculpas pelo incômodo</Typography>
                        <div
                        style={{
                            marginTop:'20px'
                        }}>
                            <Typography sx={{
                                color:'text.primary'
                            }}>Olá, sabemos que é chato, mas para continuar
                            usando algumas funções, você precisa verificar seu E-mail.</Typography>
                            <Typography sx={{
                                color:'text.primary',
                                marginTop:'10px'
                            }}>Isso é para a sua própria segurança, ok?</Typography>
                            <Typography sx={{
                                color:'text.primary',
                                marginTop:'30px',
                                fontSize:'15px'
                            }}>Se precisar de outro E-mail de confirmação, é só clicar abaixo.</Typography>

                            <Button
                            onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/client/mail-confirm${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,{

                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => {
                                    toast.success('Enviado')
                                    setEmailConfirm(false)
                                })
                                .catch(err => toast.error(err.response.data))
                                .finally(() => setLoading(false))
                            }}
                            color='success'
                            variant='contained'
                            fullWidth
                            sx={{marginTop:'10px'}}
                            >Enviar E-mail</Button>
                            <Button
                            onClick={() => {
                                setEmailConfirm(false)
                                setLoading(true)
                                axios
                                .get(`${api}/client/confirmed`,{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => setUser({...user, email_verificado: response.data}))
                                .catch(err => toast.error(err.response.data))
                                .finally(() => setLoading(false))
                            }}
                            sx={{
                                fontSize:'12px',
                                marginTop:'10px'
                            }}
                            fullWidth
                            >Já tenho o E-mail</Button>

                            <Typography sx={{
                                color:'text.primary',
                                marginTop:'30px',
                                fontSize:'15px',
                                opacity:0.7
                            }}>Um E-mail não confirmado pode ser cadastrado por outro CPF e gerar inconvenientes</Typography>
                        </div>
                    </div>
                </Modal>

                <ToastContainer 
                stacked
                closeOnClick
                theme={theme.palette.mode} />

                <BrowserRouter>
                    <Login ref={loginRef} />
                    <Routes>

                        {/* <Route 
                        exact 
                        path="/login" 
                        element={<Components.Login />} /> */}

                        <Route 
                        exact 
                        path="/" 
                        element={<Theme
                        
                        ><Components.Home /></Theme>} />

                        <Route 
                        exact 
                        path="/product" 
                        element={<Theme
                        
                        ><Components.Product /></Theme>} />

                        <Route 
                        exact 
                        path="/product/:idproduto" 
                        element={<Theme
                        
                        ><Components.DetailProduct /></Theme>} />

                        <Route 
                        exact 
                        path="/action/:idlojaecommerceacao" 
                        element={<Theme
                        
                        ><Components.Action /></Theme>} />

                        <Route 
                        exact 
                        path="/order" 
                        element={<Theme
                        lock={true}
                        menu={false}
                        ><Components.Order /></Theme>} />

                        <Route 
                        exact 
                        path="/account" 
                        element={<Theme
                        lock={true}
                        menu={false}
                        ><Components.Account /></Theme>} />

                        <Route 
                        exact 
                        path="/order/:idvenda" 
                        element={<Theme
                        lock={true}
                        menu={false}
                        ><Components.OrderDetail /></Theme>} />

                        <Route 
                        exact 
                        path="/mail-confirm/:token" 
                        element={<Theme
                        lock={false}
                        menu={true}
                        ><Components.MailConfirm /></Theme>} />

                        <Route 
                        exact 
                        path="/reset-password/:token" 
                        element={<Theme
                        lock={false}
                        menu={true}
                        ><Components.ResetPassword /></Theme>} />

                        <Route 
                        exact 
                        path="/payment" 
                        element={<Theme
                        lock={false}
                        menu={false}
                        ><Components.Payment /></Theme>} />

                        <Route 
                        exact 
                        path="/verify" 
                        element={<Theme
                        lock={false}
                        menu={false}
                        ><Components.Verify /></Theme>} />

                        <Route 
                        exact 
                        path="/readme/privacy" 
                        element={<Theme
                        lock={false}
                        menu={false}
                        ><Components.Readme.Privacy /></Theme>} />

                        <Route 
                        exact 
                        path="/readme/terms" 
                        element={<Theme
                        lock={false}
                        menu={false}
                        ><Components.Readme.Terms /></Theme>} />

                        {/* <Route 
                        exact 
                        path="/dash" 
                        element={<Security_internal_shield><Components.Dash /></Security_internal_shield>} />  */}


                        <Route path="*" element={<Navigate to={'/'} />} />

                    </Routes>
                </BrowserRouter>
            </LocalizationProvider>
            </AnalyticsContext.Provider>
            </SocketContext.Provider>
            </FilterContext.Provider>
            </ReplaceOrdeContext.Provider>
            </EmailConfirmContext.Provider>
            </CartContext.Provider>
            </ProductsContext.Provider>
            </LoginContext.Provider>
            </LoadingCheckoutContext.Provider>
            </CheckoutContext.Provider>
            </SizeContext.Provider>
            </BlurContext.Provider>
            </UserContext.Provider>
            </LoadingContext.Provider>
            </PreferencesContext.Provider>
            </ThemeProvider>
            </StoreContext.Provider>
            </KeyContext.Provider>
            </ReactPWAInstallProviderfrom>
        </ColorModeContext.Provider>
    )
}

function Theme ({children, menu=true, lock=false}) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [loading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [user, setUser] = useContext(UserContext)
    const [store, setStore] = useContext(StoreContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)

    const [checkout, setCheckout] = useContext(CheckoutContext)
    const [network, setNetwork] = useState({connection: true, modal: false, already: false})
    const [state, setState] = useState({})

    const [searchParams] = useSearchParams();

    const location = useLocation()
    const navigate = useNavigate()
    const loginRef = useContext(LoginContext)
    const geolocation = useGeolocation()

    const adicionarMetaTags = (titulo, descricao, imagem) => {
        const metaTags = [
            { name: 'og:title', content: titulo },
            { name: 'og:description', content: descricao },
            { name: 'og:image', content: imagem },
        ];
  
        metaTags.forEach((metaTag) => {
            const tag = document.createElement('meta');
            tag.setAttribute('property', metaTag.name);
            tag.setAttribute('content', metaTag.content);
            document.head.appendChild(tag);
        });
    };
    
    const adicionarConfiguracoesPWA = (manifestParams, iconURL, splashURL) => {
        const manifest = document.createElement('link');
        manifest.rel = 'manifest';
        manifest.href = URL.createObjectURL(
            new Blob([JSON.stringify(manifestParams)], { type: 'application/json' })
        );
        document.head.appendChild(manifest);
  
        const linkIcon = document.createElement('link');
        linkIcon.rel = 'icon';
        linkIcon.href = iconURL;
        document.head.appendChild(linkIcon);
  
        const linkSplash = document.createElement('link');
        linkSplash.rel = 'apple-touch-startup-image';
        linkSplash.sizes = '2048x2732';
        linkSplash.href = splashURL;
        document.head.appendChild(linkSplash);
    };

    const update_theme = ( code = undefined ) => {
        if (Date.now() - preferences.last_update < 60 * 60 * 1000 && !code) {
            const color = getColors(preferences, theme)

            document.querySelector('html').style = `background-color:${color.background}`
            document.querySelector('body').style = `background-color:${color.background}`
            document.querySelector('#root').style = `background-color:${color.background}`

            adicionarConfiguracoesPWA({
                "short_name": preferences?.usa_pagina_centralizada ? preferences.codigo_pagina_centralizada : preferences.codigo,
                "name": preferences.nome,
                "icons": [
                  {
                    "src": "favicon.ico",
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                  },
                  {
                    "src": "logo192.png",
                    "type": "image/png",
                    "sizes": "192x192"
                  },
                  {
                    "src": "logo512.png",
                    "type": "image/png",
                    "sizes": "512x512"
                  }
                ],
                "start_url": ".",
                "display": "standalone",
                "theme_color": color.primary,
                "background_color": color.background
            }, `https://${preferences.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`,
            `https://${preferences.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`)

            adicionarMetaTags(preferences.nome, `Loja virtual ${preferences.nome}`, `https://${preferences.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`)

            return setLoading(false)
        } else {
            setLoading(true)
            axios
            .get(`${api}/preferences`)
            .then(response => {

                const r = { ...response.data, last_update: Date.now() }
                const color = getColors(r, theme)

                document.querySelector('html').style = `background-color:${color.background}`
                document.querySelector('body').style = `background-color:${color.background}`
                document.querySelector('#root').style = `background-color:${color.background}`

                adicionarConfiguracoesPWA({
                    "short_name": r?.usa_pagina_centralizada ? r.codigo_pagina_centralizada : r.codigo,
                    "name": r.nome,
                    "icons": [
                      {
                        "src": "favicon.ico",
                        "sizes": "64x64 32x32 24x24 16x16",
                        "type": "image/x-icon"
                      },
                      {
                        "src": "logo192.png",
                        "type": "image/png",
                        "sizes": "192x192"
                      },
                      {
                        "src": "logo512.png",
                        "type": "image/png",
                        "sizes": "512x512"
                      }
                    ],
                    "start_url": ".",
                    "display": "standalone",
                    "theme_color": color.primary,
                    "background_color": color.background
                }, `https://${r.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`,
                `https://${r.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`)
    
                adicionarMetaTags(r.nome, `Loja virtual ${r.nome}`, `https://${r.storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`)

                setPreferences({
                    ...(code ? preferences : {}),
                    ...r,
                    closed_message: r.aberta === false,
                    // storecode: response.data.codigo,
                    // storecode_matriz: response.data.codigo,
                    storecode: response.data.pagina_centralizada ? undefined : response.data.codigo,
                    storecode_matriz: response.data.codigo,
                    open_location: !response.data.usa_pagina_centralizada || code ? false : true
                })
            })
            .catch(err => {
                if (navigator.onLine && !preferences.idlojaecommerce) setNotFound(true)
            })
            .finally(() => setLoading(false))
        }
    }

    const verifyAccount = () => {
        const controller = new AbortController()
        axios
        .get(`${api}/client/integration/verify`,{
            headers: {
                Authorization: user?.token
            },
            signal: controller.signal
        })
        .then(response => {
            if ( response?.data?.require_verification === true && window.location.pathname !== '/verify') navigate('/verify')
        })
        .catch(err => {
            if ( err?.response?.data?.require_verification === true && window.location.pathname !== '/verify') navigate('/verify')
        })

        return controller
    } 

    useEffect(() => {
        if (searchParams.get('store_code')) {
            setLoading(true)
            axios
            .get(`${api}/preferences?store_code=${searchParams.get('store_code')}`,{
                headers: {}
            })
            .then(response => {

                const r = {
                    ...response.data,
                    last_update: Date.now()
                }
                const color = getColors(r, theme)

                document.querySelector('html').style = `background-color:${color.background}`
                document.querySelector('body').style = `background-color:${color.background}`
                document.querySelector('#root').style = `background-color:${color.background}`

                axios.interceptors.request?.handlers?.map((e,index) => {
                    axios.interceptors.request.eject(index)
                })
    
                axios.interceptors.request.use((config) => {
                    const queryString = `store_code=${searchParams.get('store_code')}`;
                    
                    config.url += `${config.url.includes('?') ? '&' : '?'}${queryString}`;
                    
                    return config;
                });
    
                localStorage.code = searchParams.get('store_code')

                setPreferences({
                    ...(preferences || {}),
                    ...r,
                    closed_message: r.aberta === false,
                    storecode: response.data.pagina_centralizada ? undefined : response.data.codigo,
                    storecode_matriz: response.data.codigo,
                    // open_location: !response.data.usa_pagina_centralizada || store.selected || code ? false : true
                    open_location: false
                })
            })
            .catch(err => {
                if (navigator.onLine && !preferences.idlojaecommerce) setNotFound(true)
            })
            .finally(() => setLoading(false))
        }
        else update_theme()

        if (searchParams.get('idvenda')) {
            if (!user.token) loginRef.current?.open()
            else navigate(`/order/${searchParams.get('idvenda')}`)
        }

        setNetwork({connection:navigator.onLine, modal: !navigator.onLine})
    } ,[])

    useEffect(() => {
        if (!network.connection && navigator.online) {
            toast.success('Você está conectado')
        }
        setNetwork({connection:navigator.onLine, modal: !network.already && !navigator.onLine})
    },[navigator.online])
    
    useEffect(() => {
        if ( !user.token ) return 
        const controller = verifyAccount()

        return () => {
            controller.abort()
        }
    }, [user.token])

    const theme = useTheme()
    const color = getColors(preferences, theme)

    const coords = geolocation.coords
    const CancelToken = axios.CancelToken;
    let cancel;

    const findStore = (geolocation=false) => {
        setLoading(true)
        axios
        .get(`${api}/centralized?${geolocation?'latlng':'cep'}=${geolocation ? `${coords?.latitude},${coords?.longitude}` : preferences.client_cep}`)
        .then(response => {
            setStore({open:true, list:response.data})
        })
        .catch(err => toast.error(err.response?.data))
        .finally(() => setLoading(false))
    }  

    useEffect(() => {
        document.querySelector('html').style = `background-color:${color.background}`
        document.querySelector('body').style = `background-color:${color.background}`
        document.querySelector('#root').style = `background-color:${color.background}`
    },[theme.palette.mode])

    useEffect(() => {
        if (cancel !== undefined) cancel()
        if ( ['/readme/privacy','/readme/terms'].indexOf(location.pathname) !== -1 ) return setAnalytics({ ...analytics, open_policy: false })
        if ( !analytics.privacy || !analytics.terms ) return setAnalytics({ ...analytics, open_policy: true })

        const device = deviceDetect()

        axios
        .post(`${api}/client/metadata`,{
            unique_id: analytics.unique_id,
			isbrowser: device.isBrowser ? true : false ,
			osversion: device.osVersion,
			useragent: device.userAgent,
			browsername: device.browserName,
			model: device.model,
			vendor: device.vendor,
            os: device.os || device.osName
        },{
            headers: {
                Authorization: user.token || datetime
            },
            cancelToken: new CancelToken(function executor(c) {
                // Armazena a função de cancelamento para uso futuro
                cancel = c;
            })
        })
        .then(response => setAnalytics({ ...analytics, unique_id: response.data?.unique_id }))
        .catch(err => {})
    },[
        user.token,
        analytics.open_policy
    ])

    const openLocation = () => {
        if ( ['/order','/account','/payment','/teste','/readme/privacy','/readme/terms'].indexOf(location.pathname) !== -1 ) return false
        if ( location.pathname.indexOf('/order') !== -1 ) return false 
        if ( analytics.open_policy ) return false
        if ( store.open ) return false

        return preferences.open_location
    }

    return (
        <>
        <Modal
        open={analytics.open_policy && !notFound}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:"flex"
        }}
        >
            <div
            style={{
                borderRadius:'10px',
                padding:'20px',
                backgroundColor: color.background,
                display:'flex',
                flexDirection:'column',
                maxWidth:'250px'
            }}>
                <Typography
                sx={{
                    color:'text.primary',
                    fontWeight:'bold',
                    fontSize:'20px'
                }}
                >Termos e políticas</Typography>

                <Typography
                sx={{
                    color:'text.primary',
                    marginTop:'10px'
                }}
                >
                    Para continuar nessa plataforma você precisa concordar com nossa 
                    {analytics.privacy ? ' política de privacidade ' : 
                    <Link
                    disabled={analytics.privacy}
                    href='/readme/privacy'
                    > política de privacidade </Link>}
                    e nossos {analytics.terms ? ' termos de uso ' : 
                    <Link disabled={analytics.terms} href='/readme/terms'>termos de uso</Link>}.
                </Typography>

                <FormControlLabel 
                control={<Checkbox 
                    checked={state.terms}
                    onChange={({ target }) => setState({ ...state, terms: target.checked })}
                />} 
                label={
                    <Typography sx={{ color: 'text.primary'}}>Aceito os termos de uso</Typography>
                } 
                />

                <FormControlLabel control={
                    <Checkbox
                    checked={state.privacy}
                    onChange={({ target }) => setState({ ...state, privacy: target.checked })}
                    />} 
                label={
                    <Typography sx={{ color: 'text.primary'}}>Aceito a política de privacidade</Typography>
                } 
                />
                <Button
                sx={{
                    // color:color.primary,
                    marginTop:'10px'
                }}
                disabled={!state.terms || !state.privacy}
                onClick={() => setAnalytics({ privacy: true, terms: true})}
                size='small'
                variant='outlined'
                fullWidth
                color='success'
                >continuar</Button>
            </div>
        </Modal>
        
        <Modal
        open={store.open}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:"flex"
        }}
        >
            <List 
            sx={{ 
                width: '100%', 
                maxWidth: 360, 
                bgcolor: 'background.paper',
                maxHeight:'90vh',
                overflowY: 'auto',
                borderRadius: '10px'
            }}>
                {store.selected && (
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'right',
                        width:'100%'
                    }}
                    >
                        <Button
                        color='error'
                        onClick={() => setStore({ ...store, open: false })}
                        >
                            fechar
                        </Button>
                    </div>
                )}
                {!store.list?.length ? 
                <div
                style={{
                    // padding:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'right',
                        width:'100%'
                    }}
                    >
                        <Button
                        color='error'
                        onClick={() => setStore({ open: false, selected: undefined, list: []})}
                        >
                            fechar
                        </Button>
                    </div>
                    <Typography 
                    sx={{
                        color: 'text.primary', 
                        textAlign:'center',
                        marginBottom:'20px',
                        fontWeight:'bold'
                    }}>
                    Nenhuma loja próxima encontrada</Typography>
                </div>
                :store.list?.map(e => (
                    <ListItemButton
                    onClick={() => {
                        axios.interceptors.request?.handlers?.map((e,index) => {
                            axios.interceptors.request.eject(index)
                        })

                        axios.interceptors.request.use((config) => {
                            const queryString = `store_code=${e.codigo}`;
                            
                            config.url += `${config.url.includes('?') ? '&' : '?'}${queryString}`;
                            
                            return config;
                        });

                        localStorage.code = e.codigo

                        setStore({open: false, selected: e, list:store.list})
                        update_theme(e.codigo)
                    }}
                    >
                        <ListItemAvatar>
                            <StorefrontIcon sx={{color: color.fontPrimary}} />
                        </ListItemAvatar>
                        <ListItemText primary={
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between'
                            }}>
                                <Typography sx={{color:'text.primary'}}>{e.nome}</Typography>
                                <Typography sx={{color:'text.secondary', fontSize:'13px'}}>{e.distancia?.toFixed(2)} Km</Typography>
                            </div>
                        } secondary={e.gmaps_info?.results[0]?.formatted_address} />
                    </ListItemButton>
                ))}
            </List>
        </Modal>

        <Modal
        open={openLocation()}
        sx={{
            justifyContent:'center',
            alignItems:'center',
            display:"flex"
        }}
        >
            <div
            style={{
                borderRadius:'10px',
                padding:'20px',
                backgroundColor: color.background,
                display:'flex',
                flexDirection:'column',
                maxWidth:'250px'
            }}
            >
                <Typography
                sx={{
                    color: 'text.primary'
                }}
                >
                    Precisamos encontrar o estabelecimento mais próximo.
                </Typography>
                <Typography
                sx={{
                    color: 'text.secondary',
                    fontSize: '13px',
                    marginBottom:'40px'
                }}>
                    Onde você vai receber ou buscar sua encomenda ?
                </Typography>
                
                <InputMask 
                mask="99999-999" 
                maskChar={null}
                value={preferences.client_cep}
                onChange={({target}) => setPreferences({...preferences, client_cep:target.value})}
                >
                {(inputProps) => <TextField 
                                {...inputProps}
                                inputProps={{
                                    id:'cep'
                                }}
                                InputLabelProps={{shrink:preferences.client_cep?true:false}}
                                label='CEP'
                                onKeyDown={(event) => {
                                }}
                                sx={{
                                    width:'100%',
                                    margin:'10px 0px'
                                }}
                                size='small'
                                />}
                </InputMask>

                <Button
                sx={{
                    color:color.fontPrimary,
                    borderColor: color.fontPrimary
                }}
                variant='outlined'
                onClick={() => findStore(false)}
                >
                    buscar
                </Button>

                <ButtonBase 
                disabled={!geolocation.isAvailable || !geolocation.isEnabled}
                onClick={() => findStore(true)}
                sx={{
                    color:color.fontPrimary,
                    marginTop:'10px',
                    width:'100%',
                    padding:'10px',
                    opacity: !geolocation.isAvailable || !geolocation.isEnabled ? 0.2 : 1
                    // justifyContent:'space-evenly'
                    }}>
                    <LocationOnIcon />
                    <Typography sx={{marginLeft:'10px'}}>Usar localização</Typography>
                </ButtonBase>
            </div>
        </Modal>

        <Modal
        open={loading}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:'rgba(0,0,0,0.85)'
        }}
        >
            <div
            style={{
                width:'300px',
                height:'200px',
                borderRadius:'20px',
                justifyContent:'center',
                alignItems:'center',
                display:'flex',
                flexDirection:"column",
                backgroundColor:theme.palette.mode === 'dark' ? '#000' : '#fff'
            }}
            >
                <CircularProgress sx={{
                    //position:'absolute'
                }} />
                <Typography
                sx={{
                    color: theme.palette.mode !== 'dark' ? '#000' : '#fff',
                    // position:'relative',
                    // top:'50px',
                    marginTop:'10px'
                }}
                >Carregando tema...</Typography>
            </div>
        </Modal>

        <Modal
        open={false}
        onClose={() => {}}
        sx={{
            justifyContent:'center',
            alignItems:"center",
            display:'flex'
        }}>
            <Wallet />
        </Modal>

        <Modal
        open={false}
        onClose={() => {}}
        sx={{
            justifyContent:'center',
            alignItems:"center",
            display:'flex'
        }}>
            <AddCard />
        </Modal>

        <Modal
        open={preferences.closed_message && network.connection}
        // open={true}
        onClose={() => setPreferences({...preferences, closed_message: false})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%'
        }}
        >
            <div
            style={{
                // width:'380px',
                maxWidth:'400px',
                width:'100%',
                padding:'20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                borderRadius:'10px'
            }}
            >
                <Typography
                sx={{
                    fontSize:'30px',
                    color:'text.primary'
                }}
                >Loja Fechada</Typography>
                <div
                style={{
                    // width:'350px'
                }}
                >
                    <Lottie 
                    animationData={closed}
                    // segments={[57,271]}
                    // goTo={57}
                    height={'70px'}
                    width={'70px'}
                    onEnterFrame={(data) => {
                        // if (data.currentTime >= 152) {
                        //     setStartSector(false)
                        // }
                    }}
                    play={true}
                    // onClick={() => setStart(true)}
                    loop={false}
                    />
                </div>
                <Typography
                sx={{
                    fontSize:'20px',
                    color:'text.primary'
                }}
                >Nossa loja está fechada, mas você pode continuar navegando para ver nosso catálogo e montar seu carrinho 😁</Typography>
            
                <Button 
                color='success' 
                sx={{marginTop:'15px'}}
                onClick={() => setPreferences({...preferences, closed_message: false})}>Entendi</Button>
            </div>
        </Modal>

        <Modal
        open={network.modal}
        // open={true}
        onClose={() => setNetwork({...network, modal: false, already: true})}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%'
        }}
        >
            <div
            style={{
                maxWidth:'400px',
                width:'100%',
                padding:'20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                borderRadius:'10px'
            }}
            >
                <Typography
                sx={{
                    fontSize:'30px',
                    color:'text.primary'
                }}
                >Oops...</Typography>
                <div
                style={{
                    width:'350px'
                }}
                >
                    <Lottie 
                    animationData={no_network}
                    // segments={[57,271]}
                    // goTo={57}
                    height={'70px'}
                    width={'70px'}
                    onEnterFrame={(data) => {
                        // if (data.currentTime >= 152) {
                        //     setStartSector(false)
                        // }
                    }}
                    play={true}
                    // onClick={() => setStart(true)}
                    loop={false}
                    />
                </div>
                <Typography
                sx={{
                    fontSize:'20px',
                    color:'text.primary'
                }}
                >Parece que você está sem conexão com a internet, mas você pode continuar navegando para ver nosso catálogo e montar seu carrinho 😁. 
                Nós te avisaremos se os valores dos ítens no seu carrinho mudarem quando sua internet voltar.</Typography>
            
                <Button 
                color='success' 
                sx={{marginTop:'15px'}}
                onClick={() => setNetwork({...network, modal: false, already: true})}>Entendi</Button>
            </div>
        </Modal>

        {/* <Checkout /> */}
        
        {notFound && ['/readme/privacy','/readme/terms'].indexOf(location.pathname) === -1 ? 
            <Box
            style={{
                width:'100vw',
                height:'100vh',
                left:0,
                top:0,
                position:'fixed',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
            }}
            sx={{
                bgcolor: 'background.default',
                color:'text.primary'
            }}>
                <Logo fontSize={120}/>
                <Typography>Infelizmente não encontramos o site que procura...</Typography>
            </Box>
        : 
        (
            lock ? <Security_shield menu={menu}>{children}</Security_shield> : (menu ? <Menu checkout={() => setCheckout(true)}>{children}</Menu> : children)
        )
        }
        </>
    )
}

const Security_shield = props => {
    const [user, setUser] = useContext(UserContext)
    const loginRef = useContext(LoginContext)
    
    useEffect(() => {
        if (!user.token) return loginRef.current?.open()
        if (user.exp <= Date.now()) return loginRef.current?.open()
    },[])

    return props.menu ? <Menu>{props.children}</Menu> : props.children
    
}