import { Typography } from "@mui/material"

const Header = ( { style, children, ...props } ) => {

    return (
        <Typography sx={{ color:'text.primary', fontWeight:"bold", fontSize:'17px', ...style }}>{children}</Typography>
    )
}

const Topic = ( { style, children, ...props } ) => {

    return (
        <Typography sx={{ color:'text.primary', fontWeight:"bold", fontSize:'14px', ...style }}>{children}</Typography>
    )
}

const SubTopic = ( { style, children, ...props } ) => {

    return (
        <Typography sx={{ color:'text.primary', fontWeight:"bold", fontSize:'13px', ...style }}>{children}</Typography>
    )
}

const Content = ( { style, children, ...props } ) => {

    return (
        <Typography sx={{ color:'text.primary', fontSize:'13px', ...style }}>{children}</Typography>
    )
}

export { Header, Topic, SubTopic, Content }