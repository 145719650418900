import { useContext, useEffect, useState } from "react"
import { PreferencesContext } from "../../../../context"
import { Typography } from "@mui/material"
import vlrvenda from "../../../../utils/vlrvenda"

export default function Item (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [state, setState] = useState(props.state)

    const storecode = preferences.storecode

    useEffect(() => setState(props.state), [props.state])
    
    return (
        <div
        style={{
            display:'flex',
            flexDirection: 'column'
        }}>
            <div
            style={{
                display:'flex',
                alignItems:'center'
            }}>
                <img 
                className='product_card'
                style={{
                    objectFit:'cover',
                    width:'30px',
                    height:'30px',
                    top:0,
                    left:0,
                    borderRadius:'5px',
                    backgroundColor:'#fff',
                    opacity: 1,
                    transition: 'opacity 0.1s linear'
                }} 
                src={`https://${storecode}.allon.cloud/image/main/${state.ean}?internal=${state.imageminterna ? 'true' : 'false'}&idproduto=${state.idproduto || state.idprodutopai}`} />
                <div>
                    <Typography sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        textOverflow:'ellipsis',
                        marginLeft:'5px'
                    }}>{state.nome}</Typography>
                    
                    {state.caracteristicas
                    ?.filter(({atributo, propriedade}) => (atributo && propriedade))
                    .length ?
                    <Typography
                    sx={{
                        fontSize:'10px',
                        textOverflow:'ellipsis',
                        overflow:'hidden',
                        marginLeft:'5px',
                        color:'text.primary'
                    }}>{
                        state.caracteristicas
                        ?.filter(({atributo, propriedade}) => (atributo && propriedade))
                        ?.map(({atributo, propriedade}) => (`${atributo?.toUpperCase()}`)).join(', ')}
                    </Typography> : ''}
                </div>
            </div>
            <div>
                {state.removed ? 
                <>
                    <Typography sx={{
                        color:'text.primary',
                        fontWeight:'bold',
                        fontSize:'18px'
                    }}>REMOVIDO</Typography>
                </> : 
                <>
                <Typography 
                sx={{
                    color:'text.primary',
                    fontSize:'14px'
                }}>Estoque: {state.quantidade}</Typography>
                <Typography
                sx={{
                    color:'text.primary',
                    fontSize:'14px'
                }}>{state.variavel ? `${state.adicionado} ${state.unidmedida}` : state.adicionado} x {vlrvenda(state).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}{state.variavel ? ` (${state.incrementovariavel || 1} ${state.unidmedida})`:''}</Typography>
                <Typography
                sx={{
                    color:'text.primary',
                    fontWeight:'bold'
                }}>
                    {(vlrvenda(state) * (state.adicionado / (state.variavel ? (state.incrementovariavel || 1) : 1))).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}
                </Typography>
                </>}
            </div>
        </div>
    )
}