import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    TextField, 
    Typography,
    Button,
    Box,
    LinearProgress,
    linearProgressClasses
} from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { integer } from "../../utils/input";
import getColors from "../getColor";
import { PreferencesContext, UserContext } from "../../context";
import { useTheme } from '@mui/material/styles'
import { toast } from "react-toastify";

const BarraProgressoCartao = ({ credito, limite }) => {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const utilizado = credito - limite;
    const porcentagemUtilizada = (utilizado / credito) * 100;
  
    const theme = useTheme();
    const color = getColors(preferences, theme);
  
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            // backgroundColor: color.fontPrimary,
            // opacity: 0.3
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color.fontPrimary,
            opacity: 1
        },
    }));

    const labelRef = useRef()
  
    return (
      <Box>
        <Typography variant="h5" sx={{fontSize:'18px'}}>Limite disponível: R${limite}</Typography>
        <BorderLinearProgress
          variant="determinate"
          value={porcentagemUtilizada}
          sx={{
            height: 20,
            marginTop: 2,
            marginBottom: 2,
            borderRadius: 10,
            backgroundColor: '#f44336',
          }}
        />
        <div 
        style={{ position: 'relative' }}>
            <div
            ref={labelRef}
            style={{
                top: '-10px',
                position: 'absolute',
                left: `calc(${porcentagemUtilizada}% - ${(labelRef.current?.offsetWidth || 0) / 2}px)`,
                display:'flex',
                flexDirection:'column',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    // position: 'absolute',
                    // top: '-10px',
                    // left: `calc(${porcentagemUtilizada}% - 8px)`,
                    width: 0,
                    height: 0,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderBottom: `8px solid ${color.fontPrimary}`, // Cor do indicador
                }}
                />

                <Typography
                variant="body2"
                style={{
                    textAlign: 'center',
                    color: color.fontPrimary, // Cor do texto
                }}
                >
                    {utilizado.toLocaleString('pt-BR', { style:'currency', currency: 'BRL' })}
                </Typography>
            </div>
        </div>
        <div
        style={{ marginTop:'40px' }}
        >
            <Typography 
            sx={{
                fontWeight:'bold',
                fontSize:'16px'
            }}
            variant="body2"
            >Limite total: {credito.toLocaleString('pt-BR', { style:'currency', currency: 'BRL' })}</Typography>
        </div>
      </Box>
    );
};

export default function Other(props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [paymentParams, setPaymentParams] = useState({})
    const [typeParams, setTypeParams] = useState({})
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    
    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => setPaymentParams(props.paymentParams), [props.paymentParams])
    useEffect(() => setTypeParams(props.typeParams), [props.typeParams])
    useEffect(() => setOpen(props.opened), [props.opened])

    const pay = () => {
        paymentParams.onPay({
            selectedPaymentMethod: typeParams.payment_type_id,
            formData: {
                // ...formData, 
                ...state,
                payment_type_id: typeParams.payment_type_id,
                idformapagto: typeParams.idformapagto,
                idconectorpagamento: typeParams.idconectorpagamento,
                card: typeParams.cartao_beneficio ? { idclientecartao: typeParams.idclientecartao } : undefined
            },
            paymentParams
        })
    }

    return (
        <Accordion 
        expanded={open}
        onChange={(a,state) => {
            if ( state ) props.onOpen()
            else props.onClose()
        }}
        key={typeParams.idformapagto}
        {...props.accordionProps}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            {...props.accordionSumaryProps}
            >
            <Typography>{typeParams.apelido}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {typeParams.cartao_beneficio &&
                    <BarraProgressoCartao credito={typeParams.credito} limite={typeParams.limite} />
                    }
                    {typeParams.solicita_troco && 
                        <TextField 
                        value={state.money_change}
                        onChange={({target}) => setState({...state, money_change:integer(target.value, state.money_change)})}
                        size='small'
                        name='money_change'
                        inputProps={{
                            id:'money_change'
                        }}
                        label='Troco para: '
                        type='number'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        />
                    }
                    <Button
                    fullWidth
                    sx={{
                        bgcolor: color.fontPrimary,
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        marginTop:'10px'
                    }}
                    startIcon={<LockOutlinedIcon />}
                    onClick={pay}
                    disabled={
                        (typeParams.cartao_beneficio && paymentParams.amount > typeParams.limite) ||
                        (typeParams.solicita_troco && state.money_change && state.money_change < paymentParams.amount)
                    }
                    >
                        Pagar
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}