import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Button, 
    TextField, 
    Typography
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import InputMask from 'react-input-mask'
import getColors from "../getColor";
import { toast } from "react-toastify";
import { PreferencesContext, UserContext } from "../../context";
import { useTheme } from '@mui/material/styles'
import { MercadoPagoSettingsContext } from "./context";
import address from '../../utils/address'

export default function Boleto(props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [paymentParams, setPaymentParams] = useState({})
    const [typeParams, setTypeParams] = useState({})
    const [state, setState] = useState({})
    const [card, setCard] = useState({ isValid: false })
    const [open, setOpen] = useState(false)
    const [connector, setConnector] = useState({})    

    const mercadoPagoSettings = useContext(MercadoPagoSettingsContext)
    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => setOpen(props.opened), [props.opened])

    useEffect(() => {
        setTypeParams(props.typeParams)
        setPaymentParams(props.paymentParams)

        if (props.typeParams && props.paymentParams) init(props.typeParams, props.paymentParams)
    }, [
        props.typeParams,
        props.paymentParams
    ])

    const init = async (  ) => {
        if ( typeParams.conector === 'cielo' ) return
        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()

        mercadoPagoSettings.customization.paymentMethods = {
            [typeParams.to_conector]: 'all'
        }

        mercadoPagoSettings.callbacks.onSubmit = ({ selectedPaymentMethod, formData }) => {
            return new Promise((resolve, reject) => {

                if (typeof paymentParams.onPay === 'function') 
                paymentParams.onPay({
                    selectedPaymentMethod,
                    formData: {
                        ...formData, 
                        payment_type_id: selectedPaymentMethod,
                        idformapagto: typeParams.idformapagto,
                        idconectorpagamento: typeParams.idconectorpagamento
                    },
                    paymentParams
                },() => {
                    resolve();
                    if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                },() => {
                    reject()
                })
            })
        }

        mercadoPagoSettings.callbacks.onError = (error) => {
            if ( typeof paymentParams.onError === 'function' ) paymentParams.onError(error)
            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
        }

        if ( typeParams.conector === 'mercado_pago' ) {

            const key = paymentParams.conector.mercado_pago.key.public_key
            const mercado_pago = new window.MercadoPago(key)
            const bricksBuilder = mercado_pago.bricks()

            const paymentBrickController = await bricksBuilder.create(
                'payment',
                'paymentBrick_boleto_container',
                mercadoPagoSettings
            );

            setConnector({ mercado_pago, bricksBuilder, paymentBrickController })
        }
    }

    const accordionState = (state) => {
        if ( state ) {
            props.onOpen()
            init()
        } else {
            if ( typeParams.conector === 'mercado_pago') {
                if ( !connector.paymentBrickController ) return
                connector.paymentBrickController.unmount()
            }
            props.onClose()
        }
    }
    
    const pay = () => {
        const obj = {
            rua: state.rua,
            numero: state.numero,
            complemento: state.complemento,
            cep: state.cep,
            bairro: state.bairro,
            cidade: state.cidade,
            estado: state.estado
        }

        if (!obj.cep) return toast.error('Informe o cep')
        if (!obj.rua) return toast.error('Informe a rua')
        if (!obj.cidade) return toast.error('Informe a cidade')
        if (!obj.bairro) return toast.error('Informe o bairro')
        if (!obj.estado) return toast.error('Informe o estado')
        if (!obj.numero) return toast.error('Informe o numero')

        paymentParams.onPay({
            selectedPaymentMethod: typeParams.payment_type_id,
            formData: {
                // ...formData, 
                ...obj,
                payment_type_id: typeParams.payment_type_id,
                idformapagto: typeParams.idformapagto,
                idconectorpagamento: typeParams.idconectorpagamento
            },
            paymentParams,
            save_payment: true
        })
    }
    
    return (
        <Accordion 
        expanded={open}
        onChange={(a,state) => accordionState(state)}
        key={typeParams.idformapagto}
        {...props.accordionProps}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            {...props.accordionSumaryProps}
            >
            <Typography>{typeParams.apelido}</Typography>
            </AccordionSummary>
            <AccordionDetails
            sx={{ p: 0 }}
            >
            <div>
                {typeParams.conector === 'mercado_pago' && <div id="paymentBrick_boleto_container" class='payment-brick' style={{fontFamily:'Quicksand'}}></div>}
                {typeParams.conector === 'cielo' &&
                <>
                    <div
                    style={{
                        padding:'10px',
                        display:'flex',
                        flexWrap:'wrap'
                    }}>
                        <InputMask 
                        mask="99999-999" 
                        maskChar={null}
                        value={state.cep}
                        onChange={({target}) => {
                            setState({...state, cep:target.value})
                            if (target.value.length === 9) address(target.value, (err, out) => {
                                if ( err ) return 
                                setState({
                                    ...state, 
                                    ...out,
                                    cep:target.value,
                                    complemento: state.complemento || out.complemento
                                })
                            })
                        }}
                        size='small'
                        name='cep'
                        fullWidth
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            inputProps={{
                                                id:'cep'
                                            }}
                                            label='CEP'
                                            onKeyDown={(event) => {
                                                // if (event.keyCode===13) sendCpf()
                                            }}
                                            InputLabelProps={{shrink: state.cep ? true : false}}
                                            />}
                        </InputMask>

                        <TextField 
                        mask="" 
                        maskChar={null}
                        value={state.rua}
                        onChange={({target}) => setState({...state, rua:target.value})}
                        size='small'
                        name='rua'
                        inputProps={{
                            id:'rua'
                        }}
                        label='Rua'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        InputLabelProps={{shrink: state.rua ? true : false}}
                        />
                        <TextField 
                        mask="" 
                        maskChar={null}
                        value={state.cidade}
                        onChange={({target}) => setState({...state, cidade:target.value})}
                        size='small'
                        name='cidade'
                        inputProps={{
                            id:'cidade'
                        }}
                        label='Cidade'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        InputLabelProps={{shrink: state.cidade ? true : false}}
                        />
                        <TextField 
                        mask="" 
                        maskChar={null}
                        value={state.bairro}
                        onChange={({target}) => setState({...state, bairro:target.value})}
                        size='small'
                        name='bairro'
                        inputProps={{
                            id:'bairro'
                        }}
                        label='Bairro'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        InputLabelProps={{shrink: state.bairro ? true : false}}
                        />
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            width:'100%'
                        }}
                        >
                            <TextField 
                            mask="" 
                            maskChar={null}
                            value={state.estado}
                            onChange={({target}) => setState({...state, estado:target.value})}
                            size='small'
                            name='estado'
                            inputProps={{
                                id:'estado'
                            }}
                            label='Estado'
                            onKeyDown={(event) => {
                                // if (event.keyCode===13) sendCpf()
                            }}
                            sx={{
                                marginTop:'10px',
                                width:'100px'
                            }}
                            InputLabelProps={{shrink: state.estado ? true : false}}
                            />

                            <TextField 
                            mask="" 
                            maskChar={null}
                            value={state.numero}
                            onChange={({target}) => setState({...state, numero:target.value})}
                            size='small'
                            name='numero'
                            inputProps={{
                                id:'numero'
                            }}
                            label='Número'
                            onKeyDown={(event) => {
                                // if (event.keyCode===13) sendCpf()
                            }}
                            sx={{
                                marginTop:'10px',
                                width:'150px'
                            }}
                            InputLabelProps={{shrink: state.numero ? true : false}}
                            />
                        </div>
                        <TextField 
                        mask="" 
                        maskChar={null}
                        value={state.complemento}
                        onChange={({target}) => setState({...state, complemento:target.value})}
                        size='small'
                        name='complemento'
                        inputProps={{
                            id:'complemento'
                        }}
                        label='Complemento'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        InputLabelProps={{shrink: state.complemento ? true : false}}
                        />
                    </div>
                    <div
                    style={{
                        padding:'10px'
                    }}
                    >
                        <Button
                        fullWidth
                        sx={{
                            bgcolor: color.fontPrimary,
                            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                        }}
                        startIcon={<LockOutlinedIcon />}
                        onClick={pay}
                        >
                            Pagar
                        </Button>
                    </div>
                </>}
            </div>
            </AccordionDetails>
        </Accordion>
    )
}