import { useTheme } from "@mui/material/styles"
import { useContext, useEffect, useState } from "react"
import { PreferencesContext } from "../../../../../context/index"

import LaunchIcon from '@mui/icons-material/Launch';

import ProductCard from '../../product/card'
import Slider from "react-slick";
import { 
    ButtonBase, 
    Skeleton, 
    Typography 
} from "@mui/material";
import getColors from "../../../../getColor";
import axios from "axios";
import { api } from "../../../../../utils/defaults";
import { useNavigate } from "react-router-dom";
import usePersistedState from "../../../../../utils/usePersistedState";

const products = []
const key = new Date().toLocaleDateString('pt-BR')
export default function Index (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [action, setAction] = useState(props.action)
    const [products, setProducts] = usePersistedState(`action_${action.idlojaecommerceacao}`,[], key)
    const [loading, setLoading] = useState(true)

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const settings = props.settings
    const navigate = useNavigate()

    useEffect(() => {
        setAction(props.action)

        if (!props.loading) {
            axios
            .get(`${api}/action/product/${props.action.idlojaecommerceacao}`)
            .then(response => setProducts(response.data))
            .catch(err => {})
            .finally(() => setLoading(false))
        }
    },[props.action])


    return (
        !products.length && !loading ?
            <></> :
        <div 
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:"column",
            overflow:"hidden"
        }}>
            <div 
            style={{
                display:'flex',
                flexDirection:"column",
                width:'100%'
            }}>
                {loading ? 
                <div style={{
                    width:'100%',
                    borderRadius: '7px',
                    marginTop:'5px',
                    display:'flex',
                    width:'100%',
                    alignItems:'center'
                }}>
                    <Skeleton sx={{margin:'10px'}} width={'200px'} height={50} />
                    <Skeleton sx={{margin:'10px'}} width={30} height={30} variant="circular" />
                </div>
                : <div style={{
                    width:'100%',
                    borderRadius: '7px',
                    marginTop:'5px'
                }}>
                    <ButtonBase onClick={() => navigate(`action/${action.idlojaecommerceacao}`)}>
                        <Typography 
                        sx={{
                            margin:'10px', 
                            color: color.fontPrimary,
                            fontFamily: '',
                            fontSize:'22px',
                            display:'flex',
                            alignItems:'center',
                            fontSize:'19px'
                        }}>
                            {action.nomeprateleira} <LaunchIcon sx={{marginLeft:'10px',fontSize:'19px'}} />
                        </Typography>
                    </ButtonBase>
                </div>}
                
                <div
                style={{
                    margin: '0px 0px 10px 0px'
                }}>
                    <Slider {...{...settings,slidesToShow: products.length < settings.slidesToShow && !loading ? products.length : settings.slidesToShow}}>
                        {(loading ? [...new Array(4)] : products)?.map((e,index) => (
                            <div>
                                <div
                                style={{
                                    display:'flex',
                                    justifyContent:"center",
                                    alignItems:'center',
                                    width:'100%'
                                }}>
                                    <ProductCard {...e} />
                                </div>
                            </div>
                        ))}
                    </Slider>
                    
                </div>
            </div>
        </div>
    )
}