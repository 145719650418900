const { v4: uuidv4 } = require('uuid');
const { decrypt, encrypt } = require('./crypt');

const getKey = () => {
    let key = localStorage.getItem('_key')
    if (!key) return setKey()
    let decrypted_key = decrypt(key, process.env.REACT_APP_ENCRYPT_KEY)
    return decrypted_key || key
}

const setKey = () => {
    let key = uuidv4()
    let key_not_encrypted = key
    key = encrypt(key, process.env.REACT_APP_ENCRYPT_KEY)
    localStorage.setItem('_key', key)
    return key_not_encrypted
}

module.exports = {
    getKey,
    setKey
}