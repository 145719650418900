import { 
    Badge, 
    Button, 
    ButtonBase, 
    CircularProgress, 
    Divider, 
    Fab, 
    IconButton, 
    Modal, 
    Popover, 
    TextField, 
    Typography, 
    useTheme, 
    Zoom 
} from "@mui/material";

import React, { 
    useContext, 
    useEffect, 
    useReducer, 
    useRef, 
    useState 
} from "react";

import { 
    AnalyticsContext,
    CartContext, 
    EmailConfirmContext, 
    LoadingContext, 
    LoginContext, 
    PreferencesContext, 
    ReplaceOrdeContext, 
    SizeContext, 
    UserContext
} from "../../../../context";

import getColors from "../../../getColor";

import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import axios from "axios";
import { api } from "../../../../utils/defaults";
import { toast } from "react-toastify";
import { float, integer } from "../../../../utils/input";
import SwipeableViews from 'react-swipeable-views';
import { useLocation, useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask'

import pac from '../../../../static/pac.png'
import sedex from '../../../../static/sedex.png'

import { isMobile, deviceDetect } from 'react-device-detect';
import isAuth from "../../../../utils/isauth";
import Checkout from '../../../checkout/index' 
import vlrvenda from "../../../../utils/vlrvenda";

import Address from "./address";
import Check from "./check";
import createOrder from "../../../../utils/createOrder";
import sendAction from "../../../../utils/sendAction";
import useGeolocation from "../../../../utils/useGeolocation.ts";

export default function Index (props) {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [cart, setCart] = useContext(CartContext)
    const loginRef = useContext(LoginContext)
    const [emailConfirm, setEmailConfirm] = useContext(EmailConfirmContext)
    const [replaceOrder, setReplaceOrder] = useContext(ReplaceOrdeContext)
    const [size, setSize] = useContext(SizeContext)
    const [open, setOpen] = useState(false)
    const [cards, setCards] = useState([])
    const [ignore, forceUpdate] = useReducer(x => x + 1,0)
    const [delAnchor, setDelAnchor] = useState(null)
    const [index, setIndex] = useState(0)
    const [order, setOrder] = useState({})
    const [number, setNumber] = useState({ open:false, telefone1: user.telefone1, telefone2: user.telefone2 })

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()
    const geolocation = useGeolocation()

    let cancel

    const [card, setCard] = useState({})
    
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const add = (e) => {
        if (e.separado && (e.contado === e.pedido || e.adicionado + 1 > e.contado)) return
        const c = cart
        c[e.index].adicionado = e.adicionado + (e.variavel ? (e.incrementovariavel || 1) : 1) <= e.quantidade - (e.qtdminima || 0) ? e.adicionado + (e.variavel ? (e.incrementovariavel || 1) : 1) : e.adicionado
        setCart(c)
        forceUpdate()
    }

    const remove = (e) => {
        if (e.separado && e.contado === e.pedido) return

        const c = cart
        if (!e.adicionado) {
            c.splice(e.index, 1)
            setCart(c.map((e,index) => ({...e, index})))
            sendAction({
                unique_id: analytics.unique_id,
                action_id: 'cart',
                detail_action_id: 'removeFromCart',
                complete_detail: e?.nome,
                idproduto: e?.idproduto,
                idvariacaoproduto: e?.idvariacaoproduto,
                idprodutocategoria: null,
                nivelcategoria: null,
                token: user.token,
                lat: geolocation?.coords?.latitude,
                lng: geolocation?.coords?.longitude
            },
            (c) => (cancel = c),
            cancel)
            return
        }
        c[e.index].adicionado = e.adicionado - (e.variavel ? (e.incrementovariavel || 1) : 1) > 0 ? e.adicionado - (e.variavel ? (e.incrementovariavel || 1) : 1) : 0
        setCart(c)
        forceUpdate()
    }

    const change = (e,value) => {
        if (e.separado && (e.contado === e.pedido || value > e.contado)) return
        if (e.variavel) value = value * (e.incrementovariavel || 1)
        const c = cart
        c[e.index].adicionado = value < 0 ? e.adicionado : (
            value <= e.quantidade - (e.qtdminima || 0) ? value : e.adicionado
        )
        setCart(c)
        forceUpdate()
    }

    const filtered  = cart
    const storecode = preferences.storecode

    const fn_checkout = ( amount = undefined, usr = undefined ) => {
        usr = usr || user

        if (!preferences.loja_allon_ativa && !replaceOrder.idvenda) return toast.error('Loja temporariamente inativa para venda')
        if (!isAuth(usr)) return loginRef.current?.open((err, out) => {
            if ( out ) fn_checkout( amount, out )
        })
        if (!usr.email_verificado) return setEmailConfirm(true)
        if (!usr.telefone1 && !usr.telefone2) return setNumber({ open:true })
        if (!preferences.aberta) return setPreferences({...preferences, closed_message: true, error: true})

        if (cart.find(({variavel, incremento_minimo, adicionado, separado}) => (
            variavel && incremento_minimo > adicionado && !separado
        ))) return toast.error('Verifique se todos os ítens atingiram a quantidade mínima')
        
        checkoutRef.current?.init({
            onReady: () => {},
            onError: () => {},
            onPay: async (data, resolve=()=>{}, reject=()=>{}) => {
                setLoading(true)
                createOrder({ 
                    payment: data.formData, 
                    list: cart?.filter(({adicionado}) => adicionado).map(e => ({
                        idproduto: e.idproduto,
                        idvariacaoproduto: e.idvariacaoproduto,
                        idprodutopai: e.idprodutopai,
                        quantidade: e.quantidade,
                        qtdminima: e.qtdminima,
                        valor: e.valor,
                        desconto: e.desconto,
                        promocao: e.promocao,
                        porcdesconto: e.porcdesconto,
                        idlojaecommerceacao: e.idlojaecommerceacao,
                        vlrvenda: e.vlrvenda,
                        adicionado: e.adicionado
                    })), 
                    save_payment: data.save_payment || false,
                    delivery_type: order.delivery_type,
                    entrega: {
                        endereco: order.endereco,
                        numero: order.numero,
                        complemento: order.complemento,
                        referencia: order.referencia,
                        favorite_address: order.favorite_address,
                        formatted_address: order.address?.formatted_address,
                        address: order.address,
                        cep: order.address?.cep,
                        complete_components: order.address?.complete_components,
                        frete: order.frete?.change
                    },
                    card: data.card ? {
                        ...data.card,
                        mp_info: undefined,
                        ...data.card?.mp_info
                    } : undefined,
                    replace_payment: order.replace_payment,
                    idvenda: order.idvenda,
                    metadata: {
                        ...isMobile,
                        ...deviceDetect()
                    }
                }, usr.token,(err, out) => {
                    setLoading(false)

                    if ( err ) {
                        reject()
                        if (err?.response?.status === 400) {
                            checkRef.current?.check(err?.response?.data?.list || [])
                            if (err?.response?.data?.message) toast.error(err?.response?.data?.message)
                        } else {
                            toast.error(typeof err?.response?.data === 'string' ? err?.response?.data : err?.response?.data?.message)

                            axios
                            .get(`${api}/client/cards?verificado=true`, {
                                headers: {
                                    Authorization: usr.token
                                }
                            })
                            .then(response => setCards(response.data))
                            .catch(err => {})
                        }
                        return
                    }

                    resolve()
                    checkoutRef.current?.unmount()
                    setCart([])
                    setTimeout(() => navigate(`/order/${out.idvenda}`), 500)
                })
            },
            enableSaveCard: true,
            amount: amount || vlrcompras+taxa(),
            card: card.new ? undefined : card
        })
    }

    const fn_replaceOrder = () => {
        if (parseFloat(replaceOrder?.vlrfrete || 0) + vlrcompras > parseFloat(replaceOrder?.money_change)) return setReplaceOrder({
            ...replaceOrder, 
            money_change: parseFloat(replaceOrder?.money_change || 0),
            open_money_change: true
        })

        if (replaceOrder.replace_payment) {
            setOrder({
                ...replaceOrder,
                ...replaceOrder.entrega
            })
            return setIndex(1)
        }

        setLoading(true)
        axios
        .put(`${api}/order`,{
            ...replaceOrder,
            list: cart?.filter(({separado, adicionado}) => (separado || adicionado)).map(e => ({
                idproduto: e.idproduto,
                idvariacaoproduto: e.idvariacaoproduto,
                idprodutopai: e.idprodutopai,
                quantidade: e.quantidade,
                qtdminima: e.qtdminima,
                valor: e.valor,
                desconto: e.desconto,
                promocao: e.promocao,
                porcdesconto: e.porcdesconto,
                idlojaecommerceacao: e.idlojaecommerceacao,
                vlrvenda: e.vlrvenda,
                adicionado: e.adicionado
            }))
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setCart([])
            setReplaceOrder({})
            toast.success('Pedido Alterado')
            navigate(`/order/${response.data.idvenda}`)
        })
        .catch(err => {
            if (err.response.status === 409) {
                checkRef.current?.check(err?.response?.data?.list || [])
                return
            }
            toast.error(err.response.data)
            if (err.response.status === 404) {
                setCart([])
                setReplaceOrder({})
                setOpen(false)
            }
        })
        .finally(() => setLoading(false))
    }

    const next = () => {
        if (index === 2) return
        if (replaceOrder.idvenda && !replaceOrder.replace_payment) {
            return fn_replaceOrder()
        }
        if (index === 0) {
            return setIndex(1)
        }
        if (!order.delivery_type) {
            // setOrder({...order, error:true})
            return toast.error('Selecione uma forma de recebimento (Entrega / Retirada / Frete)')
        }
        if ((order.delivery_type === 'delivery' || order.delivery_type === 'frete') && !order.address) {
            // setOrder({...order, error:true})
            return toast.error('Informe um endereço para a entrega')
        }
        if (!card.idclientecartao && !card.new) {
            // setOrder({...order, error: true})
            return toast.error('Selecione um cartão ou modelo de pagamento.')
        }

        if (
            order.delivery_type !== 'retirada' &&
            preferences.delivery_max && 
            parseInt(preferences.delivery_max) && 
            distance > parseInt(preferences.delivery_max)
        ) {
            // setOrder({...order, error: true})
            return toast.error(`A loja não entrega para essa distância`)
        }
        if (
            order.delivery_type !== 'retirada' &&
            preferences.delivery_min && 
            parseInt(preferences.delivery_min) && 
            distance < parseInt(preferences.delivery_min)
        ) {
            // setOrder({...order, error: true})
            return toast.error(`A loja não entrega para essa distância`)
        }
        if (
            preferences.pedidominimo && 
            parseFloat(preferences.pedidominimo) && 
            vlrcompras < parseFloat(preferences.pedidominimo)
        ) {
            // setOrder({...order, error: true})
            return toast.error(`A loja tem um pedído mínimo de ${parseFloat(preferences.pedidominimo).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})} em ítens`)
        }

        // setOrder({...order, error: false})

        if (order.replace_payment) {
            fn_checkout(parseFloat(order.valorfinal) + parseFloat(order.vlrfrete))
            return setIndex(2)
        }

        checkRef.current?.check(undefined, (err, data) => {
            if ( err ) {
                console.log(err)
                toast.error(err?.response?.data)
                return 
            }

            setIndex(2)
            if (!data.find(({altered}) => altered)) fn_checkout()
        })
    }

    const handleChangeIndex = (newindex) => {
        if (replaceOrder.replace_payment) {
            if (newindex === 0) {
                return
            }
        }

        if (replaceOrder.idvenda) {
            return
        }

        if (newindex === 1 && index === 2) {
            checkoutRef.current?.unmount()
        }

        if (newindex === 2) {
            if (!card.mp_info && !card.new) {
                // setOrder({...order, error: true})
                return toast.error('Selecione um cartão ou modelo de pagamento.')
            }
            if (!order.delivery_type) {
                setIndex(1)
                // setOrder({...order, error: true})
                return toast.error('Selecione uma forma de recebimento (Entrega / Retirada / Frete)')
            }
            if ((order.delivery_type === 'delivery' || order.delivery_type === 'frete') && !order.address) {
                setIndex(1)
                // setOrder({...order, error: true})
                return toast.error('Informe um endereço para a entrega')
            }
            
            if (
                order.delivery_type !== 'retirada' &&
                preferences.delivery_max && 
                parseInt(preferences.delivery_max) && 
                distance > parseInt(preferences.delivery_max)
            ) {
                setIndex(1)
                // setOrder({...order, error: true})
                return toast.error(`A loja não entrega para essa distância`)
            }
            if (
                order.delivery_type !== 'retirada' &&
                preferences.delivery_min && 
                parseInt(preferences.delivery_min) && 
                distance < parseInt(preferences.delivery_min)
            ) {
                setIndex(1)
                // setOrder({...order, error: true})
                return toast.error(`A loja não entrega para essa distância`)
            }
            if (
                preferences.pedidominimo && 
                parseFloat(preferences.pedidominimo) && 
                vlrcompras < parseFloat(preferences.pedidominimo)
            ) {
                setIndex(1)
                // setOrder({...order, error: true})
                return toast.error(`A loja tem um pedído mínimo de ${parseFloat(preferences.pedidominimo).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})} em ítens`)
            }

            if (newindex===2 && !user.telefone1 && !user.telefone2) return setNumber({open:true})

            // setOrder({...order, error: false})

            if (order.replace_payment) {
                fn_checkout(parseFloat(order.valorfinal) + parseFloat(order.vlrfrete))
                return setIndex(newindex)
            }
            
            checkRef.current?.check(undefined, (err, data) => {
                if ( err ) {
                    return toast.error(err?.response?.data)
                } 

                if (!data.find(({altered}) => altered)) fn_checkout(order.replace_payment ? parseFloat(order.valorfinal) + parseFloat(order.vlrfrete) : undefined)
            })
        }

        setIndex(newindex)
    } 

    useEffect(() => {
        if (preferences.delivery) {
            setOrder({delivery_type:'delivery'})
        } else 
        if (preferences.frete) {
            setOrder({delivery_type:'frete'})
        } else 
        if (preferences.retirada) {
            setOrder({delivery_type:'retirada'})
        }

        if ( user.token ) {
            axios
            .get(`${api}/client/cards?verificado=true`, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(response => setCards(response.data))
            .catch(err => {})
        }

        if (replaceOrder.replace_payment) {
            setOrder({
                ...replaceOrder,
                ...replaceOrder.entrega
            })
            setIndex(1)
        }
    },[
        preferences.storecode,
        user.token
    ])

    const vlrcompras = cart.reduce((a, next) => (a = a + vlrvenda(next)*(next.adicionado / (next.variavel ? (next.incrementovariavel || 1) : 1))),0)
    const distance = parseFloat((order.address?.rows[0]?.elements[0]?.distance?.value || 0) / 1000)

    const taxa = () => {
        let valor = 0

        if (order.delivery_type === 'retirada') return valor
        if (order.delivery_type === 'frete') {
            if (order.frete?.change === 'pac') return parseFloat(order.frete?.valorpac)
            if (order.frete?.change === 'sedex') return parseFloat(order.frete?.valorsedex)
        }

        if (preferences.delivery_vlr_fixo && parseFloat(preferences.delivery_vlr_fixo)) {
            valor = parseFloat(preferences.delivery_vlr_fixo)
        }

        if (!preferences.delivery_vlr_fixo || !parseFloat(preferences.delivery_vlr_fixo) || distance > preferences.delivery_vlr_fixo_max) {
            valor += (
                Number.isNaN(parseFloat(preferences.delivery_vlr_variavel) * ((distance - preferences.delivery_vlr_fixo_max)/preferences.delivery_vlr_variavel_max)) ? 0 :
                parseFloat(preferences.delivery_vlr_variavel) * ((distance - preferences.delivery_vlr_fixo_max)/preferences.delivery_vlr_variavel_max)
            )
        }

        return valor
    }

    useEffect(() => {
        if (order.delivery_type !== 'frete') return
        if (!order.address?.cep) return
        if (!open) return
        if (vlrcompras === order.frete?.vlrcompras && order.address?.cep === order.frete?.cep) return

        setLoading(true)
        
        axios
        .post(`${api}/tool/delivery_charge/correio`,{
            origin: order.address?.cep,
            product: cart.map(({ adicionado, idproduto, idvariacaoproduto }) => ({ idproduto, idvariacaoproduto, quantidade: adicionado}))
        })
        .then(({ data }) => setOrder({...order, frete:{...data, change: 'sedex', vlrcompras, cep: order.address?.cep, pacotes: undefined}}))
        .catch(err => toast.error(err.response?.data?.message || err.response?.data))
        .finally(() => setLoading(false))

    },[
        order.address?.cep, 
        vlrcompras, 
        order.delivery_type,
        open
    ])

    const checkoutRef = useRef()
    const addressRef = useRef()
    const checkRef = useRef()

    return (
        <>
            <Modal
            open={replaceOrder?.open_money_change}
            onClose={() => setReplaceOrder({ ...replaceOrder, open_money_change: false })}
            sx={{
                justifyContent:"center",
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column"
                }}
                >
                    <TextField 
                    value={replaceOrder.money_change}
                    onChange={({target}) => setReplaceOrder({ ...replaceOrder, money_change: float(target.value) })}
                    label='Troco para: '
                    type='number'
                    size='small'
                    />

                    <Button
                    sx={{
                        bgcolor: color.fontPrimary,
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        marginTop:'10px'
                    }}
                    startIcon={<LockOutlinedIcon />}
                    onClick={replaceOrder}
                    disabled={
                        parseFloat(replaceOrder?.money_change) && 
                        (parseFloat(replaceOrder?.vlrfrete || 0) + vlrcompras > parseFloat(replaceOrder?.money_change))
                    }
                    >
                        concluir
                    </Button>
                </div>
            </Modal>

            <Modal
            open={number.open}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                    <div
                    style={{
                        padding:'10px',
                        display:'flex',
                        justifyContent:'space-between',
                        backgroundColor:color.background,
                        borderRadius:'10px 10px 0px 0px',
                        flexDirection:"column",
                        maxWidth:'330px'
                    }}>
                        <Typography
                        sx={{
                            color:'text.primary',
                            fontsize:'15px',
                            padding:'10px'
                        }}
                        >Podemos entrar em contato por qual número de whatsapp para informá-lo(a) sobre seu pedido ?</Typography>
                        {/* <Typography
                        sx={{
                            color:'text.primary',
                            fontSize:'12px',
                            padding:'10px',
                            opacity:0.7
                        }}
                        >*O contato será feito somente por mensagens automáticas da plataforma*</Typography> */}
                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={number.telefone1}
                        onChange={({target}) => setNumber({...number, telefone1:target.value})}
                        >
                            {(inputProps) =>  <TextField 
                            {...inputProps}
                            InputLabelProps={{shrink:number.telefone1?true:false}}
                            label='telefone 1'
                            sx={{ margin:'5px', width:'320px' }}
                            helperText='(21) 999999999'
                            />}
                        </InputMask>

                        <InputMask 
                        mask="(99) 9999999999" 
                        maskChar={null}
                        value={number.telefone2}
                        onChange={({target}) => setNumber({...number, telefone2:target.value})}
                        >
                            {(inputProps) => <TextField
                            {...inputProps} 
                            InputLabelProps={{shrink:number.telefone2?true:false}}
                            label='telefone 2'
                            sx={{ margin:'5px', width:'320px' }}
                            helperText='(21) 999999999'
                            />}
                        </InputMask>

                        <Button
                        onClick={() => {
                            if (!number.telefone1 && !number.telefone2) return toast.error('Preencha algum número para contato da plataforma')
                            setLoading(true)
                            axios
                            .put(`${api}/client`,number,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                toast.success('Número adicionado ao seu cadastro')
                                setNumber({open:false})
                                setUser({...user, telefone1:number.telefone1, telefone2: number.telefone2})
                            })
                            .catch(err => toast.error(err.response.data))
                            .finally(() => setLoading(false))
                        }}
                        variant='contained'
                        sx={{
                            backgroundColor: color.primary,
                            margin:'5px'
                        }}
                        >continuar</Button>

                    </div>
            </Modal>

            <Address 
            ref={addressRef}
            onChange={(data) => setOrder({ ...order, ...data })}
            />

            <Check 
            ref={checkRef}
            />

            <Popover 
            anchorEl={open}
            open={open}
            onClose={() => {
                checkoutRef.current?.unmount()
                setOpen(false)
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                marginBottom:'20px',
                backgroundColor:'rgba(0,0,0,0)',
                '.MuiPaper-root' : {
                    backgroundColor:'rgba(0,0,0,0)',
                    marginBottom:'20px',
                    backgroundImage:'none',
                    boxShadow:'none',
                    ...(
                        size.width <= 500 ? {
                            width:'100vw',
                            // display:'flex',
                            // justifyContent:'center'
                        } : {}
                    )
                },
                '.MuiBackdrop-root': {
                    backgroundColor:'rgba(0,0,0,0.5)'
                }
            }}
            onClick={({clientY}) => {
                // if (window.innerHeight - clientY <= 80) setOpen(false)
            }}
            >
                <div
                style={{
                    width: size.width > 440 ? '380px' : `100%`,
                    height:'600px',
                    backgroundColor: 'rgba(0,0,0,0)',
                    margin:'0px 0px 70px 0px',
                    borderRadius:'10px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <SwipeableViews 
                    index={index}
                    onChangeIndex={(index) => handleChangeIndex(index)}
                    disabled={card.open}
                    style={{
                        boxShadow:'black 0px 0px 9px 0px',
                        borderRadius:'10px'
                    }}
                    resistance
                    enableMouseEvents={false}
                    >

                        <div
                        style={{
                            boxShadow:'black 0px 0px 9px 0px',
                            marginBottom:'10px',
                            borderRadius:'10px'
                        }}>
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'right',
                                backgroundColor:color.background,
                                borderRadius:'10px 10px 0px 0px'
                            }}>
                                {/* <IconButton onClick={() => setOpen(false)}>
                                    <CancelIcon color='error'/>
                                </IconButton> */}
                                <Button
                                color='error'
                                endIcon={<CancelIcon />}
                                size='small'
                                variant='contained'
                                sx={{height:'25px',margin:'5px 5px 0px 0px'}}
                                onClick={() => setOpen(false)}
                                >
                                    fechar
                                </Button>
                            </div>
                            <div
                            style={{
                                backgroundColor:color.background,
                                height:'490px',
                                width:'100%',
                                borderRadius:'0px 0px 10px 10px',
                                maxHeight:'490px',
                                overflowY:'auto'
                            }}>
                                {replaceOrder.replace_payment && <Typography
                                sx={{
                                    fontSize:'12px',
                                    textAlign:'center',
                                    color:'text.primary'
                                }}>Não é possível alterar os ítens</Typography>}
                                {filtered?.map((e,index) => (
                                    <>
                                    <div
                                    style={{
                                        minHeight:'63px',
                                        opacity: e.separado && e.contado === e.pedido ? 0.4 : 1
                                    }}>
                                        <div
                                        style={{
                                            display:'flex',
                                            justifyContent:'space-between',
                                            margin:'5px'
                                        }}>
                                            <div
                                            style={{
                                                display:'flex'
                                            }}>
                                                <img 
                                                className='product_card'
                                                style={{
                                                    objectFit:'cover',
                                                    width:'60px',
                                                    height:'60px',
                                                    top:0,
                                                    left:0,
                                                    borderRadius:'5px',
                                                    backgroundColor:'#fff',
                                                    opacity: 1,
                                                    transition: 'opacity 0.1s linear'
                                                }} 
                                                src={`https://${storecode}.allon.cloud/image/main/${e.ean}?internal=${e.imageminterna ? 'true' : 'false'}&idproduto=${e.idproduto || e.idprodutopai}`} />
                                                <div
                                                style={{
                                                    marginLeft:'5px',
                                                    maxHeight:'63px',
                                                    display:'flex',
                                                    flexDirection:"column",
                                                    justifyContent:'space-between'
                                                }}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:'12px',
                                                        textOverflow:'ellipsis',
                                                        maxHeight:e.variacao || e.caracteristicas?.filter(({atributo, propriedade}) => (atributo && propriedade)).length ? '18px' : '39px',
                                                        overflow:'hidden'
                                                    }}>{e.nome}</Typography>

                                                    {e.variacao || e.caracteristicas?.filter(({atributo, propriedade}) => (atributo && propriedade)).length ?
                                                    <Typography
                                                    sx={{
                                                        fontSize:'10px',
                                                        textOverflow:'ellipsis',
                                                        maxHeight:'18px',
                                                        overflow:'hidden'
                                                    }}>{
                                                        e.variacao || e.caracteristicas?.filter(({atributo, propriedade}) => (atributo && propriedade))?.map(({atributo, propriedade}) => (`${atributo?.toUpperCase()}`)).join(', ')}
                                                    </Typography> : ''}
                                                    <Typography
                                                    sx={{
                                                        fontWeight:'bold',
                                                        fontSize:'16px'
                                                    }}>{(vlrvenda(e) * (e.adicionado / (e.variavel ? (e.incrementovariavel || 1) : 1))).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                                                </div>
                                            </div>
                                            <div
                                            style={{
                                                display:'flex',
                                                flexDirection:"column",
                                                justifyContent:'space-between'
                                            }}>
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    textAlign:'right'
                                                }}>{vlrvenda(e).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}{e.variavel ? ` | ${e.incrementovariavel} ${e.unidmedida}` : ''}</Typography>
                                                {e.separado && e.contado !== e.quantidade &&
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    textAlign:'right'
                                                }}
                                                >Pedido: {e.quantidade}</Typography>}
                                                <div
                                                style={{
                                                    display:'flex',
                                                    minWidth:'100px',
                                                    alignItems:'center',
                                                    width:'inherit',
                                                    justifyContent:'space-between',
                                                    padding:'5px',
                                                    borderRadius:'5px',
                                                    boxShadow: `${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`
                                                }}>
                                                    <>
                                                        {e.adicionado >= 1 ? 
                                                        <RemoveIcon 
                                                        onClick={() => remove(e)}
                                                        sx={{
                                                            fontSize:'18px', 
                                                            color: color.fontPrimary,
                                                            cursor:'pointer'
                                                        }} /> : 
                                                        <DeleteIcon 
                                                        onClick={() => remove(e)}
                                                        sx={{
                                                            fontSize:'18px', 
                                                            color: color.fontPrimary,
                                                            cursor:'pointer'
                                                        }}/>}
                                                    </>
                                                    <input 
                                                    value={e.adicionado / (e.variavel ? (e.incrementovariavel || 1) : 1)}
                                                    onChange={({target}) => change(e,integer(target.value))}
                                                    size='small'
                                                    style={{
                                                        width:'50px',
                                                        textAlign:'center',
                                                        border:'hidden',
                                                        fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
                                                        letterSpacing:'0.5px',
                                                        fontSize:'17px',
                                                        fontWeight:"bold",
                                                        backgroundColor:'rgba(0,0,0,0)',
                                                        color: theme.palette.mode === 'light' ? '#000' : '#fff'
                                                    }}
                                                    inputProps={{
                                                        style:{
                                                            textAlign:'center',
                                                            padding:'0px',
                                                            border:'0px solid',
                                                            background:''
                                                        }
                                                    }}
                                                    />
                                                    <AddIcon 
                                                    onClick={() => add(e)}
                                                    sx={{
                                                        fontSize:'18px', 
                                                        color: color.fontPrimary,
                                                        cursor:'pointer',
                                                        opacity: e.adicionado === e.quantidade - (e.qtdminima || 0) ? 0.5 : 1
                                                    }}/>
                                                </div>
                                                {e.variavel &&
                                                <div
                                                style={{
                                                    display:'flex',
                                                    justifyContent:'space-between'
                                                }}
                                                >
                                                    <Typography
                                                    sx={{
                                                        fontSize:'12px',
                                                        color:'text.secondary',
                                                        textAlign:'right',
                                                        width:(e.incremento_minimo || 0) > e.adicionado ? 'inherit' : '100%'
                                                    }}
                                                    >{e.adicionado} {e.unidmedida}</Typography>
                                                    {(e.incremento_minimo || 0) > e.adicionado &&
                                                    <Typography
                                                    sx={{
                                                        fontSize:'12px',
                                                        color:'red',
                                                        textAlign:'right',
                                                        opacity:0.7,
                                                        width:'inherit',
                                                        fontWeight:'bold'
                                                    }}
                                                    >{`Min ${e.incremento_minimo}`} {e.unidmedida}</Typography>}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider sx={{margin:'10px 0px'}} />
                                    </>
                                ))}
                            </div>
                        </div>

                        <div
                        style={{
                            boxShadow:'black 0px 0px 9px 0px',
                            marginBottom:'10px',
                            borderRadius:'10px'
                        }}>
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'space-between',
                                backgroundColor:color.background,
                                borderRadius:'10px 10px 0px 0px'
                            }}>
                                <IconButton onClick={() => {
                                    if (replaceOrder.replace_payment) return
                                    setIndex(0)
                                }}>
                                    <ArrowBackIosNewOutlinedIcon color='error'/>
                                </IconButton>
                                <Button
                                color='error'
                                endIcon={<CancelIcon />}
                                size='small'
                                variant='contained'
                                sx={{height:'25px',margin:'5px 5px 0px 0px'}}
                                onClick={() => setOpen(false)}
                                >
                                    fechar
                                </Button>
                                {/* <IconButton onClick={() => setOpen(false)}>
                                    <CancelIcon color='error'/>
                                </IconButton> */}
                            </div>
                            <div
                            style={{
                                backgroundColor:color.background,
                                height:'490px',
                                width:'100%',
                                borderRadius:'0px 0px 10px 10px',
                                maxHeight:'490px',
                                overflowY:'auto',
                                display:'flex',
                                flexDirection:"column",
                                justifyContent:'space-between'
                            }}>
                                <div>
                                    {replaceOrder.replace_payment || replaceOrder.idvenda && <Typography
                                    sx={{
                                        fontSize:'12px',
                                        textAlign:'center',
                                        color:'text.primary'
                                    }}
                                    >{
                                        replaceOrder.replace_payment ? 'Só é possível alterar o pagamento' : 'Apenas alteração de ítens habilitada'
                                        }</Typography>}
                                    <div>
                                    {preferences.delivery &&
                                    <ButtonBase
                                    onClick={() => {
                                        if (order.replace_payment) return
                                        setOrder({...order, delivery_type: 'delivery'})
                                    }}
                                    >
                                        <Typography 
                                        sx={{
                                            color: order.delivery_type === 'delivery' ? color.fontPrimary : 'text.primary',
                                            borderBottom: order.delivery_type === 'delivery' && `1px solid ${color.primary}`,
                                            width:'max-content',
                                            margin:'5px',
                                            padding:'5px',
                                            fontWeight:order.delivery_type === 'delivery' && 'bold'
                                        }}>Entrega</Typography>
                                    </ButtonBase>}
                                    {preferences.retirada &&
                                    <ButtonBase
                                    onClick={() => {
                                        if (order.replace_payment) return
                                        setOrder({...order, delivery_type: 'retirada'})}
                                    }>
                                        <Typography 
                                        sx={{
                                            color: order.delivery_type === 'retirada' ? color.fontPrimary : 'text.primary',
                                            borderBottom: order.delivery_type === 'retirada' && `1px solid ${color.primary}`,
                                            width:'max-content',
                                            margin:'5px',
                                            padding:'5px',
                                            fontWeight:order.delivery_type === 'retirada' && 'bold'
                                        }}>Retirada</Typography>
                                    </ButtonBase>}
                                    {preferences.frete &&
                                    <ButtonBase
                                    onClick={() => {
                                        if (order.replace_payment) return
                                        setOrder({...order, delivery_type: 'frete'})}
                                    }>
                                        <Typography 
                                        sx={{
                                            color: order.delivery_type === 'frete' ? color.fontPrimary : 'text.primary',
                                            borderBottom: order.delivery_type === 'frete' && `1px solid ${color.primary}`,
                                            width:'max-content',
                                            margin:'5px',
                                            padding:'5px',
                                            fontWeight:order.delivery_type === 'frete' && 'bold'
                                        }}>Correio</Typography>
                                    </ButtonBase>}
                                    </div>

                                    <ButtonBase 
                                    onClick={() => {
                                        if (order.replace_payment) return
                                        if (order.delivery_type === 'retirada') return
                                        addressRef.current?.open()
                                    }}
                                    sx={{width:'100%'}}>
                                        <div
                                        style={{
                                            margin:'5px',
                                            display:'flex',
                                            alignItems:"center",
                                            marginTop:'20px',
                                            width:'100%'
                                        }}>
                                            <div>
                                                {order.delivery_type === 'delivery' ? 
                                                <LocationOnOutlinedIcon sx={{color:color.fontPrimary}} /> : 
                                                (order.delivery_type === 'retirada' ? 
                                                <DirectionsRunOutlinedIcon sx={{color:color.fontPrimary}} /> : 
                                                <LocalShippingIcon sx={{color:color.fontPrimary}} />)}
                                            </div>
                                            <div
                                            style={{
                                                marginLeft:'5px'
                                            }}>
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    textAlign:'left'
                                                }}
                                                >{order.delivery_type === 'delivery' ? 
                                                'Entregar em' : (order.delivery_type === 'retirada' ? 
                                                'Retirar em' : 
                                                'Enviar para')}</Typography>
                                                {order.delivery_type === 'delivery' || order.delivery_type === 'frete' ?
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    fontWeight:'bold',
                                                    textAlign:'left'
                                                }}
                                                >{order.address?.destination_addresses && order.address?.destination_addresses[0]}</Typography> :
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    fontWeight:'bold',
                                                    textAlign:'left'
                                                }}
                                                >{preferences.gmaps_info?.results[0]?.formatted_address}</Typography>
                                                }
                                                {order.address?.rows[0] && order.delivery_type === 'delivery' &&
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    textAlign:'left',
                                                    marginTop:'5px'
                                                }}>
                                                    {order.address?.rows[0]?.elements[0]?.distance?.text}, {order.address?.rows[0]?.elements[0]?.duration?.text}
                                                </Typography>}
                                            </div>
                                        </div>
                                    </ButtonBase>

                                    {!card.open &&
                                    <ButtonBase 
                                    onClick={() => setCard({...card, open:true})}
                                    sx={{width:'calc(100%)'}}>
                                        <div
                                        style={{
                                            margin:'5px',
                                            display:'flex',
                                            alignItems:"center",
                                            marginTop:'20px',
                                            width:'100%'
                                        }}>
                                            <div>
                                                <CreditScoreOutlinedIcon sx={{color:color.fontPrimary}} />
                                            </div>
                                            <div
                                            style={{
                                                marginLeft:'5px',
                                                width:'inherit'
                                            }}>
                                                <Typography
                                                sx={{
                                                    fontSize:'13px',
                                                    textAlign:'left'
                                                }}
                                                >Selecionar cartão / modelo de pagamento</Typography>
                                                <div
                                                style={{
                                                    display:'flex',
                                                    width:'100%',
                                                    justifyContent:'space-between'
                                                }}>
                                                    {card.new ? 
                                                    <Typography
                                                    sx={{
                                                        fontSize:'13px',
                                                        textAlign:'left',
                                                        marginTop:'5px',
                                                        padding:'5px',
                                                        borderRadius:'2px',
                                                        backgroundColor: color.fontPrimary,
                                                        color: color.background
                                                    }}>
                                                        Listar formas de pagamento no final da compra
                                                    </Typography> :
                                                    <Typography
                                                    sx={{
                                                        fontSize:'13px',
                                                        textAlign:'left',
                                                        marginTop:'5px'
                                                    }}>
                                                        {card.brand} - {card.expiration} - &#9679; &#9679; &#9679; &#9679; {card.cardNumber?.substring(12,16)}
                                                    </Typography>}
                                                    {card.mp_info?.payment_method?.secure_thumbnail &&
                                                    <img 
                                                    style={{
                                                        width:'20px',
                                                        objectFit:'contain'
                                                    }}
                                                    src={card.mp_info?.payment_method?.secure_thumbnail} />}
                                                </div>
                                            </div>
                                        </div>
                                    </ButtonBase>}

                                    {card.open &&
                                    <div
                                    style={{
                                        display:'flex',
                                        width:'100%',
                                        overflowX:'scroll',
                                        marginTop:'20px'
                                    }}>
                                        <div
                                        onClick={() => setCard({new:true})}
                                        style={{
                                            width:'100px',
                                            height:'100px',
                                            margin:'10px',
                                            boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                                            padding:'10px',
                                            minWidth:'100px',
                                            borderRadius:'10px',
                                            display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'space-between',
                                            cursor:'pointer'
                                        }}>
                                            <div
                                            style={{
                                                display:'flex',
                                                width:'100%'
                                            }}>
                                                <AddIcon sx={{color: color.fontPrimary}} />
                                            </div>
                                            <div>
                                                <Typography 
                                                sx={{
                                                    color:'text.primary',
                                                    fontSize:'13px',
                                                    marginLeft:'5px'
                                                }}>Abrir</Typography>
                                                <Typography 
                                                sx={{
                                                    color:'text.primary',
                                                    fontSize:'13px',
                                                    marginLeft:'5px',
                                                    fontWeight:'bold'
                                                }}>Novas Opções</Typography>
                                            </div>
                                        </div>
                                        {cards.filter(({verificado, ativo, interno}) => verificado && ativo && !interno)?.map(e => (
                                            <div
                                            onClick={() => setCard(e)}
                                            style={{
                                                width:'100px',
                                                height:'100px',
                                                margin:'10px',
                                                boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                                                padding:'10px',
                                                minWidth:'100px',
                                                borderRadius:'10px',
                                                display:'flex',
                                                flexDirection:'column',
                                                justifyContent:'space-between',
                                                cursor:'pointer'
                                            }}>
                                                <div
                                                style={{
                                                    display:'flex',
                                                    width:'100%'
                                                }}>
                                                    {
                                                        e.mp_info?.payment_method.secure_thumbnail ? 
                                                        <img 
                                                        style={{
                                                            width:'20px',
                                                            objectFit:'contain'
                                                        }}
                                                        src={e.mp_info?.payment_method.secure_thumbnail} /> : 
                                                        <Typography 
                                                        sx={{
                                                            color:'text.primary',
                                                            fontSize:'13px',
                                                            marginLeft:'5px'
                                                        }}>{e.brand}</Typography>
                                                    }

                                                    <Typography 
                                                    sx={{
                                                        color:'text.primary',
                                                        fontSize:'13px',
                                                        marginLeft:'5px'
                                                    }}>{e.apelido}</Typography>
                                                </div>
                                                <div>
                                                    <Typography 
                                                    sx={{
                                                        color:'text.primary',
                                                        fontSize:'13px',
                                                        marginLeft:'5px'
                                                    }}>{e.expiration}</Typography>

                                                    <Typography 
                                                    sx={{
                                                        color:'text.primary',
                                                        fontSize:'13px',
                                                        marginLeft:'5px',
                                                        fontWeight:'bold'
                                                    }}>&#9679; &#9679; &#9679; &#9679; {e.cardNumber?.substring(12, 16)}</Typography>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}

                                    {order.delivery_type === 'frete' &&
                                    order.frete &&
                                    <div
                                    style={{
                                        marginTop:'10px'
                                    }}
                                    >
                                        <ButtonBase
                                        onClick={() => setOrder({...order, frete: {...order.frete, change: 'sedex'}})}
                                        sx={{
                                            margin:'10px',
                                            borderRadius:'10px',
                                            width:'calc(100% - 20px)'
                                        }}
                                        >
                                            <div
                                            style={{
                                                display:'flex',
                                                width:'calc(100% - 20px)',
                                                padding:'10px',
                                                borderRadius:'5px',
                                                border:`1px solid ${order.frete?.change === 'sedex' ? color.fontPrimary : color.background}`
                                            }}
                                            >
                                                <img
                                                src={sedex}
                                                alt='sedex'
                                                style={{
                                                    width:'30px',
                                                    objectFit:'contain'
                                                }}
                                                ></img>
                                                <div
                                                style={{
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    width:'100%',
                                                    alignItems:'center'
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        alignItems:'left',
                                                        marginLeft:'10px'
                                                    }}
                                                    >
                                                        <Typography
                                                        sx={{
                                                            textAlign:'left',
                                                            fontSize:'13px',
                                                            fontWeight:'bold',
                                                            color: 'text.primary'
                                                        }}
                                                        >SEDEX</Typography>
                                                        <Typography
                                                        sx={{
                                                            textAlign:'left',
                                                            fontSize:'13px',
                                                            color: 'text.primary'
                                                        }}>{order.frete.prazosedex} Dia{parseInt(order.frete.prazosedex) > 1 ? 's' : ''}, {order.frete.valorsedex.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}</Typography>
                                                    </div>
                                                    <CheckCircleIcon 
                                                    sx={{color: order.frete?.change === 'sedex' ? color.fontPrimary : color.background}}
                                                    />
                                                </div>
                                            </div>
                                        </ButtonBase>
                                        
                                        <ButtonBase
                                        onClick={() => setOrder({...order, frete: {...order.frete, change: 'pac'}})}
                                        sx={{
                                            margin:'10px',
                                            borderRadius:'10px',
                                            width:'calc(100% - 20px)'
                                        }}
                                        >
                                            <div
                                            style={{
                                                display:'flex',
                                                width:'calc(100% - 20px)',
                                                padding:'10px',
                                                borderRadius:'5px',
                                                border: `1px solid ${order.frete?.change === 'pac' ? color.fontPrimary : color.background}` 
                                            }}
                                            >
                                                <img
                                                src={pac}
                                                alt='pac'
                                                style={{
                                                    width:'30px',
                                                    objectFit:'contain'
                                                }}
                                                ></img>
                                                <div
                                                style={{
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    width:'100%',
                                                    alignItems:'center'
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        alignItems:'left',
                                                        marginLeft:'10px'
                                                    }}
                                                    >
                                                        <Typography
                                                        sx={{
                                                            textAlign:'left',
                                                            fontSize:'13px',
                                                            fontWeight:'bold',
                                                            color: 'text.primary'
                                                        }}
                                                        >PAC</Typography>
                                                        <Typography
                                                        sx={{
                                                            textAlign:'left',
                                                            fontSize:'13px',
                                                            color: 'text.primary'
                                                        }}>{order.frete.prazopac} Dia{parseInt(order.frete.prazopac) > 1 ? 's' : ''}, {order.frete.valorpac.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}</Typography>
                                                    </div>
                                                    <CheckCircleIcon 
                                                    sx={{color: order.frete?.change === 'pac' ? color.fontPrimary : color.background}}
                                                    />
                                                </div>
                                            </div>
                                        </ButtonBase>
                                    </div>}
                                </div>

                                <div
                                style={{
                                    margin:'10px 10px 10px 10px',
                                }}>
                                    <div
                                    style={{
                                        display:'flex',
                                        justifyContent:'space-between'
                                    }}>
                                        <Typography
                                        sx={{
                                            fontSize:'15px'
                                        }}>
                                            Subtotal
                                        </Typography>
                                        <Typography
                                        sx={{
                                            fontSize:'15px'
                                        }}>
                                            {vlrcompras.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                                        </Typography>
                                    </div>
                                    {['delivery','frete'].indexOf(order.delivery_type) !== -1 && <div
                                    style={{
                                        display:'flex',
                                        justifyContent:'space-between'
                                    }}>
                                        <Typography
                                        sx={{
                                            fontSize:'15px'
                                        }}>
                                            Taxa de Entrega
                                        </Typography>
                                        <Typography
                                        sx={{
                                            fontSize:'15px'
                                        }}>
                                            {taxa().toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                                        </Typography>
                                    </div>}
                                    <div
                                    style={{
                                        display:'flex',
                                        justifyContent:'space-between'
                                    }}>
                                        <Typography
                                        sx={{
                                            color: color.fontPrimary,
                                            fontSize:'17px',
                                            fontWeight:'bold'
                                        }}>
                                            Total
                                        </Typography>
                                        <Typography
                                        sx={{
                                            color: color.fontPrimary,
                                            fontSize:'17px',
                                            fontWeight:'bold'
                                        }}>
                                            {(vlrcompras + taxa()).toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'left',
                                backgroundColor:color.background,
                                borderRadius:'10px 10px 0px 0px'
                            }}>
                                <Button 
                                onClick={() => {
                                    checkoutRef.current?.unmount()
                                    setIndex(1)
                                }}
                                color='error' sx={{height:'40px'}}>cancelar</Button>
                            </div>
                            <div
                            style={{
                                backgroundColor:color.background,
                                height:'490px',
                                width:'100%',
                                borderRadius:'0px 0px 10px 10px',
                                maxHeight:'490px',
                                overflowY:'auto'
                            }}>

                                <Checkout 
                                ref={checkoutRef}
                                style={{
                                    width:'auto'
                                }}
                                ComponentProps={{
                                    accordionProps: {
                                        sx: {
                                            width: 'auto !important',
                                            // display:'flex'
                                        }
                                    },
                                    accordionSumaryProps: {
                                        sx: {
                                            width: '100% !important'
                                        }
                                    }
                                }}
                                />

                            </div>
                        </div>
                    </SwipeableViews>
                    
                    <div
                    style={{
                        height:'60px',
                        backgroundColor: color.fontPrimary,
                        borderRadius:'10px',
                        display:'flex',
                        justifyContent:'space-between',
                        flexDirection:'row',
                        alignItems:"center",
                        boxShadow:'black 0px 0px 9px 0px'
                    }}>
                        {(!replaceOrder.idvenda || replaceOrder.replace_payment) &&
                        <IconButton
                        onClick={({currentTarget}) => setDelAnchor(currentTarget)}
                        sx={{
                            marginLeft:'15px',
                            color:color.background
                        }}>
                            <DeleteIcon />
                        </IconButton>}
                        
                        {replaceOrder?.idvenda ? 
                        <div
                        style={{
                            display:'flex',
                            flexDirection:"column"
                        }}
                        >
                            <Typography
                            onClick={next}
                            sx={{color:color.background,margin:'0px 10px'}}
                            >{vlrcompras.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                            </Typography>
                            <Typography
                            onClick={next}
                            sx={{
                                color:color.background,
                                margin:'0px 10px',
                                fontSize:'14px'
                            }}
                            >Entrega: {parseFloat(replaceOrder?.vlrfrete || 0).toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                            </Typography>
                        </div>: 
                        <Typography
                        onClick={next}
                        sx={{color:color.background,margin:'0px 10px'}}
                        >{vlrcompras.toLocaleString('pt-BR', {currency:'BRL',style:'currency'})}
                        </Typography>}
                        
                        {index === 2 ?
                        <div style={{width:'55px'}}></div> :
                        <>
                        {
                            replaceOrder.idvenda ? 
                            <Button 
                            onClick={next}
                            sx={{
                                color:color.background,
                                fontWeight:'bold'
                            }}>concluir</Button>:
                            <IconButton
                            onClick={next}
                            sx={{
                                marginRight:'15px',
                                color:color.background
                            }}>
                                {index === 0 ? <ShoppingCartCheckoutIcon /> : <PaymentsOutlinedIcon />}
                            </IconButton>
                        }
                        </>
                        }
                    </div>
                </div>
            </Popover>

            <Popover
            open={delAnchor}
            anchorEl={delAnchor}
            onClose={() => setDelAnchor(null)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                backgroundColor:'rgba(0,0,0,0)',
                '.MuiPaper-root' : {
                    backgroundColor:'rgba(0,0,0,0)',
                    backgroundImage:'none',
                    boxShadow:'none'
                }
            }}>
                <div
                style={{
                    borderRadius:'10px',
                    width:'200px',
                    padding:'15px',
                    backgroundColor:color.background
                }}>
                    <Typography>Deseja excluir todos os ítens do carrinho ?</Typography>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'10px'
                    }}>
                        <Button
                        color='error'
                        onClick={() => setDelAnchor(null)}
                        >Não</Button>
                        <Button
                        onClick={() => {
                            setCart([])
                            setDelAnchor(null)
                            setReplaceOrder({})
                            if (preferences.delivery) {
                                setOrder({delivery_type:'delivery'})
                            } else 
                            if (preferences.frete) {
                                setOrder({delivery_type:'frete'})
                            } else 
                            if (preferences.retirada) {
                                setOrder({delivery_type:'retirada'})
                            }
                            forceUpdate()
                            sendAction({
                                unique_id: analytics.unique_id,
                                action_id: 'cart',
                                detail_action_id: 'clearCart',
                                complete_detail: `Limpou carrinho`,
                                idproduto: null,
                                idvariacaoproduto: null,
                                idprodutocategoria: null,
                                nivelcategoria: null,
                                token: user.token,
                                lat: geolocation?.coords?.latitude,
                                lng: geolocation?.coords?.longitude
                            },
                            (c) => (cancel = c),
                            cancel)
                        }}
                        color='success'
                        >Sim</Button>
                    </div>
                </div>
            </Popover>

            <Zoom
            in={filtered.length || open}
            timeout={transitionDuration}
            style={{
                transitionDelay: `${195}ms`,
            }}
            unmountOnExit
            >
                <Fab
                onClick={({currentTarget}) => {
                    setOpen(currentTarget)
                }}
                sx={{
                    position:'fixed',
                    bottom: '20px',
                    left: 'calc((100% - 56px)/2)',
                    bgcolor:color.fontPrimary,
                    color:color.secondary,
                    transition: 'all 1s linear'
                }}>
                    <ShoppingCartOutlinedIcon />
                    <Badge
                    badgeContent={
                        <Typography
                        sx={{
                            fontSize:'12px',
                            backgroundColor:color.fontPrimary,
                            borderRadius:'5px',
                            padding:'1px',
                            boxShadow:'0px 0px 9px 1px black',
                            minWidth:'25px'
                        }}>{filtered.length <= 999 ? filtered.length : '999+'}</Typography>
                    }
                    sx={{
                        top:'-20px',
                        left:'10px'
                    }}
                    >
                        
                    </Badge>
                </Fab>
            </Zoom>
        </>
    )
}