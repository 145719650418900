import axios from 'axios'
import { api } from './defaults'

const CancelToken = axios.CancelToken;

const sendAction = ( params, executor, cancel = undefined ) => {
    if ( !params.unique_id ) return 
    if ( !params.action_id ) return 
    if ( !params.detail_action_id ) return 
    if (cancel !== undefined) cancel()

    axios
    .post(`${api}/client/metadata/action`,params,{
        headers: {
            Authorization: params.token
        },
        cancelToken: new CancelToken(executor)
    })
    .then(response => {})
    .catch(err => {})
}

export default sendAction