import { useTheme } from '@mui/material/styles'

export default function Index (props) {
    const theme = useTheme()

    return (
        <div
        style={{
            padding:'20px',
            borderRadius:'10px',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727' 
        }}>

        </div>
    )
}