import axios from "axios";
import encrypt from "./aes";
import { api } from "./defaults";

const createOrder = async (data, token, callback=()=>{}) => {

    try {
        const key = await axios.get(`${api}/public_key`)

        const encrypted = encrypt( key.data.publicKey, JSON.stringify(data) )
        
        const response = await axios.post(`${api}/order`, encrypted,{
            headers: {
                Authorization: token,
                client_id: key.data.clientId
            }
        })

        callback(undefined, response.data)
        return { err: undefined, out: response.data }
    } catch (err) {
        callback(err, undefined)
        return { err, out: undefined }
    }
}

export default createOrder