import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import auth from './firebase'
import { useTheme } from '@mui/material/styles'
import Logo from '../../logo'
import { 
    Button, 
    ButtonBase, 
    Divider, 
    FormControl, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    Modal, 
    OutlinedInput, 
    TextField, 
    Typography 
} from "@mui/material";
import google from '../../../static/google.png'
import axios from "axios";
import { api } from "../../../utils/defaults";
import React, { forwardRef, useContext, useState } from "react";
import { LoadingContext, LoginContext, UserContext } from "../../../context";
import InputMask from 'react-input-mask'
import { passwordStrength } from 'check-password-strength'
import { useNavigate, useSearchParams } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

const Login = forwardRef((props, ref) => {
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState({})
    const [loading, setLoading] = useContext(LoadingContext)
    const [pass, setPass] = useState(false)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
        'login_hint': 'user@example.com'
    });

    const googleLogin = () => {
        setLoading(true)
        signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;

            axios
            .post(`${api}/client/g/oauth${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,{
                accessToken: user.accessToken,
                cpf: state.cpf
            })
            .then(response => {
                setState({})
                if (props.onAuth) return props.onAuth(response.data)
                if (searchParams.get('idvenda')) navigate(`/order/${searchParams.get('idvenda')}`)
                if (typeof state.callback === 'function') state.callback(undefined, response.data)
                setUser(response.data)
                localStorage.setItem('_exp',`${response.data.exp}.${Date.now()}`)
                setState({ open: false })
            })
            .catch(err => {
                if (err.response?.data?.require_field) {
                    if (err.response?.data?.require_field !== 'cpf') return toast.error(err.response.data.message)
                    toast.error(err.response?.data?.message)
                    return setState({...state, accessToken: err.response?.data?.accessToken, require_cpf: true})
                }

                toast.error(typeof err.response?.data === 'string' ? err.response?.data : err.response?.data?.message)
                if (typeof state.callback === 'function') state.callback(err.response?.data, undefined)
            })
            .finally(() => setLoading(false))

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            toast.error(errorMessage)
            setLoading(false)
        });
    }

    const sendCpf = () => {
        if (state.accessToken) {
            axios
            .post(`${api}/client/g/oauth${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,{
                accessToken: state.accessToken,
                cpf: state.cpf
            })
            .then(response => {
                setState({})
                if (props.onAuth) return props.onAuth(response.data)
                if (searchParams.get('idvenda')) navigate(`/order/${searchParams.get('idvenda')}`)
                if (typeof state.callback === 'function') state.callback(undefined, response.data)
                setUser(response.data)
                localStorage.setItem('_exp',`${response.data.exp}.${Date.now()}`)
                setState({ open: false })
            })
            .catch(err => {
                if (err.response?.data?.require_field) {
                    if (err.response?.data?.require_field !== 'cpf') return toast.error(err.response.data.message)
                    toast.error(err.response?.data?.message)
                    return setState({...state, accessToken: err.response?.data?.accessToken, require_cpf: true})
                }

                toast.error(typeof err.response.data === 'string' ? err.response?.data : err.response?.data?.message)
                if (typeof state.callback === 'function') state.callback(err.response?.data, undefined)
            })
            .finally(() => setLoading(false))
        } else {
            login()
        }
    }

    const sendPassword = () => {
        if (passwordStrength(state.new_password).id !== 3) return toast.error('Crie uma senha forte')
        if (!state.new_password || !state.new_password_retype) return toast.error('Preencha os dois campos de senha')
        if (state.new_password !== state.new_password_retype) return toast.error('Senhas não conferem')

        login()
    }

    const login = () => {
        if (state.mode === 'cadaster') {
            if (passwordStrength(state.new_password).id !== 3) return toast.error('Crie uma senha forte')
            if (!state.new_password || !state.new_password_retype) return toast.error('Preencha os dois campos de senha')
            if (state.new_password !== state.new_password_retype) return toast.error('Senhas não conferem')
            
            setLoading(true)
            axios
            .post(`${api}/client${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,{
                ...state,
                senha: state.new_password
            })
            .then(response => {
                if (props.onAuth) props.onAuth(response.data)
                if (searchParams.get('idvenda')) navigate(`/order/${searchParams.get('idvenda')}`)
                if (typeof state.callback === 'function') state.callback(undefined, response.data)
                setUser(response.data)
                localStorage.setItem('_exp',`${response.data.exp}.${Date.now()}`)
                setState({ open: false })
            })
            .catch(err => {
                toast.error(typeof err.response?.data === 'string' ? err.response?.data : err.response?.data?.message)
                if (typeof state.callback === 'function') state.callback(err.response?.data, undefined)
            })
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/client/auth${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,state)
            .then(response => {
                if (props.onAuth) props.onAuth(response.data)
                if (searchParams.get('idvenda')) navigate(`/order/${searchParams.get('idvenda')}`)
                if (typeof state.callback === 'function') state.callback(undefined, response.data)
                setUser(response.data)
                localStorage.setItem('_exp',`${response.data.exp}.${Date.now()}`)
                setState({ open: false })
            })
            .catch(err => {
                if (err.response?.data?.require_field) {
                    if (err.response.data?.require_field === 'cpf') {
                        setState({...state, require_cpf: true})
                        return toast.error(err.response?.data?.message)
                    }

                    if (err.response?.data?.require_field === 'senha') {
                        setState({...state, require_password: true})
                        return toast.error(err.response?.data?.message)
                    }
                    toast.error(err.response?.data?.message)
                }
                
                toast.error(typeof err.response.data === 'string' ? err.response.data : err.response.data?.message)
                if (typeof state.callback === 'function') state.callback(err.response?.data, undefined)
            })
            .finally(() => setLoading(false))
        }
    }

    const resetpassword = () => {
        if (!state.email) return toast.error('Informe um E-mail')
        if (!state.cpf) return toast.error('Informe um CPF')

        setLoading(true)
        axios
        .post(`${api}/client/reset-password${`?restore=${window.location.pathname}${window.location.search}`.replaceAll('&','+')}`,state)
        .then(response => {
            toast.success('Verifique seu E-mail')
            setState({ ...state, open: false })
        })
        .catch(err => toast.error(typeof err.response?.data === 'string' ? err.response?.data : err.response?.data?.message))
        .finally(() => setLoading(false))
    }
    
    const theme = useTheme()

    React.useImperativeHandle(ref, () => ({
        open: (callback=()=>{}) => setState({ ...state, callback, open: true }),
        close: () => setState({ ...state, callback: undefined, open: false})
    }))

    return (
        <>
            <Modal
            open={state.require_cpf}
            sx={{
                justifyContent:"center",
                alignItems:'center',
                display:"flex"
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727',
                    minWidth:'300px',
                    display:'flex',
                    flexDirection:"column"
                }}>

                    <Typography sx={{
                        fontFamily:'Quicksand',
                        marginBottom:'10px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                    }}>Por segurança, informe seu CPF</Typography>

                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={state.cpf}
                    onChange={({target}) => setState({...state, cpf:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        inputProps={{
                                            id:'cliente_cpf_ref'
                                        }}
                                        InputLabelProps={{shrink:state.cpf?true:false}}
                                        label='CPF'
                                        onKeyDown={(event) => {
                                            if (event.keyCode===13) sendCpf()
                                        }}
                                        />}
                    </InputMask>

                    <Button
                    variant='outlined'
                    sx={{margin:'10px 0px'}}
                    onClick={() => sendCpf()}
                    >confirmar</Button>
                </div>
            </Modal>

            <Modal
            open={state.require_password}
            sx={{
                justifyContent:"center",
                alignItems:'center',
                display:"flex"
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#272727',
                    minWidth:'300px',
                    display:'flex',
                    flexDirection:"column"
                }}>

                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={state.cpf}
                    onChange={({target}) => setState({...state, cpf:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        inputProps={{
                                            id:'cliente_cpf_ref'
                                        }}
                                        InputLabelProps={{shrink:state.cpf?true:false}}
                                        label='CPF'
                                        onKeyDown={(event) => {
                                            if (event.keyCode===13) sendPassword()
                                        }}
                                        sx={{marginBottom:'10px'}}
                                        />}
                    </InputMask>

                    <Typography 
                    sx={{
                        fontFamily:'Quicksand',
                        marginBottom:'10px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                    }}>Crie uma senha forte</Typography>

                    {/* <TextField 
                    value={state.new_password}
                    onChange={({target}) => setState({...state, new_password: target.value})}
                    label='Nova senha'
                    type='password'
                    onKeyDown={(event) => {
                        if (event.keyCode===13) sendPassword()
                    }}
                    helperText={
                        state.new_password && <Typography sx={{
                            color: strength_level[passwordStrength(state.new_password).id].color
                        }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                    }
                    /> */}

                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                        <OutlinedInput  
                        label='Nova senha'
                        type={pass ? 'text' : 'password'}
                        value={state.new_password}
                        onChange={({target}) => setState({...state, new_password: target.value})}
                        onKeyDown={(event) => {
                            if (event.keyCode===13) sendPassword()
                        }}
                        helperText={
                            state.new_password && <Typography sx={{
                                color: strength_level[passwordStrength(state.new_password).id].color
                            }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>

                    <TextField 
                    value={state.new_password_retype}
                    onChange={({target}) => setState({...state, new_password_retype: target.value})}
                    label='Confirme a senha'
                    type='password'
                    onKeyDown={(event) => {
                        if (event.keyCode===13) sendPassword()
                    }}
                    helperText={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype ? 'Senhas não conferem' : ''}
                    error={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype}
                    sx={{marginTop:'10px'}}
                    />

                    <Button
                    variant='outlined'
                    sx={{margin:'10px 0px'}}
                    onClick={() => sendPassword()}
                    >confirmar</Button>
                </div>
            </Modal>

            <Modal
            open={state.open}
            onClose={() => setState({ ...state, open:false, callback: undefined })}
            sx={{
                width:'100%',
                height:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    minWidth:'300px',
                    padding:'20px',
                    borderRadius:'10px',
                    boxShadow:'rgb(0 0 0) 0px 0px 11px 1px',
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#121212',
                    display:'flex',
                    flexDirection:"column"
                }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'center'
                    }}>
                    <Logo 
                    style={{
                        width:'140px',
                        margin:'15px'
                    }} />
                    </div>

                    {state.mode==='cadaster' &&
                    <TextField 
                    //variant="standard"
                    label='Nome'
                    value={state.nome}
                    onChange={({target}) => setState({...state, nome: target.value})}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) login()
                    }}
                    sx={{margin:'10px 0px'}}
                    />}

                    <TextField 
                    //variant="standard"
                    label='E-mail'
                    value={state.email}
                    onChange={({target}) => setState({...state, email: target.value})}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) login()
                    }}
                    sx={{margin:'10px 0px'}}
                    />

                    {(state.mode==='cadaster' || state.mode==='reset-password') &&
                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={state.cpf}
                    onChange={({target}) => setState({...state, cpf:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        inputProps={{
                                            id:'cliente_cpf_ref'
                                        }}
                                        InputLabelProps={{shrink:state.cpf?true:false}}
                                        label='CPF'
                                        onKeyDown={(event) => {
                                            if (event.keyCode===13) sendCpf()
                                        }}
                                        />}
                    </InputMask>}

                    {state.mode==='cadaster' &&
                    <>
                    <Typography 
                        sx={{
                            fontFamily:'Quicksand',
                            marginBottom:'10px',
                            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                        }}>Crie uma senha forte</Typography>

                        {/* <TextField 
                        value={state.new_password}
                        onChange={({target}) => setState({...state, new_password: target.value})}
                        label='Nova senha'
                        type='password'
                        onKeyDown={(event) => {
                            if (event.keyCode===13) sendPassword()
                        }}
                        helperText={
                            state.new_password && <Typography sx={{
                                color: strength_level[passwordStrength(state.new_password).id].color
                            }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                        }
                        /> */}
                        
                        <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                            <OutlinedInput  
                            label='Nova senha'
                            type={pass ? 'text' : 'password'}
                            value={state.new_password}
                            onChange={({target}) => setState({...state, new_password: target.value})}
                            onKeyDown={(event) => {
                                if (event.keyCode===13) sendPassword()
                            }}
                            helperText={
                                state.new_password && <Typography sx={{
                                    color: strength_level[passwordStrength(state.new_password).id].color
                                }}>{strength_level[passwordStrength(state.new_password).id].label}</Typography>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPass(!pass)}
                                    onMouseDown={() => setPass(!pass)}
                                    edge="end"
                                >
                                    {!pass ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            }
                            />
                        </FormControl>

                        <TextField 
                        value={state.new_password_retype}
                        onChange={({target}) => setState({...state, new_password_retype: target.value})}
                        label='Confirme a senha'
                        type='password'
                        onKeyDown={(event) => {
                            if (event.keyCode===13) sendPassword()
                        }}
                        helperText={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype ? 'Senhas não conferem' : ''}
                        error={state.new_password && state.new_password_retype && state.new_password !== state.new_password_retype}
                        sx={{marginTop:'10px'}}
                        />
                    </>
                    }

                    {/* {state.mode!=='reset-password' && state.mode!=='cadaster' && 
                    <TextField 
                    //variant="standard"
                    label='Senha'
                    type='password'
                    value={state.senha}
                    onChange={({target}) => setState({...state, senha: target.value})}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) login()
                    }}
                    sx={{margin:'10px 0px'}}
                    />} */}

                    {state.mode!=='reset-password' && state.mode!=='cadaster' && 
                    <FormControl sx={{ marginTop:'10px' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput  
                        label='Senha'
                        type={pass ? 'text' : 'password'}
                        value={state.senha}
                        onChange={({target}) => setState({...state, senha: target.value})}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) login()
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPass(!pass)}
                                onMouseDown={() => setPass(!pass)}
                                edge="end"
                            >
                                {!pass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>}

                    <Button
                    onClick={() => {
                        if (state.mode==='reset-password') return resetpassword()
                        login()
                    }}
                    variant='outlined'
                    sx={{margin:'10px 0px'}}
                    >{state.mode==='cadaster'?'Cadastrar':(state.mode==='reset-password'?'confirmar':'Entrar')}</Button>

                    <ButtonBase
                    onClick={() => googleLogin()}
                    >
                        <div
                        style={{
                            margin:'10px 0px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <img 
                            style={{
                                width:'25px',
                                height:'25px',
                                objectFit:'contain',
                                marginRight:'15px'
                            }}
                            src={google} alt='google'></img>
                            <Typography
                            sx={{
                                fontFamily:'Quicksand',
                                color: 'text.primary'
                            }}>Entrar com Google</Typography>
                        </div>
                    </ButtonBase>
                    <Divider sx={{margin:'10px 0px'}} />
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        <ButtonBase onClick={() => setState({mode: state.mode==='reset-password'?'login':'reset-password'})}>
                            <Typography
                            sx={{
                                fontSize:'15px',
                                textAlign:'center',
                                marginTop:'10px',
                                opacity: 0.7,
                                color: 'text.primary'
                            }}
                            >{state.mode==='reset-password'?'Entrar':'Esqueci a senha'}</Typography>
                        </ButtonBase>
                        <ButtonBase
                        onClick={() => setState({...state, mode:state.mode === 'cadaster' ? 'login' : 'cadaster'})}
                        >
                            <Typography
                            sx={{
                                fontSize:'15px',
                                textAlign:'center',
                                marginTop:'10px',
                                opacity: 0.7,
                                color: 'text.primary'
                            }}
                            >{state.mode === 'cadaster' ? 'Já tem cadastro ?' : 'Não tenho cadastro'}</Typography>
                        </ButtonBase>
                    </div>
                </div>
            </Modal>
        </>
    )
})

export default Login