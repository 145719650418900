import React, { forwardRef, useContext } from 'react'
import { UserContext } from "../../context"
import { toast } from "react-toastify"
import { Button, Typography, useTheme } from '@mui/material'

const SaveCard = forwardRef((props, ref) => {
    const [user, setUser] = useContext(UserContext)
    const theme = useTheme()

    const ask = (callback=()=>{}) => {
        if ( !user.token ) return false
        return new Promise((resolve) => {
            let id = toast(
                <div
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#121212':'#eee',
                    // padding:'20px',
                    borderRadius:'10px'
                }}>
                    <Typography 
                    sx={{
                        color:'text.primary',
                        fontSize:'18px',
                        marginBottom:'10px'
                    }}>
                        Deseja salvar o cartão para compras futuras?
                    </Typography>

                    <Button 
                    onClick={() => {
                        resolve(true)
                        callback(true)
                        toast.dismiss(id)
                    }} variant='contained' color='success' sx={{marginRight:'10px'}}>Sim</Button>
                    <Button 
                    onClick={() => {
                        resolve(false)
                        callback(false)
                        toast.dismiss(id)
                    }} variant='contained' color='error'>Não</Button>
                </div>
                ,{
                    autoClose: false,
                    closeButton: false,
                    closeOnClick: false,
                    draggable: false
                })
        })
    }
    React.useImperativeHandle(ref, () => ({ 
        ask
    }))

    return (
        <>
        </>
    )
})

export default SaveCard