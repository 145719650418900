import { Button, CircularProgress, Divider, Modal, Typography, useTheme } from "@mui/material"
import React, { forwardRef, useContext, useReducer, useState } from "react"
import getColors from "../../../getColor"
import { CartContext, PreferencesContext } from "../../../../context"
import axios from "axios"
import { api } from "../../../../utils/defaults"
import Item from "./item"
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Check = forwardRef((props, ref) => {
    const [cart, setCart] = useContext(CartContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [check, setCheck] = useState([])
    const [checking, setChecking] = useState(false)
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    const theme = useTheme()
    const color = getColors(preferences, theme)

    React.useImperativeHandle(ref, () => ({ 
        check: async (list=undefined, callback=()=>{}) => {
            if ( list ) {
                return setCheck(list)
            }
            setChecking(true)

            try {
                
                const response = await axios.post(`${api}/cart/check`,{list:cart?.filter(({adicionado}) => adicionado).map(e => ({
                    idproduto: e.idproduto,
                    idvariacaoproduto: e.idvariacaoproduto,
                    idprodutopai: e.idprodutopai,
                    quantidade: e.quantidade,
                    qtdminima: e.qtdminima,
                    valor: e.valor,
                    desconto: e.desconto,
                    promocao: e.promocao,
                    porcdesconto: e.porcdesconto,
                    idlojaecommerceacao: e.idlojaecommerceacao,
                    vlrvenda: e.vlrvenda,
                    adicionado: e.adicionado
                }))})

                setChecking(false)
                setCheck(response.data)
                callback(undefined, response.data)
                return { err: undefined, out: response.data}

            } catch (err) {
                
                setChecking(false)
                callback(err, undefined)
                return { err, out: undefined }
            }
        },
        setChecking: ( state ) => setChecking(state)
    }))

    return (
        <>
            <Modal
            open={checking}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    alignItems:'center'
                }}>
                    <CircularProgress sx={{color:color.fontPrimary}} />
                    <Typography sx={{
                        color:'text.primary',
                        marginTop:'10px'
                    }}>Validando seu carrinho...</Typography>
                </div>
            </Modal>

            <Modal
            open={check.filter(({altered}) => altered).length}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <div
                style={{
                    backgroundColor: color.background,
                    borderRadius:'10px',
                    padding:'15px',
                    maxWidth:'380px'
                }}>
                    <Typography sx={{
                        color:'text.primary',
                        textAlign:'center',
                        fontSize:'18px',
                        fontWeight:'bold',
                        marginBottom:'10px'
                    }}>Existem atualizações em alguns ítens do seu carrinho</Typography>
                    <div
                    style={{
                        width:'100%',
                        height:'500px',
                        overflowY:'auto'
                    }}> 
                        {check.filter(({altered}) => altered)?.map((e,index) => {
                            const item = cart.find(i => (e.received.idproduto === i.idproduto && e.received.idvariacaoproduto === i.idvariacaoproduto))
                            return (
                                <div>
                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'space-between'
                                    }}>
                                        <Item state={item} />
                                        <ArrowForwardOutlinedIcon sx={{color:color.fontPrimary}} />
                                        <Item state={{
                                            ...item,
                                            ...e.atualized,
                                            removed: !e.atualized.idproduto && !e.atualized.idvariacaoproduto
                                        }} />
                                    </div>

                                    <div
                                    style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'space-between',
                                        marginTop:'10px'
                                    }}>
                                        <Button
                                        onClick={() => {
                                            const c = cart
                                            c[item.index].adicionado = 0
                                            setCart(c)

                                            const ch = check
                                            ch.splice(index,1)
                                            setCheck(ch)
                                            forceUpdate()
                                        }}
                                        color='error'
                                        variant='outlined'>
                                            remover
                                        </Button>
                                        <Button
                                        onClick={() => {
                                            if (!e.atualized.idproduto && !e.atualized.idvariacaoproduto) {
                                                const c = cart
                                                c[item.index].adicionado = 0
                                                setCart(c)
                                                const ch = check
                                                ch.splice(index,1)
                                                setCheck(ch)
                                                forceUpdate()
                                                return
                                            }
                                            const c = cart
                                            c[item.index] = {
                                                ...item,
                                                ...e.atualized
                                            }
                                            setCart(c)
                                            const ch = check
                                            ch.splice(index,1)
                                            setCheck(ch)
                                            forceUpdate()
                                        }}
                                        color='success'
                                        variant='outlined'>
                                            confirmar
                                        </Button>
                                    </div>
                                    <Divider sx={{margin:'10px 0px'}} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Modal>

        </>
    )
})

export default Check