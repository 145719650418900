import { useState, useEffect } from 'react'
import {
    encrypt,
    decrypt
} from './crypt'

const usePersistedState = ( key, initialState, crypt=false ) => {
    const [state, setState] = useState( () => {
        const storageValue = crypt ? decrypt(localStorage.getItem(key),crypt) : localStorage.getItem(key)

        if (storageValue) {
            try {
                return JSON.parse(storageValue)
            } catch (err) {
                return storageValue
            }
        } else {
            return initialState
        }
    })

    const newState = a => {
        if (a===undefined) return
        setState(a)
        a = crypt ? encrypt(JSON.stringify(a),crypt) : JSON.stringify(a)
        localStorage.setItem(key,a)
    }
    useEffect(() => localStorage.setItem(key,crypt ? encrypt(JSON.stringify(state),crypt) : JSON.stringify(state)),[key, state, crypt])

    return [state, newState]
}

export default usePersistedState