import forge from 'node-forge';

const encrypt = (publicKey, data) => {
    try {
        const key = forge.random.getBytesSync(16);
        const iv = forge.random.getBytesSync(16);

        const cipher = forge.cipher.createCipher('AES-CBC', key);
        cipher.start({ iv });
        cipher.update(forge.util.createBuffer(data));
        cipher.finish();
        const encrypted = cipher.output;

        const chaveSimetricaTexto = forge.util.bytesToHex(key);
        const chaveSimetricaCriptografada = forge.pki.publicKeyFromPem(publicKey).encrypt(chaveSimetricaTexto, 'RSA-OAEP');
        
        return {
            data: forge.util.encode64(encrypted.bytes()),
            key: `${forge.util.encode64(chaveSimetricaCriptografada)}._.${forge.util.encode64(iv)}`
        }
    } catch (err) {
        console.log(err)
        return {
            error: true,
            err
        }
    }
}

export default encrypt;

