import React, { useContext, useState, useEffect } from 'react'
import {
    Box,
    Button,
    Skeleton,
    Typography
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CategoryIcon from '@mui/icons-material/Category';

import {
    SizeContext,
    PreferencesContext,
    BlurContext,
    KeyContext,
    MenuContext,
    UserContext,
    AnalyticsContext
} from '../../../../context'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import getColors from '../../../getColor';
import Action from '../home/action'
import { api } from '../../../../utils/defaults';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import usePersistedState from '../../../../utils/usePersistedState';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useDrag from '../../../../utils/useDrag';
import ScrollToTop from "react-scroll-to-top";
import sendAction from '../../../../utils/sendAction';
import useGeolocation from '../../../../utils/useGeolocation.ts';

function SampleNextArrow(props) {
    const { sx, onClick, ft } = props;
    const [style, setStyle] = useState(sx)
    const [font, setFont] = useState(ft)
    useEffect(() => setStyle(sx),[sx])
    useEffect(() => setFont(ft),[ft])
    
    return (
        <div
        //className={className}
        style={{
            display: "block", 
            position:'absolute', 
            right:'10px', 
            zIndex:100,
            fontSize:0,
            lineHeight:0,
            top:'45%',
            padding:0,
            transform:'translate(0,-50%)',
            cursor:'pointer',
            color:'#fff',
            border:'none',
            outline:'none',
            background:'transparent',
            fontSize:'40px',
            ...style,
        }}
        onClick={onClick}>
            <ArrowForwardIosIcon sx={{fontSize:font}} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { sx, onClick, ft } = props;
    const [style, setStyle] = useState(sx)
    const [font, setFont] = useState(ft)
    useEffect(() => setStyle(sx),[sx])
    useEffect(() => setFont(ft),[ft])

    return (
        <div
        //className={className}
        style={{ 
            display: "block", 
            position:'absolute', 
            left:'10px', 
            zIndex:100,
            fontSize:0,
            lineHeight:0,
            top:'45%',
            padding:0,
            transform:'translate(0,-50%)',
            cursor:'pointer',
            color:'#fff',
            border:'none',
            outline:'none',
            background:'transparent',
            ...style, 
        }}
        onClick={onClick}>
            <ArrowBackIosNewIcon sx={{fontSize:font}} />
        </div>
    );
}

export default function App() {
    const theme = useTheme();
    const [user, setUser] = useContext(UserContext)
    const [key, setKey] = useContext(KeyContext)
    const [size, setSize] = useContext(SizeContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [blur, setBlur] = useContext(BlurContext)
    const [actions, setActions] = usePersistedState('actions',[], key)
    const [category, setCategory] = usePersistedState('category',[], key)
    const [loadingAction, setLoadingAction] = useState(true)

    let cancel
    const color = getColors(preferences, theme)
    const menuRef = useContext(MenuContext)
    const navigate = useNavigate()
    const geolocation = useGeolocation()

    const ColorButton = styled(Button)(({ theme }) => ({
        color: color.fontPrimary,
        backgroundColor: color.primary,
        '&:hover': { backgroundColor: '', },
    }));

    const settings = {
        autoplay:false,
        dots: true,
        infinite: true,
        speed: 400,
        autoplaySpeed: 5000,
        slidesToShow: size.width >= 1200 ? 4 : (size.width >= 620 ? 3 : 2),
        slidesToScroll: size.width >= 1200 ? 4 : (size.width >= 620 ? 3 : 2),
        initialSlide: 0,
        nextArrow: 
            <SampleNextArrow 
                sx={{
                    top:size.width >= 600 ? '45%' : '50%', 
                    color: color.primary
                }} 
                ft={size.width >= 600 ? '40px' : '30px'}/>,
        prevArrow: 
            <SamplePrevArrow 
                sx={{
                    top:size.width >= 600 ? '45%' : '50%', 
                    color: color.primary
                }} 
                ft={size.width >= 600 ? '40px' : '30px'}/>,
        appendDots: dots => (
            <div
              style={{
                padding: "10px",
                // position:'absolute',
                // bottom:size.width >= 600 ? '-30px' : '-20px',
                right:"10px"
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        )
    };

    useEffect(() => {
        axios
        .get(`${api}/action`)
        .then(response => setActions(response.data))
        .catch(err => {})
        .finally(() => setLoadingAction(false))
        axios
        .get(`${api}/category/1`)
        .then(response => setCategory(response.data))
        .catch(err => {})
    },[preferences.storecode])

    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const handleDrag = ({ scrollContainer }) => (ev) =>
        dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
        }
        });

    const [selected, setSelected] = useState("");
    const handleItemClick = (itemId) => {
        if (dragging) {
        return false;
        }
        setSelected(selected !== itemId ? itemId : "");
    };

    return (
        <Box
        sx={{
            display: 'flex',
            width: '100%',
            bgcolor:color.background,
            margin:'0px',
            padding:'0px',
            flexDirection:"column",
            // filter:blur && 'blur(5px)',
            overflowY:'hidden',
            minHeight: `calc(100vh - ${menuRef.current?.offsetHeight}px)`
        }}
        >

            {loadingAction ? 
            <Skeleton 
            variant="rectangular" 
            width="100vw" 
            height="27vw"
            /> :
            <Slider {...{
                ...settings,
                autoplay: true,
                slidesToScroll:1,
                slidesToShow: 1,
                speed: 1000,
                appendDots: dots => (
                    <div
                        style={{
                        padding: "10px",
                        position:'absolute',
                        bottom:size.width >= 600 ? '30px' : '0px',
                        right:"10px"
                        }}
                    >
                        <ul style={{ margin: "0px" }}> {dots} </ul>
                    </div>
                )
            }}>
                {actions.filter(({criabanner, fotobanner}) => (criabanner && fotobanner))?.map(e => (
                    <div
                    onClick={() => {
                        if (size.width >= 600) return
                        if (e.urlbanner) return window.open(e.urlbanner)
                        navigate(`/action/${e.idlojaecommerceacao}`)
                    }}
                    >
                        <img src={`data:${e.tipofotobanner};base64,${e.fotobanner}`} style={{
                            display:"block",
                            width:'100%',
                            height:'100%',
                            objectFit:'contain'
                        }}></img>
                        <div style={{
                            display:'flex',
                            justifyContent:'center',
                            position:'relative',
                            top:'-70px'
                        }}>
                            {size.width >= 600 && e.clicabanner && 
                            <ColorButton 
                            onClick={() => {
                                if (e.urlbanner) return window.open(e.urlbanner.indexOf('http')!== -1 ? e.urlbanner : `https://${e.urlbanner}`)
                                navigate(`/action/${e.idlojaecommerceacao}`)
                            }}
                            variant='contained'>ver acao</ColorButton>}
                        </div>
                    </div>
                ))}
            </Slider>}

            <div
            style={{
                // display:'flex',
                width:'100%',
                // overflowX:'auto'
            }}
            onMouseLeave={dragStop}>
                <ScrollMenu
                // LeftArrow={LeftArrow}
                // RightArrow={RightArrow}
                onMouseDown={() => dragStart}
                onMouseUp={() => dragStop}
                onMouseMove={handleDrag}
                >
                    {category?.sort((a,b) => {
                        if (a.categorian1 > b.categorian1) return 1
                        if (a.categorian1 < b.categorian1) return -1
                        return 0
                    }).map(e => (
                        <div
                        style={{
                            cursor:'pointer',
                            maxWidth:'110px',
                            userSelect:'none'
                        }}
                        onClick={(event) => {
                            navigate('/product',{
                                state: {
                                    nivelcategoria: 1,
                                    idprodutocategoria: e.idprodutocategorian1
                                }
                            })
                            sendAction({
                                unique_id: analytics.unique_id,
                                action_id: 'product',
                                detail_action_id: 'category',
                                complete_detail: `${e.categorian1}`,
                                idproduto: null,
                                idvariacaoproduto: null,
                                idprodutocategoria: e.idprodutocategorian1,
                                nivelcategoria: 1,
                                token: user.token,
                                lat: geolocation?.coords?.latitude,
                                lng: geolocation?.coords?.longitude
                            },
                            (c) => (cancel = c), 
                            cancel)
                        }}
                        >
                            <div
                            style={{
                                width:'70px',
                                borderRadius:'70px',
                                height:'70px',
                                backgroundColor: color.secondary,
                                margin:'10px 20px',
                                minWidth: '70px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center'
                            }}
                            >
                                {/* <CategoryIcon sx={{opacity:0.5}} /> */}
                                <img
                                style={{
                                    width:'100%',
                                    height:'100%',
                                    borderRadius:'100%'
                                }}
                                src={`https://${preferences.storecode}.allon.cloud/image/category/${e.categorian1}`}
                                >
                                </img>
                            </div>
                            <Typography
                            sx={{
                                textAlign:'center',
                                fontSize:'11px'
                            }}
                            >{e.categorian1}</Typography>
                        </div>
                    ))}
                </ScrollMenu>
            </div>

            {(loadingAction ? [{}] : actions.filter(e => (e.criaprateleira)))?.map((e,index) => (<Action action={e} settings={settings} loading={loadingAction} />))}
            <ScrollToTop smooth style={{
                backgroundColor: color.primary,
                boxShadow: 'none'
            }} />
        </Box>
    );
}
