import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Button, 
    TextField, 
    Typography
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import InputMask from 'react-input-mask'
import getColors from "../getColor";
import { toast } from "react-toastify";
import { PreferencesContext, UserContext } from "../../context";
import { useTheme } from '@mui/material/styles'
import { MercadoPagoSettingsContext } from "./context";

export default function Pix(props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [paymentParams, setPaymentParams] = useState({})
    const [typeParams, setTypeParams] = useState({})
    const [state, setState] = useState({})
    const [card, setCard] = useState({ isValid: false })
    const [open, setOpen] = useState(false)
    const [connector, setConnector] = useState({})    

    const mercadoPagoSettings = useContext(MercadoPagoSettingsContext)
    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => setOpen(props.opened), [props.opened])

    useEffect(() => {
        setTypeParams(props.typeParams)
        setPaymentParams(props.paymentParams)

        if (props.typeParams && props.paymentParams) init(props.typeParams, props.paymentParams)
    }, [
        props.typeParams,
        props.paymentParams
    ])

    const init = async (  ) => {
        if ( typeParams.conector === 'cielo' ) return
        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()

        mercadoPagoSettings.customization.paymentMethods = {
            [typeParams.to_conector]: 'all'
        }

        mercadoPagoSettings.callbacks.onSubmit = ({ selectedPaymentMethod, formData }) => {
                            
            return new Promise((resolve, reject) => {

                if (typeof paymentParams.onPay === 'function') 
                paymentParams.onPay({
                    selectedPaymentMethod,
                    formData: {
                        ...formData, 
                        payment_type_id: selectedPaymentMethod,
                        idformapagto: typeParams.idformapagto,
                        idconectorpagamento: typeParams.idconectorpagamento
                    },
                    paymentParams
                },() => {
                    resolve();
                    if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                },() => {
                    reject()
                })
            })
        }

        mercadoPagoSettings.callbacks.onError = (error) => {
            if ( typeof paymentParams.onError === 'function' ) paymentParams.onError(error)
            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
        }

        if ( typeParams.conector === 'mercado_pago' ) {

            const key = paymentParams.conector.mercado_pago.key.public_key
            const mercado_pago = new window.MercadoPago(key)
            const bricksBuilder = mercado_pago.bricks()

            const paymentBrickController = await bricksBuilder.create(
                'payment',
                'paymentBrick_pix_container',
                mercadoPagoSettings
            );

            setConnector({ mercado_pago, bricksBuilder, paymentBrickController })
        }
    }

    const accordionState = (state) => {
        if ( state ) {
            props.onOpen()
            init()
        } else {
            if ( typeParams.conector === 'mercado_pago') {
                if ( !connector.paymentBrickController ) return
                connector.paymentBrickController.unmount()
            }
            props.onClose()
        }
    }
    
    const pay = () => {
        paymentParams.onPay({
            selectedPaymentMethod: typeParams.payment_type_id,
            formData: {
                // ...formData, 
                payment_type_id: typeParams.payment_type_id,
                idformapagto: typeParams.idformapagto,
                idconectorpagamento: typeParams.idconectorpagamento
            },
            paymentParams,
            save_payment: true
        })
    }
    
    return (
        <Accordion 
        expanded={open}
        onChange={(a,state) => accordionState(state)}
        key={typeParams.idformapagto}
        {...props.accordionProps}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            {...props.accordionSumaryProps}
            >
            <Typography>{typeParams.apelido}</Typography>
            </AccordionSummary>
            <AccordionDetails
            sx={{ p: 0 }}
            >
            <div>
                {typeParams.conector === 'mercado_pago' && <div id="paymentBrick_pix_container" class='payment-brick' style={{fontFamily:'Quicksand'}}></div>}
                {typeParams.conector === 'cielo' &&
                <>
                    <div
                    style={{
                        padding:'10px'
                    }}
                    >
                        <Button
                        fullWidth
                        sx={{
                            bgcolor: color.fontPrimary,
                            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                        }}
                        startIcon={<LockOutlinedIcon />}
                        onClick={pay}
                        >
                            Pagar
                        </Button>
                    </div>
                </>}
            </div>
            </AccordionDetails>
        </Accordion>
    )
}