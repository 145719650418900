import { 
    useState, 
    useContext, 
    useCallback, 
    useEffect, 
    useRef, 
    useReducer 
} from 'react'

import { 
    IconButton, 
    Typography,
    ButtonBase,
    Popover,
    TextField,
    Button,
    Skeleton,
    Divider
} from '@mui/material'

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useTheme } from '@mui/material/styles'

import {
    AnalyticsContext,
    BlurContext,
    CartContext,
    // LoadingContext,
    PreferencesContext,
    SizeContext,
    UserContext
} from '../../../../../context'

import getColors from '../../../../getColor';
import { integer } from '../../../../../utils/input'
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
// import { RWebShare } from "react-web-share";

import './style.css'
import Image from './image';
import { useNavigate } from 'react-router-dom';
import addToCart from '../../../../../utils/addToCart';
import vlrvenda from '../../../../../utils/vlrvenda';
import get_tag from '../../../../../utils/getPromoTag';
import { api } from '../../../../../utils/defaults';
import sendAction from '../../../../../utils/sendAction';
import { toast } from 'react-toastify';
import useGeolocation from '../../../../../utils/useGeolocation.ts';

export default function Index (props) {
    const [quantidade, setQuantidade] = useState(0)
    const [blur, setBlur] = useContext(BlurContext)
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [cart, setCart] = useContext(CartContext)
    const [user, setUser] = useContext(UserContext)

    const [state, setState] = useState(props)
    const [products, setProducts] = useState([])
    const [caracteristicas, setCaracteristicas] = useState({})
    const [caracteristicas_filtradas, setCaracteristicas_filtradas] = useState({})
    const [filter, setFilter] = useState({})

    const [filtered, setFiltered] = useState([])
    const [images, setImages] = useState([])
    const [imagesLoad, setImagesLoad] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false)

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()
    const geolocation = useGeolocation()

    let cancel;

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    useEffect(() => {
        if (!props.idproduto && !props.idvariacaoproduto) return setState({loading: true, caracteristicas: []})

        const p = [...(props.variacao || [props])]
        const c = [...new Set(
            p
            ?.map(e => (e.caracteristicas))
            .reduce((a, next) => (a = [...a, ...(next || [])]),[])
            ?.map(e => (JSON.stringify(e)))
        )].reduce((a, next) => {
            next = JSON.parse(next)
            if (!next || next.propriedade === null) return a
            return a = {
                ...a,
                [next.propriedade] : [
                    ...(a[next.propriedade] || []),
                    next
                ]
            }
        },{})

        setProducts(p.map(e => ({...e, vlrvenda: vlrvenda(e)})))
        setCaracteristicas(c)
        setState(props)
    },[
        props.idproduto,
        props.idvariacaoproduto
    ])

    useEffect(() => {
        const f = products.filter(e => {
            const check = e.caracteristicas.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check.length) return e
        }).map((e) => ({
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        setCaracteristicas_filtradas([...new Set(
            f
            ?.map(e => (e.caracteristicas))
            .reduce((a, next) => (a = [...a, ...(next || [])]),[])
            ?.map(e => (JSON.stringify(e)))
        )].reduce((a, next) => {
            if (!next || next.propriedade === null) return a
            next = JSON.parse(next)
            return a = {
                ...a,
                [next.propriedade] : [
                    ...(a[next.propriedade] || []),
                    next
                ]
            }
        },{}))

        setFiltered(f)
        setQuantidade(parseFloat((f[0]?.adicionado ? (f[0]?.variavel ? f[0]?.adicionado / (f[0]?.incrementovariavel || 1) : f[0]?.adicionado) : 0).toFixed(1)))
    },[
        products.length,
        filter,
        cart.reduce((a, {adicionado}) => a += adicionado,0),
        state.idproduto,
        state.idvariacaoproduto
    ])

    useEffect(() => {
        const filtered = products.filter(e => {
            const check = e.caracteristicas.filter(e => (
                filter[e.propriedade]?.atributo === e.atributo
            ))
    
            if (Object.entries(filter).filter(e => (e[1])).length === check.length) return e
        }).map((e) => ({
            ...(cart.find(i => (
                (e.idproduto && i.idproduto === e.idproduto) ||
                (e.idvariacaoproduto && i.idvariacaoproduto === e.idvariacaoproduto)
            )) || e)
        }))

        if (
            filtered[0]?.adicionado === parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1) )||
            filtered[0]?.adicionado === undefined && quantidade === 0
        ) return

        const t = setTimeout(() => {
            const c = addToCart(filtered[0],cart,quantidade)
            if (c[1]) {
                setCart(c[0])
                const f = filtered
                f[0].adicionado = parseInt(quantidade * (filtered[0]?.variavel ? filtered[0].incrementovariavel : 1))
                setFiltered(f)
            }
        }, 300);

        if ( !filtered[0]?.adicionado && quantidade ) {
            sendAction({
                unique_id: analytics.unique_id,
                action_id: 'cart',
                detail_action_id: 'addToCart',
                complete_detail: filtered[0]?.nome,
                idproduto: filtered[0]?.idproduto,
                idvariacaoproduto: filtered[0]?.idvariacaoproduto,
                idprodutocategoria: null,
                nivelcategoria: null,
                token: user.token,
                lat: geolocation?.coords?.latitude,
                lng: geolocation?.coords?.longitude
            },
            (c) => (cancel = c),
            cancel)
        }

        return () => {
            clearTimeout(t)
        }
    },[quantidade])

    const disable = () => {
        if (!filtered.length) return true
        if (filtered.length === 1) return false
        // if (Object.entries(caracteristicas).length !== Object.entries(filter).length) return true
        return true
    }

    const storecode = preferences.storecode
    const promotag = get_tag(filtered[0])
    const vrvenda = vlrvenda(filtered[0])

    const parcela = preferences.qtdparcelasemjuros > 1 ? preferences.qtdparcelasemjuros : preferences.qtdmaximaparcelas;
    const dividir = preferences.qtdparcelasemjuros > 1 ? vrvenda : vrvenda*(100 + (parseFloat(preferences.porcjuros) || 0))/100;

    return (
        <>
        <Popover
        open={anchorEl2 !== null}
        anchorEl={anchorEl2}
        onClose={() => {
            setAnchorEl2(null)
            setBlur(false)
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        sx={{
            // borderRadius:'10px'
        }}
        >
            <div
            style={{ 
                backgroundColor:color.secondary,
                minWidth:'300px',
                maxWidth: size.width <= 500 ? '95vw' : '380px',
                width: size.width <= 500 ? '95vw' : '380px'
                // borderRadius:'10px'
            }}>
                <div style={{ position:'relative' }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:promotag ? 'space-between' : 'right',
                        alignItems:'center',
                        position:'absolute',
                        zIndex:99,
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.5)',
                        borderRadius: '5px 5px 5px 5px'
                    }}>
                        {promotag && 
                        <Typography 
                        sx={{
                            backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                            color:theme.palette.mode==='dark'?'#000':'#fff',
                            fontWeight:'bold',
                            fontSize:'15px',
                            borderRadius:'5px',
                            padding:'6px',
                            margin:'0px 0px 0px 10px',
                            boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.5)`
                        }}>
                            {promotag}
                        </Typography>}

                        <div
                        style={{
                            width:'50%',
                            justifyContent:'space-around',
                            display:'flex',
                            alignItems:'center'
                        }}
                        >
                            {/* <RWebShare
                            data={{
                                text: `Veja ${filtered[0]?.nome} que eu encontrei em ${preferences.nome} por ${filtered[0]?.vlrvenda?.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}`,
                                url: `https://${storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                                title: `${filtered[0]?.nome} em ${preferences.nome}`,
                            }}
                            // onClick={() => console.log("shared successfully!")}
                            >
                                
                            </RWebShare> */}
                            <IconButton 
                            onClick={()  => {
                                if ( !navigator.share ) return toast.error('Desculpa, seu navegador não suporta')
                                navigator.share({
                                    text: `Veja ${filtered[0]?.nome} que eu encontrei em ${preferences.nome} por ${filtered[0]?.vlrvenda?.toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}`,
                                    url: `https://${preferences.usa_pagina_centralizada ? preferences.codigo_pagina_centralizada : storecode}.allon.store/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}${preferences.usa_pagina_centralizada ? '?store_code='+storecode: ''}`,
                                    title: `${filtered[0]?.nome} em ${preferences.nome}`,
                                })
                                .then(()=>{})
                                .catch(()=>{})
                            }}
                            sx={{ color: color.primary }}>
                                <ShareIcon />
                            </IconButton>

                            <IconButton
                            onClick={() => {
                                navigate(`/product/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`)
                                setAnchorEl(null)
                                setAnchorEl2(null)
                            }}
                            >
                                <OpenInNewIcon sx={{ color: color.primary }} />
                            </IconButton>

                            <Button
                            color='error'
                            endIcon={<HighlightOffIcon />}
                            size='small'
                            variant='contained'
                            sx={{height:'25px',marginRight:'15px',marginLeft:'10px'}}
                            onClick={() => {
                                setAnchorEl2(null)
                                setBlur(false)
                            }}
                            >
                                fechar
                            </Button>
                            {/* <IconButton 
                            onClick={() => {
                                setAnchorEl2(null)
                                setBlur(false)
                            }}
                            color='error'>
                                <HighlightOffIcon />
                            </IconButton> */}

                        </div>
                    </div>
                    <div
                    style={{
                        maxWidth: size.width <= 500 ? 'calc(95vw - 9px)' : '380px',
                        width:'100%',
                        backgroundColor:'#fff',
                        borderRadius: '5px 5px 0px 0px'
                    }}>
                    <ImageGallery
                    style={{
                        width: size.width >= 500 ? 'fit-content' : '300px'
                    }}
                    items={images.length ? [
                        ...(images.filter(e => {
                            const check = filtered.find(i => (
                                (e.idvariacaoproduto === i.idvariacaoproduto && i.idvariacaoproduto !== null)
                                || (e.idproduto === i.idproduto && i.idproduto !== null)
                            ))

                            if (check) return e
                        }))?.map(e => ({
                            original: `data:${e.type};base64,${e.file}`,
                            thumbnail: `data:${e.type};base64,${e.file}`
                        }))] : [
                            {
                                original: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`,
                                thumbnail: `https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`
                            }
                        ]} 
                    />
                    </div>
                    {!filtered[0]?.ignoraecommerce &&
                    <Typography
                    sx={{
                        fontSize:'12px',
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        opacity:0.8,
                        textAlign:'center'
                    }}>{(filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))} {filtered[0]?.unidmedida?.toUpperCase()} no estoque</Typography>}
                </div>

                <div
                style={{
                    padding:'10px'
                }}
                >
                <Typography
                sx={{
                    //color:color.fontSecondary,
                    color:'text.primary',
                    fontFamily:'Quicksand',
                    textAlign:'center',
                    fontSize:'16px',
                    margin:'5px'
                }}>{filtered[0]?.nome}</Typography>

                {/* <Typography sx={{
                    margin:'10px',
                    color: color.fontSecondary
                }}>{props.descricao}</Typography> */}
                
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-around',
                    width:'100%',
                    alignItems:'center'
                }}>
                    {promotag &&
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        textAlign:"center",
                        fontSize:'17px',
                        fontWeight:"bold",
                        textDecoration:'line-through',
                        opacity:0.7
                    }}>
                        {(parseFloat(filtered[0]?.valor)).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>}
                    <Typography 
                    sx={{
                        fontSize:'20px',
                        margin:'10px',
                        //color:color.fontSecondary,
                        color:'text.primary',
                    }}>
                        {vrvenda?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                </div>

                <div
                style={{
                    display:'flex',
                    flexWrap:'wrap',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    borderRadius:'10px',
                    width:'calc(100% - 10px)',
                    marginBottom:'10px'
                }}
                >
                    {Object.entries(caracteristicas)?.map(e => (
                        <div 
                        style={{
                            // width:'200px',
                            // marginBottom:'10px'
                            margin:'10px'
                        }}>
                            <Typography 
                            sx={{
                                margin:'0px 0px 5px 10px',
                                fontWeight:'bold',
                                //color:color.fontSecondary,
                                color:'text.primary',
                                fontSize:'12px'
                            }}>{e[0]}</Typography>
                            <div 
                            style={{
                                display:'flex',
                                flexWrap:'wrap',
                                justifyContent:'center'
                            }}>
                                {e[1]?.map(j => (
                                    <ButtonBase
                                    disabled={!caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo))}
                                    sx={{
                                        opacity: !caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo)) ? 0.35 : 1
                                    }}
                                    onClick={() => setFilter({
                                        ...filter,
                                        [e[0]] : filter[e[0]] && filter[e[0]].atributo === j.atributo ? undefined : j
                                    })}>
                                        {filter[e[0]]?.atributo === j.atributo &&
                                        <div
                                        style={{
                                            position:'absolute',
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'right',
                                            height:'100%'
                                        }}>
                                            <CheckCircleIcon sx={{
                                                fontSize:'15px',
                                                color: color.fontSecondary
                                            }} />
                                        </div>}
                                        <Typography sx={{
                                            width:'fit-content',
                                            padding:'5px',
                                            borderRadius:'2.5px',
                                            margin:'5px',
                                            // color:filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor,
                                            color: color.fontSecondary,
                                            //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                            fontSize:'12px',
                                            //color:color.fontSecondary,
                                            border: `1px solid ${filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor}`,
                                            // backgroundColor:filter[e[0]]?.atributo === j.atributo ? j.cor : ''
                                            backgroundColor: j.cor
                                        }}>
                                            {(j.atributo)?.toUpperCase()}
                                        </Typography>
                                    </ButtonBase>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                
                {filtered[0]?.variavel &&
                <div>
                    <Typography sx={{
                        color:'text.secondary',
                        fontSize:'14px',
                        fontWeight:'bold'
                    }}>Adicionado</Typography>
                    <div
                    style={{
                        display:'flex',
                        // flexDirection:'column',
                        marginBottom:'15px'
                    }}
                    >
                        <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize:'15px',
                            width:'50%'
                        }}
                        >Quantidade: {quantidade * (filtered[0]?.incrementovariavel || 1)} {filtered[0]?.unidmedida?.toUpperCase()}</Typography>

                        <div
                        style={{
                            width:'1px',
                            height:'20px',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            borderRadius:'10px'
                        }}
                        ></div>


                        <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize:'15px',
                            fontWeight:'bold',
                            width:'calc(50% - 10px)',
                            textAlign:'right'
                        }}
                        >Total: {(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                    </div>
                    {filtered[0]?.incremento_minimo &&
                    <Typography sx={{
                        color:'text.secondary',
                        fontSize:'14px',
                        fontWeight:'bold',
                        marginBottom:'10px'
                    }}>Mínimo de {`${filtered[0]?.incremento_minimo} ${filtered[0]?.unidmedida}`}</Typography>}
                </div>
                }

                <div
                style={{
                    display:'flex',
                    // justifyContent:'space-between',
                    alignItems:'center'
                }}
                >
                    <div
                    style={{
                        width:'100%'
                    }}
                    >
                        {quantidade ? 
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'center',
                            textAlign:'center'
                        }}>
                            <IconButton
                            disabled={disable()}
                            sx={{
                                opacity: disable() ? 0.5 : 1
                            }}
                            onClick={() => {
                                if (quantidade === 0) {
                                    const ad = addToCart(filtered[0],cart,-1)
                                    if (ad[1]) {
                                        const f = filtered
                                        f[0].adicionado = undefined
                                        setFiltered(f)
                                        setCart(ad[0])
                                        forceUpdate()
                                        sendAction({
                                            unique_id: analytics.unique_id,
                                            action_id: 'cart',
                                            detail_action_id: 'removeFromCart',
                                            complete_detail: f[0]?.nome,
                                            idproduto: f[0]?.idproduto,
                                            idvariacaoproduto: f[0]?.idvariacaoproduto,
                                            idprodutocategoria: null,
                                            nivelcategoria: null,
                                            token: user.token,
                                            lat: geolocation?.coords?.latitude,
                                            lng: geolocation?.coords?.longitude
                                        },
                                        (c) => (cancel = c),
                                        cancel)
                                    }
                                } else {
                                    setQuantidade(quantidade===0 ? 0 : quantidade - 1)
                                }
                            }}>
                                {quantidade === 0 && filtered[0]?.adicionado !== undefined ? <DeleteIcon sx={{color: color.fontSecondary}} /> : <RemoveIcon sx={{color: color.fontSecondary}} />}
                            </IconButton>

                            <TextField
                            value={quantidade}
                            disabled={disable()}
                            onChange={({target}) => {
                                const quant = integer(target.value)
                                if (filtered[0] && !filtered[0]?.ignoraecommerce && quant > (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))) return setQuantidade((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)))
                                setQuantidade(quant)
                            }}
                            sx={{
                                width:'100px',
                                textAlign:'center',
                                //color:color.fontSecondary,
                                color:'text.primary',
                            }}
                            size='small'
                            inputProps={{min: 0, 
                                style: { 
                                    textAlign: 'center', 
                                    //color:color.fontSecondary,
                                    color:'text.primary',
                                }}}
                            />
                            
                            <IconButton
                            disabled={filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()}
                            sx={{
                                opacity: (filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()) ? 0.5 : 1
                            }}
                            onClick={() => setQuantidade(quantidade+1)}>
                                <AddIcon sx={{color: color.fontSecondary}} />
                            </IconButton>
                        </div> : 
                        <Button
                        onClick={() => setQuantidade(quantidade+1)}
                        startIcon={
                            <AddShoppingCartIcon />
                        }
                        variant='contained'
                        size=''
                        sx={{
                            backgroundColor: color.fontPrimary,
                            width: 'calc(100% - 10px)'
                        }}
                        disabled={disable()}
                        >
                            adicionar ao carrinho
                        </Button>}

                        <div>
                            {filtered[0]?.variavel ? <Typography
                            sx={{
                                fontSize:'12px',
                                //color:color.fontSecondary,
                                color:'text.secondary',
                                opacity:0.8,
                                textAlign:'center'
                            }}>1 equivale a {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida}`}</Typography> : 
                            <Typography
                            sx={{
                                color: 'text.primary',
                                fontSize:'14px',
                                fontWeight:'bold',
                                textAlign:'center'
                            }}
                            >{(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>}
                        </div>
                    </div>

                </div>
                
                {/* <Button 
                onClick={() => {
                    setAnchorEl2(null)
                    setBlur(false)
                }}
                // disabled={disable()}
                size='large' 
                sx={{
                    //color:color.primary,
                    color:'text.primary',
                    fontWeight:"bold",
                    fontSize:'12px'
                }}
                fullWidth>fechar</Button> */}
                </div>
            </div>
        </Popover>

        <Popover
        open={anchorEl!==null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        sx={{
            '.MuiPaper-root': {
                backgroundColor:color.secondary
            }
        }}
        >
            <div
            style={{
                backgroundColor:color.secondary,
                height:'100%',
                padding:'10px'
            }}>
                <div>
                    {Object.entries(caracteristicas)?.map(e => (
                        <div 
                        style={{
                            width:'200px',
                            marginBottom:'10px'
                        }}>
                            <Typography 
                            sx={{
                                margin:'0px 0px 5px 10px',
                                fontWeight:'bold',
                                //color:color.fontSecondary,
                                color:'text.primary',
                                fontSize:'12px'
                            }}>{e[0]}</Typography>
                            <div 
                            style={{
                                display:'flex',
                                flexWrap:'wrap',
                                justifyContent:'center'
                            }}>
                                {e[1]?.map(j => (
                                    <ButtonBase
                                    disabled={!caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo))}
                                    sx={{
                                        opacity: !caracteristicas_filtradas[e[0]] || !caracteristicas_filtradas[e[0]].find(i => (i.atributo===j.atributo)) ? 0.35 : 1
                                    }}
                                    onClick={() => setFilter({
                                        ...filter,
                                        [e[0]] : filter[e[0]] && filter[e[0]].atributo === j.atributo ? undefined : j
                                    })}>
                                        {filter[e[0]]?.atributo === j.atributo &&
                                        <div
                                        style={{
                                            position:'absolute',
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'right',
                                            height:'100%'
                                        }}>
                                            <CheckCircleIcon sx={{
                                                fontSize:'15px',
                                                color: color.fontSecondary
                                            }} />
                                        </div>}
                                        <Typography sx={{
                                            width:'fit-content',
                                            padding:'5px',
                                            borderRadius:'2.5px',
                                            margin:'5px',
                                            // color:filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor,
                                            color: color.fontSecondary,
                                            //border: `1px solid ${j.color ? j.color : (theme.palette.mode==='dark'?'#fff':'#000')}`,
                                            fontSize:'12px',
                                            //color:color.fontSecondary,
                                            border: `1px solid ${filter[e[0]]?.atributo === j.atributo || !j.cor ? color.fontSecondary : j.cor}`,
                                            // backgroundColor:filter[e[0]]?.atributo === j.atributo ? j.cor : ''
                                            backgroundColor: j.cor
                                        }}>
                                            {(j.atributo)?.toUpperCase()}
                                        </Typography>
                                    </ButtonBase>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                
                {filtered[0]?.variavel &&
                <div>
                    <Typography sx={{
                        color:'text.secondary',
                        fontSize:'14px',
                        fontWeight:'bold'
                    }}>Adicionado</Typography>
                    <div
                    style={{
                        display:'flex',
                        // justifyContent:'space-around',
                        flexDirection:'column',
                        marginBottom:'15px'
                    }}
                    >
                        <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize:'13px',
                            marginTop:'10px'
                        }}
                        >Quantidade: {quantidade * (filtered[0]?.incrementovariavel || 1)} {filtered[0]?.unidmedida?.toUpperCase()}</Typography>
                        <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize:'13px',
                            fontWeight:'bold',
                            marginTop:'5px'
                        }}
                        >Valor: {(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>
                    </div>
                    {filtered[0]?.incremento_minimo &&
                    <Typography sx={{
                        color:'text.secondary',
                        fontSize:'14px',
                        fontWeight:'bold',
                        marginBottom:'10px'
                    }}>Mínimo de {`${filtered[0]?.incremento_minimo} ${filtered[0]?.unidmedida}`}</Typography>}
                </div>
                }

                <div
                style={{
                    display:'flex',
                    justifyContent:'center',
                    textAlign:'center'
                }}>
                    <IconButton
                    disabled={disable()}
                    sx={{
                        opacity: disable() ? 0.5 : 1
                    }}
                    onClick={() => {
                        if (quantidade === 0) {
                            const ad = addToCart(filtered[0],cart,-1)
                            if (ad[1]) {
                                const f = filtered
                                f[0].adicionado = undefined
                                setFiltered(f)
                                setCart(ad[0])
                                forceUpdate()
                                sendAction({
                                    unique_id: analytics.unique_id,
                                    action_id: 'cart',
                                    detail_action_id: 'removeFromCart',
                                    complete_detail: f[0]?.nome,
                                    idproduto: f[0]?.idproduto,
                                    idvariacaoproduto: f[0]?.idvariacaoproduto,
                                    idprodutocategoria: null,
                                    nivelcategoria: null,
                                    token: user.token,
                                    lat: geolocation?.coords?.latitude,
                                    lng: geolocation?.coords?.longitude
                                },
                                (c) => (cancel = c),
                                cancel)
                            }
                        } else {
                            setQuantidade(quantidade===0 ? 0 : quantidade - 1)
                        }
                    }}>
                        {quantidade === 0 && filtered[0]?.adicionado !== undefined ? <DeleteIcon sx={{color: color.fontSecondary}} /> : <RemoveIcon sx={{color: color.fontSecondary}} />}
                    </IconButton>

                    <TextField
                    value={quantidade}
                    disabled={disable()}
                    onChange={({target}) => {
                        const quant = integer(target.value)
                        if (filtered[0] && !filtered[0]?.ignoraecommerce && quant > (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))) return setQuantidade((filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)))
                        setQuantidade(quant)
                    }}
                    sx={{
                        width:'100px',
                        textAlign:'center',
                        //color:color.fontSecondary,
                        color:'text.primary',
                    }}
                    size='small'
                    inputProps={{min: 0, 
                        style: { 
                            textAlign: 'center', 
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}}
                    />
                    
                    <IconButton
                    disabled={filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()}
                    sx={{
                        opacity: (filtered[0] && !filtered[0]?.ignoraecommerce && quantidade >= (filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0)) ? true : disable()) ? 0.5 : 1
                    }}
                    onClick={() => setQuantidade(quantidade+1)}>
                        <AddIcon sx={{color: color.fontSecondary}} />
                    </IconButton>
                </div>

                <div>
                    {filtered[0]?.variavel ? <Typography
                    sx={{
                        fontSize:'12px',
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        opacity:0.8,
                        textAlign:'center'
                    }}>1 equivale a {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida}`}</Typography> : 
                    <Typography
                    sx={{
                        color: 'text.primary',
                        fontSize:'14px',
                        fontWeight:'bold',
                        textAlign:'center'
                    }}
                    >{(quantidade * vrvenda).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography>}
                </div>
                
                <Button 
                onClick={() => setAnchorEl(null)}
                // disabled={disable()}
                size='large' 
                sx={{
                    //color:color.primary,
                    color:'text.primary',
                    fontWeight:"bold",
                    fontSize:'12px'
                }}
                fullWidth>fechar</Button>
            </div>
        </Popover>
        
        {state.loading || !filtered[0] ?
        <div 
        style={{
            width: size.width > 950 ? '300px' : '180px',
            height:size.width > 950 ? '320px' : '320px',
            margin:'5px',
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            borderRadius:'5px 5px 5px 5px',
            justifyContent:'space-between',
            boxShadow: `0px 0px 5px 1px rgba(0,0,0,0.3)`
        }}
        >
            <Skeleton 
            variant="rectangular" 
            width="100%" 
            height="180px"
            sx={{
                borderRadius:'5px 5px 5px 5px'
            }}
            />
            <Skeleton width='100%' />
            {size.width > 950 ? 
            <div
            style={{
                width:'100%',
                display:'flex',
                flexDirection:'column'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-around'
                }}>
                    <Skeleton width={'70%'} />
                </div>
                
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <Skeleton width={'60%'} />
                </div>
            </div> :
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'space-between'
            }}
            >
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:"space-around",
                    flexDirection:'column',
                    margin:'0px 10px',
                    marginBottom:'5px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        flexDirection:"column"
                    }}>
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <Skeleton />
                </div>
                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    height:'100%'
                }}>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
            </div>}
            
            {size.width > 950 && 
                <div
                sytle={{
                    width:'100%',
                    display:'center',
                    alignItems:'center',
                    justifyContent:'center'
                }}>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
            }
        </div>
        :
        <div 
        // {...bind()}
        onClick={(event) => {
            if (['path','button','svg'].indexOf(event.target.localName) !== -1) return
            setAnchorEl2(event.currentTarget)

            if ( imagesLoad ) return 

            axios
            .get(`https://${storecode}.allon.cloud/image/${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`)
            .then(response => {
                setImages(response.data.reduce((a, next) => {
                    return a = [...a, ...next.images?.map(e => ({...e, ...next, images:undefined}))]
                },[]))
                setImagesLoad(true)
                setAnchorEl(null)
                setBlur(true)
            })

            sendAction({
                unique_id: analytics.unique_id,
                action_id: 'product',
                detail_action_id: 'click',
                complete_detail: `${filtered[0]?.nome}`,
                idproduto: filtered[0]?.idproduto,
                idvariacaoproduto: filtered[0]?.idvariacaoproduto,
                idprodutocategoria: null,
                nivelcategoria: null,
                token: user.token,
                lat: geolocation?.coords?.latitude,
                lng: geolocation?.coords?.longitude
            },
            (c) => (cancel = c),
            cancel)
        }}
        style={{
            width:size.width > 950 ? '300px' : '180px',
            height:size.width > 950 ? '320px' : '320px',
            minHeight:size.width > 950 ? '320px' : '320px',
            background: color.secondary,
            color:'#000',
            margin:'2.5px',
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            borderRadius:'5px 5px 5px 5px',
            justifyContent:'space-between',
            boxShadow: `0px 0px 5px 1px rgba(0,0,0,0.3)`
        }}>
            <div 
            style={{
                width:'100%'
            }}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    position:'absolute'
                }}>
                    {promotag &&
                    <Typography 
                    sx={{
                        backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                        color:theme.palette.mode==='dark'?'#000':'#fff',
                        fontWeight:'bold',
                        fontSize:'12px',
                        borderRadius:'5px',
                        padding:'6px',
                        margin:'0px 0px 0px 10px',
                        boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.5)`
                    }}>
                        {promotag}
                    </Typography>}
                </div>
                <Image 
                className='product_card'
                onLoad={(e) => setImageLoaded(true)}
                style={{
                    objectFit:'cover',
                    width:'100%',
                    height:'180px',
                    top:0,
                    left:0,
                    borderRadius:'5px 5px 0px 0px',
                    backgroundColor:'#fff',
                    opacity: imageLoaded ? 1 : 0,
                    transition: 'opacity 0.1s linear'
                }} 
                alt={filtered[0]?.nome+', '+filtered[0]?.ean}
                src={`https://${storecode}.allon.cloud/image/main/${filtered[0]?.ean}?internal=${filtered[0]?.imageminterna ? 'true' : 'false'}&idproduto=${filtered[0]?.idproduto || filtered[0]?.idprodutopai}`}>

                    
                </Image>
                </div>
            <div
            style={{
                display:'flex',
                alignItems:'center',
                flexDirection:'column'
            }}>
                {!filtered[0]?.ignoraecommerce &&
                <Typography
                sx={{
                    fontSize:'10px',
                    //color:color.fontSecondary,
                    color:'text.secondary',
                    opacity:0.8,
                    textAlign:'center'
                }}>{(filtered[0]?.quantidade - (filtered[0]?.qtdminima || 0))} {filtered[0]?.unidmedida?.toUpperCase()} no estoque</Typography>}
                <Typography 
                sx={{
                    //color:color.fontSecondary,
                    color:'text.primary',
                    fontFamily:'Quicksand',
                    textAlign:'center',
                    fontSize:'15px',
                    textOverflow:'ellipsis',
                    height:'fit-content',
                    margin:'2px',
                    maxHeight: size.width <= 950 ? '70px' : '40px',
                    overflow:'hidden'
                }}>{filtered[0]?.nome}</Typography>
            </div>
            
            {size.width > 950 ? 
            <div
            style={{
                width:'100%',
                display:'flex',
                flexDirection:'column'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-around'
                }}>
                    {promotag ? 
                    <>
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'17px',
                        fontWeight:"bold",
                        textDecoration:'line-through',
                        opacity:0.7
                    }}>
                        {(parseFloat(filtered[0]?.valor)).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'20px',
                        fontWeight:"bold"
                    }}>
                        {vrvenda.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    </> :
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'20px',
                        fontWeight:"bold"
                    }}>
                        {vrvenda.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                    </Typography>
                    }
                </div>
                {filtered[0]?.variavel &&
                <Typography sx={{
                    //color:color.fontSecondary,
                    color:'text.secondary',
                    fontSize:'11px',
                    textAlign:'center'
                }}>
                    Valor referente a {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida?.toUpperCase()}`}
                </Typography>}
                
                <div>
                    {parcela > 1 &&
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.primary',
                        textAlign:"center",
                        fontSize:'15px',
                        opacity:0.7
                    }}>
                        {parcela}x de {(dividir/parcela).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}
                    </Typography>}
                </div>
            </div> :
            <div
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'space-between'
            }}
            >
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:"space-around",
                    flexDirection:'column',
                    marginLeft:'10px',
                    marginBottom:'5px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        flexDirection:"column"
                    }}>
                        
                        {filtered[0]?.variavel &&
                        <Typography sx={{
                            //color:color.fontSecondary,
                            color:'text.secondary',
                            fontSize:'11px'
                        }}>
                            {`${filtered[0]?.incrementovariavel} ${filtered[0]?.unidmedida?.toUpperCase()}`}
                        </Typography>}
                        <Typography sx={{
                            //color:color.fontSecondary,
                            color:'text.primary',
                        }}>
                            {vrvenda.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>
                        {parseInt(parseFloat(filtered[0]?.valor)) > parseFloat(vrvenda) && 
                        <Typography sx={{
                            //color:color.fontSecondary,
                            color:'text.secondary',
                            fontSize:'12px',
                            textDecoration:'line-through',
                            opacity:0.7
                        }}>
                            {parseFloat(filtered[0]?.valor)?.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}
                        </Typography>}
                    </div>
                    {parcela > 1 &&
                    <Typography sx={{
                        //color:color.fontSecondary,
                        color:'text.secondary',
                        fontSize:'13px',opacity:0.6
                    }}>
                        {parcela}x de {(dividir/parcela).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}
                    </Typography>}
                </div>
                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    height:'100%',
                    flexDirection:'column'
                }}>
                    <IconButton onClick={({currentTarget}) => setAnchorEl(currentTarget)} sx={{color:''}}>
                        <AddShoppingCartIcon sx={{color: color.fontSecondary}} />
                    </IconButton>
                    {filtered?.find(({adicionado}) => adicionado !== undefined) && <div
                    style={{
                        position:'relative',
                        top:'-5px',
                        right:'1px'
                    }}
                    onClick={({currentTarget}) => setAnchorEl(currentTarget)}
                    >
                        <div
                        style={{
                            backgroundColor: color.fontSecondary,
                            padding:'1px 5px',
                            borderRadius:'2px',
                            // position:'absolute',
                            // top:'31px',
                            width:'max-content'
                        }}
                        >
                            <Typography sx={{
                                color:'text.primary',
                                fontSize:'10px'
                            }}>{filtered[0]?.variavel ? `${Math.round(quantidade*filtered[0]?.incrementovariavel)} ${filtered[0]?.unidmedida}` : `${filtered.filter(({adicionado}) => adicionado).reduce((a,n) => (a += n.adicionado),0)} UN`}</Typography>
                        </div>
                    </div>}
                </div>
            </div>}
            
            {size.width > 950 && 
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center'
                }}>
                    <IconButton onClick={({currentTarget}) => setAnchorEl(currentTarget)} sx={{color:''}}>
                        <AddShoppingCartIcon sx={{color: color.fontSecondary}} />
                    </IconButton>
                    {filtered?.find(({adicionado}) => adicionado !== undefined) && <div
                    style={{
                        // position:'relative',
                        // top:'-5px',
                        // right:'1px'
                    }}
                    onClick={({currentTarget}) => setAnchorEl(currentTarget)}
                    >
                        <div
                        style={{
                            backgroundColor: color.fontSecondary,
                            padding:'1px 5px',
                            borderRadius:'2px',
                            // position:'absolute',
                            // top:'31px',
                            width:'max-content'
                        }}
                        >
                            <Typography sx={{
                                color:'text.primary',
                                fontSize:'10px'
                            }}>{filtered[0]?.variavel ? `${Math.round(quantidade*filtered[0]?.incrementovariavel)} ${filtered[0]?.unidmedida}` : `${filtered.filter(({adicionado}) => adicionado).reduce((a,n) => (a += n.adicionado),0)} UN`}</Typography>
                        </div>
                    </div>}
                </div>
            }
        </div>}
        </>
    )
}