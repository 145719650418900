import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Button, 
    TextField, 
    Typography
} from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import InputMask from 'react-input-mask'
import getColors from "../getColor";
import { toast } from "react-toastify";
import { PreferencesContext, UserContext } from "../../context";
import { useTheme } from '@mui/material/styles'
import { MercadoPagoSettingsContext } from "./context";
import Card from "./card";
import { validarCartao } from "./validacao";
import SaveCard from "./saveCard";

export default function Debit_card(props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [paymentParams, setPaymentParams] = useState({})
    const [typeParams, setTypeParams] = useState({})
    const [state, setState] = useState({})
    const [card, setCard] = useState({ isValid: false })
    const [open, setOpen] = useState(false)
    const [connector, setConnector] = useState({})    

    const mercadoPagoSettings = useContext(MercadoPagoSettingsContext)
    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => setOpen(props.opened), [props.opened])

    useEffect(() => {
        setTypeParams(props.typeParams)
        setPaymentParams(props.paymentParams)

        if (props.typeParams && props.paymentParams) init(props.typeParams, props.paymentParams)
    }, [
        props.typeParams,
        props.paymentParams
    ])

    const init = async (  ) => {
        if ( typeParams.conector === 'cielo' ) return
        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()

        mercadoPagoSettings.customization.paymentMethods = {
            [typeParams.to_conector]: 'all'
        }

        mercadoPagoSettings.callbacks.onSubmit = ({ selectedPaymentMethod, formData }) => {
                            
            return new Promise((resolve, reject) => {
                if ( paymentParams.enableSaveCard ) {
                    saveCardRef.current?.ask((response) => {
                        paymentParams.onPay({
                            selectedPaymentMethod,
                            formData: {
                                ...formData, 
                                payment_type_id: selectedPaymentMethod,
                                idformapagto: typeParams.idformapagto,
                                idconectorpagamento: typeParams.idconectorpagamento
                            },
                            paymentParams,
                            save_payment: response
                        },() => {
                            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                            resolve()
                        },() => {
                            reject()
                        })
                    })
                } else {
                    if (typeof paymentParams.onPay === 'function') 
                    paymentParams.onPay({
                        selectedPaymentMethod,
                        formData: {
                            ...formData, 
                            payment_type_id: selectedPaymentMethod,
                            idformapagto: typeParams.idformapagto,
                            idconectorpagamento: typeParams.idconectorpagamento
                        },
                        paymentParams
                    },() => {
                        resolve();
                        if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
                    },() => {
                        reject()
                    })
                }
            })
        }

        mercadoPagoSettings.callbacks.onError = (error) => {
            if ( typeof paymentParams.onError === 'function' ) paymentParams.onError(error)
            if ( connector.paymentBrickController ) connector.paymentBrickController.unmount()
        }

        if ( typeParams.conector === 'mercado_pago' ) {

            const key = paymentParams.conector.mercado_pago.key.public_key
            const mercado_pago = new window.MercadoPago(key)
            const bricksBuilder = mercado_pago.bricks()

            const paymentBrickController = await bricksBuilder.create(
                'payment',
                'paymentBrick_debit_card_container',
                mercadoPagoSettings
            );

            setConnector({ mercado_pago, bricksBuilder, paymentBrickController })
        }
    }
    
    const handleInputFocus = (evt) => setCard({ ...card, focus: evt.target.name })
    const cardCallback = (parameter, isValid) => {
        if ( isValid === card.isValid ) return

        setCard({...parameter, isValid})
    }

    const accordionState = (state) => {
        if ( state ) {
            props.onOpen()
            init()
        } else {
            if ( typeParams.conector === 'mercado_pago') {
                if ( !connector.paymentBrickController ) return
                connector.paymentBrickController.unmount()
            }
            props.onClose()
        }
    }
    
    const pay = () => {
        if ( typeParams.conector === 'cielo' ) {
            const validate = validarCartao(state.number, state.name, state.expiry, state.cvc)
            if ( !validate ) return toast.error('Verifique todos os campos')
        }

        saveCardRef.current?.ask((response) => {
            paymentParams.onPay({
                selectedPaymentMethod: typeParams.payment_type_id,
                formData: {
                    // ...formData, 
                    payment_type_id: typeParams.payment_type_id,
                    idformapagto: typeParams.idformapagto,
                    idconectorpagamento: typeParams.idconectorpagamento
                },
                paymentParams,
                save_payment: response,
                card: state
            })
        })
    }

    const saveCardRef = useRef()
    
    return (
        <Accordion 
        expanded={open}
        onChange={(a,state) => accordionState(state)}
        key={typeParams.idformapagto}
        {...props.accordionProps}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            {...props.accordionSumaryProps}
            >
            <Typography>{typeParams.apelido}</Typography>
            </AccordionSummary>
            <AccordionDetails
            sx={{ p: 0 }}
            >
            <div>
                <SaveCard ref={saveCardRef} />
                {typeParams.conector === 'mercado_pago' && <div id="paymentBrick_debit_card_container" class='payment-brick' style={{fontFamily:'Quicksand'}}></div>}
                {typeParams.conector === 'cielo' &&
                <>
                    <Card
                    number={state.number}
                    expiry={state.expiry}
                    cvc={state.cvc}
                    name={state.name}
                    focus={card.focus}
                    callback={cardCallback}
                    />

                    <div
                    style={{
                        padding:'10px'
                    }}
                    >
                        <InputMask 
                        mask="9999 9999 9999 9999" 
                        maskChar={null}
                        value={state.number}
                        onChange={({target}) => setState({...state, number:target.value})}
                        size='small'
                        onFocus={handleInputFocus}
                        name='number'
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            inputProps={{
                                                id:'card_number'
                                            }}
                                            label='Número'
                                            onKeyDown={(event) => {
                                                // if (event.keyCode===13) sendCpf()
                                            }}
                                            />}
                        </InputMask>

                        <TextField 
                        mask="" 
                        maskChar={null}
                        value={state.name}
                        onChange={({target}) => setState({...state, name:target.value})}
                        size='small'
                        onFocus={handleInputFocus}
                        name='name'
                        inputProps={{
                            id:'card_name'
                        }}
                        label='Nome'
                        onKeyDown={(event) => {
                            // if (event.keyCode===13) sendCpf()
                        }}
                        fullWidth
                        sx={{
                            marginTop:'10px'
                        }}
                        />

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between',
                            marginTop:'10px'
                        }}
                        >
                            <InputMask 
                            mask="99/99" 
                            maskChar={null}
                            value={state.expiry}
                            onChange={({target}) => setState({...state, expiry:target.value})}
                            size='small'
                            onFocus={handleInputFocus}
                            name='expiry'
                            sx={{
                                width:'150px'
                            }}
                            >
                                {(inputProps) => <TextField 
                                                {...inputProps}
                                                inputProps={{
                                                    id:'card_expiry'
                                                }}
                                                label='Validade'
                                                onKeyDown={(event) => {
                                                    // if (event.keyCode===13) sendCpf()
                                                }}
                                                />}
                            </InputMask>

                            <InputMask 
                            mask="999" 
                            maskChar={null}
                            value={state.cvc}
                            onChange={({target}) => setState({...state, cvc:target.value})}
                            size='small'
                            onFocus={handleInputFocus}
                            name='cvc'
                            sx={{
                                width:'150px'
                            }}
                            >
                                {(inputProps) => <TextField 
                                                {...inputProps}
                                                inputProps={{
                                                    id:'card_cvc'
                                                }}
                                                label='CVC'
                                                onKeyDown={(event) => {
                                                    // if (event.keyCode===13) sendCpf()
                                                }}
                                                />}
                            </InputMask>
                        </div>
                    </div>

                    <div
                    style={{
                        padding:'10px'
                    }}
                    >
                        <Button
                        fullWidth
                        sx={{
                            bgcolor: color.fontPrimary,
                            color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                        }}
                        startIcon={<LockOutlinedIcon />}
                        onClick={pay}
                        >
                            Pagar
                        </Button>
                    </div>
                </>}
            </div>
            </AccordionDetails>
        </Accordion>
    )
}