import { Typography } from "@mui/material"
import { useTheme } from '@mui/material/styles'

export default function Index (props) {
    const theme = useTheme()

    return (
        <div>
            <img 
            style={{
                width:'230px',
                margin:'30px',
                ...props.style
            }}
            src={`https://allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`} alt='allon'></img>
            {/* <Typography 
            sx={{
                width:'100%',
                fontFamily:'Alumni Sans Pinstripe',
                fontSize:props.fontSize,//'140px'
                textAlign:'center',
                lineHeight:1.1,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
            }}>ALL
            <span 
            style={{
                fontWeight:'900'
            }}>ON</span>
            </Typography>
            {props.text&&
            <Typography
            sx={{
                position:'relative',
                //top:'-20px',
                opacity:0.5,
                fontSize:props.textSize,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
            }}
            >{props.text}</Typography>} */}
            
        </div>
    )
}