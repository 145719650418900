import { createContext } from 'react'

const ColorModeContext = createContext({ toggleColorMode: () => {} });
const SizeContext = createContext()
const PreferencesContext = createContext()
const BlurContext = createContext()
const FullScreenContext = createContext()
const UserContext = createContext()
const LoadingContext = createContext()
const CheckoutContext = createContext()
const LoadingCheckoutContext = createContext()
const LoginContext = createContext()
const ProductsContext = createContext()
const CartContext = createContext()
const EmailConfirmContext = createContext()
const ReplaceOrdeContext = createContext()
const FilterContext = createContext()
const SocketContext = createContext()
const StoreContext = createContext()
const KeyContext = createContext()
const CardContext = createContext()
const MenuContext = createContext()
const AnalyticsContext = createContext()

export {
    ColorModeContext,
    SizeContext,
    PreferencesContext,
    BlurContext,
    FullScreenContext,
    UserContext,
    LoadingContext,
    CheckoutContext,
    LoadingCheckoutContext,
    LoginContext,
    ProductsContext,
    CartContext,
    EmailConfirmContext,
    ReplaceOrdeContext,
    FilterContext,
    SocketContext,
    StoreContext,
    KeyContext,
    CardContext,
    MenuContext,
    AnalyticsContext
}