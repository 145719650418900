import vlrvenda from "./vlrvenda"

const addToCart = (product, cart, quantidade) => {
    if (!product) return [[],false]
    
    const c = cart
    const f = product

    if (f.variavel) quantidade = quantidade * (f.incrementovariavel || 1)
    quantidade = Math.round(quantidade)

    if (quantidade < 0) {
        if (f.adicionado === undefined)
        return [cart, false]
        else {
            c.splice(f.index, 1)
            return [c, true]
        }
    }
    
    if (f?.adicionado !== undefined && (f.separado && (f.contado === f.pedido || quantidade > f.contado))) return [cart, false]

    if (f?.adicionado !== undefined) {
        c[f.index] = {
            ...f.separado ? f : product,
            adicionado: quantidade,
            vlrvenda: vlrvenda(f)
        }
    } else {
        c.push({
            ...product,
            adicionado: quantidade,
            vlrvenda: vlrvenda(f)
        })
    }

    return [c?.map((e,index) => ({...e, index})) || [], true]
} 

export default addToCart