import React, { forwardRef, useContext, useEffect, useState } from "react";

import { 
    Button, 
    ButtonBase, 
    Modal, 
    Typography,
    TextField,
    useTheme
} from "@mui/material"

import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import SwipeableViews from 'react-swipeable-views';
import axios from "axios";
import { api } from "../../../../utils/defaults";

import useGeolocation from "../../../../utils/useGeolocation.ts";

import { toast } from "react-toastify";
import { LoadingContext, PreferencesContext, UserContext } from "../../../../context";
import getColors from "../../../getColor";
import find_address from "../../../../utils/address";
import InputMask from 'react-input-mask'

const Address = forwardRef((props, ref) => {
    const [user, setUser] = useContext(UserContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [state, setState] = useState({})
    const [address, setAddress] = useState([])
    const [currentLocation, setCurrentLocation] = useState(undefined)

    const geolocation = useGeolocation()
    const theme = useTheme()
    const color = getColors(preferences, theme)

    const coords = geolocation.coords

    const update = () => {
        const controller = new AbortController()

        setLoading(true)
        axios
        .get(`${api}/client/address`, {
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setAddress(response.data))
        .catch(err => setAddress([]))
        .finally(() => setLoading(false))

        return controller
    }

    useEffect(() => {
        const controller = update()

        return () => {
            controller.abort()
        }
    },[user.token])

    const getCurrentLocation = async () => {
        if ( state.current_position && state.address?.formatted_address ) return setPage(1)
        if ( currentLocation && currentLocation.origin_addresses.find(e => e) ) {
            setPage(1)
            return setState({
                ...state, 
                address: {...currentLocation, current_position: true, find:''},
                current_position: true,
                numero: '',
                referencia:'',
                complemento:'',
                favorite_address:'',
                idclienteendereco:undefined
            })
        }

        setLoading(true)

        try {
            let response = await axios.get(`${api}/preferences/distance?destinations=${coords?.latitude},${coords?.longitude}`)

            setPage(1)
            const formatted_address = response.data?.destination_addresses[0]
            let cep
            let complete_components

            if ( formatted_address ) {
                
                const regexCEP = /\b\d{5}-\d{3}\b/;
                const resultadoCEP = formatted_address.match(regexCEP);

                if (resultadoCEP) {
                    cep = resultadoCEP[0];

                    const { err, out } = await find_address(cep)
                    if ( out ) complete_components = out
                }
            }

            const obj = {
                ...state, 
                address: {
                    ...response.data, 
                    current_position: true, 
                    formatted_address,
                    find:'', 
                    complete_components, 
                    cep
                },
                current_position: true,
                numero: '',
                referencia:'',
                complemento:'',
                favorite_address:'',
                idclienteendereco:undefined
            }

            setState(obj)

            setCurrentLocation({ ...response.data, complete_components, cep, formatted_address })
        } catch (err) {
            toast.error(err?.response?.data)
        }
        setLoading(false)
    }

    const change = () => {
        if ( page === 1 ) return setPage(0)
        if ( page === 0 ) {
            if ( state.address?.formatted_address ) {
                if (typeof props.onChange === 'function') props.onChange(state)
            }

            setOpen(false)
        }
    }

    const findAddress = async () => {
        if (
            !state.endereco || !state.numero || 
            (state.address?.find === `${state.endereco}+${state.numero}`) ||
            state.current_position ||
            state.idclienteendereco
        ) return

        setLoading(true)

        try {
            let response = await axios.get(`${api}/preferences/distance?destinations=${state.endereco}, numero ${state.numero}`)

            setState({
                ...state, 
                address: {...response.data, current_position: false, find: `${state.endereco}+${state.numero}`},
                current_position: false
            })

            const formatted_address = response.data?.destination_addresses[0]
            let cep
            let complete_components

            if ( formatted_address ) {
                
                const regexCEP = /\b\d{5}-\d{3}\b/;
                const resultadoCEP = formatted_address.match(regexCEP);

                if (resultadoCEP) {
                    cep = resultadoCEP[0];

                    const { err, out } = await find_address(cep)
                    if ( out ) complete_components = out
                }
            }

            const obj = {
                ...state, 
                address: {
                    ...response.data, 
                    current_position: false, 
                    find:`${state.endereco}+${state.numero}`, 
                    complete_components, 
                    cep,
                    formatted_address
                },
                current_position: false
            }
            
            setState(obj)
    
        } catch (err) {
            toast.error(err?.response?.data)
        }

        setLoading(false)
    }

    const changeAddress = async (data) => {
        try {
            if (data.idclienteendereco === state.idclienteendereco) {
                // setOpen(false)
                // setPage(0)
                return 
            }

            setLoading(true)
            let response = await axios.get(`${api}/preferences/distance?destinations=${data?.formatted_address}`)

            const formatted_address = response.data?.destination_addresses[0]
            let cep
            let complete_components

            if ( formatted_address ) {
                
                const regexCEP = /\b\d{5}-\d{3}\b/;
                const resultadoCEP = formatted_address.match(regexCEP);

                if (resultadoCEP) {
                    cep = resultadoCEP[0];

                    const { err, out } = await find_address(cep)
                    if ( out ) complete_components = out
                }
            }

            const obj = {
                ...state, 
                address: {
                    ...response.data, 
                    current_position: false, 
                    find:'', 
                    complete_components, 
                    formatted_address,
                    cep
                },
                current_position: false,
                numero: '',
                referencia:'',
                complemento:'',
                favorite_address:'',
                idclienteendereco:data.idclienteendereco
            }

            setState(obj)
            setOpen(false)
            setPage(0)
            props.onChange(obj)
        } catch (err) {
            toast.error(err?.response?.data)
        }

        setLoading(false)
    }

    React.useImperativeHandle(ref, () => ({ 
        open: () => setOpen(true),
        close: () => setOpen(false)
        // init: init,
        // unmount: unmount
    }))

    return (
        <Modal
        open={open}
        // onClick={() => setOpenAddress(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div
            style={{
                boxShadow:'black 0px 0px 9px 0px',
                borderRadius:'10px',
                overflowX:'hidden'
            }}>
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'space-between',
                    backgroundColor:color.background,
                    borderRadius:'10px 10px 0px 0px'
                }}>
                    {page === 0 ?
                    <Button 
                    color='error' 
                    startIcon={<ArrowBackIosNewOutlinedIcon color='error'/>} 
                    onClick={change}>
                        concluído
                    </Button> : 
                    <Button 
                    color='error' 
                    startIcon={<ArrowBackIosNewOutlinedIcon color='error'/>} 
                    onClick={() => setPage(0)}>
                        voltar
                    </Button>}
                    
                    {page === 1 &&
                    <Button
                    color='error'
                    disabled={!state.address?.formatted_address}
                    onClick={() => {
                        if ( state.address?.formatted_address ) {
                            if (typeof props.onChange === 'function') props.onChange(state)
                            setOpen(false)
                        }
                    }}
                    >concluído</Button>}
                </div>

                <SwipeableViews 
                index={page}
                onChangeIndex={(index) => setPage(index)}
                style={{
                    // borderRadius:'10px',
                    // width:'380px',
                    overflowX:'hidden',
                    maxWidth:'500px'
                }}
                // enableMouseEvents={openAddress === 1} 
                enableMouseEvents
                resistance>
                    <div
                    style={{
                        backgroundColor:color.background,
                        height:'510px',
                        borderRadius:'0px 0px 10px 10px',
                        maxHeight:'510px',
                        overflowY:'auto'
                    }}>
                        <div
                        style={{
                            // width:'100%',
                            display:'flex',
                            justifyContent:'space-between',
                            marginTop:'10px',
                            margin:'0px 10px'
                        }}>
                            <ButtonBase
                            onClick={getCurrentLocation}
                            disabled={!geolocation.isAvailable || !geolocation.isEnabled}
                            >
                                <div
                                style={{
                                    border:`0.5px solid ${color.fontPrimary}`,
                                    borderRadius:'5px',
                                    padding:'8px',
                                    display:'flex',
                                    alignItems:'center',
                                    backgroundColor: state.current_position && color.fontPrimary,
                                    opacity: ( !geolocation.isAvailable || !geolocation.isEnabled ) ? 0.3 : 1
                                }}>
                                    <GpsFixedOutlinedIcon 
                                    sx={{
                                        color:state.current_position ? color.background : color.fontPrimary,
                                        marginRight:'10px'
                                    }} />
                                    <Typography 
                                    sx={{
                                        color:state.current_position ? color.background : color.fontPrimary,
                                        fontSize:'13px'
                                    }}>Usar Localização</Typography>
                                </div>
                            </ButtonBase>

                            <ButtonBase
                            onClick={() => {
                                if (state.current_position) {
                                    setState({
                                        ...state, 
                                        current_position: false,
                                        numero: '',
                                        referencia:'',
                                        complemento:'',
                                        favorite_address:'',
                                        idclienteendereco:undefined
                                    })
                                    return setPage(1)
                                }
                                setState({ ...state, current_position: false, idclienteendereco:undefined })
                                setPage(1)
                            }}>
                                <div
                                style={{
                                    border:`0.5px solid ${color.fontPrimary}`,
                                    borderRadius:'5px',
                                    padding:'8px',
                                    display:'flex',
                                    alignItems:'center'
                                }}>
                                    <EditLocationOutlinedIcon 
                                    sx={{
                                        color:color.fontPrimary,
                                        marginRight:'10px'
                                    }} />
                                    <Typography
                                    sx={{
                                        color:color.fontPrimary,
                                        fontSize:'13px'
                                    }}>Inserir Endereço</Typography>
                                </div>
                            </ButtonBase>
                        </div>

                        {state.address?.formatted_address && !state.idclienteendereco &&
                        <ButtonBase 
                        onClick={() => {
                            if (state.address?.formatted_address && typeof props.onChange === 'function') props.onChange(state)
                            setPage(0)
                            setOpen(false)
                        }}
                        sx={{textAlign:'left'}}>
                            <div
                            style={{
                                display:'flex',
                                alignItems:'center',
                                margin:'20px 10px'
                            }}>
                                <div
                                style={{
                                    marginRight:'10px'
                                }}>
                                    <FmdGoodOutlinedIcon sx={{color: color.fontPrimary}} />
                                </div>
                                <div
                                style={{

                                }}>
                                    <Typography
                                    sx={{
                                        fontWeight:"bold",
                                        color: color.fontPrimary,
                                        fontSize:'15px'
                                    }}
                                    >Adicionado</Typography>
                                    <Typography
                                    sx={{
                                        color: color.fontPrimary,
                                        fontSize:'15px',
                                        opacity:0.8
                                    }}>{state.address?.formatted_address}</Typography>
                                </div>
                            </div>
                        </ButtonBase>}

                        {address?.map(e => (
                            <ButtonBase 
                            onClick={() => changeAddress(e)}
                            sx={{textAlign:'left'}}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                    margin:'20px 10px'
                                }}>
                                    <div
                                    style={{
                                        marginRight:'10px'
                                    }}>
                                        {e.favorite_address?.toLowerCase() === 'casa' && <HomeOutlinedIcon sx={{color: e.idclienteendereco === state.idclienteendereco ? color.fontPrimary : "text.primary"}}/>}
                                        {e.favorite_address?.toLowerCase() === 'trabalho' && <WorkOutlineOutlinedIcon sx={{color: e.idclienteendereco === state.idclienteendereco ? color.fontPrimary : "text.primary"}}/>}
                                        {e.favorite_address?.toLowerCase() !== 'casa' &&
                                        e.favorite_address?.toLowerCase() !== 'trabalho' &&
                                        <FmdGoodOutlinedIcon sx={{color: e.idclienteendereco === state.idclienteendereco ? color.fontPrimary : "text.primary"}} />}
                                    </div>
                                    <div
                                    style={{
    
                                    }}>
                                        <Typography
                                        sx={{
                                            fontWeight:"bold",
                                            color: e.idclienteendereco === state.idclienteendereco ? color.fontPrimary : "text.primary",
                                            fontSize:'15px'
                                        }}
                                        >{e.favorite_address ? e.favorite_address : e.complemento}</Typography>
                                        <Typography
                                        sx={{
                                            color: e.idclienteendereco === state.idclienteendereco ? color.fontPrimary : "text.primary",
                                            fontSize:'15px',
                                            opacity:0.8
                                        }}>{e?.formatted_address}</Typography>
                                    </div>
                                </div>
                            </ButtonBase>
                        ))}

                    </div>

                    <div
                    style={{
                        backgroundColor:color.background,
                        height:'490px',
                        // width:'360px',
                        borderRadius:'0px 0px 10px 10px',
                        maxHeight:'490px',
                        overflowY:'auto',
                        padding:'10px',
                        overflowX:'hidden'
                    }}>
                        <Typography sx={{
                            color:'text.primary',
                            fontSize:'17px',
                            marginBottom:'20px',
                            fontWeight:'bold'
                        }}>{state.address?.formatted_address}</Typography>

                        {!state.current_position &&
                        <InputMask 
                        mask="99999-999" 
                        maskChar={null}
                        value={state.cep}
                        onChange={({target}) => {
                            setState({...state, cep:target.value})
                            if (target.value.length === 9) find_address(target.value, (err, out) => {
                                if ( err ) return 
                                setState({
                                    ...state,
                                    endereco: `${out.rua} - ${out.bairro}, ${out.cidade} - ${out.estado}`,
                                    cep: target.value,
                                    complemento: state.complemento || out.complemento
                                })
                            })
                        }}
                        size='small'
                        name='cep'
                        fullWidth
                        >
                            {(inputProps) => <TextField 
                                            {...inputProps}
                                            inputProps={{
                                                id:'cep'
                                            }}
                                            label='CEP'
                                            onKeyDown={(event) => {
                                            }}
                                            InputLabelProps={{shrink: state.cep ? true : false}}
                                            />}
                        </InputMask>
                        }

                        {!state.current_position &&
                        <TextField 
                        label='Endereço'
                        sx={{width:'100%'}}
                        variant='standard'
                        value={state.endereco}
                        onChange={({target}) => setState({...state, endereco: target.value})}
                        InputLabelProps={{shrink: state.endereco ? true : false}}
                        onBlur={findAddress}
                        />}

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:"space-between",
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}>
                            <TextField 
                            label='Número'
                            sx={{width:'calc(30% - 20px)'}}
                            variant='standard'
                            value={state.numero}
                            onChange={({target}) => setState({...state, numero: target.value})}
                            onBlur={findAddress}
                            />
                            <TextField 
                            label='Complemento'
                            sx={{width:'calc(70% - 20px)'}}
                            variant='standard'
                            helperText='Apartamento / Bloco / Casa'
                            value={state.complemento}
                            onChange={({target}) => setState({...state, complemento: target.value})}
                            />
                        </div>

                        <TextField 
                        label='Ponto de Referência'
                        sx={{width:'100%'}}
                        variant='standard'
                        value={state.referencia}
                        onChange={({target}) => setState({...state, referencia: target.value})}
                        />

                        {user.token &&
                        <div
                        style={{
                            marginTop:'40px'
                        }}>
                            <Typography sx={{fontSize:'13px', fontWeight:'bold',color:'text.primary'}}>Favoritar como</Typography>
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'space-between',
                                marginTop:'10px'
                            }}>
                                <div
                                onClick={() => setState({...state, favorite_address: state.favorite_address === 'Casa' ? '' : 'Casa'})}
                                style={{
                                    display:'flex',
                                    width:'calc(40% - 20px)',
                                    border: `1px solid ${color.fontPrimary}`,
                                    opacity:state.favorite_address === 'Casa' ? 1 : 0.5,
                                    justifyContent:'center',
                                    padding:'10px 20px',
                                    borderRadius:'10px',
                                    cursor:'pointer'
                                }}>
                                    <HomeOutlinedIcon 
                                    sx={{
                                        marginRight:'5px',
                                        color:color.fontPrimary
                                    }} />
                                    <Typography sx={{color:color.fontPrimary}}>Casa</Typography>
                                </div>

                                <div
                                onClick={() => setState({...state, favorite_address: state.favorite_address === 'Trabalho' ? '' : 'Trabalho'})}
                                style={{
                                    display:'flex',
                                    width:'calc(40% - 20px)',
                                    border: `1px solid ${color.fontPrimary}`,
                                    opacity:state.favorite_address === 'Trabalho' ? 1 : 0.5,
                                    justifyContent:'center',
                                    padding:'10px 20px',
                                    borderRadius:'10px',
                                    cursor:'pointer'
                                }}>
                                    <WorkOutlineOutlinedIcon 
                                    sx={{
                                        marginRight:'5px',
                                        color:color.fontPrimary
                                    }} />
                                    <Typography sx={{color:color.fontPrimary}}>Trabalho</Typography>
                                </div>
                            </div>
                        </div>}
                    </div>
                </SwipeableViews>
            </div>
        </Modal>
    )
})

export default Address