import { useContext, useState } from 'react'
import { AnalyticsContext, PreferencesContext } from "../../context"
import { 
    Box, 
    useTheme,
    FormControlLabel,
    Checkbox,
    Typography,
    Button
} from "@mui/material"
import { Header, Topic, SubTopic, Content } from './components'
import getColors from '../getColor'

export default function Terms ( props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [read, setRead] = useState(false)
    const theme = useTheme()
    const color = getColors(preferences, theme)
    
    return (    
        <Box
        sx={{
            display: 'flex',
            width: 'calc(100% - 20px)',
            margin:'0px',
            padding:'0px',
            flexDirection:"column",
            // filter:blur && 'blur(5px)',
            overflowY:'hidden',
            overflowX:'hidden',
            padding:'10px',
            alignItems:'center'
        }}
        >
            <div
            style={{
                maxWidth:'1000px',
            }}
            >
                <Header>Termos de Serviço</Header>

                <Content>
                    Ao acessar ou utilizar os serviços oferecidos pela Allon, você concorda em cumprir e aceitar os seguintes termos de serviço. Caso não concorde com alguma parte destes termos, por favor, abstenha-se de utilizar nossos serviços.
                </Content>

                <Topic>1. Uso do Serviço</Topic>
                <SubTopic>1.1 Permissão de Uso:</SubTopic>
                <Content>
                    A Allon concede a você uma licença limitada, não exclusiva e intransferível para utilizar nossos serviços de acordo com estes termos.
                </Content>
                <SubTopic>1.2 Restrições:</SubTopic>
                <Content>
                    Você concorda em não modificar, copiar, distribuir, transmitir, exibir, executar, reproduzir, publicar, licenciar, criar trabalhos derivados, transferir ou vender quaisquer informações, software, produtos ou serviços obtidos por meio de nossos serviços.
                </Content>
                <Topic>2. Conta do Usuário</Topic>
                <SubTopic>
                    2.1 Registro:
                </SubTopic>
                <Content>
                    Ao criar uma conta, você concorda em fornecer informações precisas, atualizadas e completas. Você é responsável por manter a confidencialidade de suas credenciais de conta.
                </Content>
                <SubTopic>
                    2.2 Responsabilidade:
                </SubTopic>
                <Content>
                    Você é responsável por todas as atividades realizadas através de sua conta. Informe-nos imediatamente sobre qualquer uso não autorizado ou suspeito de sua conta.
                </Content>
                <Topic>
                    3. Conteúdo do Usuário
                </Topic>
                <SubTopic>
                    3.1 Propriedade:
                </SubTopic>
                <Content>
                    Você mantém a propriedade de todo o conteúdo que envia ou disponibiliza através de nossos serviços.
                </Content>
                <SubTopic>
                    3.2 Licença:
                </SubTopic>
                <Content>
                    Ao enviar conteúdo, você concede à Allon uma licença mundial, não exclusiva, livre de royalties para usar, reproduzir, modificar, distribuir e exibir o conteúdo em conexão com a prestação dos serviços.
                </Content>
                <Topic>
                    4. Responsabilidades e Obrigações
                </Topic>
                <SubTopic>
                    4.1 Uso Adequado:
                </SubTopic>
                <Content>
                    Você concorda em usar nossos serviços apenas para fins legais e adequados. Não deve utilizar nossos serviços de maneira que infrinja direitos de terceiros ou viole leis aplicáveis.
                </Content>
                <SubTopic>
                    4.2 Rescisão de Serviço:
                </SubTopic>
                <Content>
                    A Allon reserva-se o direito de recusar, suspender ou encerrar seus serviços a qualquer momento, a critério exclusivo da empresa.
                </Content>
                <Topic>
                    5. Limitação de Responsabilidade
                </Topic>
                <SubTopic>
                    5.1 Disponibilidade do Serviço:
                </SubTopic>
                <Content>
                    A Allon não garante a disponibilidade contínua e ininterrupta de seus serviços e pode modificar, suspender ou descontinuar qualquer aspecto de seus serviços a qualquer momento.
                </Content>
                <Topic>
                    6. Alterações nos Termos de Serviço
                </Topic>
                <Content>
                    A Allon reserva-se o direito de modificar estes termos a qualquer momento. Alterações serão efetivas imediatamente após a publicação. O uso continuado de nossos serviços após tais modificações constituirá aceitação dos novos termos.
                </Content>
                <Topic>
                    7. Lei Aplicável
                </Topic>
                <Content>
                    Estes termos são regidos e interpretados de acordo com as leis do Brasil. Qualquer litígio decorrente ou relacionado a estes termos estará sujeito à jurisdição exclusiva dos tribunais competentes no Brasil.
                </Content>

                <Topic style={{marginTop:'10px'}}>Ao utilizar os serviços da Allon, você concorda com estes termos. Se tiver dúvidas ou preocupações, entre em contato conosco em contato@allon.cloud.</Topic>
                <Topic style={{marginTop:'10px'}}>Este termo é efetivo a partir de 06/11/2023 e permanecerá em vigor, exceto em caso de revisão.</Topic>

                <FormControlLabel control={
                <Checkbox 
                onChange={({target}) => setRead(target.checked)}
                />} label={
                    <Typography
                    sx={{ color:'text.primary' }}
                    >Li, Aceito e concordo...</Typography>
                } />

                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center'
                }}
                >
                    <Button
                    size='small'
                    variant='contained'
                    disabled={!read}
                    onClick={() => {
                        setAnalytics({ ...analytics, terms: true })
                        window.location.replace(analytics.privacy ? '/' : '/readme/privacy')
                    }}
                    >
                        {analytics.privacy ? 'Continuar' : 'Política de privacidade'}
                    </Button>
                </div>
            </div>
        </Box>
    )
}