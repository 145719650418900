import { 
    Divider, 
    IconButton, 
    InputBase, 
    MenuItem, 
    Modal, 
    Paper, 
    Popover, 
    Skeleton, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material"

import { 
    Box,
    Button
} from "@mui/material"

import { 
    useContext, 
    useEffect, 
    useRef, 
    useState 
} from "react"

import { 
    useNavigate, 
    useParams 
} from "react-router-dom"

import { 
    CartContext, 
    LoadingContext, 
    PreferencesContext, 
    ReplaceOrdeContext, 
    SizeContext, 
    SocketContext, 
    UserContext 
} from "../../../context"

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MapIcon from '@mui/icons-material/Map';
import NavigationIcon from '@mui/icons-material/Navigation';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddIcon from '@mui/icons-material/Add';

import getColors from "../../getColor"
import { api } from "../../../utils/defaults"
import axios from "axios"
import useInterval from "use-interval"
import InputMask from 'react-input-mask';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useGeolocation from "../../../utils/useGeolocation.ts";
import { 
    MapContainer, 
    Marker, 
    Popup, 
    TileLayer, 
    useMap 
} from 'react-leaflet'
import L from 'leaflet'
import "leaflet/dist/leaflet.css";

import MarkHelmet from '../../../static/marker-helmet.png'
import MarkPinIcon from '../../../static/market-pin.png'
import { toast } from "react-toastify"
import QRCode from "react-qr-code";
import Checkout from '../../checkout/index'
import createOrder from "../../../utils/createOrder"
// import Lottie from "react-lottie-player";
// import driverSeparingLottie from '../../../static/security.json'

const Recenter = ({ lat , lng, b }) => {
    const map = useMap();

    useEffect(() => {
        map.flyTo([lat, lng], map.getZoom())
        const bounds = L.latLngBounds(b);
        map.fitBounds(bounds);
    }, [lat, lng]);

    return null;
}

const Map = (props) => {
    const [position, setPosition] = useState({ lat: 0, lng: 0 }); // set default position
    const [geolocation, setGeolocation] = useState({ lat: 0, lng: 0 })
    const params = useParams()
    const socket = useContext(SocketContext)

    useEffect(()=> {
        if (props.position) setPosition(props.position)
    }, [props.position]);

    useEffect(()=> {
        if (props.geolocation) setGeolocation(props.geolocation)
    }, [
        props.geolocation?.lat,
        props.geolocation?.lng
    ]);

    const userIcon = L.icon({
        iconUrl: MarkPinIcon,
        // shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',
    
        iconSize:     [50, 50], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [25, 50], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0],  // the same for the shadow
        popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
    });

    const pinIcon = L.icon({
        iconUrl: MarkHelmet,
        // shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',
    
        iconSize:     [50, 50], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [25, 50], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0],  // the same for the shadow
        popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
    });

    useEffect(() => {
        socket.on(`delivery_geolocation_${params.idvenda}`, data => {
            setPosition({
                lat: data.geolocation?.latitude || 0,
                lng: data.geolocation?.longitude || 0
            })
        })

        return () => {
            socket.off(`delivery_geolocation_${params.idvenda}`)
        }
    },[])

    return (
        <MapContainer 
        center={geolocation} 
        zoom={13} 
        scrollWheelZoom={true}
        >
            <Recenter 
            b={[geolocation, {lat: position.lat, lng: position.lng}]}
            lat={position.lat || geolocation.lat} 
            lng={position.lng || geolocation.lng} 
            />

            <TileLayer
                attribution='&copy; allon'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            
            <Marker 
            icon={userIcon} 
            position={geolocation}
            >
                <Popup>
                    <Typography>Você</Typography>
                </Popup>
            </Marker>

            {position.lat && position.lng &&
            <Marker 
            icon={pinIcon} 
            position={{lat: position.lat, lng: position.lng}}>
                <Popup>
                    <Typography>Entregador</Typography>
                </Popup>
            </Marker>}

        </MapContainer>
    )
}

const Status = (props) => {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState(props.order)
    const [open, setOpen] = useState(false)
    const [checkout, setCheckout] = useState(false)
    const [cards, setCards] = useState([])
    const [selected, setSelected] = useState({new:false, selected: false})

    const fn_checkout = ( card = undefined ) => {
        setLoading(true)
        checkoutRef.current?.init({
            onReady: () => {
                setLoading(false)
                setSelected({ ...card, new: card ? false : true, selected: true })
            },
            onError: () => {
                setLoading(false)
                setSelected({ new: false, selected: false })
            },
            onUnmount: () => setSelected({ new: false, selected: false }),
            onPay: async (data, resolve=()=>{}, reject=()=>{}) => {
                setLoading(true)
                createOrder({ 
                    ...state,
                    payment: data.formData, 
                    save_payment: data.save_payment || false,
                    replace_payment: true
                }, user.token,(err, out) => {
                    setLoading(false)

                    if ( err ) {
                        reject()
                        toast.error(err?.response?.data?.message || err?.response?.data)
                        return
                    }

                    toast.success('Sucesso')
                    props.update()
                    resolve()
                    checkoutRef?.current?.unmount()
                    setCheckout(false)
                })
            },
            enableSaveCard: true,
            amount: parseFloat(state.valor_final),
            card
        })
    }

    useEffect(() => {
        axios
        .get(`${api}/client/cards?verificado=true`, {
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setCards(response.data))
        .catch(err => {})
    },[])

    useEffect(() => setState(props.order), [props.order])
    useEffect(() => {
        if ( !checkout ) setSelected({new:false, selected: false})
    },[checkout])

    const checkoutRef = useRef()
    const topRef = useRef()
    const theme = useTheme()
    const color = getColors(preferences, theme)

    if ( state.idvendapagamentostatus === 1 && [6,7,8,9].indexOf(state.payment_list?.find(({unique_id}) => unique_id === state.payment_unique_id)?.idvendapagamentostatus) !== -1 ) {
        
        return (
            <>
            <Modal
            open={checkout}
            onClose={() => setCheckout(false)}
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            >
                <div
                style={{
                    width:'100vw',
                    // height:'100vh',
                    maxWidth:'350px',
                    maxHeight:'85vh',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1f1f1f' : '#fff',
                    borderRadius:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <Button
                        startIcon={<ArrowBackIosNewOutlinedIcon />}
                        disabled={!selected.selected}
                        sx={{color:color.fontPrimary}}
                        onClick={() => checkoutRef?.current?.unmount()}
                        >
                            voltar
                        </Button>
                        <Button
                        sx={{color:color.fontPrimary}}
                        onClick={() => {
                            checkoutRef?.current?.unmount()
                            setCheckout(false)
                        }}
                        >
                            fechar
                        </Button>
                    </div>
                    {!selected.selected ? 
                    <div
                    ref={topRef}
                    style={{
                        display:'flex',
                        width:'100%',
                        overflowX:cards.filter(({verificado, ativo, interno}) => verificado && ativo && !interno).length >= 2 ? 'scroll' : 'hidden'
                    }}>
                        <div
                        onClick={() => fn_checkout()}
                        style={{
                            width:'100px',
                            height:'100px',
                            margin:'10px',
                            boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                            padding:'10px',
                            minWidth:'100px',
                            borderRadius:'10px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            cursor:'pointer'
                        }}>
                            <div
                            style={{
                                display:'flex',
                                width:'100%'
                            }}>
                                <AddIcon sx={{color: color.fontPrimary}} />
                            </div>
                            <div>
                                <Typography 
                                sx={{
                                    color:'text.primary',
                                    fontSize:'13px',
                                    marginLeft:'5px'
                                }}>Abrir</Typography>
                                <Typography 
                                sx={{
                                    color:'text.primary',
                                    fontSize:'13px',
                                    marginLeft:'5px',
                                    fontWeight:'bold'
                                }}>Novas Opções</Typography>
                            </div>
                        </div>
                        {cards.filter(({verificado, ativo, interno}) => verificado && ativo && !interno)?.map(e => (
                            <div
                            onClick={() => fn_checkout(e)}
                            style={{
                                width:'100px',
                                height:'100px',
                                margin:'10px',
                                boxShadow:`${theme.palette.mode === 'light' ? '#000' : 'rgba(255,255,255,0.1)'} 0px 0px 6px -1px`,
                                padding:'10px',
                                minWidth:'100px',
                                borderRadius:'10px',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                cursor:'pointer'
                            }}>
                                <div
                                style={{
                                    display:'flex',
                                    width:'100%'
                                }}>
                                    {
                                        e.mp_info?.payment_method.secure_thumbnail ? 
                                        <img 
                                        style={{
                                            width:'20px',
                                            objectFit:'contain'
                                        }}
                                        src={e.mp_info?.payment_method.secure_thumbnail} /> : 
                                        <Typography 
                                        sx={{
                                            color:'text.primary',
                                            fontSize:'13px',
                                            marginLeft:'5px'
                                        }}>{e.brand}</Typography>
                                    }

                                    <Typography 
                                    sx={{
                                        color:'text.primary',
                                        fontSize:'13px',
                                        marginLeft:'5px'
                                    }}>{e.apelido}</Typography>
                                </div>
                                <div>
                                    <Typography 
                                    sx={{
                                        color:'text.primary',
                                        fontSize:'13px',
                                        marginLeft:'5px'
                                    }}>{e.expiration}</Typography>

                                    <Typography 
                                    sx={{
                                        color:'text.primary',
                                        fontSize:'13px',
                                        marginLeft:'5px',
                                        fontWeight:'bold'
                                    }}>&#9679; &#9679; &#9679; &#9679; {e.cardNumber?.substring(12, 16)}</Typography>
                                </div>
                            </div>
                        ))}
                    </div> : <></>}
                    <div
                    style={{
                        overflowY:'auto',
                        maxHeight:`calc(85vh - ${(topRef?.current?.clientHeight || 0) + 46}px)`
                    }}
                    >
                        <Checkout 
                        ref={checkoutRef}
                        style={{
                            width:'auto'
                        }}
                        ComponentProps={{
                            accordionProps: {
                                sx: {
                                    width: 'auto !important',
                                    // display:'flex'
                                }
                            },
                            accordionSumaryProps: {
                                sx: {
                                    width: '100% !important'
                                }
                            }
                        }}
                        />
                    </div>
                </div>
            </Modal>

            <Paper
            sx={{ 
                p: '5px 10px', 
                display: 'flex', 
                margin:'20px 0px',
                flexDirection:"column",
                justifyContent:'center',
                maxWidth:'980px'
            }}
            >
                <div
                style={{
                    display:'flex',
                    alignItems:"center",
                }}
                >
                    <ErrorOutlineIcon color='error' />
                    <Typography color='error' sx={{ marginLeft:'10px'}}>Problema com seu pagamento</Typography>
                </div>

                <Typography
                sx={{
                    marginTop:'10px'
                }}
                >{state.apelido} | {parseFloat(state.valor_final).toLocaleString('pt-BR',{currency:'BRL',style:"currency"})} - {new Date(state.dtpagamento).toLocaleString('pt-BR')}
                </Typography>

                <div
                style={{
                    marginTop:'10px'
                }}
                >
                    <Button
                    color='success'
                    startIcon={<PaymentsIcon />}
                    size="small"
                    variant='outlined'
                    onClick={() => setCheckout(true)}
                    >
                        Pagar novamente
                    </Button>
                </div>
            </Paper>
            </>
        )
    }

    switch (props.conector) {
        case 'mercado_pago':
        return (
            <div 
            style={{
                fontFamily:'Quicksand !important',
                maxWidth:'1000px'
            }} id="statusScreenBrick_container">

            </div>
        );
        case 'cielo':
        return (
            (state.idvendapagamentostatus === 1 && (state.payment_method_id === 'Boleto' || state.payment_method_id === 'Pix')) ? 
            <div
            style={{
                display:'flex',
                margin: '20px 0px'
            }}
            >
                {state.payment_method_id === 'Boleto' ? 
                <div>
                    <Typography>Pague o Boleto até {new Date(`${state?.payment_response?.Payment?.ExpirationDate}T04:00:00.000Z`).toLocaleDateString('pt-BR')}</Typography>
                    <Paper
                    component="form"
                    sx={{ 
                        p: '2px 4px', 
                        display: 'flex', 
                        alignItems: 'center',
                        width: '100%' 
                    }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder=""
                            inputProps={{ 'aria-label': '' }}
                            value={state?.code_string}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            {/* <SearchIcon /> */}
                        </IconButton>
                        <CopyToClipboard 
                        onCopy={() => toast.success('Copiado')}
                        text={state?.code_string}>
                            <ContentCopyIcon 
                            sx={{
                                cursor:'pointer',
                                fontSize: '20px',
                                p: '10px'
                            }}
                            />
                        </CopyToClipboard>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton 
                        onClick={() => window.open(state?.payment_response?.Payment?.Url)}
                        color="primary" sx={{ p: '10px', fontSize: '20px' }} aria-label="directions">
                            <OpenInNewIcon />
                        </IconButton>
                    </Paper>
                </div> : 
                <div>
                    <Typography>Pague com PIX Copia e cola ( ou  QR code )</Typography>
                    <Paper
                    component="form"
                    sx={{ 
                        p: '2px 4px', 
                        display: 'flex', 
                        alignItems: 'center',
                        width: '100%' 
                    }}
                    >
                        <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder=""
                        inputProps={{ 'aria-label': '' }}
                        value={state?.code_string}
                        disabled
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            {/* <SearchIcon /> */}
                        </IconButton>
                        <CopyToClipboard 
                        onCopy={() => toast.success('Copiado')}
                        text={state?.code_string}>
                            <ContentCopyIcon 
                            sx={{
                                cursor:'pointer',
                                fontSize: '20px',
                                p: '10px'
                            }}
                            />
                        </CopyToClipboard>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton 
                        onClick={() => setOpen(true)}
                        color="primary" sx={{ p: '10px', fontSize: '20px' }} aria-label="directions">
                            <QrCodeIcon />
                        </IconButton>
                    </Paper>
                    <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                    >
                        <Paper
                        component="form"
                        sx={{ 
                            p: '10px', 
                            display: 'flex', 
                            alignItems: 'center',
                            width: '100%',
                            maxWidth:'300px',
                            display:"flex",
                            flexDirection:'column',
                            borderRadius:'10px'
                        }}
                        >
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:"right"
                            }}
                            >
                                <Button 
                                onClick={() => setOpen(false)}
                                color='error'>fechar</Button>
                            </div>
                            <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%", borderRadius:'10px' }}
                            value={state?.code_string}
                            viewBox={`0 0 256 256`}
                            />
                        </Paper>
                    </Modal>
                </div>}
            </div> : 
            <div
            style={{
                width:'100%'
            }}
            >

            </div>
        );
    }
}

export default function Index (props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [cart, setCart] = useContext(CartContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [replaceOrder, setReplaceOrder] = useContext(ReplaceOrdeContext)
    const [order, setOrder] = useState({})
    const [loading, setLoading] = useState(true)
    const [latlng, setLatlng] = useState({lat: 0, lng: 0})
    const [reasonsAnchor, setReasonsAnchor] = useState(null)
    const [payment, setPayment] = useState({})
    const [cadaster, setCadaster] = useState(user)
    const [showPayment, setShowPayment] = useState(false)
    const [reasons, setReasons] = useState({
        text:'', 
        options:[
            'Atraso no pedido',
            'Demora no atendimento',
            'Desisti de comprar',
            'Comprei em outra loja'
        ]
    })

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()
    const params = useParams()
    const geolocation = useGeolocation()
    const coords = geolocation.coords

    useEffect(() => {
        // setLoading(true)
        // axios
        // .get(`${api}/payment/method`)
        // .then(response => {
        //     setPayment(response.data)
        // })
        // .catch(err => toast.error(err.response?.data))
        // .finally(() => setLoading(false))
        setLoading(true)
        getData()

    },[
        preferences.storecode,
        user.token
    ])

    const getData = () => {
        axios
        .get(`${api}/order/detail/${params.idvenda}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setOrder(response.data))
        .catch(err => toast.error(err.response?.data))
        .finally(() => setLoading(false))
    }

    const tag = (state) => {
        if (!state) return ''
        return state.promocao || state.desconto || state.porcdesconto ? (
            state.promocao && !Number.isNaN(parseFloat(state.promocao)) && parseFloat(state.promocao) > 0 ? `DE: ${parseFloat(state.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}` : 
            (state.desconto && !Number.isNaN(parseFloat(state.desconto)) && parseFloat(state.desconto) > 0 ? `- ${parseFloat(state.desconto).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}` :
            `${parseInt(state.porcdesconto)}%`
            )
        ) : ''
    }

    const storecode = preferences.storecode
    useInterval(() => getData(), 15000)

    const cancel = (text) => {
        if (!text) return toast.error('Informe um motivo')
        if (text.length < 10) return toast.error('O motivo precisa de 10 caracteres no mínimo')

        setLoading(true)
        axios
        .post(`${api}/order/cancel/${order.idvenda}`,{
            motivocancelamento: text
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Cancelado')
            getData()
            setReasonsAnchor(null)
        })
        .catch(err => toast.error(err.response?.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {

        if ( !order.idvenda ) return 

        if ( order?.conector === 'mercado_pago' ) {
            try {
                window.statusScreenBrickController.unmount()
            } catch (err) {}
    
            try {
                setPayment({...payment, loading: true})
            
                const bricks = new window.MercadoPago(order.key.public_key);
                const bricksBuilder = bricks.bricks();
    
                const renderStatusScreenBrick = async (bricksBuilder) => {
                    const settings = {
                        initialization: {
                            paymentId: order.payment_response.id, // id do pagamento a ser mostrado
                        },
                        callbacks: {
                            onReady: () => {
                                setPayment({...payment, loading: false})
                            },
                            onError: (error) => {
                                console.error(error);
                                setPayment({...payment, loading: false})
                            },
                        },
                    };
                    
                    try {
                        window.statusScreenBrickController = await bricksBuilder.create(
                            'statusScreen',
                            'statusScreenBrick_container',
                            settings,
                        );  
                    } catch (err) {
                    
                    }
                };
    
                if (bricksBuilder) renderStatusScreenBrick(bricksBuilder);
            } catch (err) {}
        } else {
            setPayment({...payment, loading: false})
        }

    },[
        order.idvenda, 
        order.idvendapagamentostatus,
        user.token
    ])

    return (
        <Box
        sx={{
            bgcolor: color.background,
            // width:'100vw',
            // height:'100vh',
            // position:'fixed',
            top:0,
            left:0,
            color:'text.primary',
            display:'flex',
            justifyContent:'center',
            overflowX:'hidden'
        }}>
            <Modal
            open={showPayment}
            onClose={() => setShowPayment(false)}
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
            >
                <div
                style={{
                    width:'100vw',
                    // height:'100vh',
                    maxWidth:size.width >= 600 ? '600px' : '90vw',
                    maxHeight:'85vh',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1f1f1f' : '#fff',
                    borderRadius:'10px',
                    padding:'10px'
                }}
                >
                    <div
                    style={{
                        display:"flex",
                        justifyContent:'right',
                        width:'100%'
                    }}
                    >
                        <Button
                        color='error'
                        onClick={() => setShowPayment(false)}
                        size='small'
                        variant='outlined'
                        >
                            fechar
                        </Button>
                    </div>
                    <table
                    style={{
                        textAlign:"center",
                        width:'100%'
                    }}
                    >
                        <tr
                        style={{textAlign:'center'}}
                        >
                            <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontWeight:'bold'}}>Método</Typography></td>
                            <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontWeight:'bold'}}>Valor</Typography></td>
                            <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontWeight:'bold'}}>Status</Typography></td>
                            <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontWeight:'bold'}}>Data/Hora</Typography></td>
                        </tr>
                        {order?.payment_list?.map(e => (
                            <tr
                            style={{textAlign:'center'}}
                            >
                                <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontSize:'14px'}}>{e.apelido}</Typography></td>
                                <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontSize:'14px'}}>{parseFloat(e.valor_final || e.valor_inicial).toLocaleString('pt-BR',{ currency:'BRL', style: 'currency'})}</Typography></td>
                                <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontSize:'14px'}}>{e.nmvendapagamentostatus}</Typography></td>
                                <td style={{textAlign:'center'}}><Typography sx={{color:'text.primary', fontSize:'11px'}}>{new Date(e.dtpagamento).toLocaleString('pt-BR')}</Typography></td>
                            </tr>
                        ))}
                    </table>
                </div>

            </Modal>

            <Popover
            open={reasonsAnchor!==null}
            onClose={() => setReasonsAnchor(null)}
            anchorEl={reasonsAnchor}
            >
                <Box
                sx={{
                    maxWidth:'360px',
                    padding:'10px'
                }}
                >
                    <Typography>O que aconteceu ?</Typography>
                    <Divider sx={{marginTop:'10px'}} />
                    {reasons.options?.map(e => (
                        <MenuItem onClick={() => cancel(e)}>{e}</MenuItem>
                    ))}
                    <Divider sx={{marginTop:'10px'}} />
                    <TextField 
                    value={reasons.text}
                    onChange={({target}) => setReasons({...reasons, text: target.value})}
                    multiline
                    label='Outro motivo'
                    fullWidth
                    />
                    <Button
                    variant="contained"
                    fullWidth
                    color='error'
                    sx={{
                        marginTop:'10px'
                    }}
                    onClick={() => cancel(reasons.text)}
                    >enviar</Button>
                </Box>
            </Popover>

            <div
            style={{
                width:'100%',
                maxWidth:'1000px'
            }}>
                <div
                style={{
                    width:'100%',
                    top:0,
                    left:0,
                    backgroundColor: color.background,
                    width:'100%',
                    zIndex: 999
                }}>
                    <Button onClick={() => navigate('/order')} sx={{color:color.fontPrimary}} startIcon={<ArrowBackIosNewOutlinedIcon />}>
                    voltar
                    </Button>
                </div>
                <div
                style={{
                    margin:'10px',
                    display:'flex',
                    alignItems:'center'
                }}>
                    <div>
                    {loading ? 
                    <Skeleton variant="rounded" width={130} height={50} /> :
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'50px'
                    }}>{order.idvenda}</Typography>}
                    </div>
                    <div
                    style={{
                        marginLeft:'10px'
                    }}>
                        {loading ? <Skeleton variant="rounded" width={220} height={50} /> :
                        <>
                        <Typography
                        sx={{
                            fontSize:'14px',
                            fontWeight:'bold'
                        }}>{order.delivery_type} &#9679; {order.ecommerce ? 'Compra Online' : 'Compra Física'}</Typography>
                        <Typography>{new Date(order.dtavenda).toLocaleString('pt-BR')}</Typography>
                        </>
                        }
                    </div>
                    
                </div>
                {order?.idvendastatus !== 5 && 
                parseFloat(order?.valor_final || order?.valor_inicial) > parseFloat(order?.valorfinal) + parseFloat(order?.vlrfrete) &&
                !order?.refunded &&
                !order?.auto_reimbursement &&
                order?.idconectorpagamento &&
                <>
                <Typography
                sx={{
                    margin:order?.reimbursement_code ? '10px 10px 0px 10px' : '10px 10px 20px 10px'
                }}
                >Não esqueça de pedir seu reembolso de {
                    (parseFloat(order?.valor_final || order?.valor_inicial) - (parseFloat(order?.valorfinal) + parseFloat(order?.vlrfrete)))
                    .toLocaleString('pt-BR',{currency:'BRL',style:'currency'})
                    }.</Typography>

                {order?.reimbursement_code && <Typography
                sx={{
                    fontWeight:'bold',
                    margin:'0px 10px 20px 10px',
                    fontSize:'20px'
                }}
                >Informe o código: {order?.reimbursement_code}</Typography>}
                </>
                }
                
                <div
                style={{
                    margin:'10px',
                    // display:'flex',
                    // flexDirection:'column'
                }}>
                    <div
                    style={{
                        display:'flex',
                        alignItems:'center'
                    }}>
                        <Divider>
                            {loading ? <Skeleton variant="rounded" width={100} height={20} sx={{marginRight:'10px'}}/> :
                            <Typography
                            noWrap={false}
                            sx={{
                                fontWeight:'bold',
                                width:'max-content',
                                marginRight:'10px'
                            }}>{order.nmvendastatus}</Typography>}
                        </Divider>
                    </div>

                    {/* <Lottie 
                    animationData={driverSeparingLottie}
                    // segments={[57,271]}
                    // goTo={57}
                    height={200}
                    width={200}
                    onEnterFrame={(data) => {
                        // if (data.currentTime >= 152) {
                        //     setStartSector(false)
                        // }
                    }}
                    play={true}
                    // onClick={() => setStart(true)}
                    /> */}

                    {(order.idvendastatus === 12 || order.idvendastatus === 3)  &&
                    <>
                    {order.delivery_type === 'delivery' ? 
                    <div
                    style={{
                        width:'100%',
                        minHeight:'300px',
                        borderRadius:'10px',
                        marginBottom:'10px',
                        maxWidth:'1000px'
                    }}
                    >
                        <div
                        style={{
                            width:'100%',
                            height:'300px',
                            borderRadius:'10px'
                        }}>
                        <Map 
                        geolocation={order.entrega?.geometry?.location}
                        position={latlng}
                        />
                        </div>
                        {order.idvendastatus === 12 &&
                        <div
                        style={{
                            marginTop:'10px'
                        }}>
                            <Typography
                            sx={{
                                color:'text.primary',
                                fontSize:'15px'
                            }}>
                            {[order.entregador.split(' ')[0], order.entregador.split(' ')[1]].filter(e => e).join(' ')} chegou ao destino com seu pedido...<br/>                    
                            </Typography>
                            <Typography
                            sx={{
                                color:'text.secondary',
                                fontSize:'13px'
                            }}>
                            Ele(a) poderá te aguardar por até 5 minutos. Se preferir, poderá entrar em contato para encontrá-lo(a).                     
                            </Typography>
                            <Button
                            onClick={() => window.open(`https://wa.me/55${order.telefoneentregador.replaceAll('(','').replaceAll(')','').replaceAll(' ','')}`)}
                            startIcon={<WhatsAppIcon />}
                            color='success'
                            sx={{margin:'15px 0x'}}
                            >Whatsapp</Button>
                            <Button
                            onClick={() => window.open(`tel:${order.telefoneentregador.replaceAll('(','').replaceAll(')','').replaceAll(' ','')}`)}
                            startIcon={<LocalPhoneIcon />}
                            color='success'
                            sx={{margin:'15px 0px 15px 10px'}}
                            >Ligar</Button>
                        </div>
                        }
                    </div> : 
                    <div
                    style={{
                        width:'100%',
                        borderRadius:'10px',
                        marginBottom:'10px',
                        maxWidth:'1000px'
                    }}
                    >
                        <div
                        style={{
                            marginTop:'10px'
                        }}>
                            <Typography
                            sx={{
                                color:'text.primary',
                                fontSize:'15px'
                            }}>
                            Seu pedido já foi postado nos correios, o código de rastreio é <div
                            style={{
                                display:'flex',
                                alignItems:"center",
                                padding:'10px',
                                backgroundColor:'rgba(140, 140, 140, 0.23)',
                                borderRadius:'5px',
                                width:'fit-content'
                            }}
                            >
                                <span>{order.tracking_code}</span>
                                {!order.copied ? <CopyToClipboard text={order.tracking_code}
                                onCopy={() => setOrder({...order, copied: true})}>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                    marginLeft:'50px',
                                    fontSize:'11px',
                                    padding:'7px',
                                    borderRadius:'5px',
                                    backgroundColor:'rgba(140, 140, 140, 0.23)',
                                    cursor:'pointer'
                                }}
                                >
                                    copiar <ContentCopyIcon sx={{fontSize:'15px',marginLeft:'10px'}} />
                                </div>
                                </CopyToClipboard> : 
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                    marginLeft:'50px',
                                    fontSize:'11px',
                                    padding:'7px',
                                    borderRadius:'5px',
                                    backgroundColor:'rgba(140, 140, 140, 0.23)',
                                    cursor:'pointer'
                                }}>
                                    copiado
                                    <CheckCircleIcon sx={{fontSize:'15px',marginLeft:'10px'}} color='success' />
                                </div>
                                }
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                    marginLeft:'10px',
                                    fontSize:'11px',
                                    padding:'7px',
                                    borderRadius:'5px',
                                    backgroundColor:'rgba(52, 247, 0, 0.23)',
                                    cursor:'pointer'
                                }}
                                onClick={() => {
                                    setLoading(true)
                                    axios
                                    .post(`${api}/order/arrive/${order.idvenda}`,{},{
                                        headers: {
                                            Authorization: user.token
                                        }
                                    })
                                    .then(response => {
                                        setOrder({...order, idvendastatus: 6})
                                        toast.success('Obrigado')
                                    })
                                    .catch(err => toast.error(err.response?.data))
                                    .finally(() => setLoading(false))
                                }}
                                >
                                    Recebi meu pedido
                                </div>
                            </div>
                            </Typography>
                            <Typography sx={{
                                color:'text.primary',
                                marginTop:'20px'
                            }}>Se quiser, pode entrar em contato com o vendedor:</Typography>
                            <Button
                            onClick={() => window.open(`https://wa.me/55${(preferences.telefone1 || preferences.telefone2).replaceAll('(','').replaceAll(')','').replaceAll(' ','')}`)}
                            startIcon={<WhatsAppIcon />}
                            color='success'
                            sx={{margin:'15px 0x'}}
                            >Whatsapp</Button>
                            <Button
                            onClick={() => window.open(`tel:${(preferences.telefone1 || preferences.telefone2).replaceAll('(','').replaceAll(')','').replaceAll(' ','')}`)}
                            startIcon={<LocalPhoneIcon />}
                            color='success'
                            sx={{margin:'15px 0px 15px 10px'}}
                            >Ligar</Button>
                        </div>
                    </div>
                    }
                    </>
                    }

                    {order.delivery_code && (
                        order.idvendastatus === 12 || order.idvendastatus === 4 || order.idvendastatus === 3
                    ) &&
                    <>
                    <Typography
                    sx={{fontSize:'15px'}}
                    >O código de confirmação é</Typography>
                    <Typography
                    sx={{
                        fontWeight:'bold',
                        letterSpacing:'2px',
                        fontSize:'25px',
                        color:'red'
                    }}
                    >{order.delivery_code}</Typography>
                    </>
                    }
                    
                    <div
                    style={{
                        display:'flex',
                        alignItems:'center'
                    }}>
                        <Divider>
                            <Typography
                            sx={{
                                fontWeight:'bold',
                                width:'max-content',
                                marginRight:'10px'
                            }}>Pagamento {order.nmvendapagamentostatus}</Typography>
                        </Divider>
                    </div>
                    {payment.loading &&
                        <Skeleton
                        variant='rounded'
                        sx={{
                            width:'100%',
                            maxwidth:'1000px',
                            height:'300px'
                        }}
                        ></Skeleton>
                    }

                    <Button
                    startIcon={<ReceiptIcon />}
                    size='small'
                    sx={{ color: color.primary }}
                    onClick={() => setShowPayment(true)}
                    >
                        meus pagamentos
                    </Button>

                    <Status 
                    conector={order.conector}
                    order={order}
                    update={getData}
                    />

                    {order.idvendapagamentostatus === 6 && 
                    <Button
                    onClick={() => {
                        setCart(order.list?.map((e, index) => ({
                            ...e, 
                            adicionado: e.quantidade, 
                            index, 
                            pedido: e.quantidade,
                            vlrvenda: parseFloat(e.valorfinal)
                        })))
                        setReplaceOrder({
                            ...order,
                            replace_payment: true
                        })
                        navigate('/')
                    }}
                    color='success'
                    >
                        Alterar pagamento
                    </Button>
                    }
                    {
                    (
                        (order.idvendastatus && 
                        order.idvendastatus !== 5 && 
                        order.idvendastatus !== 6 &&
                        order.delivery_type !== 'frete') || 
                        (order.delivery_type === 'frete' && [3,6,11,12].indexOf(order.idvendastatus) === -1) 
                    ) &&
                    <Button 
                    sx={{
                        // paddingLeft:'0px'
                    }}
                    onClick={({currentTarget}) => setReasonsAnchor(currentTarget)}
                    variant='outlined'
                    size="small"
                    color='error'>Quero cancelar meu pedido</Button>}
                </div>

                <div
                style={{
                    margin:'20px 10px 10px 10px',
                    maxWidth:'1000px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        {loading ? <Skeleton variant="rounded" width={50} height={15} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>Subtotal</Typography>}
                        {loading ? <Skeleton variant="rounded" width={70} height={15} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>{parseFloat(order.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        {loading ? <Skeleton variant="rounded" width={50} height={15} sx={{marginTop:'5px'}} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>Desconto</Typography>}
                        {loading ? <Skeleton variant="rounded" width={70} height={15} sx={{marginTop:'5px'}} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>{(parseFloat(order.valor) - parseFloat(order.valorfinal)).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        opacity:0.7
                    }}>
                        {loading ? <Skeleton variant="rounded" width={110} height={15} sx={{marginTop:'5px'}} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>{order.delivery_type === 'frete' ? (order.entrega.frete?.frete) : 'Taxa de entrega'}</Typography>}
                        {loading ? <Skeleton variant="rounded" width={70} height={15} sx={{marginTop:'5px'}} /> :
                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>{(Number.isNaN(parseFloat(order.vlrfrete)) ? 0 : parseFloat(order.vlrfrete))
                        .toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'5px'
                    }}>
                        {loading ? <Skeleton variant="rounded" width={40} height={15} /> :
                        <Typography
                        sx={{
                            fontWeight:"bold"
                        }}>Total</Typography>}
                        {loading ? <Skeleton variant="rounded" width={70} height={15} /> :
                        <Typography
                        sx={{
                            fontWeight:"bold"
                        }}>{(parseFloat(order.valorfinal) + (Number.isNaN(parseFloat(order.vlrfrete)) ? 0 : parseFloat(order.vlrfrete))).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                    </div>
                    {order?.money_change &&
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'5px'
                    }}>
                        {loading ? <Skeleton variant="rounded" width={40} height={15} /> :
                        <Typography
                        sx={{
                            fontWeight:"bold"
                        }}>Troco</Typography>}
                        {loading ? <Skeleton variant="rounded" width={70} height={15} /> :
                        <Typography
                        sx={{
                            fontWeight:"bold"
                        }}>{(parseFloat(order?.money_change) - (parseFloat(order.valorfinal) + (Number.isNaN(parseFloat(order.vlrfrete)) ? 0 : parseFloat(order.vlrfrete)))).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}
                        <></>
                    </div>}
                </div>
                <Divider />
                <div
                style={{
                    margin:'20px 10px 20px 10px',
                    maxWidth:'1000px',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center'
                }}>
                    {loading ? 
                    <div>
                        <Skeleton variant="rounded" width={200} height={20} sx={{marginBottom:'5px'}} />
                        <Skeleton variant="rounded" width={200} height={20} />
                    </div> :
                    <div>
                        <Typography>{order?.apelido || `${order?.payment_method_type} ● ${order?.payment_method_id}`}</Typography>
                        {/* <Typography>{order?.payment_method_type}</Typography> */}
                        {( order?.money_change || order?.idconectorpagamento ) && 
                        <Typography>
                            {order?.money_change ? `Troco para ${parseFloat(order?.money_change).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}`: `${order?.installments || 1} Parcela (s)`}
                        </Typography>}
                    </div>}
                    {loading ? 
                    <Skeleton variant="rounded" width={90} height={20} /> :
                    <Typography>
                        &#9679; &#9679; &#9679; &#9679; {order.cardNumber?.substring(order.cardNumber.length - 4,order.cardNumber.length)}
                    </Typography>}
                </div>
                <Divider />
                {['frete', 'delivery'].indexOf(order.delivery_type) !== -1 && order.entrega?.formatted_address ?
                <div
                style={{
                    margin:'20px 10px 20px 10px',
                    maxWidth:'1000px'
                }}>
                    {loading ? <Skeleton variant="rounded" width={70} height={20} sx={{marginBottom:'10px'}} /> :
                    <Typography sx={{fontWeight:'bold',marginBottom:'10px'}}>Entregar em</Typography>}
                    {loading ? 
                    <Skeleton variant="rounded" width={330} height={20} /> :
                    <Typography>{order.entrega?.formatted_address}</Typography>}
                    {loading ? 
                    <Skeleton variant="rounded" width={200} height={20} sx={{marginTop:'5px'}} /> :
                    <Typography>{[order.entrega?.complemento?.completo, order.entrega?.numero?.completo, order.entrega?.referencia].filter(e => e).join(' / ')}</Typography>}
                    {loading ? 
                    <Skeleton variant="rounded" width={100} height={20} sx={{marginTop:'5px'}} /> :
                    <>
                    <Typography sx={{marginTop:'10px'}}>{order.entrega?.distance?.text}, {order.entrega?.duration?.text}</Typography>
                    <Typography sx={{fontSize:'12px'}}>Estimativa referente tempo do trânsito na hora da compra</Typography>
                    </>}
                    {order.delivery_type === 'frete' ? <Typography sx={{fontSize:'15px',marginTop:'10px', fontWeight:'bold'}}>Prazo {order.entrega?.frete?.frete}: {order.entrega?.frete?.frete === 'sedex' ? order.entrega?.frete?.prazosedex : order.entrega?.frete?.prazopac} dia(s)</Typography> : <></>}
                </div> : 
                <div
                style={{
                    margin:'20px 10px 20px 10px',
                    maxWidth:'1000px'
                }}>
                    {loading ? <Skeleton variant="rounded" width={70} height={20} sx={{marginBottom:'10px'}} /> :
                    <Typography sx={{fontWeight:'bold',marginBottom:'10px'}}>Retirar em</Typography>}
                    {loading ? 
                    <Skeleton variant="rounded" width={330} height={20} /> :
                    <Typography>{preferences.gmaps_info?.results[0]?.formatted_address || `${preferences.logradouro}, ${preferences.localidade}, ${preferences.nrologradouro} - ${preferences.bairro}, ${preferences.cep}`}</Typography>}
                    {/* {loading ? 
                    <Skeleton variant="rounded" width={200} height={20} sx={{marginTop:'5px'}} /> :
                    <Typography>{[order.entrega?.complemento, order.entrega?.numero, order.entrega?.referencia].filter(e => e).join(' / ')}</Typography>} */}
                    {loading ? 
                    <div
                    style={{
                        display:'flex'
                    }}
                    >
                        {/* <Button
                        startIcon={<MapIcon/>}
                        onClick={() => {
                            if (state.idvendastatus === 5)
                            window.open(`https://www.google.com/maps/dir/?api=1&origin=${coords.coords?.latitude}%2C${coords.coords?.longitude}&destination=${state.entrega?.address?.origin_addresses[0]}&travelmode=driving`)
                            else
                            window.open(`https://www.google.com/maps/dir/?api=1&origin=${state.entrega?.address?.origin_addresses[0]}&destination=${state.entrega?.address?.destination_addresses[0]}&travelmode=driving`)
                        }}
                        sx={{
                            marginRight:'10px'
                        }}
                        >abrir maps</Button>
                        <Button
                        startIcon={<NavigationIcon/>}
                        onClick={() => {
                            if (state.idvendastatus === 5)
                            window.open(`https://www.waze.com/ul?q=${state.entrega?.address?.origin_addresses[0]}`)
                            else
                            window.open(`https://www.waze.com/ul?q=${state.entrega?.address?.destination_addresses[0]}`)
                        }}
                        sx={{ }}
                        >abrir waze</Button> */}
                    </div>
                    :
                    <div
                    style={{
                        display:'flex',
                        marginTop:'10px'
                    }}
                    >
                        <Button
                        startIcon={<MapIcon/>}
                        onClick={() => {
                            window.open(`https://www.google.com/maps/dir/?api=1&origin=${coords?.latitude}%2C${coords?.longitude}&destination=${preferences.gmaps_info?.results[0]?.formatted_address || `${preferences.logradouro}, ${preferences.localidade}, ${preferences.nrologradouro} - ${preferences.bairro}, ${preferences.cep}`}&travelmode=driving`)
                        }}
                        sx={{
                            marginRight:'10px',
                            backgroundColor: color.fontPrimary,
                            color: color.background
                        }}
                        variant='contained'
                        size="small"
                        >abrir maps</Button>
                        <Button
                        startIcon={<NavigationIcon/>}
                        onClick={() => {
                            window.open(`https://www.waze.com/ul?q=${preferences.gmaps_info?.results[0]?.formatted_address || `${preferences.logradouro}, ${preferences.localidade}, ${preferences.nrologradouro} - ${preferences.bairro}, ${preferences.cep}`}`)
                        }}
                        sx={{
                            backgroundColor: color.fontPrimary,
                            color: color.background
                        }}
                        variant='contained'
                        size="small"
                        >abrir waze</Button>
                    </div>
                    }
                </div>}
                <Divider />
                {order.idvendastatus === 9 && 
                <div
                style={{
                    width:'calc(100% - 20px)',
                    margin:'10px',
                    maxWidth:'1000px'
                }}>
                    <div
                    style={{
                        border: `1px solid ${theme.palette.mode === 'light' ? '#000' : '#fff'}`,
                        borderRadius: '10px',
                        padding:'10px 30px'
                    }}>
                        <Typography sx={{
                            fontSize:'20px',
                            fontWeight:'bold'
                        }}>Alteração Necessária</Typography>

                        <Typography
                        sx={{
                            fontSize:'15px'
                        }}>
                            {!order?.auto_reimbursement && order.idconectorpagamento ? `
                            Alguns ítens do seu pedido estavam em falta e você pode alterá-los em até 20 minutos, se nada for feito nesse tempo
                            os itens faltantes serão removidos e os respectivos valores serão devolvidos. Como você não utilizou uma forma de pagamento que permita estorno automático,
                            se necessário o reembolso, o operador da loja estará autorizado a entrar em contato através do seu número. 
                            Você poderá aguardar, ou entrar em contato com a loja no final do processo para solicitar o reembolso.
                            ` : `
                            Alguns ítens do seu pedido estavam em falta e você pode alterá-los em até 20 minutos, se nada for feito nesse tempo
                            os itens faltantes serão removidos e os respectivos valores estornados ao seu cartão. (O valor da diferença na alteração do pedido também será estornado).
                            `}
                        </Typography>

                        {!order?.auto_reimbursement && !user.phone_altered && order.idconectorpagamento &&
                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            flexWrap:'wrap',
                            margin:'20px 0px'
                        }}
                        >
                            <InputMask 
                            mask="(99) 9999999999" 
                            maskChar={null}
                            value={cadaster.telefone1}
                            onChange={({target}) => setCadaster({...cadaster, telefone1:target.value})}
                            >
                                {(inputProps) => <TextField 
                                                {...inputProps}
                                                InputLabelProps={{shrink:cadaster.telefone1?true:false}}
                                                label='Tel 1 (WhatsApp)'
                                                onKeyDown={(event) => {
                                                }}
                                                sx={{
                                                    width:size.width <= 400 ? '100%' : '200px'
                                                }}
                                                helperText='(021) 999999999'
                                                />}
                            </InputMask>

                            <InputMask 
                            mask="(99) 9999999999" 
                            maskChar={null}
                            value={cadaster.telefone2}
                            onChange={({target}) => setCadaster({...cadaster, telefone2:target.value})}
                            >
                                {(inputProps) => <TextField 
                                                {...inputProps}
                                                InputLabelProps={{shrink:cadaster.telefone2?true:false}}
                                                label='Tel 2 (WhatsApp)'
                                                onKeyDown={(event) => {
                                                }}
                                                sx={{
                                                    width:size.width <= 400 ? '100%' : '200px'
                                                }}
                                                helperText='(021) 999999999'
                                                />}
                            </InputMask>

                            <Button
                            color='success'
                            sx={{
                                width:size.width <= 400 ? '100%' : '200px',
                                marginTop:'10px'
                            }}
                            variant='outlined'
                            onClick={() => {
                                if (!cadaster.telefone1 && !cadaster.telefone2) return toast.error('Informe ao menos 1 número')
                                setLoading(true)
                                axios
                                .put(`${api}/client`,cadaster,{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(response => {
                                    toast.success('Obrigado')
                                    setUser({...user, phone_altered: true})
                                })
                                .catch(err => toast.error(err.response?.data))
                                .finally(() => setLoading(false))
                            }}
                            >
                                confirmar alteração
                            </Button>
                        </div>
                        }
                        <Button 
                        onClick={() => {
                            if (
                                ['Cartao de Credito', 'Cartao de Debito'].indexOf(order?.payment_method_type) === -1 && 
                                !user.phone_altered &&
                                !cadaster.telefone1 && 
                                !cadaster.telefone2
                            ) return toast.error('Informe ao menos 1 número antes')

                            setCart(order.separacao.list?.map((e, index) => ({
                                ...e, 
                                adicionado: e.contado ? parseInt(e.contado) : 0, 
                                contado: e.contado || 0, 
                                index, 
                                separado: true,
                                pedido: e.quantidade
                            })))

                            setReplaceOrder(order)
                            navigate('/')
                        }}
                        variant='outlined' color='error' sx={{marginTop:'10px'}}>alterar pedido</Button>
                    </div>
                </div>}
                <div
                style={{
                    width:'100%',
                    display:'flex',
                    flexWrap:'wrap'
                }}>
                    {loading ? 
                    <div
                    style={{
                        display:'flex',
                        margin:'10px'
                    }}>
                        <Skeleton variant="rounded" width={80} height={80} />
                        <div>
                            <Skeleton variant="rounded" width={200} height={20} sx={{margin:'5px'}} />
                            <Skeleton variant="rounded" width={90} height={20} sx={{margin:'5px'}} />
                            <Skeleton variant="rounded" width={110} height={17} sx={{margin:'5px'}} />
                        </div>
                    </div> :
                    order
                    .list?.filter(({quantidade}) => quantidade)?.map(e => (
                        <div
                        style={{
                            margin:'10px',
                            display:'flex',
                            opacity: e.quantidadecontada!==null && e.quantidadecontada === 0 ? 0.4 : 1,
                            maxWidth: size.width <= 800 ? '100%' : '400px',
                            width:'100%'
                        }}>
                            <div
                            style={{
                                position:"relative"
                            }}>
                                <div>
                                    <div
                                    style={{
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        position:'absolute'
                                    }}>
                                        {tag(e) &&
                                        <Typography 
                                        sx={{
                                            backgroundColor:theme.palette.mode==='dark'?'#fff':'#000',
                                            color:theme.palette.mode==='dark'?'#000':'#fff',
                                            fontWeight:'bold',
                                            fontSize:'12px',
                                            borderRadius:'5px',
                                            padding:'6px',
                                            margin:'5px 0px 0px 5px',
                                            boxShadow: `0px 0px 11px 1px rgba(0,0,0,0.5)`
                                        }}>
                                            {tag(e)}
                                        </Typography>}
                                    </div>
                                    <img 
                                    className='product_card'
                                    style={{
                                        objectFit:'cover',
                                        width:'80px',
                                        height:'80px',
                                        top:0,
                                        left:0,
                                        borderRadius:'5px',
                                        backgroundColor:'#fff',
                                        opacity: 1,
                                        transition: 'opacity 0.1s linear'
                                    }} 
                                    src={`https://${storecode}.allon.cloud/image/main/${e.ean}?internal=${e.imageminterna ? 'true' : 'false'}&idproduto=${e.idproduto || e.idprodutopai}`} />
                                </div>
                            </div>
                            <div
                            style={{
                                marginLeft:'10px'
                            }}>
                                <Typography 
                                sx={{
                                    fontWeight:'bold',
                                    fontSize:'13px'
                                }}>{e.nome}</Typography>
                                <Typography
                                sx={{
                                    fontSize:'11px',
                                    opacity:0.8
                                }}>{e.variacao}</Typography>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:"center"
                                }}>
                                    {tag(e) && 
                                    <Typography 
                                    sx={{
                                        textDecoration:'line-through',
                                        marginRight:'10px',
                                        fontSize:'13px'
                                    }}>{parseFloat(e.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}

                                    <Typography
                                    sx={{
                                        fontWeight:"bold",
                                        fontSize:'14px'
                                    }}>{parseFloat(e.valorfinal).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})} {e.variavel ? ` | ${e.incrementovariavel} ${e.unidmedida}` : ''}</Typography>
                                </div>
                                <div
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap'
                                }}>
                                <Typography
                                sx={{
                                    fontSize:'13px',
                                    textDecoration: e.quantidadecontada!==null && e.quantidadecontada !== e.quantidade && 'line-through',
                                    marginRight: e.quantidadecontada!==null && e.quantidadecontada !== e.quantidade && '10px'
                                }}>Pedido: {e.quantidade} {e.variavel ? e.unidmedida : ''}</Typography>
                                {e.quantidadecontada!==null && e.quantidadecontada!==e.quantidade && <Typography
                                sx={{
                                    fontSize:'13px',
                                    fontWeight:'bold'
                                }}>{e.quantidadecontada === 0 ? 'REMOVIDO' : `Contado: ${e.quantidadecontada} ${e.variavel ? e.unidmedida : ''}`}</Typography>}
                                </div>
                                <div
                                style={{
                                    display:'flex',
                                    alignItems:"center"
                                }}>
                                    {tag(e) && 
                                    <Typography 
                                    sx={{
                                        textDecoration:'line-through',
                                        marginRight:'10px',
                                        fontSize:'13px'
                                    }}>{parseFloat(e.valor).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>}

                                    <Typography
                                    sx={{
                                        fontWeight:"bold",
                                        fontSize:'14px',
                                        color: color.fontPrimary
                                    }}>{e.quantidadecontada!==null && e.quantidadecontada!==e.quantidade ? 
                                        parseFloat(e.variavel ? (e.valorfinal/(e.incrementovariavel || 1))* e.quantidadecontada : e.valorfinal * e.quantidadecontada).toLocaleString('pt-BR', {currency:'BRL', style:'currency'}) :
                                        parseFloat(e.variavel ? (e.valorfinal/(e.incrementovariavel || 1))* e.quantidade : e.valorfinal * e.quantidade).toLocaleString('pt-BR', {currency:'BRL', style:'currency'})}</Typography>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                style={{
                    width:'100%',
                    height:'20px'
                }}>

                </div>
            </div>
        </Box>
    )
}