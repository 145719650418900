import { useState, useContext, useRef, useEffect, useMemo } from 'react'

import {
    Box,
    IconButton,
    InputBase,
    Button,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Tooltip,
    ButtonBase,
    Typography,
    Avatar,
    ListSubheader,
    ListItemIcon,
    Collapse,
    Autocomplete,
    Grid,
    TextField,
    Popper,
    ClickAwayListener
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTheme, styled, alpha } from '@mui/material/styles'

import {
    ColorModeContext,
    BlurContext,
    PreferencesContext,
    SizeContext,
    LoginContext,
    UserContext,
    FilterContext,
    StoreContext,
    MenuContext,
    AnalyticsContext
} from '../../context'

import getColors from '../getColor'

import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WalletIcon from '@mui/icons-material/Wallet';
import LogoutIcon from '@mui/icons-material/Logout';
import DownloadIcon from '@mui/icons-material/Download';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReplayIcon from '@mui/icons-material/Replay';
import HomeIcon from '@mui/icons-material/Home';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import isAuth from '../../utils/isauth';
import Cart from '../sites_models/products/cart'

import { 
    useLocation, 
    useNavigate, 
    useParams 
} from 'react-router-dom';

import { debounce } from '@mui/material/utils';
import axios from 'axios';
import { api } from '../../utils/defaults';
import { toast } from 'react-toastify';

import { useReactPWAInstall } from "react-pwa-install";
import ScanProduct from '../sites_models/products/product/scan';
import sendAction from '../../utils/sendAction';
import useGeolocation from '../../utils/useGeolocation.ts';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.15),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3)
    },
    color: 'text.primary'//color.fontPrimary
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'text.primary',
    width:'100%',
    '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    //transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '100%',
    },
},
}));

// const autocompleteService = { current: null };

export default function Index (props) {
    const [blur, setBlur] = useContext(BlurContext)
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [store, setStore] = useContext(StoreContext)
    const [user, setUser] = useContext(UserContext)
    const [filter, setFilter] = useContext(FilterContext)
    const [inputValue, setInputValue] = useState('')
    const [category, setCategory] = useState([])
    const [options, setOptions] = useState([])
    const [value, setValue] = useState({})
    const [deferredPrompt, setDeferredPrompt] = useState(null)
    const [menu, setMenu] = useState(null);
    const [userAnchor, setUserAnchor] = useState(null)
    const [completeAnchor, setCompleteAnchor] = useState(null)
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const color = getColors(preferences, theme)
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const loginRef = useContext(LoginContext)
      
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color.fontPrimary
    }));

    const avatarClick = (event) => setUserAnchor(event.currentTarget)

    const wallet = () => { }

    const installApp = () => {
        pwaInstall({
        title: `Install ${preferences.nome}`,
        // logo: `https://${storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`,
        // features: (
        //     <ul>
        //     <li>Cool feature 1</li>
        //     <li>Cool feature 2</li>
        //     <li>Even cooler feature</li>
        //     <li>Works offline</li>
        //     </ul>
        // ),
        // description: "This is a very good app that does a lot of useful stuff. ",
        })
        .then(() => alert("Instrução ou download concluído"))
        .catch(() => toast.error("Cancelado"));
        // deferredPrompt.prompt();
        // deferredPrompt.userChoice.then((choiceResult) => {
        //     if (choiceResult.outcome === 'accepted') {
        //         console.log('User accepted the prompt');
                
        //     } else {
        //         console.log('User dismissed the prompt');
        //     }
        //     // deferredPrompt = null;
        // });

        // window.addEventListener('appinstalled', (event) => {
        //     toast.success('aplicação instalada com sucesso')
        // });
    }

    // const handlers = useSwipeable({
    //     onSwiped: (eventData) => console.log("User Swiped!", eventData),
    //     onSwipedRight: (eventData) => setMenu(true)
    // });

    const menuRef = useRef()

    const fetch = useMemo(
        () =>
          debounce((request, callback) => {
            // axios
            // .get(`${api}/product/search/${request.input}${request.idlojaecommerceacao ? `?idlojaecommerceacao=${request.idlojaecommerceacao}`:
            // (request.nivelcategoria ? `?nivelcategoria=${request.nivelcategoria}&idprodutocategoria=${request.idprodutocategoria}` : ``)
            // }`)
            // .then(response => callback(response.data))
            // .catch(err => {
            //     callback([])
            // })
            axios
            .get(`${api}/product/search/${request.input}`)
            .then(response => callback(response.data))
            .catch(err => {
                callback([])
            })
          }, 400),
        [],
    );
    
    useEffect(() => {
        let active = true;
    
        if (inputValue === '') {
          setOptions(value ? [value] : []);
          return undefined;
        }
    
        fetch({ input: inputValue, ...params, ...location.state }, (results) => {
          if (active) {
            let newOptions = [];
    
            if (value) {
              newOptions = [value];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            setOptions(newOptions);
          }
        });
    
        return () => {
          active = false;
        };
    }, [
        value, 
        inputValue, 
        fetch
    ]);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => setDeferredPrompt(e));

        return () => {
            window.removeEventListener('resize', () => setSize({width:window.innerWidth,height:window.innerHeight}))

            window.removeEventListener('beforeinstallprompt', (e) => setDeferredPrompt(e));
        }
    },[preferences.storecode])

    useEffect(() => {
        if (
            (location.state?.idprodutocategoria && location.state?.nivelcategoria===1) || 
            (location.state?.prev?.idprodutocategoria && location.state?.prev?.nivelcategoria===1)
        ) {
            axios
            .get(`${api}/category/n2/${(location.state?.prev?.idprodutocategoria || location.state?.idprodutocategoria)}`)
            .then(response => setCategory(response.data))
            .catch(err => console.log(err))
        } else {
            axios
            .get(`${api}/category/2`)
            .then(response => setCategory(response.data))
            .catch(err => console.log(err))
        }
    },[
        location.state?.idprodutocategoria,
        window.location.hostname.split('.')[0],
        preferences.storecode,
        preferences.storecode_matriz,
        preferences.codigo
    ])

    const storecode = preferences.storecode || preferences.storecode_matriz || window.location.hostname.split('.')[0];

    const handleKeydown = (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            setFilter({...filter, nome: inputValue})
            setCompleteAnchor(null)
        }
    };

    const handleTextSubmit = (event) => {
        setFilter({...filter, nome: inputValue})
        setCompleteAnchor(null)
    };

    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    const CancelToken = axios.CancelToken;
    let cancel;

    useEffect(() => {
        if (!filter.nome) return 
        
        navigate('/product')
        sendAction({
            unique_id: analytics.unique_id,
            action_id: 'product',
            detail_action_id: 'search',
            complete_detail: filter.nome,
            idproduto: null,
            idvariacaoproduto: null,
            idprodutocategoria: null,
            nivelcategoria: null,
            token: user.token,
            lat: geolocation?.coords?.latitude,
            lng: geolocation?.coords?.longitude
        },
        (c) => (cancel = c), 
        cancel)
    },[
        filter.nome
    ])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const scrollThreshold = menuRef.current?.clientHeight || 30; // Defina o valor de X como o limite de rolagem desejado
            const scrollDelta = prevScrollPos - currentScrollPos;
            const sensitivity = 10;
        
            
            if (scrollDelta * (scrollDelta > 0 ? 1 : -1) >= sensitivity) {
                if (prevScrollPos > currentScrollPos || currentScrollPos <= scrollThreshold) {
                    setIsMenuVisible(true); // Rolar para cima ou rolagem menor/igual a X, mostra o menu
                } else {
                    setIsMenuVisible(false); // Rolar para baixo, esconde o menu
                }
            }
      
          setPrevScrollPos(currentScrollPos);
        };
      
        window.addEventListener('scroll', handleScroll);
      
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [
        prevScrollPos,
        menuRef.current
    ]);

    const logout = () => { setUserAnchor(null); setUser({}); }
    const scanProductRef = useRef()
    const geolocation = useGeolocation()
    
    return (
        <Box 
        className='main'
        sx={{
            // alignItems: 'center',
            // justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            // position:'fixed',
            top:0,
            left:0,
            // width:'100vw',
            // height:'100vh',
            // maxHeight:'100vh',
            // filter: blur && 'blur(2px)',
            padding: 0,
            minHeight:'100vh'
          }}
        // {...handlers}
        >
            <Cart />
            <ScanProduct ref={scanProductRef} />
            
            <Popover
            open={menu!==null}
            anchorEl={menu}
            onClose={() => setMenu(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
                <div
                style={{
                    // minWidth:'350px'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'right'
                    }}
                    >
                        <Button
                        sx={{color:color.fontPrimary,marginRight:'10px'}}
                        endIcon={<ReplayIcon />}
                        size='small'
                        onClick={() => window.location.reload()}
                        >Atualizar</Button>
                    </div>
                    {preferences.usa_pagina_centralizada &&
                    <div
                    style={{
                        padding:'10px'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            justifyContent:"space-between"
                        }}
                        >
                            <Typography sx={{color:'text.primary', fontSize:'15px'}}>{store.selected?.nome}</Typography>
                            <Typography sx={{color:'text.secondary', fontSize:'13px'}}>{store.selected?.distancia?.toFixed(2)} Km</Typography>
                        </div>
                        <Typography
                        noWrap={false}
                        sx={{
                            color:'text.secondary', 
                            fontSize:'13px',
                            flexWrap:'wrap'
                        }}
                        >{store.selected?.gmaps_info?.results[0]?.formatted_address}</Typography>
                    </div>}
                    {preferences.usa_pagina_centralizada &&
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <ButtonBase
                        onClick={() => setStore({...store, open: true})}
                        sx={{
                            width:'40%',
                            margin:'10px 0px',
                            padding:'10px 0px'
                        }}
                        >
                            <StorefrontIcon
                            sx={{
                                marginRight:'10px',
                                color: color.fontPrimary
                            }}
                            />
                            <Typography>Mudar Loja</Typography>
                        </ButtonBase>
                        <ButtonBase
                        onClick={() => setPreferences({...preferences, open_location: true})}
                        sx={{
                            width:'60%',
                            margin:'10px 0px'
                        }}>
                            <LocationOnIcon 
                            sx={{
                                marginRight:'10px',
                                color: color.fontPrimary
                            }}
                            />
                            <Typography>Alterar endereço</Typography>
                        </ButtonBase>
                    </div>}
                    <div 
                    style={{
                        display:'flex',
                        margin:'5px',
                        maxHeight:'300px',
                        overflow:'hidden',
                        maxWidth:`${size.width - 20}px`,
                        backgroundColor: color.secondary,
                        borderRadius: '5px',
                        justifyContent:'space-between'
                    }}>
                        <div 
                        style={{
                            margin:'5px',
                            overflowY:'auto',
                            width:'100%'
                        }}>
                            <Typography 
                            sx={{
                                fontSize:'18px', 
                                color:color.fontSecondary,
                                marginLeft: '10px'
                            }}>Categorias</Typography>
                            <List
                            sx={{
                                // maxHeight:'400px',
                                // overflowY:'auto'
                            }}
                            >
                                {category?.map(e => (
                                    <ListItem 
                                    onClick={() => {
                                        navigate('/product',{
                                            state: {
                                                nivelcategoria: 2,
                                                idprodutocategoria: e.idprodutocategorian2,
                                                prev: location.state?.nivelcategoria ? {
                                                    nivelcategoria: location.state?.nivelcategoria,
                                                    idprodutocategoria: location.state?.idprodutocategoria
                                                } : undefined
                                            }
                                        })
                                        setMenu(null)
                                        sendAction({
                                            unique_id: analytics.unique_id,
                                            action_id: 'product',
                                            detail_action_id: 'category',
                                            complete_detail: `${e.categorian2}`,
                                            idproduto: null,
                                            idvariacaoproduto: null,
                                            idprodutocategoria: e.idprodutocategorian2,
                                            nivelcategoria: 2,
                                            token: user.token,
                                            lat: geolocation?.coords?.latitude,
                                            lng: geolocation?.coords?.longitude
                                        },
                                        (c) => (cancel = c), 
                                        cancel)
                                    }}
                                    disablePadding>
                                        <ListItemButton>
                                        <ListItemText sx={{
                                            color:color.fontSecondary
                                        }} primary={
                                            <Typography sx={{fontSize:'12px'}}>{e.categorian2}</Typography>
                                        } />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <div
                        style={{
                            display:'flex'
                        }}
                        >
                        <Divider orientation="vertical" flexItem />
                        <div 
                        style={{
                            margin:'5px',
                            display:'flex',
                            flexDirection:'column',
                            width:'fit-content'
                        }}>
                            <IconButton 
                            onClick={() => {
                                setFilter({...filter, nome: ''})
                                setInputValue('')
                                navigate('/')
                            }}
                            sx={{color:color.fontSecondary}}>
                                <HomeIcon />
                            </IconButton>

                            <Tooltip title={`Modo ${theme.palette.mode==='dark'?'claro':'escuro'}`}>
                            <IconButton onClick={colorMode.toggleColorMode} sx={{color:color.fontSecondary}}>
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                            </Tooltip>

                            <IconButton 
                            onClick={() => scanProductRef.current?.open()}
                            sx={{color:color.fontSecondary}}>
                                <DocumentScannerIcon />
                            </IconButton>
                        </div>
                        </div>
                    </div>
                    </div>
            </Popover>

            <Popover
            open={userAnchor!==null}
            anchorEl={userAnchor}
            onClose={() => setUserAnchor(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: color.secondary}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        // <ListSubheader component="div" id="nested-list-subheader">
                        // <Typography sx={{
                        //     padding:'5px 0px',
                        //     textAlign:'center'
                        // }}>{user.decodedToken ? user.decodedToken.name : user.nome}</Typography>
                        // </ListSubheader>
                        <ListItemButton onClick={() => navigate('/account')}>
                            <ListItemIcon>
                                <Avatar 
                                sx={{
                                    width:'25px',
                                    height:'25px'
                                }}
                                alt={user.decodedToken ? user.decodedToken?.name : user.nome} src={user.decodedToken?.picture} />
                            </ListItemIcon>
                            <ListItemText sx={{}} primary={user.decodedToken ? user.decodedToken?.name : user.nome} />
                        </ListItemButton>
                    }
                    >
                    <ListItemButton onClick={() => navigate('/account')}>
                        <ListItemIcon>
                            <ManageAccountsIcon sx={{color:color.fontSecondary}}/>
                        </ListItemIcon>
                        <ListItemText sx={{color:color.fontSecondary}} primary="Minha conta" />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate('/order')}>
                        <ListItemIcon>
                            <ShoppingCartIcon sx={{color:color.fontSecondary}}/>
                        </ListItemIcon>
                        <ListItemText sx={{color:color.fontSecondary}} primary="Minhas compras" />
                    </ListItemButton>
                    <ListItemButton onClick={wallet}>
                        <ListItemIcon>
                            <WalletIcon sx={{color:color.fontSecondary}}/>
                        </ListItemIcon>
                        <ListItemText sx={{color:color.fontSecondary}} primary="Minha carteira (breve)" />
                    </ListItemButton>
                    {supported() && !isInstalled() &&
                    (<ListItemButton onClick={installApp}>
                        <ListItemIcon>
                            <DownloadIcon sx={{color:color.fontSecondary}}/>
                        </ListItemIcon>
                        <ListItemText sx={{color:color.fontSecondary}} primary="Instalar App" />
                    </ListItemButton>)}
                    <ListItemButton onClick={() => logout() }>
                        <ListItemIcon>
                            <LogoutIcon sx={{color:color.fontSecondary}}/>
                        </ListItemIcon>
                        <ListItemText sx={{color:color.fontSecondary}} primary="Sair" />
                    </ListItemButton>
                    </List>
            </Popover>

            <Box 
            ref={menuRef}
            sx={{
                color: color.fontPrimary,
                width:'100%',
                backgroundColor: color.primary,
                borderRadius: '0px',
                position: 'fixed',
                top: '-100px',
                left: 0,
                zIndex: 999,
                transition: 'all 0.3s ease',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                height: '100px'
            }} >
            </Box>

            <Box 
            ref={menuRef}
            sx={{
                color: color.fontPrimary,
                width:'100%',
                backgroundColor: color.primary,
                borderRadius: '0px',
                position: 'fixed',
                top: isMenuVisible ? 0 : -menuRef.current?.clientHeight,
                left: 0,
                zIndex: 999,
                transition: 'all 0.3s ease',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px'
            }} >
                {size.width<=600 && 
                <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                    height:'55px',
                    padding:'7px 0px 0px 0px'
                }}>
                    {!preferences.aberta &&
                    <div
                    style={{
                        padding:'5px',
                        borderRadius:'3px',
                        position:'fixed',
                        top:'3px',
                        left:'3px',
                        color:'black',
                        fontSize:'12px',
                        display:'flex',
                        alignItems:'center',
                        zIndex:99,
                        cursor: 'pointer'
                    }}
                    onClick={() => setPreferences({...preferences, closed_message: true})}
                    >
                        <div
                        style={{
                            width:'10px',
                            height:'10px',
                            borderRadius:'10px',
                            backgroundColor:'red'
                        }}></div>
                        <Typography
                        sx={{
                            fontSize:'12px',
                            marginLeft:'5px',
                            color:'text.primary'
                        }}
                        >Loja fechada</Typography>
                    </div>
                    }
                    <ButtonBase
                    onClick={() => {
                        setFilter({...filter, nome: ''})
                        setInputValue('')
                        navigate('/')
                    }}
                    sx={{
                        display:'flex',
                        flexDirection:"column",
                        justifyContent:'center',
                        alignItems:'center',
                        height:'inherit'
                    }}
                    >
                        <img style={{ 
                            // margin:'10px 0px 10px 0px',
                            maxHeight:'50px',
                            objectFit:'contain',
                            width:'180px'
                        }} src={`https://${storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`}></img>
                    </ButtonBase>
                </div>}

                {size.width<=600 && 
                <div style={{
                    display:'flex',
                    justifyContent:"space-between",
                    alignItems:'center',
                    height:'50px'
                }}>
                    
                    {!isAuth(user)?// não esta autenticado
                        <Button onClick={() => loginRef.current?.open()} sx={{margin:'0px 20px 0px 20px'}} color="inherit">
                            <PersonOutlineIcon sx={{marginRight:1}} />Login
                        </Button>:
                        <IconButton 
                        sx={{
                            width: '36.5px',
                            height: '36.5px',
                            margin:'0px 10px',
                        }}
                        onClick={avatarClick} size="large">
                            {user.decodedToken ? <Avatar alt={user.decodedToken.name} src={user.decodedToken.picture} /> : <AccountCircle sx={{fontSize:30}} size='large' />}
                        </IconButton>
                    }

                    <ClickAwayListener
                    onClickAway={() => {
                        setCompleteAnchor(null)
                    }}
                    >
                    <Search
                    style={{
                        width:'100%'
                    }}
                    >
                        <SearchIconWrapper
                        > <SearchIcon /> </SearchIconWrapper>

                        <StyledInputBase
                        placeholder="Buscar produto…"
                        inputProps={{ 
                            'aria-label': 'search',
                            style: { width:'100%' }
                        }}
                        style={{ width:'100%' }}
                        value={inputValue}
                        onChange={({target}) => setInputValue(target.value)}
                        onFocus={({currentTarget}) => setCompleteAnchor(currentTarget)}
                        // onKeyDown={({keyCode}) => {
                        //     if (keyCode===13) {
                        //         setFilter({...filter, nome: inputValue})
                        //         setCompleteAnchor(null)
                        //     }
                        // }}
                        onSubmitEditing={handleTextSubmit}
                        onKeyDown={handleKeydown}
                        />

                        <Popper 
                        open={completeAnchor!==null} 
                        anchorEl={completeAnchor}
                        sx={{
                            width:'78%'
                        }}
                        >
                        <Box 
                        sx={{ 
                            p: 1, 
                            bgcolor: 'background.paper',
                            borderRadius:'5px',
                            color: 'text.primary'
                        }}>
                            {options.filter(({nome}) => nome).length ? 
                            options.filter(({nome}) => nome)?.map((e,index) => (
                                <div
                                style={{
                                    padding:'5px 10px',
                                    cursor:'pointer'
                                }}
                                onClick={() => {
                                    setInputValue(e.nome)
                                    setFilter({...filter, nome: e.nome})
                                    setCompleteAnchor(null)
                                }}
                                >
                                    {index!==0 && <Divider sx={{marginBottom:'5px'}} />}
                                    <Typography
                                    sx={{
                                        color:'text.secondary',
                                        border:'hidden'
                                    }}
                                    >{e.nome}</Typography>
                                </div>
                            )) : 
                            <div
                            style={{
                                padding:'5px 10px'
                            }}
                            >
                                <Typography
                                sx={{
                                    color:'text.secondary',
                                    border:'hidden'
                                }}
                                >Sem resultados...</Typography>
                            </div>}
                        </Box>
                        </Popper>
                        
                    </Search>
                    </ClickAwayListener>
                    
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={({currentTarget}) => setMenu(currentTarget)}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>}

                {size.width>600 && 
                <div style={{
                    display:'flex',
                    justifyContent:"space-between",
                    alignItems:'center',
                    height:'56px'
                }}>
                    <div style={{
                        display:'flex',
                        justifyContent:'left',
                        alignItems:'center',
                        margin:'10px 0px 10px 0px',
                        width:'50%',
                        marginLeft:'50px'
                    }}>
                        <ButtonBase
                        onClick={() => {
                            setFilter({...filter, nome: ''})
                            setInputValue('')
                            navigate('/')
                        }}
                        ><img 
                        style={{ 
                            margin:'10px 0px 10px 0px',
                            maxHeight:'40px',
                            objectFit:'contain',
                            width:'180px'
                        }}
                        src={`https://${storecode}.allon.cloud/logo?theme=${theme.palette.mode==='light'?'dark':'light'}`}></img></ButtonBase>
                        {!isAuth(user) ? // nao esta autenticado
                            <Button onClick={() => loginRef.current?.open()} sx={{margin:'0px 20px 0px 20px'}} color="inherit">
                                <PersonOutlineIcon sx={{marginRight:1}} />Login
                            </Button>:
                            <IconButton 
                            sx={{
                                width: '36.5px',
                                height: '36.5px',
                                margin:'0px',
                                marginLeft:'10px'
                            }}
                            onClick={avatarClick} size="large">
                                {user.decodedToken ? 
                                <Avatar 
                                alt={user.decodedToken.name} 
                                src={user.decodedToken.picture} 
                                /> : 
                                <AccountCircle sx={{fontSize:40}} size='large' />
                                }
                            </IconButton>
                        }
                        {!preferences.aberta &&
                        <div
                        style={{
                            padding:'5px',
                            borderRadius:'3px',
                            color:'black',
                            fontSize:'12px',
                            display:'flex',
                            alignItems:'center',
                            zIndex:99,
                            cursor:'pointer'
                        }}
                        onClick={() => setPreferences({...preferences, closed_message: true})}>
                            <div
                            style={{
                                width:'10px',
                                height:'10px',
                                borderRadius:'10px',
                                backgroundColor:'red'
                            }}></div>
                            <Typography
                            sx={{
                                fontSize:'12px',
                                marginLeft:'5px',
                                color:'text.primary'
                            }}
                            >Loja fechada</Typography>
                        </div>
                        }
                    </div>
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'50%',
                        marginRight:'50px'
                    }}>
                        <ClickAwayListener
                        onClickAway={() => {
                            setCompleteAnchor(null)
                        }}
                        >
                        <Search
                        style={{
                            width:'100%'
                        }}
                        >
                            <SearchIconWrapper > <SearchIcon /> </SearchIconWrapper>

                            <StyledInputBase
                            placeholder="Buscar produto…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={inputValue}
                            onChange={({target}) => setInputValue(target.value)}
                            onFocus={({currentTarget}) => setCompleteAnchor(currentTarget)}
                            // onKeyDown={({keyCode}) => {
                            //     if (keyCode===13) {
                            //         setFilter({...filter, nome: inputValue})
                            //         setCompleteAnchor(null)
                            //     }
                            // }}
                            onSubmitEditing={handleTextSubmit}
                            onKeyDown={handleKeydown}
                            />

                            <Popper 
                            open={completeAnchor!==null} 
                            anchorEl={completeAnchor}
                            sx={{
                                width:'39%'
                            }}
                            >
                            <Box 
                            sx={{ 
                                p: 1, 
                                bgcolor: 'background.paper',
                                borderRadius:'5px',
                                color: 'text.primary'
                            }}>
                                {options.filter(({nome}) => nome).length ? 
                                options.filter(({nome}) => nome)?.map((e,index) => (
                                    <div
                                    style={{
                                        padding:'5px 10px',
                                        cursor:'pointer'
                                    }}
                                    onClick={() => {
                                        setInputValue(e.nome)
                                        setFilter({...filter, nome: e.nome})
                                        setCompleteAnchor(null)
                                    }}
                                    >
                                        {index!==0 && <Divider sx={{marginBottom:'5px'}} />}
                                        <Typography
                                        sx={{
                                            color:'text.secondary',
                                            border:'hidden'
                                        }}
                                        >{e.nome}</Typography>
                                    </div>
                                )) : 
                                <div
                                style={{
                                    padding:'5px 10px'
                                }}
                                >
                                    <Typography
                                    sx={{
                                        color:'text.secondary',
                                        border:'hidden'
                                    }}
                                    >Sem resultados...</Typography>
                                </div>}
                            </Box>
                            </Popper>
                            
                        </Search>
                        </ClickAwayListener>
                        {/* <Search>
                            <SearchIconWrapper> <SearchIcon /> </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="Buscar produto…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={inputValue}
                            onChange={({target}) => setInputValue(target.value)}
                            />
                        </Search> */}
                        
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={({currentTarget}) => setMenu(currentTarget)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </div>}
            </Box>

            <Box 
            sx={{
                width:'100%',
                // height: isMenuVisible ? menuRef.current?.clientHeight : 0,
                height: menuRef.current?.clientHeight,
                transition: 'all 0.3s ease'
            }} >

            </Box>

            <Box 
            sx={{
                width:'100%',
                // height: isMenuVisible ? menuRef.current?.clientHeight : 0,
                height: menuRef.current?.clientHeight,
                transition: 'all 0.3s ease',
                position: 'fixed',
                top: -menuRef.current?.clientHeight,
                backgroundColor: color.primary,
                zIndex: 9999
            }} >

            </Box>

            <MenuContext.Provider value={menuRef}>
            {props.children}
            </MenuContext.Provider>
            
            <div
            style={{
                width:'100%',
                height:'70px',
                backgroundColor: color.background
            }}>

            </div>
        </Box>
    )
}