import { useContext, useState } from 'react'
import { AnalyticsContext, PreferencesContext } from "../../context"
import { 
    Box, 
    useTheme,
    FormControlLabel,
    Checkbox,
    Typography,
    Button
} from "@mui/material"
import { Header, Topic, SubTopic, Content } from './components'
import getColors from '../getColor'

export default function Privacy ( props) {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [analytics, setAnalytics] = useContext(AnalyticsContext)
    const [read, setRead] = useState(false)
    const theme = useTheme()
    const color = getColors(preferences, theme)
    
    return (    
        <Box
        sx={{
            display: 'flex',
            width: 'calc(100% - 20px)',
            margin:'0px',
            padding:'0px',
            flexDirection:"column",
            // filter:blur && 'blur(5px)',
            overflowY:'hidden',
            overflowX:'hidden',
            padding:'10px',
            alignItems:'center'
        }}
        >
            <div
            style={{
                maxWidth:'1000px',
            }}
            >
                <Header>Política de Privacidade</Header>

                <Content>
                    Esta Política de Privacidade detalha como a Allon coleta, utiliza e compartilha informações pessoais de usuários em relação aos nossos serviços.
                </Content>

                <Topic>1. Informações Coletadas</Topic>
                <SubTopic>1.1 Informações de Identificação Pessoal:</SubTopic>
                <Content>
                    Coletamos informações diretamente fornecidas por você, como nome, endereço de e-mail e outras formas de contato, ao se registrar ou utilizar nosso serviço.
                </Content>
                <SubTopic>1.2 Informações de Uso:</SubTopic>
                <Content>
                    Podemos coletar dados sobre a interação com nosso serviço, incluindo páginas acessadas, recursos utilizados e informações de desempenho.
                </Content>
                <Topic>2. Uso das Informações</Topic>
                <SubTopic>2.1 Finalidade:</SubTopic>
                <Content>
                    Utilizamos as informações coletadas para oferecer, manter, proteger e aprimorar nosso serviço, além de personalizar sua experiência.
                </Content>
                <SubTopic>
                    2.2 Compartilhamento de Dados:
                </SubTopic>
                <Content>
                    Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer o serviço ou conforme exigido por lei.
                </Content>
                <Topic>
                    3. Cookies e Tecnologias de Rastreamento
                </Topic>
                <Content>
                    Utilizamos cookies e tecnologias similares para aprimorar a experiência do usuário, analisar tendências e administrar o serviço. O controle sobre o uso de cookies está disponível nas configurações do seu navegador.
                </Content>
                <Topic>
                    4. Segurança de Dados
                </Topic>
                <Content>
                    Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.
                </Content>
                <Topic>
                    5. Acesso e Controle dos Usuários
                </Topic>
                <Content>
                    Você pode acessar, corrigir ou excluir suas informações pessoais ao entrar em sua conta.
                </Content>
                <Topic>
                    6. Retenção de Dados
                </Topic>
                <Content>
                    Retemos suas informações pelo tempo necessário para cumprir as finalidades descritas nesta política, a menos que um período de retenção mais longo seja necessário ou permitido por lei.
                </Content>
                <Topic>
                    7. Responsabilidade dos Dados
                </Topic>
                <Content>
                    Reservamos o direito de compartilhar diretamente com o contratante dos nossos serviços <span style={{fontWeight:'bold', color:color.fontPrimary}}>"{preferences.nome}"{preferences.cnpj ? ` (CNPJ ${preferences.cnpj})` : ( preferences.cpf ? ` (CPF ${preferences.cpf})` : '')}</span> as informações fornecidas, como nome, endereço de e-mail e outras formas de contato, além das coletadas sobre a interação com o serviço. Este contratante é responsável por proteger esses dados de acordo com as leis vigentes, evitando mau uso ou compartilhamento inadequado.
                </Content>
                <Topic>
                    8. Alterações na Política de Privacidade
                </Topic>
                <Content>
                    Reservamos o direito de atualizar esta política conforme necessário. Usuários serão notificados sobre alterações significativas por meio de avisos no serviço ou outros meios.
                </Content>
                <Topic>
                    9. Informações de Contato
                </Topic>
                <Content>
                    Para dúvidas sobre esta política de privacidade, entre em contato conosco em contato@allon.cloud.
                </Content>
                <Topic style={{marginTop:'10px'}}>Esta política é efetiva a partir de 06/11/2023 e permanecerá em vigor, exceto em caso de revisão.</Topic>

                <FormControlLabel control={
                <Checkbox 
                onChange={({target}) => setRead(target.checked)}
                />} label={
                    <Typography
                    sx={{ color:'text.primary' }}
                    >Li, Aceito e concordo...</Typography>
                } />

                <div
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center'
                }}
                >
                    <Button
                    size='small'
                    variant='contained'
                    disabled={!read}
                    onClick={() => {
                        setAnalytics({ ...analytics, privacy: true })
                        window.location.replace(analytics.terms ? '/' : '/readme/terms')
                    }}
                    >
                        {analytics.terms ? 'Continuar' : 'Termos de uso'}
                    </Button>
                </div>
            </div>
        </Box>
    )
}