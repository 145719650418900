import { useContext, useEffect, useRef, useState } from "react"
import { LoadingContext, PreferencesContext, SizeContext, UserContext } from "../../../context"

import {
    Typography,
    TextField,
    Button,
    FormControl,
    useTheme,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControlLabel,
    Switch,
    Modal,
    Paper,
    ButtonBase,
    Slide,
    Backdrop,
    IconButton,
    Divider
} from '@mui/material'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SecurityIcon from '@mui/icons-material/Security';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';

import { passwordStrength } from 'check-password-strength'
import InputMask from 'react-input-mask';
import { MobileDatePicker } from '@mui/x-date-pickers';
import axios from 'axios'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import getColors from "../../getColor";
import { useNavigate } from "react-router-dom";
import { api } from "../../../utils/defaults";
import PinInput from "react-pin-input";

const strength_level = [
    {
        label: 'Senha Muito Fraca',
        color: 'red'
    },
    {
        label: 'Senha Fraca',
        color: 'red'
    },
    {
        label: 'Senha Média',
        color: 'yellow'
    },
    {
        label: 'Senha Forte',
        color: 'green'
    }
]

const recomendationList = ( user ) => {
    const list = [
        {
            name: 'Adicionar Telefone 1',
            checked: user.telefone1 ? true : false
        },
        // {
        //     name: 'Verificar Telefone 1',
        //     checked: user.telefone1_verificado ? true : false
        // },
        {
            name: 'Adicionar Telefone 2',
            checked: user.telefone2 ? true : false
        },
        // {
        //     name: 'Verificar Telefone 2',
        //     checked: user.telefone2_verificado ? true : false
        // },
        // {
        //     name: 'Habilitar autenticação de 2 fatores',
        //     checked: user.duplofator_email || user.duplofator_telefone ? true : false
        // },
        {
            name: 'Habilitar autenticação inteligente',
            checked: user.compra_unificada ? true : false
        },
        {
            name: 'Preencher o sexo biológico',
            checked: user.sexo ? true : false
        },
        {
            name: 'Preencher a data de nascimento',
            checked: user.dtnascimento ? true : false
        }
    ]

    return list
}

export default function Index (props) {
    const [size, setSize] = useContext(SizeContext)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [cadaster, setCadaster] = useState(user)

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const navigate = useNavigate()
    const [state, setState] = useState({})

    const updatePasswd = () => {
        if (!cadaster.senha) return toast.error('senha não pode ser vazia')
        if (cadaster.senha && cadaster.senha !== cadaster.confirma) return toast.error('Senhas não conferem')
        if (cadaster.senha && passwordStrength(cadaster.senha).id !== 3) return toast.error('Crie uma senha forte')

        setLoading(true)
        axios
        .put(`${api}/client/password`,cadaster,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => toast.success('Sucesso'))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const updateIauth = () => {
        if (cadaster.compra_unificada && !cadaster.pin) return toast.error('Pin não pode ser vazio')
        setLoading(true)

        axios
        .put(`${api}/client/iauth`,cadaster,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Sucesso')
            update()
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const updateInfo = () => {
        setLoading(true)
        axios
        .put(`${api}/client`,{
            ...cadaster,
            senha: undefined
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Sucesso')
            setUser({...user, ...response.data})
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }   

    const unlock = async () => {
        if (!state.senha) return toast.error('Insira a senha')
        setLoading(true)
        axios
        .post(`${api}/client/reset/pin`,{
            senha: state.senha
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            toast.success('Sucesso')
            update()
            setState({})
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/client`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            setUser({
                ...user, 
                ...data, 
                exp: user.exp,
                iat: user.iat
            })
            setCadaster({...cadaster, compra_unificada: data.compra_unificada, ...data})
        })
        .catch(({response}) => toast.error(typeof response.data === 'string' ? response.data : response.data?.message))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        update()
    },[])

    const recomendation = recomendationList( user )
    const recomendContainer = useRef()

    return (
        <Box
        sx={{
            // bgcolor: color.background,
            minHeight:'calc(100vh - 50px)',
            display:'flex',
            width:'calc(100% - 50px)',
            padding:'25px',
            justifyContent:'center',
            flexWrap:'wrap',
            overflowX:'hidden'
        }}>
            {/* <div
            style={{
                width:'100%',
                top:0,
                left:0,
                backgroundColor: color.background,
                width:'100%',
                zIndex: 999
            }}>
                <Button
                startIcon={<ArrowBackIosNewIcon />}
                sx={{
                    marginRight:'10px',
                    margin:'10px 0px 0px 20px',
                    paddingLeft:'0px',
                    paddingBottom:'0px'
                }}
                onClick={() => navigate('/')}
                >voltar</Button>
            </div> */}

            <Modal
            open={state.update_passwd}
            onClose={() => setState({...state, update_passwd: false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                    maxHeight:'80vh',
                    overflowY:'auto',
                    width:'100%'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'5px'
                    }}
                    >
                        <Button
                        color='error'
                        variant='outlined'
                        size='small'
                        startIcon={<CloseIcon />}
                        onClick={() => setState({...state, update_passwd: false})}
                        ></Button>

                        <Button
                        color='success'
                        variant='contained'
                        size='small'
                        startIcon={<SaveIcon />}
                        onClick={updatePasswd}
                        ></Button>
                    </div>

                    <TextField 
                    value={cadaster.senha}
                    onChange={({target}) => setCadaster({...cadaster, senha: target.value})}
                    label='Senha'
                    type='password'
                    size='small'
                    helperText={
                        cadaster.senha ? <Typography sx={{
                            color: strength_level[passwordStrength(cadaster.senha).id].color
                        }}>{strength_level[passwordStrength(cadaster.senha).id].label}</Typography> :
                        'Nova senha'
                    }
                    sx={{ margin:'5px 0px', width:'100%' }}
                    />
                    <TextField 
                    value={cadaster.confirma}
                    onChange={({target}) => setCadaster({...cadaster, confirma: target.value})}
                    label='confirmar Senha'
                    type='password'
                    size='small'
                    helperText={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma ? 'Senhas não conferem' : ''}
                    error={cadaster.senha && cadaster.confirma && cadaster.senha !== cadaster.confirma}
                    sx={{ margin:'5px 0px', width:'100%' }}
                    />
                </div>
            </Modal>

            <Modal
            open={state.helpiauth}
            onClose={() => setState({...state, helpiauth: false})}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    flexDirection:"column",
                    maxWidth:size.width <= 500 ? 'calc(100vw - 70px)' : '500px',
                    maxHeight:'80vh',
                    overflowY:'auto'
                }}
                >
                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'18px'
                    }}
                    >
                        O que é a autenticação inteligente ?
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Com a autenticação inteligente é possível acessar seus dados de QUALQUER lugar utilizando seu CPF e seu PIN.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">Como vou usar ?</Divider>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Em compras físicas, é possível comprar usando seus cartões ( crédito / débito ) e benefícios ( convênios etc ) cadastrados na nuvem, 
                        basta inserir seu CPF e seu PIN na finalização da compra em qualquer loja física parceira.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        É possível consultar saldos e compras.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        ... E qualquer outro recurso futuro que não solicite E-mail e senha.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">É seguro ?</Divider>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        Serão listados somente os cartões e benefícios que forem cadastrados na conta.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        A autenticação inteligente tem um limite de 3 tentativas, após isso será bloqueado, enviados avisos ao responsável da conta (Whatsapp e E-mail), e somente 
                        o responsável poderá desbloquear através do E-commerce > Menu > Minha conta.
                    </Typography>

                    <Typography
                    sx={{
                        color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    >
                        O responsável poderá alterar o PIN a qualquer momento através do E-commerce > Menu > Minha conta.
                    </Typography>

                    <Divider sx={{ color:'text.secondary', margin:'10px 0px'}} textAlign="left">Aviso</Divider>

                    <Typography
                    sx={{
                        // color:'text.primary',
                        fontSize:'15px',
                        marginTop:'10px'
                    }}
                    color='error'
                    >
                        Para fazer compras usando benefícios, convênios, cartões virtuais etc, por canais fora da loja virtual ( Totens, caixas etc ), a autenticação inteligente 
                        deverá estar ativada
                    </Typography>

                    <div
                    style={{
                        marginTop:'10px',
                        display:"flex",
                        justifyContent:'center'
                    }}>
                        <Button
                        color='success'
                        // sx={{
                        //     width:'calc(50% - 5px)'
                        // }}
                        // variant='contained'
                        onClick={() => setState({...state, helpiauth: false})}
                        >
                            entendi
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
            open={state.open}
            sx={{
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
                    padding:'20px',
                    borderRadius: '5px'
                }}
                >
                    <TextField 
                    type={'password'}
                    sx={{
                        marginBottom:'10px'
                    }}
                    placeholder='Sua senha'
                    onChange={({target}) => setState({...state, senha: target.value})}
                    />

                    <Button
                    variant='outlined'
                    color='success'
                    fullWidth
                    onClick={unlock}
                    >Desbloquear</Button>
                </div>
            </Modal>

            <div
            ref={recomendContainer}
            style={{
                position:'absolute',
                top:state.recomend ? 50 : 0,
                left:0,
                width:'calc(100% - 50px)',
                padding:'25px',
                zIndex: state.recomend ? 999 : 0,
                transition: !state.recomend ? 'all 2s linear' : ''
            }}
            >
                <Slide 
                direction="down" 
                in={state.recomend} 
                mountOnEnter 
                unmountOnExit
                container={recomendContainer.current}
                >
                    
                    <Paper
                    sx={{
                        padding:'10px 20px',
                        borderRadius:'5px',
                        bgcolor: theme.palette.mode === 'light' ? '#e8e8e8' : '#121212',
                        display:'flex',
                        flexDirection:'column',
                        marginTop:'10px'
                    }}
                    >
                    <div
                    style={{
                        width:'100%',
                        justifyContent:'right',
                        display:'flex'
                    }}
                    >
                        <Button
                        startIcon={<CloseIcon />}
                        // variant='outlined'
                        size='small'
                        color="error"
                        onClick={() => setState({ ...state, recomend: false })}
                        >
                            fechar
                        </Button>
                    </div>
                    {recomendation.map(e => (
                        <div
                        style={{
                            padding:'10px',
                            borderRadius:'5px',
                            // bgcolor: theme.palette.mode === 'light' && '#e8e8e8',
                            display:'flex',
                            alignItems:'center',
                            width:'100%'
                        }}
                        >
                            {!e.checked? 
                            <ReportIcon color='error' /> : 
                            <CheckCircleIcon color='success' />
                            }
                            <Typography 
                            sx={{
                                marginLeft:'10px', 
                                fontSize:'17px', 
                                fontWeight:'bold'
                            }} 
                            color={ !e.checked ? 'error' : 'success'}>
                                {e.name}
                            </Typography>
                        </div>
                    ))}
                    </Paper>
                    
                </Slide>
                
            </div>

            <div
            onClick={() => {
                if ( state.recomend ) setState({ ...state, recomend: false })
            }}
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                // alignItems:'center',
                maxWidth:'500px',
                width:'100%',
                // transition: 'all 1s linear',
                filter: state.recomend && 'blur(5px)'
            }}
            >
                <ButtonBase
                onClick={() => setState({ ...state, recomend: true })}
                sx={{ m: 0, p: 0 }}
                >
                    <Paper
                    sx={{
                        padding:'10px 20px',
                        borderRadius:'5px',
                        bgcolor: theme.palette.mode === 'light' ? '#e8e8e8' : '#121212',
                        display:'flex',
                        alignItems:'center',
                        width:'100%'
                    }}
                    >
                        {recomendation.find(({checked}) => !checked) ? 
                        <ReportIcon color='error' /> : 
                        <CheckCircleIcon color='success' />
                        }
                        <Typography 
                        sx={{
                            marginLeft:'10px', 
                            fontSize:'17px', 
                            fontWeight:'bold'
                        }} 
                        color={ recomendation.find(({checked}) => !checked) ? 'error' : 'success'}>
                            {recomendation.find(({checked}) => !checked) ? `Você tem ${recomendation.filter(({checked}) => !checked).length} recomendações` : `Tudo certo com sua conta`}
                        </Typography>
                    </Paper>
                </ButtonBase>

                

                {/* <Paper
                sx={{
                    padding:'10px 20px',
                    borderRadius:'5px',
                    bgcolor: theme.palette.mode === 'light' ? '#e8e8e8' : '#121212',
                    display:'flex',
                    flexDirection:'column',
                    marginTop:'10px'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <Typography
                        sx={{
                            fontWeight:'bold',
                            fontSize:'17px'
                        }}
                        >
                            Autenticação de dois Fatores
                        </Typography>
                        <Button
                        color='success'
                        variant='contained'
                        size='small'
                        startIcon={<SaveIcon />}
                        ></Button>
                    </div>
                    <FormControlLabel control={<Switch defaultChecked />} label="E-mail" />
                    <FormControlLabel control={<Switch defaultChecked />} label="Telefone" />
                </Paper> */}

                <Paper
                sx={{
                    padding:'10px 20px',
                    borderRadius:'5px',
                    bgcolor: theme.palette.mode === 'light' && '#e8e8e8',
                    display:'flex',
                    flexDirection:'column',
                    marginTop:'10px'
                }}
                >
                    
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'5px',
                        alignItems:'center'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            alignItems:'center'
                        }}
                        >
                            <Typography
                            sx={{
                                fontWeight:'bold',
                                fontSize:'17px'
                            }}
                            >
                                Autenticação inteligente
                            </Typography>

                            <IconButton
                            onClick={() => setState({...state, helpiauth: true})}
                            >
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                        <Button
                        color='success'
                        variant='contained'
                        size='small'
                        startIcon={<SaveIcon />}
                        sx={{height:'26px'}}
                        onClick={updateIauth}
                        ></Button>
                    </div>

                    {cadaster.compra_unificada_bloqueada ? <></> : 
                    <PinInput
                    length={5}
                    focus
                    secret={true}
                    type="numeric"
                    onChange={data => setCadaster({...cadaster, pin: data})}
                    inputStyle={{color:'#fff'}}
                    />}

                    {cadaster.compra_unificada_bloqueada ? 
                    <Button
                    startIcon={<SecurityIcon />}
                    onClick={() => setState({...state, open: true})}
                    >
                        Desbloquear autenticação inteligente
                    </Button> :
                    <FormControlLabel 
                    sx={{color:'text.primary'}}
                    control={<Switch 
                    checked={cadaster.compra_unificada}
                    defaultChecked={user.compra_unificada}
                    onChange={({target}) => setCadaster({...cadaster, compra_unificada: target.checked})}
                    />} label="Autenticação inteligente habilitada" /> 
                    }
                </Paper>

                <Paper
                sx={{
                    padding:'10px 20px',
                    borderRadius:'5px',
                    bgcolor: theme.palette.mode === 'light' && '#e8e8e8',
                    display:'flex',
                    flexDirection:'column',
                    marginTop:'10px'
                }}
                >
                    
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'5px'
                    }}
                    >
                        <Typography
                        sx={{
                            fontWeight:'bold',
                            fontSize:'17px'
                        }}
                        >
                            Informações pessoais
                        </Typography>
                        <Button
                        color='success'
                        variant='contained'
                        size='small'
                        startIcon={<SaveIcon />}
                        onClick={updateInfo}
                        ></Button>
                    </div>

                    <TextField 
                    value={cadaster.nome}
                    onChange={({target}) => setCadaster({...cadaster, nome: target.value})}
                    label='Nome'
                    size='small'
                    sx={{ margin:'5px 0px' }}
                    />

                    <TextField 
                    value={cadaster.email}
                    onChange={({target}) => setCadaster({...cadaster, email: target.value})}
                    label='E-mail'
                    size='small'
                    sx={{ margin:'5px 0px' }}
                    disabled={cadaster.email_verificado}
                    />
                    
                    <InputMask 
                    mask="999.999.999-99" 
                    maskChar={null}
                    value={cadaster.cpf}
                    onChange={({target}) => setCadaster({...cadaster, cpf:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        inputProps={{
                                            id:'cliente_cpf_ref'
                                        }}
                                        InputLabelProps={{shrink:cadaster.cpf?true:false}}
                                        label='CPF'
                                        onKeyDown={(event) => {
                                        }}
                                        sx={{ margin:'5px 0px' }}
                                        size='small'
                                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={cadaster.telefone1}
                    onChange={({target}) => setCadaster({...cadaster, telefone1:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        InputLabelProps={{shrink:cadaster.telefone1?true:false}}
                                        label='Tel 1 (WhatsApp)'
                                        onKeyDown={(event) => {
                                        }}
                                        sx={{ margin:'5px 0px' }}
                                        helperText='(21) 999999999'
                                        size='small'
                                        />}
                    </InputMask>

                    <InputMask 
                    mask="(99) 9999999999" 
                    maskChar={null}
                    value={cadaster.telefone2}
                    onChange={({target}) => setCadaster({...cadaster, telefone2:target.value})}
                    >
                        {(inputProps) => <TextField 
                                        {...inputProps}
                                        InputLabelProps={{shrink:cadaster.telefone2?true:false}}
                                        label='Tel 2 (WhatsApp)'
                                        onKeyDown={(event) => {
                                        }}
                                        sx={{ margin:'5px 0px' }}
                                        helperText='(21) 999999999'
                                        size='small'
                                        />}
                    </InputMask>

                    <FormControl
                    sx={{ margin:'5px 0px' }}
                    size='small'
                    >
                        <MobileDatePicker
                        label="Data de nascimento"
                        views={['day','month','year']}
                        value={dayjs(cadaster.dtnascimento)}
                        size='small'
                        onChange={(newValue) => setCadaster({...cadaster, dtnascimento: newValue.toISOString()})}
                        renderInput={(params) => (
                            <TextField 
                            label="Data de nascimento" 
                            variant="outlined" 
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            {...params}
                            />
                        )}
                        />
                    </FormControl>

                    <FormControl
                    sx={{ margin:'5px 0px' }}>
                        <InputLabel>sexo biológico</InputLabel>
                        <Select
                        onChange={({target}) => setCadaster({...cadaster, sexo: target.value})}
                        value={cadaster.sexo}
                        size='small'
                        >
                            {[
                                {value:'O',name:'Prefiro não dizer'},
                                {value:'M',name:'Masculino'},
                                {value:'F',name:'Feminino'}
                            ]?.map(e => <MenuItem value={e.value}>{e.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    
                    <Button
                    color='error'
                    fullWidth
                    sx={{ marginTop:'20px' }}
                    onClick={() => setState({...state, update_passwd: true})}
                    >
                        alterar senha
                    </Button>
                    
                </Paper>

                <Paper
                sx={{
                    padding:'10px 20px',
                    borderRadius:'5px',
                    bgcolor: theme.palette.mode === 'light' && '#e8e8e8',
                    display:'flex',
                    flexDirection:'column',
                    marginTop:'20px'
                }}
                >
                    <Button
                    color='error'
                    fullWidth
                    // sx={{ fontSize: '11px' }}
                    disabled
                    startIcon={<WarningIcon />}
                    >
                        excluir conta
                    </Button>
                </Paper>
            </div>

        </Box>
    )
}