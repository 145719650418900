import axios from "axios"

const address = async ( cep, callback=()=>{} ) => {
    try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json`)
        callback(undefined, {
            rua: data.logradouro,
            complemento: data.complemento,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            cep
        })
        return {
            err: undefined, 
            out: {
                rua: data.logradouro,
                complemento: data.complemento,
                bairro: data.bairro,
                cidade: data.localidade,
                estado: data.uf,
                cep
            }
        }
    } catch (err) {
        callback(err, undefined)
        return {
            err,
            out: undefined
        }
    }
}

export default address