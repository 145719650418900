import React, { forwardRef, useContext, useRef, useState } from "react";
import { api } from "../../utils/defaults";
import axios from "axios";
import { toast } from "react-toastify";
import { PreferencesContext, UserContext } from "../../context";
import 'react-credit-cards-2/dist/es/styles-compiled.css';

import Credit_card from "./credit_card";
import Other from "./other";
import Debit_card from "./debit_card";

import { useTheme } from '@mui/material/styles'
import getColors from "../getColor";
import { MercadoPagoSettingsContext } from './context'
import Alelo from "./alelo";
import Pix from "./pix";
import Boleto from "./boleto";
import { useSearchParams } from "react-router-dom";
import SavedCard from "./savedCard";

const Type = {
    credit_card: Credit_card,
    debit_card: Debit_card,
    other: Other,
    alelo: Alelo,
    pix: Pix,
    boleto: Boleto
}

const Index = forwardRef((props, ref) => {
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [user, setUser] = useContext(UserContext)
    const [loadingCheckout, setLoadingCheckout] = useState(false)
    const [methods, setMethods] = useState([])
    const [paymentParams, setPaymentParams] = useState({})
    const [opened, setOpened] = useState(null)

    const theme = useTheme()
    const color = getColors(preferences, theme)
    const [query] = useSearchParams();

    const mercadoPagoSettings = {
        initialization: {
            amount: paymentParams.amount,
            payer: {
                email: user.email,
            },
            installments: preferences.qtdmaximaparcelas
        },
        customization: {
            maxInstallments: preferences.qtdmaximaparcelas,
            paymentMethods: {},
            visual: {
                hideFormTitle: true,
                style: {
                        theme: theme.palette.mode === 'light' ? 'default' : 'dark',
                        customVariables: {
                            "inputBackgroundColor": "rgba(0,0,0,0)",
                            "formBackgroundColor": "rgba(0,0,0,0)",
                            "baseColor": color.fontPrimary,//"rgba(0,0,0,0)",
                            "baseColorFirstVariant": color.fontPrimary,//"rgba(0,0,0,0)",
                            "baseColorSecondVariant": color.primary,
                            "buttonTextColor": theme.palette.mode === 'dark' ? "#fff" : '#000',
                        }
                    }
            }
        },
        callbacks: {
            onReady: () => {
                if ( typeof paymentParams.onReady === 'function' ) paymentParams.onReady()
            }
        }
    };

    const init = (
        params = {
            onReady: () => {},
            onError: () => {},
            onPay: () => {},
            onUnmount: () => {},
            enableSaveCard: true,
            amount: 0
        }
    ) => {
        setLoadingCheckout(true)
        axios
        .get(query.get('store_code') ? `${api}/payment/method?store_code=${query.get('store_code')}` : `${api}/payment/method`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            if ( params.card?.idclientecartao && !data.methods.find(({idformapagto}) => idformapagto === params.card?.idformapagto) ) {
                toast.error('Forma de pagamento inativa')
                if (typeof params.onError) params.onError('Forma de pagamento inativa')
                return
            }

            setPaymentParams({ ...params, ...data, card: params.card })
            setMethods(data.methods || [])
            if (typeof params.onReady) params.onReady({ ...params, ...data, card: params.card })
        })
        .catch(({response}) => {
            toast.error(typeof response?.data === 'string' ? response?.data : response?.data?.message)
            if (typeof params.onError) params.onError(response?.data)
        })
        .finally(() => setLoadingCheckout(false))
    }

    React.useImperativeHandle(ref, () => ({ 
        init,
        unmount: () => {
            setMethods([])
            setOpened(null)
            setPaymentParams({})
            if (typeof paymentParams.onUnmount === 'function') paymentParams.onUnmount()
        }
        // unmount: unmount
    }))

    return (
        <MercadoPagoSettingsContext.Provider value={mercadoPagoSettings}>
        <div
        style={{
            width:'100%',
            paddingTop:'10px',
            paddingBottom:'10px',
            ...props.style
        }}
        >
            {paymentParams.card?.idclientecartao ? 
            <SavedCard 
            {...props.ComponentProps}
            card={paymentParams.card}
            paymentParams={paymentParams} 
            /> :
            methods.sort((a,b) => {
                if (a.apelido > b.apelido) return 1
                if (a.apelido < b.apelido) return -1
                return 0
            }).map((e,index) => {
                if ( e.conector_type === null ) e.conector_type = 'other'
                if ( !Type[e.conector_type] ) return

                const TypeComponent = Type[e.conector_type]

                return (
                    <TypeComponent 
                    {...props.ComponentProps}
                    typeParams={e} 
                    paymentParams={paymentParams} 
                    opened={opened===index}
                    onOpen={() => setOpened(index)}
                    onClose={() => setOpened(null)}
                    />
                )
            })}
        </div>
        </MercadoPagoSettingsContext.Provider>
    )
})

export default Index;