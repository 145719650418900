function validarCartao(numero, nome, validade, cvc) {
    const regexNumero = /^[0-9]{4}[\s-]?[0-9]{4}[\s-]?[0-9]{4}[\s-]?[0-9]{4}$/;
    const regexNome = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    const regexValidade = /^(0[1-9]|1[0-2])\/[2-9][0-9]$/;
    const regexCVC = /^[0-9]{3}$/;

    const numeroValido = regexNumero.test(numero);
    const nomeValido = regexNome.test(nome);
    const validadeValida = regexValidade.test(validade);
    const cvcValido = regexCVC.test(cvc);

    return numeroValido && nomeValido && validadeValida && cvcValido;
}

module.exports = {
    validarCartao
}