import { CircularProgress, ImageList, useTheme } from "@mui/material"
import { useContext, useState } from "react"
import { useEffect } from "react"
import { PreferencesContext, SizeContext } from "../../../context"
import getColors from "../../getColor"

export default function InfinityScroll (props) {
    const [lock, setLock] = useState(false)
    const [page, setPage] = useState(props.page)
    const [stop, setStop] = useState(props.stop)
    const [length, setLength] = useState(props.length)
    const [preferences, setPreferences] = useContext(PreferencesContext)
    const [size, setSize] = useContext(SizeContext)

    const onScroll = (event) => {
        let target = event.target.documentElement
        
        if (lock && target.scrollHeight - 100 > lock.scrollHeight) {
            return setLock(false)
        }
        if ((target.scrollHeight - target.scrollTop) % 3 === 0 && target.scrollHeight - target.scrollTop < target.clientHeight + 1500 && !lock && !stop) {

            setLock({
                scrollHeight:target.scrollHeight,
                length
            })
            props.next(page)
            setPage(page ? page + 1 : page)
        }
    }

    useEffect(() => setStop(props.stop), [props.stop])
    useEffect(() => setLength(props.length), [props.length])
    useEffect(() => setPage(props.page), [props.page])

    const theme = useTheme()
    const color = getColors(preferences, theme)

    useEffect(() => {
        
        document.addEventListener('scroll', onScroll)

        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    },[
        lock?.scrollHeight,
        page,
        size,
        length
    ])

    return (
        <>
        {props.children}
        {/* {!stop && lock && lock.length === length ?
        <div
        style={{
            display:'flex',
            width:'100%',
            justifyContent:'center',
            backgroundColor: color.background,
            padding:'20px 0px 80px 0px'
        }}>
                <CircularProgress />
        </div> : <></>} */}
        
        {/* <ImageList
        onScroll={onScroll}
        cols={parseInt(size.width / (size.width > 950 ? 300 : 180))}
        sx={{ 
            width: '100%', 
            height: '100%',
            overflowX:'hidden',
            margin:0,
            justifyContent:'space-around'
        }}>
            {props.children}
            {!stop && lock && lock.length === length && <div
            style={{
                display:'flex',
                margin:'10px 0px',
                width:'100%',
                justifyContent:'center'
            }}>
                <CircularProgress />
            </div>}
            <div
            style={{
                width:'100%',
                height:'100px'
            }}>

            </div>
        </ImageList> */}
        </>
    )
}