import React, { forwardRef, useContext, useEffect, useState } from "react";
import { LoadingContext, SizeContext } from "../../../../../context";
import axios from "axios";
import { api } from "../../../../../utils/defaults";
import { toast } from "react-toastify";
import { Button, Modal, Typography, useTheme } from "@mui/material";
import Card from "./card";
import CloseIcon from '@mui/icons-material/Close';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const ScanProduct = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [state, setState] = useState({ openProduct: false, openScanner: false })
    const [stopStream, setStopStream] = useState(false)

    const find = ( ean, callback = undefined ) => {
        const controller = new AbortController()

        setLoading(true)
        axios //7896011102542
        .get(`${api}/product/${ean}`,{
            signal: controller.signal
        })
        .then(response => {
            if ( !response.data.length ) {
                if ( callback ) return callback({ notFind: true })
                toast.error('Não encontrado')
                return 
            }
            if ( callback ) return callback(response.data)
            setState({ ...response.data[0], openProduct: true, openScanner: true })
        })
        .catch(err => {
            if ( callback ) return callback(err)
            toast.error(err?.response?.data?.message || err?.response?.data)
        })

        return controller
    }

    React.useImperativeHandle(ref, () => ({
        find: find,
        open: () => {
            setState({ openProduct: false, openScanner: true })
            setTimeout(() => setStopStream(false), 100);
        }
    }))

    const theme = useTheme()

    return (
        <>
            <Modal
            open={state.openScanner}
            onClose={() => {
                setStopStream(true)
                setTimeout(() => setState({ openProduct: false, openScanner: false }), 100)
            }}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}>
                <div
                style={{
                    padding:'10px',
                    borderRadius: '10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'right'
                    }}
                    >
                        <Button
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            setStopStream(true)
                            setTimeout(() => setState({ openProduct: false, openScanner: false }), 100)
                        }}
                        color='error'
                        >
                            fechar
                        </Button>
                    </div>
                    <BarcodeScannerComponent
                    width={size.width <= 500 ? size.width - 40 : 500}
                    height={300}
                    stopStream={stopStream}
                    onUpdate={(err, result) => {
                        if (result) find(result.text)
                    }}
                    />
                    <Typography
                    sx={{
                        color:'text.secondary',
                        textAlign:'center'
                    }}
                    >APONTE O CÓDIGO DE BARRAS</Typography>
                </div>
            </Modal>

            <Modal
            open={state.openProduct}
            onClose={() => setState({ ...state, openProduct: false })}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}
            >
                <Card {...state} close={( all = true ) => {
                    if ( all ) {
                        setStopStream(true)
                        setTimeout(() => setState({ openProduct: false, openScanner: false }), 100)
                    }
                    setState({ ...state, openProduct: false })
                }} />
            </Modal>
        </>
    )
})

export default ScanProduct